import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { Typography, Collapse, Box, BoxProps } from '@material-ui/core'

import {
  User,
  MenuItem,
  UserProfile,
  RootMenuItem,
} from 'src/generated/graphql'
import {
  TitleText,
  SubMenuContainer,
  profileLinkStyle,
  StyledIllustration,
} from 'src/components/navigation/common'
import useMediaQueries from 'src/hooks/useMediaQueries'
import { SubMenu } from 'src/components/navigation/SubMenu'
import ProfilePhoto from 'src/components/dataDisplay/ProfilePhoto'
import { LinkTypeIcon } from 'src/components/dataDisplay/Illustration'
import NavItemSkeleton from 'src/components/navigation/NavItemSkeleton'
import useNavigation from 'src/hooks/useNavigation'
import { isClubProfile, isTeachingProfile } from 'src/utils/typeGuards'
import { useTranslation } from 'react-i18next'

export const ProfileContainer = styled.div<{
  $isActive?: boolean
  $isSmallNav?: boolean
}>(
  ({ theme, $isActive, $isSmallNav }) => css`
    width: 100%;
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 86px;
    position: relative;

    background-color: ${$isActive
      ? theme.palette.background.dark
      : theme.palette.brand.black};

    ${$isSmallNav &&
    css`
      padding: ${theme.spacing(0, 3.625, 0, 1.8)};
    `}

    &:hover {
      cursor: pointer;
    }
  `
)

const UserName = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.primary.contrastText};
    font-size: ${theme.typography.pxToRem(12)};
    line-height: 160%;
    flex: 1;
    padding-left: ${theme.spacing(2.625)}px;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    letter-spacing: 0.139em;
  `
)

export const Wrapper = styled(Box)<{ width?: number }>(
  ({ width }) => css`
    margin-top: auto;
    width: ${width ? `${width}px` : '100%'};
  `
)

const ProfileSubMenuContainer = styled(SubMenuContainer)(
  ({ theme }) => css`
    background-color: transparent;
    padding-left: ${theme.spacing(11.25)}px;
  `
)

export interface ProfileProps extends BoxProps {
  user?: User
  onSelect?: any
  loading?: boolean
  isActive?: boolean
  showName?: boolean
  selectedId?: string
  renderMenu?: boolean
  profile?: UserProfile
  showProfileLink?: boolean
  onHover?: (value: any) => void
}

export const Profile: React.FC<ProfileProps> = ({
  user,
  onHover,
  loading,
  profile,
  isActive,
  showName,
  onSelect,
  selectedId,
  renderMenu,
  showProfileLink,
  ...props
}) => {
  const { t } = useTranslation()
  const {
    data: { userMenu },
  } = useNavigation()
  const { isSmallScreen } = useMediaQueries()
  const isInstructor = profile && isTeachingProfile(profile)

  const data: RootMenuItem = useMemo((): RootMenuItem => {
    const items: MenuItem[] | undefined | null = userMenu
      ? !showProfileLink
        ? [
            ...(userMenu.items || []).slice(0, 2),
            {
              id: userMenu.id,
              label: userMenu.label,
              url: userMenu.url!,
              analyticsEvent: userMenu.analyticsEvent,
            },
            ...(userMenu.items || []).slice(2),
          ]
        : userMenu.items
      : []
    return { ...(userMenu as RootMenuItem), items }
  }, [userMenu, showProfileLink])

  const handleMouseEnter = (event: React.MouseEvent<Element>) => {
    if (onHover) {
      onHover(event)
    }
  }

  const handleMobileProfileSelect = () => {
    onSelect?.(userMenu)
  }

  const name =
    profile && isClubProfile(profile)
      ? profile.name
      : t('Global.fullName', {
          firstName: user?.firstName,
          lastName: user?.lastName,
        })

  return (
    <>
      {loading ? (
        <NavItemSkeleton hasRectangle={isSmallScreen} />
      ) : (
        <Wrapper {...props}>
          <ProfileContainer
            $isSmallNav={isSmallScreen}
            onMouseEnter={handleMouseEnter}
            data-testid="profilePhoto"
            $isActive={isActive || userMenu?.id === selectedId}
            onClick={handleMobileProfileSelect}
          >
            {profile && user && (
              <ProfilePhoto
                user={user}
                profile={profile}
                size={48}
                dotSize={10}
                isSmall
                withAvatar={isInstructor}
              />
            )}
            {showName && user && <UserName>{name}</UserName>}
            <StyledIllustration
              $isActive={isSmallScreen}
              type={LinkTypeIcon.ChevronDown}
              $isRotated={userMenu?.id === selectedId}
            />
          </ProfileContainer>
          {renderMenu && (
            <Collapse appear in={userMenu?.id === selectedId}>
              <ProfileSubMenuContainer component="ul">
                <SubMenu data={data} disableTitle onSelect={onSelect} />
              </ProfileSubMenuContainer>
            </Collapse>
          )}
        </Wrapper>
      )}
    </>
  )
}

const ViewProfileLink = styled(Link)`
  ${profileLinkStyle}
`

interface ProfileMenuItemProps extends BoxProps {
  user: User
  profile: UserProfile
  disableProfileLink?: boolean
  profileMenuData: RootMenuItem
  onProfileLinkClick?: (event: React.MouseEvent) => void
}

export const ProfileMenuItem: React.FC<ProfileMenuItemProps> = ({
  user,
  profile,
  profileMenuData,
  disableProfileLink,
  onProfileLinkClick,
  ...props
}) => {
  const { label, url } = profileMenuData
  const isInstructor = profile && isTeachingProfile(profile)

  return (
    <Box display="flex" alignItems="center" {...props}>
      <ProfilePhoto
        user={user}
        profile={profile}
        size={48}
        dotSize={10}
        isSmall
        withAvatar={isInstructor}
      />
      <Box display="flex" flexDirection="column" flex={1} pl={3}>
        <TitleText variant="h3">{`${user.firstName} ${user.lastName}`}</TitleText>
        {!disableProfileLink && url && (
          <ViewProfileLink to={url.value} onClick={onProfileLinkClick}>
            {label}
          </ViewProfileLink>
        )}
      </Box>
    </Box>
  )
}
