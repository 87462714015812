import styled, { css } from 'styled-components'
import { Button, CardContent, Divider, Card } from '@material-ui/core'
import LoaderButton from 'src/components/inputs/LoaderButton'

export const StyledCard = styled(Card)`
  width: 100%;
  max-width: 738px;
  z-index: 1;
`

export const StyledCardContent = styled(CardContent)`
  max-height: 560px;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(5)}px;
`

export const StyledDivider = styled(Divider)(
  ({ theme }) => css`
    margin: ${theme.spacing(4, 0, 5)};
  `
)

export const AgreementContent = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(3)}px;
  `
)

export const StyledButton = styled(Button)(
  ({ theme }) => css`
    padding: ${theme.spacing(1.75, 2)};
  `
)

export const ActionButton = styled(LoaderButton)(
  ({ theme }) => css`
    padding: ${theme.spacing(1.75, 2)};
    min-width: 80px;
  `
)
