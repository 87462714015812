import React, { useEffect, useRef, useState, WheelEvent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css, keyframes } from 'styled-components'

import CloseIcon from '@material-ui/icons/Close'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Box, BoxProps, Typography, IconButton } from '@material-ui/core'
import NotificationItem from 'src/components/notifications/NotificationItem'
import { NotificationItemFragment as Notification } from 'src/generated/graphql-react-query'
import Illustration, { ListIcon } from 'src/components/dataDisplay/Illustration'
import Button from 'src/components/button/Button'
import { allowNewline } from 'src/utils/helpers'

const ListHeader = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(3, 2)};
  `
)

const ListFooter = styled(Box)(
  ({ theme }) => css`
    text-align: center;
    color: ${theme.palette.brand.darkGray};
    font-size: ${theme.typography.pxToRem(10)};
    flex: 0 0 auto;
    font-weight: normal;
  `
)

const ListTitle = styled(Typography)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: ${theme.typography.pxToRem(22)};
    font-weight: normal;
  `
)

const TotalNewNotificationNumber = styled.span`
  font-weight: bold;
`

const ListContainer = styled(Box)<{ $translateY?: number }>(
  () => css`
    flex: 0 1 auto;
    overflow: auto;
    transition: transform 0.1s;
    overscroll-behavior: contain;
    word-break: break-word;
    position: relative;
  `
)
const TranslateWrapper = styled(Box)<{ $translateY?: number }>(
  ({ $translateY }) => css`
    transition: transform 0.1s;
    transform: translateY(${($translateY || 0) * 4}px);
    word-break: break-word;
  `
)
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const LoaderWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing(3.25, 0, 8.75, 0)};
    border-top: 1px solid ${theme.palette.brand.gray};
  `
)

const TopLoaderWrapper = styled(LoaderWrapper)(
  () => css`
    width: 100%;
    position: absolute;
  `
)

const Loader = styled(Illustration)`
  animation: ${rotate} 2s linear infinite;
`

const CloseButton = styled(IconButton)`
  margin-left: auto;
`

const STRETCH_THRESHOLD = 12

const calculateTranslate = (deltaY: number) => {
  const isScrollingUp = deltaY > 0
  const translate = isScrollingUp
    ? deltaY > STRETCH_THRESHOLD
      ? -1 * STRETCH_THRESHOLD
      : -1 * deltaY
    : Math.abs(deltaY) > STRETCH_THRESHOLD
    ? STRETCH_THRESHOLD
    : 1 * deltaY
  return translate
}

export interface NotificationListProps extends BoxProps {
  isLoading: boolean
  isError: boolean
  fullscreen?: boolean
  totalNewItems: number
  items?: Notification[]
  onCloseRequest: () => void
  onRequestLoadNewNotifications?: () => void
  onRequestLoadPreviousNotifications?: () => void
  isFetchingNextPage: boolean
  isFetchingPreviousPage: boolean
  onRetry: () => void
}
const NotificationsList: React.FC<NotificationListProps> = ({
  items,
  isLoading,
  isError,
  fullscreen,
  totalNewItems,
  onCloseRequest,
  onRequestLoadNewNotifications,
  onRequestLoadPreviousNotifications,
  isFetchingNextPage,
  isFetchingPreviousPage,
  onRetry,
  ...props
}) => {
  const [stretch, setStretch] = useState(0)
  const isFetching = isFetchingPreviousPage || isFetchingNextPage
  const [isRetrying, setIsRetrying] = useState(false)
  const listRef = useRef<HTMLElement>()
  const [listOffsetHeight, setListOffsetHeight] = useState(0)
  const { t } = useTranslation()
  useEffect(() => {
    setListOffsetHeight(listRef.current?.offsetHeight || 0)
  }, [listRef.current?.offsetHeight])

  // desktop/wheel scroll handler
  const handleOnWheel = (event: WheelEvent<HTMLElement>) => {
    const { offsetHeight, scrollTop } = event.currentTarget
    const deltaY = event.deltaY
    handleGetNotifications(scrollTop, offsetHeight, deltaY)
  }

  // mobile/touch scroll handler
  const [touchStartY, setTouchStartY] = useState(0)
  const handleTouchStart: React.TouchEventHandler<HTMLElement> = e => {
    setTouchStartY(e.touches[0].pageY)
  }

  const handleTouchMove: React.TouchEventHandler<HTMLElement> = event => {
    const { offsetHeight, scrollTop } = event.currentTarget
    const touchCurrentY = event.touches[0].pageY
    const deltaY = touchStartY - touchCurrentY
    handleGetNotifications(scrollTop, offsetHeight, deltaY / 4)
  }

  // handlers
  function handleGetNotifications(
    scrollTop: number,
    offsetHeight: number,
    deltaY: number
  ) {
    if (isFetching) return
    if (
      onRequestLoadPreviousNotifications &&
      listOffsetHeight <= offsetHeight + scrollTop
    ) {
      onRequestLoadPreviousNotifications()
      return
    }
    handleGetNewNotifications(scrollTop, deltaY)
  }

  function handleGetNewNotifications(scrollTop: number, deltaY: number) {
    if (scrollTop === 0) {
      setStretch(calculateTranslate(deltaY))
    } else if (stretch !== 0) {
      setStretch(0)
    }
    if (
      onRequestLoadNewNotifications &&
      scrollTop === 0 &&
      deltaY <= STRETCH_THRESHOLD * -1
    ) {
      onRequestLoadNewNotifications()
    }
  }

  useEffect(() => {
    if (!isFetching) {
      setStretch(0)
    }
  }, [isFetching])

  const handleClickAway = () => {
    if (onCloseRequest) {
      onCloseRequest()
    }
  }

  const handleRetry = async () => {
    setIsRetrying(true)
    await onRetry()
    setIsRetrying(false)
  }

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      minHeight="100px"
      {...props}
    >
      <ListContainer
        onWheel={handleOnWheel}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
      >
        {isFetchingPreviousPage && (
          <TopLoaderWrapper>
            <Loader
              data-qa-id="NotificationLoader"
              type={ListIcon.Loader}
              width={14}
              height={14}
              mx="auto"
            />
          </TopLoaderWrapper>
        )}
        <TranslateWrapper $translateY={stretch}>
          <ListHeader>
            <ListTitle data-qa-id="NotificationTitle" variant="h4">
              <Trans i18nKey="Notifications.title" />
              {fullscreen && (
                <CloseButton
                  data-qa-id="NotificationCloseIcon"
                  onClick={handleClickAway}
                >
                  <CloseIcon />
                </CloseButton>
              )}
            </ListTitle>
            <Typography data-qa-id="NotificationSubtitle" variant="body1">
              <Trans
                i18nKey="Notifications.totalNew"
                components={[<TotalNewNotificationNumber />]}
                values={{ count: totalNewItems }}
              />
            </Typography>
          </ListHeader>
          {/* Box has ref support just this is missing from the type defs */}
          <Box {...{ ref: listRef }}>
            {items?.length ? (
              items?.map((item: Notification, index) => (
                <NotificationItem
                  data-qa-id="NotificationItem"
                  key={`${item.id}-${index}`}
                  item={item}
                  unread={index < totalNewItems}
                  onCloseRequest={onCloseRequest}
                />
              ))
            ) : (
              <Box px={3.5} pt={8.75} pb={5.5} textAlign="center">
                <Typography>{t('Notifications.noNotifications')}</Typography>
                <Box mt={3}>
                  <Button
                    endIcon={<RefreshIcon />}
                    onClick={handleRetry}
                    disabled={isRetrying}
                  >
                    {t('Notifications.refresh')}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          {isFetchingNextPage && (
            <LoaderWrapper>
              <Loader
                data-qa-id="NotificationLoader"
                type={ListIcon.Loader}
                width={14}
                height={14}
                mx="auto"
              />
            </LoaderWrapper>
          )}
        </TranslateWrapper>
        {isError && !items?.length && (
          <Box px={3.5} pt={8.75} pb={5.5} textAlign="center">
            <Typography {...allowNewline}>
              <Trans i18nKey="Notifications.loadingError" />
            </Typography>
            <Box mt={3}>
              <Button
                endIcon={<RefreshIcon />}
                onClick={handleRetry}
                disabled={isRetrying}
              >
                <Trans i18nKey="Notifications.retryButton" />
              </Button>
            </Box>
          </Box>
        )}
      </ListContainer>
      <ListFooter>
        <Box data-qa-id="NotificationFooter" py={3}>
          <Trans i18nKey="Notifications.footer" components={[<strong />]} />
        </Box>
      </ListFooter>
    </Box>
  )
}

export default NotificationsList
