import React, { useRef, useMemo } from 'react'
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider as ApolloProviderBase,
  ApolloLink,
  fromPromise,
  FieldFunctionOptions,
} from '@apollo/client'
import { getOperationDefinition } from '@apollo/client/utilities'
import { IdToken, useAuth0 } from '@auth0/auth0-react'
import { setContext } from '@apollo/client/link/context'
import { Observable } from '@apollo/client/utilities/observables/Observable'

import { isErrorResult } from 'src/utils/typeGuards'
import {
  SearchClubListingsResult,
  SearchInstructorsResult,
  InStudioClubProgramFragment,
  SharedNavigation,
  GetClubsAvailabilityOnListingResult,
  Error,
  EventsResult,
} from 'src/generated/graphql'
import useAppState from 'src/hooks/useAppState'
import { SetSessionInvalidAction, SetTokenAction } from 'src/contexts/AppState'
import { useAuth0IdToken } from 'src/hooks/useAuth0IdToken'
import { useLanguageParam } from './LanguageParamProvider'
import { PageInfo } from 'src/generated/graphql-react-query'

const typePolicies = {
  Query: {
    fields: {
      // this is done to handle infinite scrolled pagination
      searchInstructors: {
        read: (existing: SearchInstructorsResult) => {
          return existing
        },
        merge: (
          existing: SearchInstructorsResult,
          incoming: SearchInstructorsResult,
          {
            args: {
              options: { offset },
            },
          }: any
        ): SearchInstructorsResult => {
          if (isErrorResult(incoming)) {
            return existing
          }
          const cache: SearchInstructorsResult = existing
            ? { ...existing }
            : {
                total: 0,
                results: [],
                __typename: 'SearchInstructorsResult',
              }
          cache.results =
            offset > 0
              ? [...cache.results!, ...incoming.results!]
              : incoming.results
          cache.total = incoming.total
          return cache
        },
      },
      // this is done to handle infinite scrolled pagination
      searchClubListings: {
        read: (existing: SearchClubListingsResult) => {
          return existing
        },
        merge: (
          existing: SearchClubListingsResult,
          incoming: SearchClubListingsResult,
          {
            args: {
              options: { offset },
            },
          }: any
        ): SearchClubListingsResult => {
          if (isErrorResult(incoming)) {
            return existing
          }
          const cache: SearchClubListingsResult = existing
            ? { ...existing }
            : {
                total: 0,
                results: [],
                __typename: 'SearchClubListingsResult',
              }
          cache.results =
            offset > 0
              ? [...cache.results!, ...incoming.results!]
              : incoming.results
          cache.total = incoming.total
          return cache
        },
      },
      // this is done to handle infinite scrolled pagination
      searchEvents: {
        read: (existing: EventsResult) => {
          return existing
        },
        merge: (
          existing: EventsResult,
          incoming: EventsResult,
          res: any
        ): EventsResult => {
          if (isErrorResult(incoming)) {
            return incoming
          }
          const cache: EventsResult = existing
            ? { ...existing }
            : {
                pageInfo: {} as PageInfo,
                edges: [],
                __typename: 'EventsResult',
              }
          // we need to do a check here to see if we are on the first cursor (like offset in args) if we are we would do this:
          // cache.edges = incoming.edges
          if (res.args.searchInfo.after)
            cache.edges = [...cache.edges!, ...incoming.edges!]
          else cache.edges = incoming.edges
          cache.pageInfo = incoming.pageInfo
          return cache
        },
      },
      // this is done to manipulate cache on watchlist
      getInstructorWatchlist: {
        read: (existing: SearchClubListingsResult) => {
          return existing
        },
        merge: (
          existing: SearchClubListingsResult,
          incoming: SearchClubListingsResult
        ): SearchClubListingsResult => {
          if (isErrorResult(incoming)) {
            return existing
          }
          const cache: SearchClubListingsResult = existing
            ? { ...existing }
            : {
                total: 0,
                results: [],
                __typename: 'SearchClubListingsResult',
              }
          cache.results = incoming.results
          cache.total = incoming.total
          return cache
        },
      },
      // this is done to manipulate cache on MMP Studio
      getClubById: {
        read: (existing: InStudioClubProgramFragment) => {
          return existing
        },
        merge: (
          existing: InStudioClubProgramFragment,
          incoming: InStudioClubProgramFragment,
          { mergeObjects }: FieldFunctionOptions
        ): InStudioClubProgramFragment => {
          if (isErrorResult(incoming)) {
            return existing
          }
          const cache: InStudioClubProgramFragment = existing
            ? mergeObjects(existing, incoming)
            : { ...incoming }

          return cache
        },
      },
      // this is done to manipulate cache on Shared Platform Nav
      getSharedNavigation: {
        read: (existing: SharedNavigation) => {
          return existing
        },
        merge: (
          existing: SharedNavigation,
          incoming: SharedNavigation,
          { mergeObjects }: FieldFunctionOptions
        ): SharedNavigation => {
          if (isErrorResult(incoming)) {
            return existing
          }
          const cache: SharedNavigation = existing
            ? mergeObjects(existing, incoming)
            : { ...incoming }

          return cache
        },
      },
      // it handle cache when adding/removing a job listing
      getClubsAvailabilityOnListing: {
        read: (existing: GetClubsAvailabilityOnListingResult) => {
          return existing
        },
        merge: (
          existing: GetClubsAvailabilityOnListingResult,
          incoming: GetClubsAvailabilityOnListingResult,
          { mergeObjects }: FieldFunctionOptions
        ): GetClubsAvailabilityOnListingResult => {
          if (isErrorResult(incoming)) {
            return existing
          }
          const cache: GetClubsAvailabilityOnListingResult = existing
            ? mergeObjects(existing, incoming)
            : { ...incoming }

          return cache
        },
      },
    },
  },
}

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
})

const ApolloProvider: React.FC = ({ children }) => {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0()
  const {
    dispatch,
    state: { token },
  } = useAppState()
  const { getIdTokenClaimedOrSaved } = useAuth0IdToken()
  const { acceptLanguageHeaderValue } = useLanguageParam()

  const isRefreshingToken = useRef(false)
  const refreshTokenPromise = useRef<Promise<string>>()

  const authLink = useMemo(
    () =>
      setContext(async () => {
        let token: IdToken | undefined = await getIdTokenClaimedOrSaved()
        if (!token) {
          token = await refreshAuth()
          isRefreshingToken.current = false
        }

        // add the authorization to the headers
        return {
          headers: {
            Authorization: token?.__raw,
            'Accept-Language': acceptLanguageHeaderValue,
            'X-API-KEY': process.env.REACT_APP_X_API_KEY,
          },
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getIdTokenClaims]
  )

  const refreshAuth = useMemo(
    () => async () => {
      isRefreshingToken.current = true
      try {
        await getAccessTokenSilently()
        return getIdTokenClaims()
      } catch (err) {
        if (err.error === 'login_required') {
          dispatch(SetSessionInvalidAction())
        }
      }
    },
    [dispatch, getAccessTokenSilently, getIdTokenClaims]
  )

  const invalidAuthMiddleware = useMemo(
    () =>
      new ApolloLink((operation, forward) => {
        return forward(operation).flatMap(response => {
          const data = Object.values(response.data ?? {})
          const errorResult = data.find(item => isErrorResult(item))

          if (errorResult) {
            switch (errorResult.type) {
              case Error.AuthExpiredToken:
                if (!isRefreshingToken.current) {
                  refreshAuth()
                }
                return fromPromise(refreshTokenPromise.current!)
                  .filter(token => !!token)
                  .flatMap(token => {
                    operation.setContext(({ headers = {} }) => ({
                      headers: {
                        ...headers,
                        Authorization: token,
                      },
                    }))
                    return forward(operation)
                  })
              case Error.AuthInvalidHeader:
              case Error.AuthHeaderMissing:
              case Error.AuthInternalError:
                dispatch(SetSessionInvalidAction())
                break
              default:
              // do nothing
            }
          }

          return Observable.of(response)
        })
      }),
    [dispatch, refreshAuth]
  )

  const addCacheKeyToQueriesAndMutations = useMemo(
    () =>
      new ApolloLink((operation, forward) => {
        if (!token) {
          getIdTokenClaims().then(claims => {
            if (claims) {
              dispatch(SetTokenAction(claims.current))
            }
          })
        }

        const { operation: operationType } =
          getOperationDefinition(operation.query) || {}

        const isQueryOperationType = operationType === 'query'

        const currentCacheKey =
          isQueryOperationType && token
            ? token.__raw
            : `${Math.random().toString(32).slice(2)}-${Math.floor(
                Math.random() * Date.now()
              )}`

        operation.variables._cacheKey = currentCacheKey

        return forward(operation)
      }),
    [token, dispatch, getIdTokenClaims]
  )

  const link = useMemo(
    () =>
      authLink
        .concat(invalidAuthMiddleware)
        .concat(addCacheKeyToQueriesAndMutations)
        .concat(httpLink),
    [authLink, invalidAuthMiddleware, addCacheKeyToQueriesAndMutations]
  )

  const client = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache({
          typePolicies,
        }),
        uri: process.env.REACT_APP_API_URL,
        link,
        defaultOptions: {
          mutate: {
            errorPolicy: 'all',
          },
          query: {
            errorPolicy: 'all',
          },
          watchQuery: {
            errorPolicy: 'all',
          },
        },
      }),
    [link]
  )

  return <ApolloProviderBase client={client}>{children}</ApolloProviderBase>
}

export default ApolloProvider
