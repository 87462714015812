import React, { useRef } from 'react'

import { ButtonProps } from '@material-ui/core/Button'
import useContentScroll from 'src/hooks/useContentScroll'
import styled, { css } from 'styled-components'
import { ExpandLessOutlined } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

const StyledIconButton = styled(IconButton)<{
  $isVisible?: boolean
  $isMobileLayout?: boolean
}>(
  ({ theme, $isVisible, $isMobileLayout }) => css`
    width: 44px; /* as per designs */
    height: 44px; /* as per designs */
    margin: 0 auto;
    position: fixed;
    bottom: ${$isMobileLayout
      ? `${theme.spacing(1)}px`
      : `${theme.spacing(2)}px`};
    right: ${$isMobileLayout
      ? `${theme.spacing(1)}px`
      : `${theme.spacing(2)}px`};
    background-color: ${theme.palette.brand.white};
    &:hover {
      background-color: ${theme.palette.brand.lightGray};
      border: 1px solid ${theme.palette.brand.gray};
    }
    opacity: ${$isVisible ? '1' : '0'};
    pointer-events: ${$isVisible ? 'visible' : 'none'};
    transition: opacity 0.3s ease-in;
    filter: drop-shadow(
      0px 1.31343px 7.8806px rgba(205, 205, 205, 0.7)
    ); /* as per designs */
    font-size: 30px;
    z-index: 1;
  `
)

export interface ScrollButtonProps extends ButtonProps {
  isVisible?: boolean
  isMobileLayout?: boolean
}

const ScrollToTopButton: React.FC<ScrollButtonProps> = ({
  isVisible,
  isMobileLayout,
}) => {
  const buttonRef = useRef()

  const { scrollToTop } = useContentScroll()
  return (
    <StyledIconButton
      onClick={scrollToTop}
      ref={buttonRef.current}
      $isVisible={isVisible}
      $isMobileLayout={isMobileLayout}
    >
      <ExpandLessOutlined color="primary" fontSize="inherit" />
    </StyledIconButton>
  )
}

export default React.memo(ScrollToTopButton)
