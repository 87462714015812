import { datadogRum } from '@datadog/browser-rum'

const prod = /connect\.lesmills\.com/
const uat = /connect\.preprod\.lesmills\.com/
const test = /connect\.test\.lesmills\.com/

export function initRUM(): void {
  try {
    const { host } = window.location
    const clientToken = process.env.REACT_APP_DATADOG_RUM_TOKEN
    const isProd = prod.test(host)
    const isUat = uat.test(host)
    const isTest = test.test(host)

    if (!clientToken) return

    const env = isProd ? 'prod' : isUat ? 'uat' : isTest ? 'test' : 'dev'

    datadogRum.init({
      applicationId: 'bb70e67b-a700-4f49-bd26-eeb107c99ca7',
      clientToken,
      site: 'datadoghq.com',
      service: 'lmi-connect',
      env,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      actionNameAttribute: 'data-dd-action-name',
    })

    datadogRum.startSessionReplayRecording()
  } catch (error) {
    // silence the error
    console.error(error)
  }
}
