import { useAuth0 } from '@auth0/auth0-react'

const useLogout = () => {
  const { logout: auth0Logout } = useAuth0()

  const logout = () => {
    sessionStorage.clear()
    localStorage.clear()
    auth0Logout({
      returnTo: window.location.origin,
    })
  }

  return { logout }
}

export default useLogout
