import { useEffect, useRef } from 'react'
import { useInfiniteQuery, useQueryClient, useMutation } from 'react-query'

import { isErrorResult } from 'src/utils/typeGuards'
import useRoleInput from 'src/hooks/useRoleInput'
import {
  GetNotificationsDocument,
  DateTimeFilterInput,
  GetNotificationsQueryVariables,
  DateTimeFilterType,
  UserRoleInput,
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables,
  MarkNotificationsAsReadDocument,
  GetNotificationsQuery,
  ErrorResult,
  NotificationItemFragment as Notification,
} from 'src/generated/graphql-react-query'
import { useGraphQLClientRequest } from 'src/hooks/useGraphQLClientRequest'

export const RESULT_TYPENAME = 'GetNotificationsResult'

interface UseNotificationsData {
  notifications: Notification[]
  isLoading: boolean
  error?: Error
  unreadCount: number
  refetch: () => void
  markNotificationsRead: () => void
  loadMore: () => void
  loadNew: () => void
  isFetchingNextPage: boolean
  isFetchingPreviousPage: boolean
}

const NOTIFICATIONS_DEFAULT_FETCH_LIMIT = 10
const NOTIFICATIONS_DEFAULT_FETCH_INTERVAL = 1000 * 60 * 10 // 10 minutes

const NOTIFICATIONS_QUERY_KEY = 'notifications'

const useNotifications = (
  limit: number = NOTIFICATIONS_DEFAULT_FETCH_LIMIT,
  refreshInterval: number = NOTIFICATIONS_DEFAULT_FETCH_INTERVAL
): UseNotificationsData => {
  const { role } = useRoleInput()
  const reactQueryClient = useQueryClient()

  const request = useGraphQLClientRequest()

  const { mutateAsync } = useMutation<
    MarkNotificationsAsReadMutation | undefined,
    unknown,
    MarkNotificationsAsReadMutationVariables
  >(variables => request(MarkNotificationsAsReadDocument, variables))

  const fetchNotifications = async (
    pageParam: GetNotificationsQueryVariables | undefined,
    role: UserRoleInput
  ) => {
    const response: GetNotificationsQuery | undefined = await request(
      GetNotificationsDocument,
      pageParam ?? {
        filter: {
          type: DateTimeFilterType.Before,
          value: new Date().toISOString(),
          limit: NOTIFICATIONS_DEFAULT_FETCH_LIMIT,
        },
        role,
      }
    )
    if (!response || isErrorResult(response.getNotifications)) {
      // throw error so we don't add error result to `pages`
      throw new Error((response as ErrorResult)?.message || '')
    }

    return response.getNotifications
  }

  const {
    data,
    isLoading,
    error,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
    isFetchingPreviousPage,
    fetchPreviousPage,
  } = useInfiniteQuery(
    [NOTIFICATIONS_QUERY_KEY, role.id],
    ({ pageParam }) => fetchNotifications(pageParam, role),
    {
      cacheTime: 0, // don't cache notifications
      retry: false,
      refetchOnWindowFocus: false,
      getPreviousPageParam: firstPage => {
        // previous page is newer items
        const firstPageItems = firstPage?.items ?? []

        const filter: DateTimeFilterInput = {
          type: DateTimeFilterType.After,
          value:
            firstPageItems.length > 0
              ? firstPageItems[0].createdAt
              : new Date().toISOString(),
          limit: limit,
        }
        return { filter, role }
      },
      getNextPageParam: lastPage => {
        // next page is older items
        const lastPageItems = lastPage?.items ?? []
        if (lastPageItems.length === 0) {
          // No more items to fetch
          return undefined
        }
        const filter: DateTimeFilterInput = {
          type: DateTimeFilterType.Before,
          value: lastPageItems[lastPageItems.length - 1].createdAt,
          limit: NOTIFICATIONS_DEFAULT_FETCH_LIMIT,
        }
        return { filter, role }
      },
    }
  )

  const notifications = (data?.pages ?? []).flatMap(page => page?.items ?? [])
  const unreadCount = notifications.filter(item => !item.readAt).length

  const intervalRef = useRef<number>(0)

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    intervalRef.current = setInterval(() => {
      fetchPreviousPage()
    }, refreshInterval)
    return () => clearInterval(intervalRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshInterval])

  useEffect(() => {
    // Remove empty pages off the start of the list (from fetch new notifications)
    // This is to stop the query cache constantly growing with empty pages
    if (!data) {
      return
    }
    const firstPageItems = data.pages[0]?.items ?? []

    if (firstPageItems.length === 0) {
      reactQueryClient.setQueryData<typeof data>(
        NOTIFICATIONS_QUERY_KEY,
        data => ({
          pages: data?.pages?.slice(1) ?? [],
          pageParams: data?.pageParams.slice(1) ?? [],
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const markNotificationsRead = async () => {
    const ids = notifications
      .filter(notification => !notification.readAt)
      .map(item => item.id)

    if (ids.length > 0) {
      try {
        const response = await mutateAsync({ id: ids, role })
        if (
          !response?.toggleNotificationsRead ||
          isErrorResult(response.toggleNotificationsRead)
        ) {
          throw new Error((response as ErrorResult)?.message || '')
        }
        const { toggleNotificationsRead } = response
        const updatedIds = toggleNotificationsRead.success
        const updatedAt = new Date().toISOString()
        // Update the query cache
        reactQueryClient.setQueryData<typeof data>(
          [NOTIFICATIONS_QUERY_KEY, role.id],
          data =>
            data
              ? {
                  pages: (data?.pages ?? []).map(page =>
                    page
                      ? {
                          ...page,
                          items:
                            page?.items?.map(item => ({
                              ...item,
                              readAt: updatedIds.includes(item.id)
                                ? updatedAt
                                : item.readAt,
                            })) ?? [],
                        }
                      : page
                  ),
                  pageParams: data?.pageParams ?? [],
                }
              : undefined
        )
      } catch (e) {
        // Error marking notifications read - catch it silently
      }
    }
  }

  const loadMore = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }

  const loadNew = () => {
    if (!isFetchingPreviousPage) {
      fetchPreviousPage()
    }
  }

  return {
    notifications,
    isLoading,
    error: error as Error | undefined,
    unreadCount,
    markNotificationsRead,
    loadMore,
    loadNew,
    refetch,
    isFetchingPreviousPage,
    isFetchingNextPage,
  }
}

export default useNotifications
