import React from 'react'

import { Collapse } from '@material-ui/core'

import {
  MenuItemLink,
  MenuItemTitle,
  SubMenuContainer,
  MenuItemPlainText,
  StyledIllustration,
  NavMenuItemStyleWrapper,
} from 'src/components/navigation/common'
import useMediaQueries from 'src/hooks/useMediaQueries'
import { MenuItemIcon, RootMenuItem, MenuItem } from 'src/generated/graphql'
import { isInternalLink } from 'src/utils/navigation'
import MenuIcon from 'src/components/navigation/MenuIcon'
import { SubMenu } from 'src/components/navigation/SubMenu'
import { MenuItemProps } from 'src/components/navigation/types'
import { LinkTypeIcon } from 'src/components/dataDisplay/Illustration'

export const NavMenuItem: React.FC<MenuItemProps> = ({
  data,
  onHover,
  onSelect,
  isActive,
  renderItems,
  disableTitle,
  open,
  ...props
}) => {
  const { url, icon, label, items } = data
  const { isSmallScreen } = useMediaQueries()

  const renderIcon = url ? (
    !isInternalLink(url) && (
      <StyledIllustration
        $isActive={isSmallScreen}
        type={LinkTypeIcon.ExternalLink}
      />
    )
  ) : items?.length ? (
    <StyledIllustration
      $isActive={isSmallScreen || open}
      type={LinkTypeIcon.ChevronDown}
      $isRotated={open}
    />
  ) : null

  const body = (
    <>
      <MenuIcon type={icon as MenuItemIcon} active={isActive} />
      {!disableTitle && <MenuItemTitle>{label}</MenuItemTitle>}
      {renderIcon}
    </>
  )

  const wrapperProps = {
    $isActive: isActive,
  }

  const onSelectMenuItem = (
    item: RootMenuItem | MenuItem,
    event: React.MouseEvent<Element>
  ) => {
    if (onSelect) {
      onSelect(item, event)
    }
  }

  const onSelectMain = (event: React.MouseEvent<Element>) => {
    onSelectMenuItem(data, event)
  }

  const content = url ? (
    <MenuItemLink onClick={onSelectMain} url={url} key={label} $isActive={open}>
      {body}
    </MenuItemLink>
  ) : (
    <>
      <MenuItemPlainText onClick={onSelectMain} $isActive={isActive || open}>
        {body}
      </MenuItemPlainText>
      {renderItems && (
        <Collapse appear in={open}>
          <SubMenuContainer component="ul" $hasCollapse={isSmallScreen}>
            <SubMenu data={data} disableTitle onSelect={onSelectMenuItem} />
          </SubMenuContainer>
        </Collapse>
      )}
    </>
  )

  return (
    <NavMenuItemStyleWrapper
      data-testid={`NavMenuItem-${data.label}`}
      {...wrapperProps}
      {...props}
      onMouseEnter={(event: React.MouseEvent<Element>) => {
        if (onHover) {
          onHover(data, event)
        }
      }}
    >
      {content}
    </NavMenuItemStyleWrapper>
  )
}
