import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@material-ui/core'
import { Cached } from '@material-ui/icons'

import Button from 'src/components/button/Button'

import { allowNewline } from 'src/utils/helpers'
import ErrorBoundary from 'src/utils/ErrorBoundary'

export const NavigationErrorWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const NavigationErrorContent = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: ${theme.spacing(7, 0)};
    text-align: center;

    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(3, 2.5)};
    }

    > * {
      &:last-child {
        margin-top: ${theme.spacing(3)}px;
      }
    }
  `
)

export interface NavigationErrorResultProps {
  onClick?: () => void
  isLoading?: boolean
}

const NavigationErrorResult: React.FC<NavigationErrorResultProps> = ({
  onClick,
  isLoading,
}) => {
  const { t } = useTranslation()

  const handleOnClick = () => onClick?.()

  return (
    <NavigationErrorWrapper>
      <NavigationErrorContent>
        <Typography variant="body1" {...allowNewline}>
          {t(`PlatformNavigation.noResultDescription.error`)}
        </Typography>

        <Button
          variant="contained"
          onClick={handleOnClick}
          endIcon={<Cached />}
          loading={isLoading}
        >
          {t(`PlatformNavigation.buttons.reload`)}
        </Button>
      </NavigationErrorContent>
    </NavigationErrorWrapper>
  )
}

const NavigationErrorResultWithErrorBoundary: React.FC<NavigationErrorResultProps> =
  props => (
    <ErrorBoundary>
      <NavigationErrorResult {...props} />
    </ErrorBoundary>
  )

export default NavigationErrorResultWithErrorBoundary
