import React from 'react'
import { Link, LinkProps, useLocation } from 'react-router-dom'

import Box from '@material-ui/core/Box'
interface ModalLinkProps {
  to: string
  fullWidth?: boolean
}

const ModalLink: React.FC<Omit<LinkProps, 'to'> & ModalLinkProps> = ({
  fullWidth = true,
  ...props
}) => {
  const location = useLocation()
  return (
    <Box {...(fullWidth && { width: '100%' })}>
      <Link
        {...props}
        to={{
          pathname: props.to,
          state: { background: location, prevPath: location.pathname },
        }}
      />
    </Box>
  )
}

export default ModalLink
