import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import useAppState from 'src/hooks/useAppState'
import AppSkeleton from 'src/components/pageSkeletons/App'
import InvalidSession from 'src/modules/InvalidSession'
import { useCookiebotConfig } from 'src/hooks/useCookiebotConfig'
import { isUnsupportedMarket } from 'src/utils/helpers'

const MAX_RETRY = 1

const Security: React.FC = ({ children }) => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    error,
    getAccessTokenSilently,
  } = useAuth0()
  const {
    state: { sessionInvalid, emailHasChanged },
  } = useAppState()

  useCookiebotConfig({ hideBanner: true })

  const [tries, setTries] = useState(0)
  useEffect(() => {
    if (error && !isLoading && tries < MAX_RETRY) {
      getAccessTokenSilently()
      setTries(prevValue => prevValue + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  if (error && tries < MAX_RETRY) {
    return <InvalidSession />
  }

  if (
    !isAuthenticated &&
    !isLoading &&
    (!emailHasChanged || !isUnsupportedMarket)
  ) {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    })
    return null
  }

  if (sessionInvalid) {
    return <InvalidSession />
  }

  if (isLoading || (error && tries <= MAX_RETRY)) {
    return <AppSkeleton />
  }

  return <>{children}</>
}

export default Security
