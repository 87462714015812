import React, { useState } from 'react'
import { MenuItem as MenuItemType } from 'src/generated/graphql'
import { SubMenuProps, SubMenuTitle } from 'src/components/navigation/common'
import { SubMenuItem } from 'src/components/navigation/SubMenuItem'

export const SubMenu: React.FC<SubMenuProps> = ({
  data,
  onSelect,
  disableTitle,
  isProfileMenu,
}) => {
  const [activeSubMenuItem, setActiveSubMenuItem] = useState<string>('')
  const handleHover = (item: MenuItemType) => {
    setActiveSubMenuItem(item.id)
  }

  const handleTitleHover = () => {
    setActiveSubMenuItem(data.id)
  }

  const handleLeave = () => {
    setActiveSubMenuItem('')
  }

  return (
    <>
      {!disableTitle && !isProfileMenu && (
        <SubMenuTitle
          item={data}
          onHover={handleTitleHover}
          onSelect={onSelect}
          isActive={data?.id === activeSubMenuItem}
        />
      )}
      {!!data?.items &&
        data.items.map((subMenuItem: MenuItemType) => {
          return (
            <SubMenuItem
              item={subMenuItem}
              onSelect={onSelect}
              key={subMenuItem.id}
              onLeave={handleLeave}
              onHover={() => handleHover(subMenuItem)}
              isActive={subMenuItem.id === activeSubMenuItem}
            />
          )
        })}
      {isProfileMenu && (
        <SubMenuTitle
          item={data}
          isActive={data?.id === activeSubMenuItem}
          onHover={handleTitleHover}
          onSelect={onSelect}
          isProfileMenu
        />
      )}
    </>
  )
}
