import React from 'react'
import { Box } from '@material-ui/core'
import { NavMenuProps } from 'src/components/navigation/types'
import { RootMenuItem } from 'src/generated/graphql'
import useMediaQueries from 'src/hooks/useMediaQueries'
import NavItemSkeleton from 'src/components/navigation/NavItemSkeleton'
import { NavMenuItem } from 'src/components/navigation/NavMenuItem'

export const NavMenu: React.FC<NavMenuProps> = ({
  data,
  loading,
  onHover,
  onSelect,
  selectedId,
  disableIcon,
  disableTitle,
  renderNested,
  activeMenuItem,
  ...props
}) => {
  const { isSmallScreen } = useMediaQueries()
  return (
    <Box width="100%" overflow="hidden" {...props}>
      {loading ? (
        <>
          {[...Array(5)].map((item, index) => (
            <NavItemSkeleton key={index} hasRectangle={isSmallScreen} />
          ))}
        </>
      ) : (
        data &&
        data.map((item: RootMenuItem) => {
          return (
            <NavMenuItem
              onHover={onHover}
              onSelect={onSelect}
              isActive={activeMenuItem === item.id}
              data={item}
              disableTitle={disableTitle}
              renderItems={renderNested}
              open={item.id === selectedId}
              key={item.id}
            />
          )
        })
      )}
    </Box>
  )
}
