import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Box, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

// COMPONENTS
import ProfilePhoto from 'src/components/dataDisplay/ProfilePhoto'
import { CloseIconButton } from 'src/components/dialog'
import clubDefault from 'src/assets/images/clubDefault.png'
import ModalLink from 'src/components/navigation/ModalLink'
import { ModalPaths } from 'src/routes'

// UTILS
import ErrorBoundary from 'src/utils/ErrorBoundary'
import { allowNewline } from 'src/utils/helpers'

// TYPES
import { User, UserProfile, UserProfileClub } from 'src/generated/graphql'
import { InstructorProfile, ClubProfile } from 'src/hooks/useProfile'
import { isTeachingProfile } from 'src/utils/typeGuards'

import { ReactComponent as WhiteMapPin } from 'src/assets/images/icons/whiteMapPin.svg'

const Container = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: ${theme.spacing(3)}px;
    justify-content: space-between;
    background-color: ${theme.palette.background.dark};

    ${theme.breakpoints.down('sm')} {
      align-items: flex-end;
      min-height: 148px; /* as per design */
      height: fit-content;
    }
  `
)

const CloseIcon = styled(Close)(
  ({ theme }) => css`
    color: ${theme.palette.brand.white};
  `
)

const ActiveProfileContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 340px;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: flex-start;
      margin-right: ${theme.spacing(1)}px;
    }

    & > :first-child {
      ${theme.breakpoints.down('sm')} {
        margin-bottom: ${theme.spacing(1.25)}px;
      }

      ${theme.breakpoints.down('xs')} {
        margin: 0;
      }
    }
  `
)

const StyledCloseButton = styled(CloseIconButton)(
  ({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      display: none;
    }
  `
)

const ActiveProfileText = styled(Typography)(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 1)};
    color: ${theme.palette.brand.white};

    ${theme.breakpoints.down('xs')} {
      margin: ${theme.spacing(1.5, 0, 0, 0)};
    }
  `
)

const SwitchAccountText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.brand.white};
    font-weight: ${theme.typography.fontWeightBold};
  `
)

const SwitchAccountContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    :hover {
      cursor: pointer;
    }

    && .MuiAvatar-root {
      font-size: ${theme.typography.pxToRem(20)};
    }
  `
)

const StyledWhiteMapPin = styled(WhiteMapPin)(
  ({ theme }) => css`
    && {
      width: 20px;
      height: 20px;

      ${theme.breakpoints.down('xs')} {
        width: 40px;
        height: 40px;
      }
    }
  `
)

export interface NavigationHeaderProps {
  activeProfile?: UserProfile
  user?: User | null
  clubProfiles?: ClubProfile[]
  instructorProfile?: InstructorProfile
  onCloseDialog?: () => void
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  user,
  activeProfile,
  clubProfiles,
  instructorProfile,
  onCloseDialog,
}) => {
  const { t } = useTranslation()

  const isActiveProfileInstructor = useMemo(
    () => activeProfile && isTeachingProfile(activeProfile),
    [activeProfile]
  )

  const userHasInstructorAccount = useMemo(
    () => !!instructorProfile,
    [instructorProfile]
  )

  const userHasMultipleAccounts = useMemo(
    () => userHasInstructorAccount && !!clubProfiles?.length,
    [clubProfiles?.length, userHasInstructorAccount]
  )

  const clubHasMultipleLocations = useMemo(
    () => !!clubProfiles?.length && clubProfiles?.length > 1,
    [clubProfiles]
  )

  // Multiple locations ? display clubDefault Image : clubProfile.image
  const currentClubProfile = useMemo(() => {
    const firstClubProfile = clubProfiles?.[0]

    if (!clubHasMultipleLocations) {
      return firstClubProfile
    } else {
      return {
        ...firstClubProfile,
        profileImage: {
          ...firstClubProfile?.profileImage,
          url: clubDefault, // Default image if club has multiple locations
        },
      }
    }
  }, [clubHasMultipleLocations, clubProfiles])

  const currentProfileToSwitch = useMemo(
    () => (isActiveProfileInstructor ? currentClubProfile : instructorProfile),
    [isActiveProfileInstructor, currentClubProfile, instructorProfile]
  )

  // Display SwitchLocation if user has no instructor profile (club only), club profile is active and club profile has multiple locations
  const shouldShowSwitchLocation = useMemo(
    () =>
      !userHasInstructorAccount &&
      !isActiveProfileInstructor &&
      clubHasMultipleLocations,
    [
      clubHasMultipleLocations,
      isActiveProfileInstructor,
      userHasInstructorAccount,
    ]
  )

  const instructorFullName = useMemo(
    () => `${user?.firstName} ${user?.lastName}`,
    [user]
  )

  const switchAccountLabel = useMemo(
    () =>
      t(
        `PlatformNavigation.${
          shouldShowSwitchLocation ? 'switchLocation' : 'switchAccount'
        }`
      ),
    [shouldShowSwitchLocation, t]
  )

  const firstName = useMemo(() => user?.firstName || '', [user])

  return (
    <Container>
      <StyledCloseButton onClick={onCloseDialog}>
        <CloseIcon />
      </StyledCloseButton>
      <ActiveProfileContainer>
        <ProfilePhoto
          minWidth={48}
          minHeight={48}
          width={48}
          height={48}
          user={{ firstName }}
          profile={activeProfile as UserProfile}
          hideAvailability
          isSmall
        />
        <ActiveProfileText variant="body1" {...allowNewline}>
          {isActiveProfileInstructor
            ? t('PlatformNavigation.loggedInAs.instructor', {
                name: instructorFullName,
              })
            : shouldShowSwitchLocation
            ? t('PlatformNavigation.loggedInAs.gfm', {
                name: (activeProfile as UserProfileClub).name,
              })
            : t('PlatformNavigation.loggedInAs.club', {
                name: (activeProfile as UserProfileClub).name,
              })}
        </ActiveProfileText>
      </ActiveProfileContainer>

      {(userHasMultipleAccounts || clubHasMultipleLocations) && (
        <ModalLink to={ModalPaths.SwitchProfile} fullWidth={false}>
          <SwitchAccountContainer>
            {shouldShowSwitchLocation ? (
              <StyledWhiteMapPin />
            ) : (
              <ProfilePhoto
                minWidth={24}
                minHeight={24}
                width={24}
                height={24}
                user={{ firstName }}
                profile={currentProfileToSwitch as UserProfile}
                hideAvailability
                isSmall
                mr={1}
              />
            )}

            <SwitchAccountText>{switchAccountLabel}</SwitchAccountText>
          </SwitchAccountContainer>
        </ModalLink>
      )}
    </Container>
  )
}

const NavigationHeaderWithErrorBoundary: React.FC<NavigationHeaderProps> =
  props => (
    <ErrorBoundary>
      <NavigationHeader {...props} />
    </ErrorBoundary>
  )

export default NavigationHeaderWithErrorBoundary
