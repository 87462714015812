import React, { useState, useEffect, useReducer, forwardRef } from 'react'
import styled, { css } from 'styled-components'

import {
  Box,
  Dialog,
  Fade,
  Slide,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { useTranslation } from 'react-i18next'

import Button from 'src/components/button/Button'
import Img from 'src/components/dataDisplay/Img'
import { MobileCloseButton, CloseButton } from 'src/components/dialog/index'

import { WalkthroughPage } from 'src/generated/graphql-react-query'

export interface WalkthroughDialogProps {
  contentPages: WalkthroughPage[]
  totalContentPages: number
  analytics?: ({
    isWelcomePage,
    isDonePage,
    isCloseDialog,
    totalPages,
    currentPage,
  }: {
    isWelcomePage?: boolean
    isDonePage?: boolean
    isCloseDialog?: boolean
    totalPages?: number
    currentPage?: number
  }) => void
  onClose: () => void
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

// TODO: styles would be addressed by LCS-331
const DialogContentWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-width: 482px;

    ${theme.breakpoints.down('sm')} {
      min-width: 300px;
    }
  `
)

const DialogImageContainer = styled(Box)(
  ({ theme }) => css`
    height: 475px;
    padding-bottom: ${theme.spacing(5)}px;

    ${theme.breakpoints.down('sm')} {
      height: 360px; /* as per design */
      padding-bottom: ${theme.spacing(2.5)}px;
    }
  `
)

const DialogTitle = styled(Box)(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing(2)}px;
  `
)
const DialogBody = styled(Box)(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing(4)}px;
  `
)

const DialogFooterContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: ${theme.spacing(4)}px;

    ${theme.breakpoints.down('sm')} {
      padding-bottom: ${theme.spacing(13.75)}px;
    }
  `
)

const StyledTitle = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    font-family: ${theme.typography.fontFamilySecondary};
    line-height: 90%;
    font-size: ${theme.typography.pxToRem(52)};
    font-weight: 600;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.typography.pxToRem(32)};
    }
  `
)

const StyledPagination = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.brand.darkGray};
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.pxToRem(14)};
    line-height: 160%;
  `
)

const StyledBody = styled.div(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    font-size: ${theme.typography.pxToRem(14)};
    line-height: 160%;
    font-family: ${theme.typography.fontFamily};
    a {
      text-decoration: underline;
    }
  `
)

const DialogMainContent = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding-left: ${theme.spacing(4)}px;
    padding-right: ${theme.spacing(4)}px;
  `
)

const StyledPrimaryButton = styled(Button)<{ $isWelcomeSlide?: boolean }>(
  ({ theme, $isWelcomeSlide }) => css`
    &&.MuiButton-contained {
      width: 110px;
      height: 44px;

      ${theme.breakpoints.down('sm')} {
        width: ${$isWelcomeSlide ? '100%' : null};
      }
    }
  `
)

const StyledSecondaryButton = styled(Button)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(2)}px;

    &&.MuiButton-outlined {
      padding: ${theme.spacing(1.75, 4.75)};
    }
  `
)

const StyledDialog = styled(Dialog)(
  ({ theme }) => css`
    .MuiDialog-paperWidthSm {
      width: 580px; /* as per design */
    }

    .MuiDialog-paperScrollPaper {
      border-radius: 20px;
    }

    ${theme.breakpoints.down('sm')} {
      .MuiDialog-paper {
        margin: 0;
        padding: 0;
        max-width: 100%;
      }

      .MuiDialog-container {
        padding-top: ${theme.spacing(7)}px;
      }

      .MuiDialog-paperFullScreen {
        border-radius: 20px 20px 0 0;
      }
    }
  `
)

const DialogActionContainer = styled(Box)<{ $isWelcomeSlide?: boolean }>(
  ({ $isWelcomeSlide }) => css`
    display: flex;
    flex-direction: row;

    ${$isWelcomeSlide
      ? css`
          width: 100%;
          justify-content: flex-end;
        `
      : null}
  `
)

const StyledCloseButton = styled(CloseButton)`
  margin: 0;
`

const DialogImage = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
`

const StyledMobileCloseButton = styled(MobileCloseButton)`
  height: 60px;
  width: 60px;
`

export type TransitionType = {
  titleContent: string
  bodyContent: (string | RichTextBlock)[]
  mobileImageUrl: string
  desktopImageUrl: string
  transition: boolean
}

interface transitionReducerAction {
  type: 'TRANSITION' | 'SET_MULTIPLE'
  payload: TransitionType
}

const transitionReducer = (
  state: TransitionType,
  action: transitionReducerAction
) => {
  const { type, payload } = action

  switch (type) {
    case 'TRANSITION':
      return { ...state, transition: payload.transition }
    case 'SET_MULTIPLE':
      return { ...state, ...payload }
    default:
      return state
  }
}

const WalkthroughDialog: React.FC<WalkthroughDialogProps> = ({
  contentPages,
  totalContentPages,
  analytics,
  onClose,
}) => {
  const [currentPageNumber, setCurrentPageNumber] = useState(0)
  const [shouldOpenModal, setShouldOpenModal] = useState(true)

  const initialTransitionState = {
    titleContent: contentPages[currentPageNumber].title,
    bodyContent: contentPages[currentPageNumber].body,
    mobileImageUrl: contentPages[currentPageNumber].image.mobile.url,
    desktopImageUrl: contentPages[currentPageNumber].image.desktop.url,
    transition: true,
  }

  const [transitionState, transitionDispatch] = useReducer(
    transitionReducer,
    initialTransitionState
  )

  const {
    titleContent,
    bodyContent,
    mobileImageUrl,
    desktopImageUrl,
    transition,
  } = transitionState

  const theme = useTheme()
  const { t } = useTranslation()

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const currentPageContent = contentPages[currentPageNumber]
  const isWelcomeSlide = currentPageNumber === 0
  const isSingleSlideWalkthrough = contentPages.length === 1

  const shouldCloseDialog =
    (!isWelcomeSlide || isSingleSlideWalkthrough) &&
    currentPageNumber === contentPages.length - 1

  const { desktop: currentDesktopImage, mobile: currentMobileImage } =
    currentPageContent.image

  const currentPrimaryButtonLabel =
    isWelcomeSlide && currentPageNumber === 0 && !isSingleSlideWalkthrough
      ? t('Walkthrough.buttonLabels.getStarted')
      : currentPageNumber === contentPages.length - 1
      ? t('Walkthrough.buttonLabels.done')
      : t('Walkthrough.buttonLabels.next')

  // the backend can potentially either send a string or an object
  const formattedWalkthoughBody: RichTextBlock[] = bodyContent.map(
    (e: string | RichTextBlock) => (typeof e === 'string' ? JSON.parse(e) : e)
  )

  const handleCloseDialog = () => {
    analytics &&
      analytics({
        isCloseDialog: true,
      })
    onClose()
    setShouldOpenModal(false)
  }

  const handlePrimaryButton = () => {
    const nextPage = currentPageNumber + 1

    if (shouldCloseDialog) {
      handleCloseDialog()
      return
    }
    transitionDispatch({
      type: 'TRANSITION',
      payload: { ...transitionState, transition: false },
    })
    setCurrentPageNumber(nextPage)
  }

  const handleSecondaryButton = () => {
    const prevPage = currentPageNumber - 1
    const shouldShowWelcomeSlice = !isWelcomeSlide && prevPage === 0
    if (shouldShowWelcomeSlice) {
      transitionDispatch({
        type: 'TRANSITION',
        payload: { ...transitionState, transition: false },
      })
      setCurrentPageNumber(0)
    } else {
      transitionDispatch({
        type: 'TRANSITION',
        payload: { ...transitionState, transition: false },
      })
      setCurrentPageNumber(prevPage)
    }
  }

  const onExitedTransition = () => {
    transitionDispatch({
      type: 'SET_MULTIPLE',
      payload: {
        transition: true,
        titleContent: contentPages[currentPageNumber].title,
        bodyContent: contentPages[currentPageNumber].body,
        mobileImageUrl: contentPages[currentPageNumber].image.mobile.url,
        desktopImageUrl: contentPages[currentPageNumber].image.desktop.url,
      },
    })
  }
  useEffect(() => {
    analytics &&
      analytics({
        isWelcomePage: isWelcomeSlide,
        isDonePage: shouldCloseDialog,
        totalPages: totalContentPages,
        currentPage: currentPageNumber,
      })
  }, [
    analytics,
    contentPages,
    currentPageNumber,
    isWelcomeSlide,
    shouldCloseDialog,
    totalContentPages,
  ])

  return (
    <StyledDialog
      PaperProps={{ square: true }}
      fullScreen={isMobileScreen}
      TransitionComponent={Transition}
      open={shouldOpenModal}
      onBackdropClick={handleCloseDialog}
    >
      <DialogContentWrapper>
        {!isMobileScreen && <StyledCloseButton onClick={handleCloseDialog} />}
        <DialogImageContainer>
          <Fade in={transition} timeout={125} onExited={onExitedTransition}>
            <DialogImage
              src={isMobileScreen ? mobileImageUrl : desktopImageUrl}
              alt={
                isMobileScreen
                  ? currentMobileImage.alt || ''
                  : currentDesktopImage.alt || ''
              }
            />
          </Fade>
        </DialogImageContainer>
        <DialogMainContent>
          <Fade in={transition} timeout={125} onExited={onExitedTransition}>
            <Box>
              <DialogTitle>
                <StyledTitle>{titleContent}</StyledTitle>
              </DialogTitle>
              <DialogBody>
                <RichText
                  render={formattedWalkthoughBody}
                  Component={StyledBody}
                />
              </DialogBody>
            </Box>
          </Fade>
          <DialogFooterContainer>
            {!isWelcomeSlide && (
              <Box>
                <StyledPagination>
                  {currentPageNumber} of {totalContentPages - 1}
                </StyledPagination>
              </Box>
            )}
            <DialogActionContainer $isWelcomeSlide={isWelcomeSlide}>
              {!isWelcomeSlide && (
                <StyledSecondaryButton
                  onClick={handleSecondaryButton}
                  variant="outlined"
                >
                  {t('Walkthrough.buttonLabels.back')}
                </StyledSecondaryButton>
              )}

              <StyledPrimaryButton
                $isWelcomeSlide={isWelcomeSlide}
                variant="contained"
                color="primary"
                onClick={handlePrimaryButton}
              >
                {currentPrimaryButtonLabel}
              </StyledPrimaryButton>
            </DialogActionContainer>
          </DialogFooterContainer>
        </DialogMainContent>
      </DialogContentWrapper>
      {isMobileScreen && (
        <StyledMobileCloseButton onClick={handleCloseDialog} />
      )}
    </StyledDialog>
  )
}

export default WalkthroughDialog
