import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import styled, { css } from 'styled-components'
import { Box } from '@material-ui/core'

interface NavItemProps {
  hasRectangle?: boolean
}

const CircleSkeleton = styled(Skeleton)(
  ({ theme }) => css`
    background-color: ${theme.palette.brand.gray};
    height: 50px;
    width: 50px;
    margin: 0 auto;
  `
)

const RectSkeleton = styled(Skeleton)(
  ({ theme }) => css`
    background-color: ${theme.palette.brand.gray};
    height: 50px;
    width: calc(100% - 100px);
    border-radius: 8px;
    margin-right: ${theme.spacing(3)}px;
  `
)

const NavItemSkeleton: React.FC<NavItemProps> = ({ hasRectangle }) => {
  return (
    <Box display="flex" justifyContent="space-between" pb={3} width="100%">
      <CircleSkeleton variant="circle" />
      {hasRectangle && <RectSkeleton variant="rect" />}
    </Box>
  )
}

export default NavItemSkeleton
