import styled, { css } from 'styled-components'
import { Box, Typography } from '@material-ui/core'

export const ListingInfo = styled(Box)`
  flex: 1;
`

export const ProgramRoleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 6px; /* as per design */
`

export const RoleContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.palette.brand.offWhite};
    width: fit-content;
    text-align: center;
  `
)

export const RoleLabel = styled(Typography)(
  ({ theme }) => css`
    margin: ${theme.spacing(1)}px;
    text-transform: uppercase;
  `
)
