import React from 'react'
import styled, { css } from 'styled-components'

import { Typography, MenuItem as MuiMenuItem, Box } from '@material-ui/core'

import { RootMenuItem, MenuItem } from 'src/generated/graphql'
import { SubMenuItemProps } from 'src/components/navigation/types'
import Illustration, {
  LinkTypeIcon,
} from 'src/components/dataDisplay/Illustration'
import useUser from 'src/hooks/useUser'
import Link from 'src/components/navigation/Link'
import { isInternalLink } from 'src/utils/navigation'
import { isClubProfile } from 'src/utils/typeGuards'
import useProfile from 'src/hooks/useProfile'
import { useTranslation } from 'react-i18next'

export const NavMenuItemIcon = styled(Illustration)<{
  $isLink: boolean
}>(
  ({ $isLink }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;

    img {
      width: 32px !important;
      height: 32px !important;
    }

    &:hover {
      cursor: ${$isLink ? 'pointer' : 'default'};
    }
  `
)

const Container = styled(Box)<{ $isProfile?: boolean }>(
  ({ $isProfile }) => css`
    width: 100%;
    flex-direction: column;
    word-break: break-word;
    ${$isProfile &&
    css`
      max-width: 200px;
      min-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
  `
)

export const TitleText = styled(Typography)<{ $isProfile?: boolean }>(
  ({ theme, $isProfile }) => css`
    margin-right: auto;
    margin-left: ${theme.spacing(2)}px;
    max-width: calc(100% - 50px);
    width: 100%;
    text-transform: uppercase;
    ${$isProfile &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}

    letter-spacing: 0.139em;
    font-size: ${theme.typography.pxToRem(12)};
    font-family: ${theme.typography.fontFamily};
    line-height: 160%;

    ${theme.breakpoints.down('sm')} {
      max-width: 100%;
    }
  `
)

export const StyledSubMenuItem = styled(MuiMenuItem)<{
  $isTitle?: boolean
  $isLink?: boolean
  $isProfileMenu?: boolean
}>(
  ({ theme, $isTitle, $isLink, $isProfileMenu }) => css`
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: ${theme.typography.pxToRem(14)};
    color: ${theme.palette.primary.contrastText};
    background-color: ${$isTitle
      ? theme.palette.background.dark
      : theme.palette.brand.black};
    height: ${$isProfileMenu ? '86px' : $isTitle ? '48px' : 'unset'};
    border-top: 0;
    margin-top: ${$isProfileMenu ? '16px' : '0'};
    white-space: pre-wrap;
    word-break: break-word;
    ${$isProfileMenu
      ? 'border-bottom-left-radius: 5px;'
      : 'border-top-right-radius: 5px;'}

    &:hover {
      cursor: ${$isLink ? 'pointer' : 'default'};
      background-color: ${$isTitle
        ? theme.palette.background.dark
        : theme.palette.brand.black};
    }
  `
)

export const profileLinkStyle = css`
  ${({ theme }) => css`
    color: ${theme.palette.brand.darkGray};
    font-size: ${theme.typography.pxToRem(14)};
    line-height: ${theme.typography.pxToRem(28)};
    font-family: ${theme.typography.fontFamily};
    margin-left: ${theme.spacing(2)}px;
  `}
`

const ProfileMenuProfileLink = styled(Typography)`
  ${profileLinkStyle}
  line-height: 160%;
`

interface MenuItemStyleProps {
  $isSubMenu?: boolean
  $isActive?: boolean
}
const menuItemCommonStyle = css<MenuItemStyleProps>`
  width: 100%;
  display: flex;
  cursor: pointer;
  height: 48px;
  align-items: center;
  justify-content: space-between;

  ${({ theme, $isSubMenu }) => css`
    color: ${theme.palette.primary.contrastText};
    justify-content: ${$isSubMenu ? 'space-between' : 'center'};
  `}
  ${NavMenuItemIcon} {
    cursor: pointer;
  }
`

export const MenuItemPlainText = styled(Box)`
  ${menuItemCommonStyle}
`

export const MenuItemLink = styled(Link)`
  ${menuItemCommonStyle}
`

export const SubMenuItemTitle = styled(Typography)<{ $isSmall?: boolean }>(
  ({ theme, $isSmall }) => css`
    max-width: calc(100% - 50px);
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.pxToRem(14)};
    letter-spacing: -0.01em;
    line-height: 120%;
    ${!$isSmall &&
    css`
      margin-left: ${theme.spacing(2)}px;
      max-width: 100%;
    `}
  `
)

export const MenuItemTitle = styled(Typography)(
  ({ theme }) => css`
    flex: 1;
    padding-left: ${theme.spacing(2.625)}px;
    font-size: ${theme.typography.pxToRem(12)};
    line-height: 160%;
    font-family: ${theme.typography.fontFamily};
    text-transform: uppercase;
    letter-spacing: 0.139em;
  `
)

export const StyledIllustration = styled(Illustration)<{
  $isActive?: boolean
  $isRotated?: boolean
}>(
  ({ $isActive, $isRotated, theme }) => css`
    transition: transform 0.5s;
    display: ${$isActive ? 'block' : 'none'};
    transform: rotate(${$isRotated ? '180deg' : '0deg'});
    margin-right: ${theme.spacing(2.625)}px;
  `
)

export const EndAdornment = styled(Illustration)<{
  $isActive?: boolean
  $isRotated?: boolean
}>(
  ({ $isActive, $isRotated, theme }) => css`
    transition: transform 0.5s;
    opacity: ${$isActive ? '1' : '0'};
    transform: rotate(${$isRotated ? '180deg' : '0deg'});
    margin-right: ${theme.spacing(2.625)}px;

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `
)

export const SubMenuContainer = styled(Box)<{
  $isProfile?: boolean
  $hasCollapse?: boolean
}>(
  ({ theme, $isProfile, $hasCollapse }) => css`
    min-width: ${$isProfile ? '100%' : '260px'};
    width: 'fit-content';
    max-width: ${$isProfile ? '100%' : '360px'};
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${$hasCollapse &&
    css`
      padding-left: ${$isProfile ? theme.spacing(7.25) : theme.spacing(4.75)}px;
      padding-bottom: ${theme.spacing(1)}px;
    `}
  `
)

export const NavMenuItemStyleWrapper = styled(Box)<MenuItemStyleProps>(
  ({ theme, $isSubMenu, $isActive }) => css`
    background-color: ${$isSubMenu || $isActive
      ? theme.palette.background.dark
      : theme.palette.brand.black};
    margin: ${theme.spacing(3, 0)};
    :first-child {
      margin-top: 0;
    }
  `
)

export const SubMenuTitle: React.FC<SubMenuItemProps> = ({
  item,
  onHover,
  isActive,
  isProfileMenu,
  onSelect,
}) => {
  const { t } = useTranslation()

  const { url, label } = item
  const {
    data: { user },
  } = useUser()
  const {
    data: { profile },
  } = useProfile()

  const name =
    profile && isClubProfile(profile)
      ? profile.name
      : t('Global.fullName', {
          firstName: user?.firstName,
          lastName: user?.lastName,
        })
  const subMenuTitleDetails = (
    <Container display="flex" $isProfile={isProfileMenu}>
      <Box display="flex" width="100%" alignItems="center">
        <TitleText variant="h3" $isProfile={isProfileMenu}>
          {isProfileMenu && user ? name : label}
        </TitleText>
        {url?.value ? (
          <EndAdornment
            $isActive={isActive}
            type={
              isInternalLink(url)
                ? LinkTypeIcon.Chevron
                : LinkTypeIcon.ExternalLink
            }
          />
        ) : null}
      </Box>
      {isProfileMenu && (
        <ProfileMenuProfileLink>{label}</ProfileMenuProfileLink>
      )}
    </Container>
  )

  const subMenuTitleContent = url ? (
    <MenuItemLink
      url={url}
      onClick={event => onSelect?.(item, event)}
      $isSubMenu
    >
      {subMenuTitleDetails}
    </MenuItemLink>
  ) : (
    subMenuTitleDetails
  )

  return (
    <StyledSubMenuItem
      $isTitle
      disableRipple
      $isLink={!!url?.value}
      onMouseEnter={onHover}
      $isProfileMenu={isProfileMenu}
      data-qa-id={label}
    >
      {subMenuTitleContent}
    </StyledSubMenuItem>
  )
}

export interface SubMenuProps {
  data: RootMenuItem
  disableTitle?: boolean
  isProfileMenu?: boolean
  onSelect?: (
    item: RootMenuItem | MenuItem,
    event: React.MouseEvent<Element>
  ) => void
}
