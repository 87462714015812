import React, { useEffect } from 'react'
import moment from 'moment'
import i18next from 'i18next'
import { Helmet } from 'react-helmet'
import prettyBytes from 'pretty-bytes'
import {
  I18nextProvider,
  initReactI18next,
  useTranslation,
} from 'react-i18next'

import { Locale } from 'src/generated/graphql'
import { localeToLanguage, replaceBlankSpaceWithUnicode } from 'src/utils/i18n'
import { useInitData } from 'src/contexts/InitData'
import { useLanguageParam } from './LanguageParamProvider'
import { I18nLoaded } from 'src/contexts/AppState'
import useAppState from 'src/hooks/useAppState'
import AppSkeleton from 'src/components/pageSkeletons/App'

export const DefaultLanguage = Locale.EnGb
export const I18nextTranslationNs = 'translation'

const HelmetWrapper: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Helmet>
      <title>{t('Global.meta.title', 'Les Mills Connect')}</title>
      <meta name="description" content={t('Global.meta.description')} />
    </Helmet>
  )
}

const I18nProvider: React.FC = ({ children }) => {
  const initData = useInitData()
  const { translations } = initData || {}
  const { set, value } = useLanguageParam()
  const {
    dispatch,
    state: { i18nLoaded },
  } = useAppState()

  useEffect(() => {
    if (translations) {
      const { locale, result: resource } = translations
      if (!locale || !resource) {
        return
      }
      const language = localeToLanguage(locale)
      if (i18next.isInitialized && i18next.language === language) {
        return
      }
      i18next.use(initReactI18next).init({
        load: 'currentOnly', // we don't want to load en when we set en-GB
        resources: {
          [language]: {
            [I18nextTranslationNs]: resource,
          },
        },
        lng: language,
        fallbackLng: language,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
          format: (value, format, lng) => {
            if (
              (typeof value === 'string' && value.trim().length === 0) ||
              value === null ||
              value === undefined
            ) {
              return ''
            }
            if (format?.startsWith('suffix')) {
              const [, suffix] = format.split(':')
              const currentSuffix = replaceBlankSpaceWithUnicode(suffix)
              return `${value}${currentSuffix}`
            }
            if (format?.startsWith('prefix')) {
              const [, prefix] = format.split(':')
              return `${prefix}${value}`
            }
            if (value instanceof Date) {
              return moment(value)
                .locale(lng || i18next.language)
                .format(format)
            }
            if (value instanceof moment) {
              return moment(value).format(format)
            }
            if (format === 'fileSize') {
              return prettyBytes(Number(value))
            }

            return value
          },
        },
        react: {
          useSuspense: false,
        },
      })
      if (language !== value) {
        set(language)
      }
      dispatch(I18nLoaded())
    }
  }, [dispatch, set, translations, value])
  if (!i18nLoaded || !i18next.isInitialized) {
    return <AppSkeleton />
  }

  return (
    <I18nextProvider i18n={i18next}>
      <>
        <HelmetWrapper />
        {children}
      </>
    </I18nextProvider>
  )
}

export default I18nProvider
