import React from 'react'
import styled from 'styled-components'
import { Link as ReactLink } from 'react-router-dom'

import { Url } from 'src/generated/graphql'
import ModalLink from 'src/components/navigation/ModalLink'
import { isInternalLink, isModalLink } from 'src/utils/navigation'

const LocalLink = styled(ReactLink)`
  cursor: pointer;
`

const A = styled.a`
  cursor: pointer;
`

interface Props {
  url: Url
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

export const Link: React.FC<Props> = ({ url, children, ...props }) => {
  const { value } = url
  if (isModalLink(url)) {
    return (
      <ModalLink to={url.value} {...props}>
        {children}
      </ModalLink>
    )
  }
  if (isInternalLink(url)) {
    return (
      <LocalLink to={value} {...props}>
        {children}
      </LocalLink>
    )
  }
  return (
    <A href={value} rel="noopener noreferrer" target="_blank" {...props}>
      {children}
    </A>
  )
}

export default Link
