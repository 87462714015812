import { Address, LocationDistanceUnit } from 'src/generated/graphql'

export const SAVED_SEARCHES_LIMIT = 3

// Match the default distance value on upsertUser, if it's missing on the user
// TODO: use market default (BE schema update needed)
export const DEFAULT_DISTANCE = 30

// initial release markets use miles
export const DEFAULT_DISTANCE_UNIT = LocationDistanceUnit.Miles

export const DESCRIPTION_FIELD_MAX_LENGTH = 1000

export const MAX_PROGRAMS = 3

export const MAX_FILE_SIZE =
  process.env.REACT_APP_MAX_FILE_SIZE &&
  !isNaN(Number(process.env.REACT_APP_MAX_FILE_SIZE))
    ? Number(process.env.REACT_APP_MAX_FILE_SIZE)
    : 10485760 // 10MB

export const PORTFOLIO_FILE_LIMIT =
  process.env.REACT_APP_PORTFOLIO_FILE_LIMIT &&
  !isNaN(Number(process.env.REACT_APP_PORTFOLIO_FILE_LIMIT))
    ? Number(process.env.REACT_APP_PORTFOLIO_FILE_LIMIT)
    : 10

export const MISSING_VALUE = '-'

export const addressGeocodingAddressTypesMap: {
  [k in keyof Address]: string
} = {
  street: 'route',
  city: 'locality',
  country: 'country',
  streetName: 'route',
  postalCode: 'postal_code',
  streetNumber: 'street_number',
  state: 'administrative_area_level_2, administrative_area_level_1',
}

// TODO? Set default email or placeholder if not defined in env
export const SUPPORT_EMAIL = process.env.SUPPORT_EMAIL || ''

export const LIMIT_REACHED = 'LIMIT_REACHED'

export const THE_TRIP_IMMERSIVE = 'THE TRIP - IMMERSIVE'
export const THE_TRIP = 'THE TRIP'
