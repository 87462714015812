import React, { useCallback, useRef, useState, useMemo } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'

import {
  Box,
  ClickAwayListener,
  Slide,
  Badge as MuiBadge,
  Popper as MuiPopper,
} from '@material-ui/core'
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined'

import useMediaQueries from 'src/hooks/useMediaQueries'
import ErrorBoundary from 'src/utils/ErrorBoundary'
import useNotifications from 'src/hooks/useNotifications'
import AppHeaderIconButton from 'src/components/layout/AppHeaderIconButton'
import NotificationsList from 'src/components/notifications/NotificationsList'

import { SetIsNotificationOpen } from 'src/contexts/AppState'
import useAppState from 'src/hooks/useAppState'

const Popper = styled(MuiPopper)`
  display: flex;
`

const Badge = styled(MuiBadge)<{ $isMobileLayout?: boolean }>(
  ({ theme, $isMobileLayout }) => css`
    .MuiBadge-badge {
      padding: 0;
      font-size: ${theme.typography.pxToRem(12)};
      ${!$isMobileLayout
        ? css`
            &:not(.MuiBadge-invisible) {
              transform: translate(0, 0);
            }
          `
        : ''}
    }
  `
)
const listStyle = css`
  overflow-y: auto;
  border-radius: 2px;
  background: ${({ theme }) => theme.palette.brand.white};
`
const DesktopListWrapper = styled(Box)(
  ({ theme }) => css`
    ${listStyle}
    width: 358px;
    max-height: 75vh;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid ${theme.palette.brand.gray};
    margin-top: ${theme.spacing(2)}px;
  `
)

const MobileListWrapper = styled(Box)`
  ${listStyle}
  width: 100vw;
  height: 100vh;
  margin-top: -5px;
`

// We have to create a portal because of the z-index settings
// for the navigation and app bar.
interface ListPortalProps {
  isMobileLayout?: boolean
  anchorEl: Element | HTMLElement
  open: boolean
  onCloseRequest: () => void
}
const ListPortal: React.FC<ListPortalProps> = ({
  anchorEl,
  isMobileLayout,
  onCloseRequest,
  children,
  open,
}) => {
  return createPortal(
    <>
      <ClickAwayListener onClickAway={onCloseRequest}>
        <Popper anchorEl={anchorEl} open={open}>
          {isMobileLayout ? (
            <Slide in={open}>
              <MobileListWrapper>{children}</MobileListWrapper>
            </Slide>
          ) : (
            <DesktopListWrapper>{children}</DesktopListWrapper>
          )}
        </Popper>
      </ClickAwayListener>
    </>,
    anchorEl?.parentElement || document.body
  )
}

interface NotificationProps {
  isDashboard?: boolean
}

const Notifications: React.FC<NotificationProps> = ({ isDashboard }) => {
  const { isSmallScreen: isMobileLayout } = useMediaQueries()
  const [showNotifications, setShowNotifications] = useState(false)
  const {
    dispatch,
    state: { isNotificationOpen },
  } = useAppState()

  const {
    notifications,
    isLoading,
    error,
    loadMore,
    loadNew,
    refetch,
    markNotificationsRead,
    unreadCount,
    isFetchingNextPage,
    isFetchingPreviousPage,
  } = useNotifications()

  const buttonRef = useRef() as React.MutableRefObject<HTMLElement>
  const cornerRef = useRef() as React.MutableRefObject<HTMLElement>

  const handleCloseRequest = useCallback(() => {
    if (showNotifications) {
      // handleCloseRequest can fire when the portal clickaway is triggered even if not open
      // make sure to only fire the request to mark notifications read if the list is open
      markNotificationsRead()
      dispatch(SetIsNotificationOpen(true))
      setShowNotifications(false)
    }
  }, [showNotifications, markNotificationsRead, dispatch])

  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()
    if (showNotifications && isNotificationOpen) {
      handleCloseRequest()
    } else {
      dispatch(SetIsNotificationOpen(true))
      setShowNotifications(true)
    }
  }

  const handleLoadMoreRequest = () => {
    if (!isLoading) {
      loadMore()
    }
  }

  const handleLoadNewRequest = () => {
    if (!isLoading) {
      loadNew()
    }
  }

  const showNotificationPortal = useMemo(
    () => showNotifications && isNotificationOpen,
    [isNotificationOpen, showNotifications]
  )

  return (
    <>
      <Box
        style={{ position: 'fixed', top: '0px', left: '0px' }}
        {...{ ref: cornerRef }}
      />
      <Box>
        <Badge
          badgeContent={unreadCount}
          color="error"
          invisible={(isMobileLayout && showNotifications) || unreadCount === 0}
          $isMobileLayout={isMobileLayout}
        >
          <AppHeaderIconButton
            innerRef={buttonRef}
            onClick={handleButtonClick}
            $isMobileLayout={isMobileLayout}
            data-qa-id="NotificationBell"
            $isDashboard={isDashboard}
          >
            <NotificationsOutlinedIcon />
          </AppHeaderIconButton>
        </Badge>
        <ListPortal
          anchorEl={isMobileLayout ? cornerRef.current : buttonRef.current}
          isMobileLayout={isMobileLayout}
          onCloseRequest={handleCloseRequest}
          open={showNotificationPortal}
        >
          <NotificationsList
            isLoading={isLoading}
            isError={!!error}
            items={notifications}
            fullscreen={isMobileLayout}
            totalNewItems={unreadCount}
            onCloseRequest={handleCloseRequest}
            onRequestLoadNewNotifications={handleLoadNewRequest}
            onRequestLoadPreviousNotifications={handleLoadMoreRequest}
            isFetchingNextPage={isFetchingNextPage}
            isFetchingPreviousPage={isFetchingPreviousPage}
            onRetry={refetch}
          />
        </ListPortal>
      </Box>
    </>
  )
}

const NotificationsErrorBoundary: React.FC<NotificationProps> = ({
  isDashboard,
}) => (
  <ErrorBoundary fallbackComponent={null}>
    <Notifications isDashboard={isDashboard} />
  </ErrorBoundary>
)

export default NotificationsErrorBoundary
