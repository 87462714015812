import { Avatar, Box, BoxProps, Fade } from '@material-ui/core'
import { rgba } from 'polished'
import React, { useRef, useState } from 'react'
import { CameraIcon } from 'src/components/dataDisplay/Illustration'
import StatusDot, {
  Status,
} from 'src/components/inputs/StatusDropdown/StatusDot'
import Menu, { MenuAction } from 'src/components/menu'
import { User, UserProfile } from 'src/generated/graphql'
import { instructorAvailabilityToStatus } from 'src/utils/helpers'
import { isTeachingProfile, isClubProfile } from 'src/utils/typeGuards'
import styled, { css } from 'styled-components'
import defaultAvatar from 'src/assets/images/icons/avatar.png'

const Wrapper = styled(Box)`
  position: relative;
  border-radius: 100%;
`

const Hover = styled(Box)(
  ({ theme }) => css`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    position: absolute;
    align-items: center;
    border-radius: 100%;
    background-color: ${rgba(theme.palette.brand.white, 0.5)};
    > * {
      margin: 0 auto;
    }
  `
)

const IconWrapper = styled(Box)(
  ({ theme }) => css`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 12.5px;
    background-color: ${rgba(theme.palette.brand.black, 0.7)};
    > * {
      margin: 0 auto;
    }
    svg {
      width: 22px;
      height: 22px;
      path {
        stroke: ${theme.palette.brand.white};
      }
    }
  `
)

const ProfilePhotoAvailabilityDot = styled(StatusDot)`
  left: 85.35%;
  top: 85.35%;
  position: absolute;
  transform: translate(-50%, -50%);
`

export const ProfileAvatar = styled(Avatar)<{
  $isSmallVersion?: boolean
  $displayText?: boolean
}>(
  ({ theme, $isSmallVersion, $displayText }) => css`
    width: 100%;
    height: 100%;
    color: ${theme.palette.text.primary};
    font-size: ${theme.typography.pxToRem($isSmallVersion ? 32 : 64)};
    font-weight: ${theme.typography.fontWeightBold};
    background-color: ${$displayText && theme.palette.brand.lightGray};

    font-family: ${theme.typography.fontFamilySecondary};
  `
)

const DEFAULT_SIZE = 124
const DEFAULT_DOT_SIZE = 16

type ProfilePhotoMenu = MenuAction[]

interface ProfilePhotoProps extends BoxProps {
  user: Pick<User, 'firstName'>
  isSmall?: boolean
  profile: UserProfile
  size?: number | string
  menu?: ProfilePhotoMenu
  dotSize?: number | string
  hideAvailability?: boolean
  withAvatar?: boolean
}
const ProfilePhoto: React.FC<ProfilePhotoProps> = ({
  menu,
  onClick,
  hideAvailability,
  user,
  profile,
  isSmall,
  withAvatar,
  size = DEFAULT_SIZE,
  dotSize = DEFAULT_DOT_SIZE,
  ...props
}) => {
  const { firstName } = user
  const { profileImage } = profile
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  const firstLetter = isClubProfile(profile) ? profile?.name[0] : firstName[0]

  let status: Status | undefined

  if (isTeachingProfile(profile) && profile.availability) {
    status = instructorAvailabilityToStatus(profile.availability)
  } else if (isClubProfile(profile)) {
    status = profile.hiring ? Status.Positive : Status.Negative
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (menu) {
      setMenuOpen(!menuOpen)
      return
    }
    if (onClick) {
      onClick(event)
    }
  }
  const handleMenuClose = () => {
    setMenuOpen(false)
    setIsMouseOver(false)
  }
  const handleMouseEnter = () => setIsMouseOver(true)
  const handleMouseLeave = () => setIsMouseOver(false)

  const wrapperRef = useRef()

  return (
    <Wrapper
      width={size}
      height={size}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid={'profile-photo-wrapper'}
      {...props}
    >
      <ProfileAvatar
        src={profileImage?.url || (withAvatar ? defaultAvatar : undefined)}
        $isSmallVersion={isSmall}
        $displayText={!profileImage?.url && !withAvatar}
      >
        {firstLetter}
      </ProfileAvatar>
      {(menu || onClick) && (
        <Fade in={isMouseOver || menuOpen}>
          <Hover>
            <IconWrapper {...{ ref: wrapperRef }}>
              <CameraIcon />
            </IconWrapper>
          </Hover>
        </Fade>
      )}
      {status && !hideAvailability && (
        <ProfilePhotoAvailabilityDot value={status} size={dotSize} />
      )}
      {menu && (
        <Menu
          open={menuOpen}
          actions={menu}
          anchorEl={wrapperRef.current}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
        />
      )}
    </Wrapper>
  )
}

export default ProfilePhoto
