import { CacheLocation, IdToken, useAuth0 } from '@auth0/auth0-react'
import { getLocalStorageKey } from 'src/utils/cypressUtils'

export const useAuth0IdToken = () => {
  const { getIdTokenClaims } = useAuth0()

  // if Cypress is present in window element, it means that integration tests are running
  const isCypressEnv = !!(window as any).Cypress
  const tokenFromLocalStorage =
    isCypressEnv &&
    JSON.parse(
      localStorage.getItem(
        getLocalStorageKey(process.env.REACT_APP_AUTH0_CLIENT_ID!) || ''
      )!
    )

  // TODO: tweak cacheLocation if needed  when Cypress is tied to pipeline
  const isCypressEnvironment =
    process.env.NODE_ENV === 'development' && isCypressEnv

  // if Cypress is present, we set cacheLocation of Auth0Provider as localstorage
  const currentCacheLocation: CacheLocation = isCypressEnvironment
    ? 'localstorage'
    : 'memory'

  const getIdTokenFromLocalStorage = (): Promise<IdToken> =>
    new Promise(resolve => resolve(tokenFromLocalStorage))

  const getIdTokenClaimedOrSaved = isCypressEnvironment
    ? getIdTokenFromLocalStorage
    : getIdTokenClaims

  return {
    currentCacheLocation,
    getIdTokenClaimedOrSaved,
    isCypressEnv,
    tokenFromLocalStorage,
  }
}
