import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import CloseIcon from '@material-ui/icons/Close'
import { Drawer as MuiDrawer, Box, IconButton } from '@material-ui/core'

import {
  SubMenuContainer,
  StyledSubMenuItem,
  NavMenuItemStyleWrapper,
} from 'src/components/navigation/common'
import {
  Profile,
  ProfileContainer,
} from 'src/components/navigation/ProfileSection'
import useUser from 'src/hooks/useUser'
import useProfile from 'src/hooks/useProfile'
import useAppState from 'src/hooks/useAppState'
import { RootMenuItem, MenuItem } from 'src/generated/graphql'
import { CloseMenuAction } from 'src/contexts/AppState'
import { NavMenu } from 'src/components/navigation/NavMenu'
import { NavBarProps } from 'src/components/navigation/types'

const MobileProfile = styled(Profile)`
  ${ProfileContainer} {
    background-color: transparent;
    padding-right: 0;
  }
`

const Drawer = styled(MuiDrawer)(
  ({ theme }) => css`
    .MuiPaper-root {
      width: 100vw;
      position: fixed;
      display: flex;
      flex-direction: column;
      max-width: 316px;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: ${theme.palette.brand.black};
    }
  `
)

const MobileNavMenu = styled(NavMenu)(
  ({ theme }) => css`
    overflow-y: auto;
    ${NavMenuItemStyleWrapper}, ${SubMenuContainer} {
      background-color: transparent;
      padding: ${theme.spacing(0, 0, 0, 4.375)};
    }

    ${SubMenuContainer} {
      padding-left: ${theme.spacing(5.5)}px;
    }
    ${SubMenuContainer} ${StyledSubMenuItem} {
      padding-left: ${theme.spacing(1)}px;
    }
  `
)

const MobileNavHeader = styled(Box)(
  ({ theme }) => css`
    top: 0;
    width: 100%;
    display: flex;
    position: sticky;
    align-items: center;
    justify-content: flex-end;
    height: ${theme.spacing(8)}px;
    background-color: ${theme.palette.brand.black};
  `
)

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    padding: ${theme.spacing(1.5, 0)};
    margin-right: ${theme.spacing(2)}px;
  `
)

const NavBarSmall: React.FC<NavBarProps> = ({
  data,
  disabled,
  loading,
  trackAnalytics,
  ...props
}) => {
  const appState = useAppState()
  const { state, dispatch } = appState
  const { menuIsOpen } = state
  const [selectedId, setSelectedId] = useState('')
  const {
    data: { user },
  } = useUser()
  const {
    data: { profile },
  } = useProfile()

  const handleCloseRequest = () => {
    dispatch(CloseMenuAction())
  }
  const handleItemSelect = (item: any) => {
    if (item?.items?.length) {
      return
    }
    trackAnalytics(item)
    handleCloseRequest()
  }

  const handleSelect = (data: RootMenuItem | MenuItem) => {
    if (!(data as RootMenuItem).items?.length) {
      return handleItemSelect(data)
    }
    return selectedId === data.id ? setSelectedId('') : setSelectedId(data.id)
  }

  return (
    <Drawer
      {...props}
      open={!disabled && menuIsOpen}
      onClose={handleCloseRequest}
      data-qa-id="DeviceNavBar"
    >
      <MobileNavHeader>
        <StyledIconButton color="secondary" onClick={handleCloseRequest}>
          <CloseIcon />
        </StyledIconButton>
      </MobileNavHeader>
      <MobileNavMenu
        data={data}
        renderNested
        loading={loading}
        selectedId={selectedId}
        onSelect={handleSelect}
      />
      {user && (
        <MobileProfile
          showName
          renderMenu
          profile={profile}
          loading={loading}
          onSelect={handleSelect}
          selectedId={selectedId}
          user={user}
        />
      )}
    </Drawer>
  )
}

export default NavBarSmall
