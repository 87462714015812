import React, { createContext, useState } from 'react'

interface UserState {
  selectedProfileId: string
  setSelectedProfileId: (profileId: string) => void
}

const sessionStorage = window.sessionStorage
const PROFILE_ID_KEY = 'profileId'
const initialState: UserState = {
  selectedProfileId: '',
  setSelectedProfileId: () => {},
}

export const profileContext = createContext<UserState>(initialState)

profileContext.displayName = 'ProfileContext'
const { Provider } = profileContext

export const ProfileProvider: React.FC = ({ children }) => {
  const [selectedProfileId, setSelectedProfileId] = useState(
    sessionStorage.getItem(PROFILE_ID_KEY) ?? ''
  )
  const handleSetSelectedProfile = (id: string) => {
    setSelectedProfileId(id)
    sessionStorage.setItem(PROFILE_ID_KEY, id)
  }
  return (
    <Provider
      value={{
        selectedProfileId,
        setSelectedProfileId: handleSetSelectedProfile,
      }}
    >
      {children}
    </Provider>
  )
}
