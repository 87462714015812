import React from 'react'
import styled, { css } from 'styled-components'

import { Box, Grow, Typography } from '@material-ui/core'

import Link from 'src/components/navigation/Link'
import { NotificationItemFragment as Notification } from 'src/generated/graphql-react-query'
import UnreadSign from 'src/components/feedback/ellipse/UnreadSign'
import NotificationTypeIcon from 'src/components/notifications/NotificationTypeIcon'
import { allowNewline } from 'src/utils/helpers'

const Container = styled(Box)<{
  $unread?: boolean
}>(
  ({ theme, $unread }) => css`
    display: flex;
    flex-direction: row;
    padding: ${theme.spacing(2.4, 3, 2, 2)};
    background: ${$unread ? theme.palette.brand.offWhite : 'transparent'};
    border-bottom: 1px solid ${theme.palette.brand.lightGray};
  `
)

const Title = styled(Typography)(
  ({ theme }) => css`
    font-size: ${theme.typography.pxToRem(14)};
    font-weight: bold;
  `
)

const Body = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.brand.darkGray};
    font-size: ${theme.typography.pxToRem(14)};
  `
)

const CreatedLabel = styled.span(
  ({ theme }) => css`
    font-size: ${theme.typography.pxToRem(10)};
  `
)

const ActionLink = styled(Link)(
  ({ theme }) => css`
    display: block;
    margin-top: ${theme.spacing(0.5)}px;
    color: ${theme.palette.success.main};
    font-weight: normal;
  `
)

interface NotificationItemProps {
  item: Notification
  unread?: boolean
  onCloseRequest: () => void
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  item,
  unread,
  onCloseRequest,
}) => {
  return (
    <Container $unread={unread}>
      <Box flex="1">
        <Box display="flex" alignItems="center">
          <NotificationTypeIcon item={item} mr={0.5} />
          <Title variant="h4" {...allowNewline}>
            {item.title}
          </Title>
        </Box>
        <Body {...allowNewline}>
          {item.body} <CreatedLabel>{item.createdAtLabel}</CreatedLabel>
        </Body>
        {item.link && (
          <ActionLink url={item.link.url} onClick={() => onCloseRequest()}>
            {item.link.text}
          </ActionLink>
        )}
      </Box>
      <Box pl={0.5} display="flex" alignItems="center">
        <Grow in={!item.readAt} mountOnEnter>
          <UnreadSign />
        </Grow>
      </Box>
    </Container>
  )
}

export default NotificationItem
