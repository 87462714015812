import React, { Suspense, lazy, useEffect } from 'react'
import { Location } from 'history'
import styled from 'styled-components'
import { Route, Switch, useLocation } from 'react-router-dom'

import { Modal } from '@material-ui/core'

import InstructorDashboard from 'src/components/pageSkeletons/InstructorDashboard'
import TeachingPositions from 'src/components/pageSkeletons/TeachingPositions'
import DefaultPage from 'src/components/pageSkeletons/DefaultPage'
import PageNotFound from './components/PageNotFound'
import useAppState from './hooks/useAppState'
import { getRoutesAfterFeatureFlags } from './utils/applyFeatureFlagsToRoutes'
import { accountCreationUrlEnv } from './utils/account'

export const AccountPaths = {
  creation: `https://${accountCreationUrlEnv()}welcome.lesmills.com/`,
}

// Instructor routes
export enum InstructorPaths {
  Dashboard = '/instructor',
  TeachingProfile = '/instructor/teaching/profile',
  TeachingPositions = '/instructor/find-work',
  TeachingFacilities = '/instructor/facilities',
  TrainingEvents = '/instructor/training-events',
  VideoGuidelines = '/instructor/training-events/video-guidelines',
  Trainer = '/instructor/trainer',
  TrainerEventDetails = '/instructor/trainer/:eventId',
  AccountDetails = '/instructor/account-details',
  AccountSettings = '/instructor/account/settings',
  Feedback = '/instructor/feedback',
  EqualOpportunityPolicy = '/instructor/eop',
  Support = '/instructor/support',
  Faq = '/instructor/faq',
}

// Club routes
export enum ClubPaths {
  Dashboard = '/club',
  ClubProfile = '/club/profile',
  MyPartnership = '/club/my-partnership',
  Affiliates = '/club/affiliates',
  AccountDetails = '/club/account-details',
  FindInstructors = '/club/find-instructors',
  Support = '/club/support',
  Faq = '/club/faq',
  TrainingEvents = '/club/training-events',
}

// Training Events tabs
export enum TrainingEventsSubPaths {
  Booked = '/booked',
  BookedEventDetails = '/booked/:eventId',
  Find = '/find',
  FindEvent = '/find/:eventId',
  EventSelectInstructors = '/find/:eventId/selecting-instructors/:ticketId',
}

// Instructor Find Work tabs
export enum FindWorkSubPaths {
  Search = '/search',
  YourEnquiries = '/your-enquiries',
  YourWatchlist = '/your-watchlist',
}

// Instructor path with params
export enum FindWorkSearchSubPaths {
  Enquire = '/enquire/:id',
  ViewClubProfile = '/club/:id',
}
// Club My Partnership tabs
export enum MyPartnershipSubPaths {
  InStudio = '/in-studio',
  AtHome = '/at-home',
  BusinessSupport = '/business-support',
}

// Club Find Instructors tabs
export enum FindInstructorsSubPaths {
  SearchInstructor = '/search',
  JobListings = '/job-listings',
  ShortLists = '/shortlist',
}

export enum AppPaths {
  Logout = '/logout',
  UnsupportedMarket = '/unsupported-market',
}

export enum ModalPaths {
  SwitchProfile = '/account/switch',
  ProfilePreview = '/profile/:profileType/:id',
}

export const urlToFilePath = (url: string): string => {
  return url.replace(/:([^/]{1,})/g, '[$1]')
}

// Those paths which should not be localized and behind a role
export const NotGuardedPaths: string[] = [AppPaths.Logout]

export const PageSkeletonComponents: {
  [k: string]: React.FunctionComponent
} = {
  [InstructorPaths.Dashboard]: InstructorDashboard,
  [InstructorPaths.TeachingPositions]: TeachingPositions,
}

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const DialogLoading = () => (
  <StyledModal open>
    <div />
  </StyledModal>
)

const PAGES_PATH = './pages'
const MODALS_PATH = './components/modals'

const PageLoading = () => {
  const { pathname } = useLocation()
  const pathMatches = Object.values(InstructorPaths).filter(path =>
    pathname.includes(path)
  )
  // Use the last path in path matches bc the earlier paths in the enum are likely to be present in longer paths
  // e.g /instructor will always match for all of the Instructor paths
  const Component =
    (pathMatches.length > 0 &&
      PageSkeletonComponents[pathMatches[pathMatches.length - 1]]) ||
    DefaultPage
  return <Component />
}

const sortByPathSegmentsNumber = (v1: string, v2: string) => {
  const v1PathsLength = v1.split('/').length
  const v2PathsLength = v2.split('/').length
  return v1PathsLength < v2PathsLength
    ? 1
    : v1PathsLength > v2PathsLength
    ? -1
    : 0
}

const routeModalsFromPath = (paths: typeof ModalPaths) => {
  return Object.values(paths)
    .sort(sortByPathSegmentsNumber)
    .map(path => {
      const filepath = urlToFilePath(`${MODALS_PATH}${path}`)
      const Component = lazy(() => import(`${filepath}`))
      return (
        <Route key={path} path={path}>
          <Suspense fallback={<DialogLoading />} key={path}>
            <Component />
          </Suspense>
        </Route>
      )
    })
}

const routeComponentsFromPaths = (
  paths: typeof InstructorPaths | typeof ClubPaths | typeof AppPaths
) => {
  return Object.values(paths)
    .sort(sortByPathSegmentsNumber)
    .map(path => {
      const filepath = urlToFilePath(`${PAGES_PATH}${path}`)
      return (
        <Route
          key={path}
          path={path}
          component={lazy(() => import(`${filepath}`))}
        />
      )
    })
}

const instructorRoutes = routeComponentsFromPaths(InstructorPaths)
const clubRoutes = routeComponentsFromPaths(ClubPaths)

export const modalPaths = routeModalsFromPath(ModalPaths)

export const AppSwitch = () => {
  const location = useLocation<{ background?: Location }>()
  const background = location?.state?.background

  const cookieBotElement = document.querySelector(
    "div > span[style='font-weight: bold;']"
  )

  const {
    state: { featureFlags },
  } = useAppState()

  useEffect(() => {
    !!cookieBotElement &&
      cookieBotElement?.parentElement?.setAttribute('style', 'display: none;')
  }, [cookieBotElement])

  return (
    <Suspense fallback={<PageLoading />}>
      <Switch location={background || location}>
        {getRoutesAfterFeatureFlags(featureFlags, [
          ...instructorRoutes,
          ...clubRoutes,
        ])}
        <Route path="*" component={PageNotFound} />
      </Switch>
    </Suspense>
  )
}
