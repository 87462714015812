import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Typography, Box, Button } from '@material-ui/core'

// COMPONENTS
import Link from 'src/components/navigation/Link'
import Divider from 'src/components/Divider'
import { ReactComponent as ExternalLinkIcon } from 'src/assets/images/icons/externalLink.svg'

import useMediaQueries from 'src/hooks/useMediaQueries'

// UTILS
import ErrorBoundary from 'src/utils/ErrorBoundary'

// TYPES
import { NavItem, Url } from 'src/generated/graphql'

export const Container = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: ${theme.spacing(1)}px;
    height: auto;
  `
)

const ImageTitleContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: ${theme.spacing(0.5)}px;

    > * {
      margin-right: ${theme.spacing(1)}px;
    }
  `
)

const HorizontalDivider = styled(Divider)(
  ({ theme }) => css`
    transform: rotate(180deg);
    height: 15px;
    width: 1px;
    background-color: ${theme.palette.brand.black};
  `
)

const StyledButtonItem = styled(Button)`
  width: 100%;
  min-height: 85px;
  height: fit-content;
`

const StyledExternalLinkIcon = styled(ExternalLinkIcon)(
  ({ theme }) => css`
    && {
      width: 24px;
      height: 24px;
      margin-left: auto;
      margin-right: 0;
      path {
        stroke: ${theme.palette.brand.black};
      }
    }
  `
)

const ContainerItemBody = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledImg = styled.img`
  image-rendering: ${`-webkit-optimize-contrast`}; /* Required for Edge & Chrome issues on mac */
`

export interface NavigationItemProps extends Partial<NavItem> {
  onClick?: () => void
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  title,
  description,
  url,
  image,
  onClick,
}) => {
  const [showExternalLinkIcon, setShowExternalLinkIcon] = useState(false)

  const { isSmallScreen } = useMediaQueries()

  return (
    <Link url={url as Url} onClick={onClick}>
      <StyledButtonItem
        variant="outlined"
        onMouseOver={() => setShowExternalLinkIcon(true)}
        onMouseLeave={() => setShowExternalLinkIcon(false)}
      >
        <ContainerItemBody>
          <Container>
            <ImageTitleContainer>
              <StyledImg
                src={image?.url}
                alt={image?.alt || ''}
                width={82}
                height={26}
              />
              {title && (
                <>
                  <HorizontalDivider />
                  <Typography variant="body1">{title}</Typography>
                </>
              )}
            </ImageTitleContainer>
            <Typography color="textSecondary" style={{ textAlign: 'left' }}>
              {description}
            </Typography>
          </Container>
          {showExternalLinkIcon && !isSmallScreen && <StyledExternalLinkIcon />}
        </ContainerItemBody>
      </StyledButtonItem>
    </Link>
  )
}

const NavigationItemWithErrorBoundary: React.FC<NavigationItemProps> =
  props => (
    <ErrorBoundary>
      <NavigationItem {...props} />
    </ErrorBoundary>
  )

export default NavigationItemWithErrorBoundary
