import { isErrorResult } from 'src/utils/typeGuards'
import { Flags, FlagsProps } from 'src/hooks/featureFlag/types'

import { InitApplicationQuery } from 'src/generated/graphql-react-query'

const useFeatureFlag = () => {
  const featureFlagsToQuery = Object.values(Flags)

  const getFeatureFlags = (
    data: InitApplicationQuery['getFeatureFlag']
  ): FlagsProps | undefined => {
    if (!data || isErrorResult(data)) {
      return undefined
    }

    if (data && data.flags) {
      const filteredFFs = featureFlagsToQuery.reduce((preFF, currentFF) => {
        const isInServerFFsAndEnabled =
          data.flags &&
          data.flags.some(
            item => item.featureFlag === currentFF && item.enabled
          )
        return { ...preFF, [currentFF]: isInServerFFsAndEnabled }
      }, {}) as FlagsProps
      return filteredFFs
    }

    return featureFlagsToQuery.reduce((preFF, currentFF) => {
      return { ...preFF, [currentFF]: false }
    }, {}) as FlagsProps
  }

  return {
    data: {
      featureFlagsToQuery,
    },
    operations: { getFeatureFlags },
  }
}

export default useFeatureFlag
