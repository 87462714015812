import { GraphQLClient } from 'graphql-request';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(client: GraphQLClient, query: string, variables?: TVariables) {
  return async (): Promise<TData> => client.request<TData, TVariables>(query, variables);
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
  AWSEmail: any;
  AWSIPAddress: any;
  AWSJSON: any;
  AWSPhone: any;
  AWSTime: any;
  AWSTimestamp: any;
  AWSURL: any;
  Long: any;
};











export type AddClubListingInput = {
  clubId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  isSubstituteListing?: Maybe<Scalars['Boolean']>;
  programs: Array<Scalars['String']>;
  times?: Maybe<Array<TimePreferenceInput>>;
};

export type AddClubListingResponse = ClubListingResult | ErrorResult;

export type AddEmergencyContactResponse = AddEmergencyContactResult | ErrorResult;

export type AddEmergencyContactResult = {
  __typename?: 'AddEmergencyContactResult';
  result?: Maybe<EmergencyContact>;
};

export type AddToShortlistInput = {
  userId: Scalars['String'];
};

export type AddToShortlistResponse = AddToShortlistResult | ErrorResult;

export type AddToShortlistResult = {
  __typename?: 'AddToShortlistResult';
  shortlist?: Maybe<Array<InstructorShortlist>>;
};

export type AddTrainingOutcomeInput = {
  attendanceStatus: AttendedStatusEnum;
  developmentSheet?: Maybe<DevelopmentSheetInput>;
  outcomeStatus?: Maybe<OutcomeEnum>;
  trainingJourneyId: Scalars['String'];
};

export type AddTrainingOutcomeResponse = AddTrainingOutcomeResult | ErrorResult;

export type AddTrainingOutcomeResult = {
  __typename?: 'AddTrainingOutcomeResult';
  developmentSheet?: Maybe<DevelopmentSheet>;
  isSuccess?: Maybe<Scalars['Boolean']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  location?: Maybe<GeoPoint>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  location?: Maybe<GeoPointInput>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
};

export type Affiliation = {
  __typename?: 'Affiliation';
  actionedDate?: Maybe<Scalars['AWSDateTime']>;
  clubId: Scalars['ID'];
  id: Scalars['ID'];
  isDigitalOnly?: Maybe<Scalars['Boolean']>;
  latestActionInitiator?: Maybe<UserRoleType>;
  pendingStatus?: Maybe<AffiliationStatus>;
  requestedBy: UserRoleType;
  requestedDate: Scalars['AWSDateTime'];
  role: AffiliationRole;
  status: AffiliationStatus;
  userId: Scalars['ID'];
};

export type AffiliationAsyncResponse = AffiliationAsyncResult | ErrorResult;

export type AffiliationAsyncResult = {
  __typename?: 'AffiliationAsyncResult';
  affilation?: Maybe<Affiliation>;
  hasPendingAffiliations?: Maybe<Scalars['Boolean']>;
  status: Scalars['String'];
};

export type AffiliationInput = {
  actionedDate?: Maybe<Scalars['AWSDateTime']>;
  clubId: Scalars['ID'];
  id: Scalars['ID'];
  requestedBy: UserRoleType;
  requestedDate: Scalars['AWSDateTime'];
  role: AffiliationRole;
  status: AffiliationStatus;
  userId: Scalars['ID'];
};

export enum AffiliationRole {
  Gfm = 'GFM',
  Instructor = 'INSTRUCTOR'
}

export enum AffiliationStatus {
  Setup = 'SETUP',
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Removed = 'REMOVED'
}

export type AgreementDocument = {
  __typename?: 'AgreementDocument';
  content: Scalars['String'];
  id: Scalars['ID'];
  market: Scalars['String'];
  type: AgreementType;
  version: Scalars['String'];
};

export type AgreementDocumentResponse = AgreementDocumentResult | ErrorResult;

export type AgreementDocumentResult = {
  __typename?: 'AgreementDocumentResult';
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  signedOn?: Maybe<Scalars['AWSDateTime']>;
  type: AgreementType;
  version: Scalars['String'];
};

export enum AgreementType {
  DataPrivacy = 'DATA_PRIVACY'
}

export enum AnalyticsEvent {
  NavigationAssessments = 'NAVIGATION_ASSESSMENTS',
  NavigationCertifications = 'NAVIGATION_CERTIFICATIONS',
  NavigationEventsTraining = 'NAVIGATION_EVENTS_TRAINING',
  NavigationFindEventsAndTraining = 'NAVIGATION_FIND_EVENTS_AND_TRAINING',
  NavigationMyEventsAndTraining = 'NAVIGATION_MY_EVENTS_AND_TRAINING',
  NavigationHome = 'NAVIGATION_HOME',
  NavigationInstructorsFindInstructors = 'NAVIGATION_INSTRUCTORS_FIND_INSTRUCTORS',
  NavigationInstructorsYourClubProfile = 'NAVIGATION_INSTRUCTORS_YOUR_CLUB_PROFILE',
  NavigationInstructorsYourPeople = 'NAVIGATION_INSTRUCTORS_YOUR_PEOPLE',
  NavigationMoreContactSupport = 'NAVIGATION_MORE_CONTACT_SUPPORT',
  NavigationMoreEqualOpportunities = 'NAVIGATION_MORE_EQUAL_OPPORTUNITIES',
  NavigationMoreFaq = 'NAVIGATION_MORE_FAQ',
  NavigationMoreGiveFeedback = 'NAVIGATION_MORE_GIVE_FEEDBACK',
  NavigationMorePrivacyPolicy = 'NAVIGATION_MORE_PRIVACY_POLICY',
  NavigationMoreWebsiteTerms = 'NAVIGATION_MORE_WEBSITE_TERMS',
  NavigationProfileLogOut = 'NAVIGATION_PROFILE_LOG_OUT',
  NavigationProfileSwitchAccount = 'NAVIGATION_PROFILE_SWITCH_ACCOUNT',
  NavigationProfileYourAccount = 'NAVIGATION_PROFILE_YOUR_ACCOUNT',
  NavigationProfileViewProfile = 'NAVIGATION_PROFILE_VIEW_PROFILE',
  NavigationPrograms = 'NAVIGATION_PROGRAMS',
  NavigationReleases = 'NAVIGATION_RELEASES',
  NavigationTeachingFindClasses = 'NAVIGATION_TEACHING_FIND_CLASSES',
  NavigationTeachingMyFacilities = 'NAVIGATION_TEACHING_MY_FACILITIES',
  NavigationTeachingMyProfile = 'NAVIGATION_TEACHING_MY_PROFILE',
  NavigationTrainer = 'NAVIGATION_TRAINER',
  NavigationManageMyPartnership = 'NAVIGATION_MANAGE_MY_PARTNERSHIP',
  NavigationBuyReleases = 'NAVIGATION_BUY_RELEASES',
  NavigationMyReleases = 'NAVIGATION_MY_RELEASES'
}

export type AppMetadataResponse = AppMetadataResult | ErrorResult;

export type AppMetadataResult = {
  __typename?: 'AppMetadataResult';
  isSuccess: Scalars['Boolean'];
};

export type AssessmentVideo = {
  __typename?: 'AssessmentVideo';
  assessmentId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  extensionRequested: Scalars['Boolean'];
  fileKey?: Maybe<Scalars['String']>;
  playback?: Maybe<Playback>;
  recordType: RecordType;
  submitted: Scalars['Boolean'];
  submittedDate?: Maybe<Scalars['String']>;
  videoNotWorking: Scalars['Boolean'];
};

export type AtHomeProductTile = {
  __typename?: 'AtHomeProductTile';
  buttons?: Maybe<Array<Link>>;
  description?: Maybe<Scalars['String']>;
  image: ImageSet;
  onlineProgram: ClubOnlineProgram;
  programs?: Maybe<Array<ClubProgram>>;
  title: Scalars['String'];
};

export enum AtHomeProgramNameEnum {
  Stretch = 'STRETCH',
  Mindfullness = 'MINDFULLNESS',
  Wellness = 'WELLNESS',
  TheTripImmersive = 'THE_TRIP_IMMERSIVE',
  LesMillsGritAthletic = 'LES_MILLS_GRIT_ATHLETIC',
  LesMillsGritStrength = 'LES_MILLS_GRIT_STRENGTH',
  LesMillsGritCardio = 'LES_MILLS_GRIT_CARDIO'
}

export enum AtHomeSpecificProgramCodeEnum {
  W = 'W',
  St = 'ST',
  Mf = 'MF',
  Gc = 'GC',
  Ga = 'GA',
  Gs = 'GS',
  Trv = 'TRV',
  Trl = 'TRL'
}

export type AttendTrainingStep = Step & {
  __typename?: 'AttendTrainingStep';
  activeStepName: Scalars['String'];
  activeStepNumber: Scalars['Int'];
  attended?: Maybe<AttendedStatusEnum>;
  cancellationPolicy: Scalars['AWSJSON'];
  contactUsEmail: Scalars['String'];
  deliveryMethod: DeliveryMethodEnum;
  details?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  eventName: Scalars['String'];
  fulfillment?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  onlineTrainingLink?: Maybe<Scalars['String']>;
  passPendingGuide: Scalars['AWSJSON'];
  programName: Scalars['String'];
  requirements?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  stepName: Scalars['String'];
  stepNumber: Scalars['Int'];
  timezone: Scalars['String'];
  trackAllocation?: Maybe<Scalars['String']>;
  trainerEmail?: Maybe<Scalars['String']>;
  trainerName?: Maybe<Scalars['String']>;
  venueAddress?: Maybe<Scalars['String']>;
};

export enum AttendedStatusEnum {
  Attended = 'ATTENDED',
  DidNotAttend = 'DID_NOT_ATTEND',
  Incomplete = 'INCOMPLETE'
}

export enum BilledCertificateActionEnum {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type BilledCertificationInput = {
  actionType: BilledCertificateActionEnum;
  id: Scalars['ID'];
};

export type BookEventInput = {
  eventId: Scalars['String'];
  instructorIds: Array<Scalars['String']>;
  ticketId: Scalars['String'];
};

export type BookEventResponse = BookEventResult | ErrorResult;

export type BookEventResult = {
  __typename?: 'BookEventResult';
  magentoLink: Scalars['String'];
};

export type BookedEvent = {
  __typename?: 'BookedEvent';
  event: Event;
  numberOfBookedInstructors: Scalars['Int'];
};

export type BookedEventResponse = BookedEventResult | ErrorResult;

export type BookedEventResult = {
  __typename?: 'BookedEventResult';
  bookedInstructors?: Maybe<Array<InstructorDetails>>;
  eventDetails: EventDetailsResult;
};

export type BookedEventsResponse = BookedEventsResult | ErrorResult;

export type BookedEventsResult = {
  __typename?: 'BookedEventsResult';
  bookedEvents?: Maybe<Array<BookedEvent>>;
};

export type BusinessSupportSection = {
  __typename?: 'BusinessSupportSection';
  id: Scalars['ID'];
  sectionTiles?: Maybe<Array<BusinessSupportTile>>;
  sectionTitle: Scalars['String'];
};

export type BusinessSupportTile = {
  __typename?: 'BusinessSupportTile';
  id: Scalars['ID'];
  image: Image;
  subtitle: Scalars['String'];
  title: Scalars['String'];
  url: Url;
};

export type CmsPaginationInput = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Certification = {
  __typename?: 'Certification';
  active: Scalars['Boolean'];
  billedClubId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastPurchase?: Maybe<Scalars['String']>;
  link: Link;
  lmqLevel: Scalars['Float'];
  pricePerQuarter?: Maybe<CertificationPrice>;
  program: Program;
  releaseStatus?: Maybe<ReleaseStatusEnum>;
  snapshotState?: Maybe<SnapshotStateEnum>;
  status: CertificationStatusEnum;
  steps?: Maybe<Array<CertificationStep>>;
  transactionStatus?: Maybe<TransactionStatusEnum>;
  transactionTimestamp?: Maybe<Scalars['Long']>;
};

export type CertificationPrice = {
  __typename?: 'CertificationPrice';
  amount: Scalars['Float'];
  currency: Currency;
};

export enum CertificationStatusEnum {
  NotCertified = 'NOT_CERTIFIED',
  Certified = 'CERTIFIED',
  Advanced = 'ADVANCED',
  Elite = 'ELITE',
  Aim_1Complete = 'AIM_1_COMPLETE'
}

export type CertificationStep = {
  __typename?: 'CertificationStep';
  action?: Maybe<Link>;
  id: Scalars['ID'];
  label: Scalars['String'];
  status: CertificationStepStatusEnum;
};

export type CertificationStepInput = {
  action?: Maybe<LinkCertificationInput>;
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  status?: Maybe<CertificationStepStatusEnum>;
};

export enum CertificationStepStatusEnum {
  Todo = 'TODO',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS'
}

export type CertifiedStep = {
  __typename?: 'CertifiedStep';
  activeStepName: Scalars['String'];
  activeStepNumber: Scalars['Int'];
  attended?: Maybe<AttendedStatusEnum>;
  cancellationPolicy: Scalars['AWSJSON'];
  contactUsEmail: Scalars['String'];
  deliveryMethod: DeliveryMethodEnum;
  details?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  eventName: Scalars['String'];
  fulfillment?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  onlineTrainingLink?: Maybe<Scalars['String']>;
  passPendingGuide: Scalars['AWSJSON'];
  programName: Scalars['String'];
  requirements?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  stepName: Scalars['String'];
  stepNumber: Scalars['Int'];
  timezone: Scalars['String'];
  trackAllocation?: Maybe<Scalars['String']>;
  trainerEmail?: Maybe<Scalars['String']>;
  trainerName?: Maybe<Scalars['String']>;
  venueAddress?: Maybe<Scalars['String']>;
};

export type ChainAffiliation = {
  __typename?: 'ChainAffiliation';
  actionedDate?: Maybe<Scalars['AWSDateTime']>;
  clubId: Scalars['ID'];
  clubName: Scalars['String'];
  id: Scalars['ID'];
  latestActionInitiator?: Maybe<UserRoleType>;
  pendingStatus?: Maybe<AffiliationStatus>;
  requestedBy: UserRoleType;
  requestedDate: Scalars['AWSDateTime'];
  role: AffiliationRole;
  status: AffiliationStatus;
  userId: Scalars['ID'];
};

export type ChainAffiliationsRemoveResponse = ChainAffiliationsRemoveRresult | ErrorResult;

export type ChainAffiliationsRemoveRresult = {
  __typename?: 'ChainAffiliationsRemoveRresult';
  receivedAffiliationIds?: Maybe<Array<Scalars['ID']>>;
  removedAffiliationIds?: Maybe<Array<Scalars['ID']>>;
  removedUserId: Scalars['ID'];
};

export type ChainAffiliationsResponse = ChainAffiliationsResult | ErrorResult;

export type ChainAffiliationsResult = {
  __typename?: 'ChainAffiliationsResult';
  successfulAffiliations?: Maybe<Array<ChainAffiliation>>;
};

export type ChainUserResponse = GetChainUser | ErrorResult;

export type Club = {
  __typename?: 'Club';
  accountId: Scalars['ID'];
  affiliations?: Maybe<Array<Affiliation>>;
  createdAt: Scalars['AWSDateTime'];
  currency?: Maybe<Currency>;
  email?: Maybe<EmailClubType>;
  id: Scalars['ID'];
  market: Market;
  name: Scalars['String'];
  parent?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile: UserProfileClub;
};

export type ClubAffiliates = {
  __typename?: 'ClubAffiliates';
  actionedDate?: Maybe<Scalars['AWSDateTime']>;
  clubId: Scalars['String'];
  distance?: Maybe<Scalars['Float']>;
  distanceUnit?: Maybe<LocationDistanceUnit>;
  id: Scalars['ID'];
  isDigitalOnly?: Maybe<Scalars['Boolean']>;
  pendingStatus?: Maybe<AffiliationStatus>;
  profile: UserProfileClub;
  requestedBy: UserRoleType;
  requestedDate: Scalars['AWSDateTime'];
  status: AffiliationStatus;
};

export type ClubAffiliationsResult = {
  __typename?: 'ClubAffiliationsResult';
  affiliations?: Maybe<Array<Affiliation>>;
};

export type ClubAvailabilityOnListing = {
  __typename?: 'ClubAvailabilityOnListing';
  clubAddress?: Maybe<Address>;
  clubId: Scalars['ID'];
  clubName: Scalars['String'];
  clubProfileImage?: Maybe<Image>;
  isAlreadyListed?: Maybe<Scalars['Boolean']>;
  isClubLicensed?: Maybe<Scalars['Boolean']>;
};

export type ClubListing = ClubSingleListing | ClubMultiListing;

export type ClubListingCommon = {
  clubInfo: UserProfileClub;
  createdAt: Scalars['AWSDateTime'];
  description?: Maybe<Scalars['String']>;
  enquiries: Array<ClubListingEnquiry>;
  enquiriesCount: Scalars['Int'];
  expiringAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  searchAppearances?: Maybe<Scalars['Int']>;
  status: ClubListingStatus;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  views: Scalars['Int'];
};

export type ClubListingEnquiry = {
  __typename?: 'ClubListingEnquiry';
  club: UserProfileClub;
  createdAt: Scalars['AWSDateTime'];
  instructor: UserProfileTeaching;
  listing: ClubListing;
  message?: Maybe<Scalars['String']>;
  program: Program;
};

export type ClubListingResult = {
  __typename?: 'ClubListingResult';
  results?: Maybe<Array<ClubListing>>;
};

export enum ClubListingStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Expired = 'EXPIRED'
}

export type ClubListingUnknown = {
  __typename?: 'ClubListingUnknown';
  clubInfo: UserProfileClub;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  description?: Maybe<Scalars['String']>;
  expiringAt?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  program?: Maybe<ClubProgram>;
  searchAppearances?: Maybe<Scalars['Int']>;
  status?: Maybe<ClubListingStatus>;
  times?: Maybe<Array<TimePreference>>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  views?: Maybe<Scalars['Int']>;
};

export type ClubMultiListing = ClubListingCommon & {
  __typename?: 'ClubMultiListing';
  clubInfo: UserProfileClub;
  createdAt: Scalars['AWSDateTime'];
  description?: Maybe<Scalars['String']>;
  enquiries: Array<ClubListingEnquiry>;
  enquiriesCount: Scalars['Int'];
  expiringAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  programs: Array<ClubProgram>;
  searchAppearances?: Maybe<Scalars['Int']>;
  status: ClubListingStatus;
  times: Array<TimePreference>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  views: Scalars['Int'];
};

export type ClubOnlineProgram = {
  __typename?: 'ClubOnlineProgram';
  activeSince?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  lastEnquiredOn?: Maybe<Scalars['AWSDateTime']>;
};

export type ClubPartnershipBusinessSupportContent = {
  __typename?: 'ClubPartnershipBusinessSupportContent';
  description: Scalars['String'];
  link: Link;
  title: Scalars['String'];
};

export type ClubPartnershipProgramStat = {
  __typename?: 'ClubPartnershipProgramStat';
  icon: Image;
  total: Scalars['Int'];
  type: ProgramTypeEnum;
};

export type ClubPartnershipProgramStats = {
  __typename?: 'ClubPartnershipProgramStats';
  programsStats?: Maybe<Array<ClubPartnershipProgramStat>>;
};

export type ClubPartnershipStandardContent = {
  __typename?: 'ClubPartnershipStandardContent';
  image: ImageSet;
  link: Link;
  subtitle: Scalars['String'];
  title: Scalars['String'];
};

export type ClubProfile = {
  __typename?: 'ClubProfile';
  bio?: Maybe<Scalars['String']>;
  hiring?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  links?: Maybe<Array<ProfileLink>>;
  media?: Maybe<Array<Image>>;
  profileImage?: Maybe<Image>;
  programs?: Maybe<Array<ClubProgram>>;
  shortlist?: Maybe<Array<InstructorShortlist>>;
  strength?: Maybe<Scalars['Int']>;
};

export type ClubProfileInput = {
  address: AddressInput;
  bio?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  createdAtLabel?: Maybe<Scalars['String']>;
  hiring: Scalars['Boolean'];
  links?: Maybe<Array<ProfileLinkInput>>;
  media?: Maybe<Array<ImageInput>>;
  strength?: Maybe<Scalars['Int']>;
};

export type ClubProgram = ProgramInterface & {
  __typename?: 'ClubProgram';
  code: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initials: Scalars['String'];
  instructorCount?: Maybe<ProgramInstructorCount>;
  name: Scalars['String'];
  programType?: Maybe<ProgramTypeEnum>;
  status?: Maybe<ProgramStatus>;
};

export type ClubProgramContent = ClubProgram | ClubProgramInStudioEmptyState;

export type ClubProgramInStudioEmptyState = {
  __typename?: 'ClubProgramInStudioEmptyState';
  content?: Maybe<InStudioEmptyState>;
  programType?: Maybe<ProgramTypeEnum>;
};

export type ClubSingleListing = ClubListingCommon & {
  __typename?: 'ClubSingleListing';
  clubInfo: UserProfileClub;
  createdAt: Scalars['AWSDateTime'];
  description?: Maybe<Scalars['String']>;
  enquiries: Array<ClubListingEnquiry>;
  enquiriesCount: Scalars['Int'];
  expiringAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isSubstituteListing: Scalars['Boolean'];
  program: ClubProgram;
  searchAppearances?: Maybe<Scalars['Int']>;
  status: ClubListingStatus;
  times: Array<TimePreference>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  views: Scalars['Int'];
};

export type ClubWithAffiliation = {
  __typename?: 'ClubWithAffiliation';
  clubId: Scalars['ID'];
  clubName: Scalars['String'];
  isDigitalOnly?: Maybe<Scalars['Boolean']>;
  latestAffiliation?: Maybe<Affiliation>;
};

export type ContactUsStep = {
  __typename?: 'ContactUsStep';
  activeStepName: Scalars['String'];
  activeStepNumber: Scalars['Int'];
  contactUsEmail: Scalars['String'];
  stepName: Scalars['String'];
  stepNumber: Scalars['Int'];
};

export type ContentField = {
  __typename?: 'ContentField';
  contentText: Scalars['AWSJSON'];
  subHeading: Scalars['String'];
  title: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  currency: Currency;
  locale: Locale;
  name: Scalars['String'];
};

export enum CountryEnum {
  UnitedKingdom = 'UNITED_KINGDOM',
  Ireland = 'IRELAND',
  Bahamas = 'BAHAMAS',
  Bermuda = 'BERMUDA',
  CarribeanIslands = 'CARRIBEAN_ISLANDS',
  PuertoRico = 'PUERTO_RICO',
  StLucia = 'ST_LUCIA',
  UnitedStates = 'UNITED_STATES',
  UsVirginIslands = 'US_VIRGIN_ISLANDS',
  Bulgaria = 'BULGARIA',
  CzechRepublic = 'CZECH_REPUBLIC',
  Denmark = 'DENMARK',
  Estonia = 'ESTONIA',
  Finland = 'FINLAND',
  Latvia = 'LATVIA',
  Lithuania = 'LITHUANIA',
  Moldova = 'MOLDOVA',
  Norway = 'NORWAY',
  Poland = 'POLAND',
  Romania = 'ROMANIA',
  Slovakia = 'SLOVAKIA',
  Sweden = 'SWEDEN',
  Austria = 'AUSTRIA',
  Germany = 'GERMANY',
  Switzerland = 'SWITZERLAND',
  Armenia = 'ARMENIA',
  Bahrain = 'BAHRAIN',
  Iraq = 'IRAQ',
  Iran = 'IRAN',
  Jordan = 'JORDAN',
  Kuwait = 'KUWAIT',
  Oman = 'OMAN',
  Pakistan = 'PAKISTAN',
  Qatar = 'QATAR',
  SaudiArabia = 'SAUDI_ARABIA',
  SouthAfrica = 'SOUTH_AFRICA',
  Syria = 'SYRIA',
  UnitedArabEmirates = 'UNITED_ARAB_EMIRATES',
  Zambia = 'ZAMBIA',
  Azerbaijan = 'AZERBAIJAN',
  Belarus = 'BELARUS',
  Georgia = 'GEORGIA',
  Kazakhstan = 'KAZAKHSTAN',
  Russia = 'RUSSIA',
  Uzbekistan = 'UZBEKISTAN',
  China = 'CHINA',
  Japan = 'JAPAN',
  Brazil = 'BRAZIL'
}

export type CountryInput = {
  currency: Currency;
  locale: Locale;
  name: Scalars['String'];
};

export type CreateAffiliationInput = {
  clubId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CreateAffiliationsInput = {
  clubIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type CreateClubListingsInput = {
  clubIds: Array<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  isSubstituteListing?: Maybe<Scalars['Boolean']>;
  programName: Scalars['String'];
  times: Array<TimePreferenceInput>;
};

export type CreateClubListingsResponse = CreateClubListingsResult | ErrorResult;

export type CreateClubListingsResult = {
  __typename?: 'CreateClubListingsResult';
  successfulListings?: Maybe<Array<ClubSingleListing>>;
};

export type CreateInstructorAccountInput = {
  country?: Maybe<CountryEnum>;
  email?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  isOptInCorrespondence?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  recaptchaToken?: Maybe<Scalars['String']>;
  state?: Maybe<StatesEnum>;
};

export type CreateInstructorAccountResponse = CreateInstructorAccountResult | ErrorResult;

export type CreateInstructorAccountResult = {
  __typename?: 'CreateInstructorAccountResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateNotificationInput = {
  body: Scalars['String'];
  link?: Maybe<LinkInput>;
  recipient?: Maybe<CreateNotificationRecipientInput>;
  title: Scalars['String'];
  type: NotificationType;
};

export type CreateNotificationRecipientInput = {
  id: Scalars['ID'];
  type: UserRoleType;
};

export enum Currency {
  Aed = 'AED',
  Brl = 'BRL',
  Cny = 'CNY',
  Czk = 'CZK',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Inr = 'INR',
  Jpy = 'JPY',
  Nok = 'NOK',
  Nzd = 'NZD',
  Pln = 'PLN',
  Rub = 'RUB',
  Sek = 'SEK',
  Twd = 'TWD',
  Usd = 'USD',
  Zar = 'ZAR',
  Sar = 'SAR'
}

export type DashboardMyTools = {
  __typename?: 'DashboardMyTools';
  id: Scalars['ID'];
  imageSet: ImageSet;
  subtitle: Scalars['String'];
  title: Scalars['String'];
  url: Url;
};

export type DashboardPageClubResponse = {
  __typename?: 'DashboardPageClubResponse';
  id: Scalars['ID'];
  links?: Maybe<Array<LinkTile>>;
  myTools?: Maybe<Array<DashboardMyTools>>;
  partnership?: Maybe<Array<PartnershipContent>>;
  promotions?: Maybe<Array<Promotion>>;
};

export type DashboardPageInstructorResponse = {
  __typename?: 'DashboardPageInstructorResponse';
  certifications?: Maybe<Array<Certification>>;
  id: Scalars['ID'];
  links?: Maybe<Array<LinkTile>>;
  promotions?: Maybe<Array<Promotion>>;
  socialMedia: SocialMediaSection;
  translation: DashboardPageTranslation;
};

export type DashboardPageTranslation = {
  __typename?: 'DashboardPageTranslation';
  title: Scalars['String'];
};

export type DateTimeFilter = {
  __typename?: 'DateTimeFilter';
  limit: Scalars['Int'];
  type: DateTimeFilterType;
  value: Scalars['AWSDateTime'];
};

export type DateTimeFilterInput = {
  limit: Scalars['Int'];
  type: DateTimeFilterType;
  value: Scalars['String'];
};

export enum DateTimeFilterType {
  Before = 'BEFORE',
  After = 'AFTER'
}

export type DateTimeFilteredResponse = {
  filter: DateTimeFilter;
  totalCount: Scalars['Int'];
};

export enum DayOfWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export type DeleteAffiliationInput = {
  id: Scalars['String'];
};

export type DeleteAffiliationsInput = {
  affiliationIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type DeleteClubListingInput = {
  clubId: Scalars['ID'];
  listingId: Scalars['ID'];
};

export type DeleteClubListingResponse = DeleteClubListingResult | ErrorResult;

export type DeleteClubListingResult = {
  __typename?: 'DeleteClubListingResult';
  clubId: Scalars['ID'];
  listingId: Scalars['ID'];
  results: Array<ClubListing>;
  status: Scalars['String'];
};

export type DeleteClubListingsInput = {
  clubIds?: Maybe<Array<Scalars['ID']>>;
  isSubstituteListing?: Maybe<Scalars['Boolean']>;
  programName?: Maybe<Scalars['String']>;
};

export type DeleteClubListingsResponse = DeleteClubListingsResult | ErrorResult;

export type DeleteClubListingsResult = {
  __typename?: 'DeleteClubListingsResult';
  success?: Maybe<Array<ClubSingleListing>>;
};

export type DeleteCurrentUserPortfolioImageInput = {
  id: Scalars['ID'];
};

export type DeleteCurrentUserPortfolioImageResponse = DeleteCurrentUserPortfolioImageResult | ErrorResult;

export type DeleteCurrentUserPortfolioImageResult = {
  __typename?: 'DeleteCurrentUserPortfolioImageResult';
  status: Scalars['String'];
};

export type DeleteNotificationInput = {
  id: Scalars['ID'];
};

export type DeleteNotificationResponse = DeleteNotificationResult | ErrorResult;

export type DeleteNotificationResult = {
  __typename?: 'DeleteNotificationResult';
  id: Scalars['ID'];
  result: Scalars['Boolean'];
};

export enum DeliveryMethodEnum {
  Online = 'ONLINE',
  InPerson = 'IN_PERSON',
  InHouse = 'IN_HOUSE',
  Webinar = 'WEBINAR',
  All = 'ALL'
}

export type DevelopmentSheet = {
  __typename?: 'DevelopmentSheet';
  filename: Scalars['String'];
  url: Scalars['String'];
};

export type DevelopmentSheetInput = {
  contentType: Scalars['String'];
  fileBase64String: Scalars['String'];
  filename: Scalars['String'];
};

export type DynamoMigrateInput = {
  requireFilter: RequireFilter;
  schemaProperty: Scalars['String'];
  showRecords: Scalars['Boolean'];
  subProperty?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  updateRecords: Scalars['Boolean'];
};

export type DynamoMigrateResponse = DynamoMigrateResult | ErrorResult;

export type DynamoMigrateResult = {
  __typename?: 'DynamoMigrateResult';
  numOfChangedRecords?: Maybe<Scalars['Int']>;
  numOfTotalRecords?: Maybe<Scalars['Int']>;
  report: Scalars['String'];
  schema: Scalars['String'];
  status: Scalars['String'];
};

export enum Event_Sort_By {
  Soonest = 'SOONEST',
  Nearest = 'NEAREST'
}

export type EmailClubType = {
  __typename?: 'EmailClubType';
  affiliations?: Maybe<Scalars['AWSEmail']>;
  jobListings?: Maybe<Scalars['AWSEmail']>;
  main?: Maybe<Scalars['AWSEmail']>;
};

export type EmailClubTypeInput = {
  affiliations?: Maybe<Scalars['AWSEmail']>;
  jobListings?: Maybe<Scalars['AWSEmail']>;
  main?: Maybe<Scalars['AWSEmail']>;
};

export enum EmailStatusEnum {
  Available = 'AVAILABLE',
  EmailInUse = 'EMAIL_IN_USE',
  DeactivatedAccount = 'DEACTIVATED_ACCOUNT',
  GfmAccount = 'GFM_ACCOUNT'
}

export enum EmailTemplateEnum {
  InstructorAffiliationRequest = 'INSTRUCTOR_AFFILIATION_REQUEST',
  InstructorAffiliationApproved = 'INSTRUCTOR_AFFILIATION_APPROVED',
  InstructorAffiliationDeclined = 'INSTRUCTOR_AFFILIATION_DECLINED',
  InstructorAffiliationRemoved = 'INSTRUCTOR_AFFILIATION_REMOVED',
  InstructorClubListingAlert = 'INSTRUCTOR_CLUB_LISTING_ALERT',
  InstructorCreditCardExpiry = 'INSTRUCTOR_CREDIT_CARD_EXPIRY',
  InstructorClubMessage = 'INSTRUCTOR_CLUB_MESSAGE',
  InstructorClubPaymentStart = 'INSTRUCTOR_CLUB_PAYMENT_START',
  InstructorClubPaymentStop = 'INSTRUCTOR_CLUB_PAYMENT_STOP',
  InstructorEmailChangeFyi = 'INSTRUCTOR_EMAIL_CHANGE_FYI',
  ClubAffiliationRequest = 'CLUB_AFFILIATION_REQUEST',
  ClubAffiliationApproved = 'CLUB_AFFILIATION_APPROVED',
  ClubAffiliationDeclined = 'CLUB_AFFILIATION_DECLINED',
  ClubAffiliationRemoved = 'CLUB_AFFILIATION_REMOVED',
  ClubListingRenewalSingle = 'CLUB_LISTING_RENEWAL_SINGLE',
  ClubListingRenewalMulti = 'CLUB_LISTING_RENEWAL_MULTI',
  ClubListingEnquirySingle = 'CLUB_LISTING_ENQUIRY_SINGLE',
  ClubListingEnquiryMulti = 'CLUB_LISTING_ENQUIRY_MULTI',
  ReportJobListingSingleCx = 'REPORT_JOB_LISTING_SINGLE_CX',
  ReportJobListingMultiCx = 'REPORT_JOB_LISTING_MULTI_CX',
  UserEmailNotUpdated = 'USER_EMAIL_NOT_UPDATED',
  UnknownAffiliationRemoved = 'UNKNOWN_AFFILIATION_REMOVED',
  ReportListing = 'REPORT_LISTING',
  InstructorCertificateClubBillingAdd = 'INSTRUCTOR_CERTIFICATE_CLUB_BILLING_ADD',
  InstructorCertificateClubBillingRemove = 'INSTRUCTOR_CERTIFICATE_CLUB_BILLING_REMOVE',
  ReportClubProfile = 'REPORT_CLUB_PROFILE'
}

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  address?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type EmergencyContactInput = {
  address?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  trainingId: Scalars['ID'];
};

export type EnquireAboutExploreProgramsInput = {
  description?: Maybe<Scalars['String']>;
  pickListValue: Scalars['String'];
  programId: Scalars['String'];
  programName: Scalars['String'];
};

export type EnquireAboutExploreProgramsResponse = EnquireAboutExploreProgramsResult | ErrorResult;

export type EnquireAboutExploreProgramsResult = {
  __typename?: 'EnquireAboutExploreProgramsResult';
  lastEnquiredOn?: Maybe<Scalars['AWSDateTime']>;
  programId: Scalars['String'];
};

export type EnquireAboutInStudioProgramsInput = {
  description?: Maybe<Scalars['String']>;
};

export type EnquireAboutInStudioProgramsResponse = EnquireAboutInStudioProgramsResult | ErrorResult;

export type EnquireAboutInStudioProgramsResult = {
  __typename?: 'EnquireAboutInStudioProgramsResult';
  isSuccess: Scalars['Boolean'];
};

export type EnquireAboutOnlineProgramInput = {
  description?: Maybe<Scalars['String']>;
  onlineProgramid: Scalars['String'];
  pickListValue?: Maybe<Scalars['String']>;
};

export type EnquireAboutOnlineProgramResponse = EnquireAboutOnlineProgramResult | ErrorResult;

export type EnquireAboutOnlineProgramResult = {
  __typename?: 'EnquireAboutOnlineProgramResult';
  lastEnquiredOn?: Maybe<Scalars['AWSDateTime']>;
  onlineProgramid: Scalars['String'];
};

export type EnquireAboutProgramInput = {
  description?: Maybe<Scalars['String']>;
  pickListValue?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
};

export type EnquireAboutProgramResponse = EnquireAboutProgramResult | ErrorResult;

export type EnquireAboutProgramResult = {
  __typename?: 'EnquireAboutProgramResult';
  lastEnquiredOn?: Maybe<Scalars['AWSDateTime']>;
  programId: Scalars['String'];
};

export enum EnquiryCategoryEnum {
  Manage = 'MANAGE',
  Enquire = 'ENQUIRE'
}

export type EnquiryClubListingSingleResult = SearchClubListingsResultCommon & {
  __typename?: 'EnquiryClubListingSingleResult';
  distance?: Maybe<Scalars['Float']>;
  distanceUnit?: Maybe<LocationDistanceUnit>;
  enquiredOn?: Maybe<Scalars['AWSDateTime']>;
  hasBeenReported?: Maybe<Scalars['Boolean']>;
  listing: EnquiryHistoryClubListing;
  messageSentOn?: Maybe<Scalars['AWSDateTime']>;
  onWatchlist?: Maybe<Scalars['Boolean']>;
};

export type EnquiryHistoryClubListing = ClubMultiListing | ClubSingleListing | ClubListingUnknown;

export type EnquiryInput = {
  id: Scalars['String'];
  syncId: Scalars['String'];
};

export type EnsureUserProfileExistsInput = {
  id?: Maybe<Scalars['ID']>;
  type: UserProfileType;
};

export type EnsureUserProfileExistsResponse = UserResult | ErrorResult;

export enum Error {
  NotificationNotFound = 'NOTIFICATION_NOT_FOUND',
  AuthHeaderMissing = 'AUTH_HEADER_MISSING',
  AuthInvalidHeader = 'AUTH_INVALID_HEADER',
  AuthMissingData = 'AUTH_MISSING_DATA',
  AuthExpiredToken = 'AUTH_EXPIRED_TOKEN',
  AuthInternalError = 'AUTH_INTERNAL_ERROR',
  AuthInvalidRole = 'AUTH_INVALID_ROLE',
  AuthUnverifiedEmail = 'AUTH_UNVERIFIED_EMAIL',
  UnauthorizedEmail = 'UNAUTHORIZED_EMAIL',
  AreaSearchGeoPointsValidation = 'AREA_SEARCH_GEO_POINTS_VALIDATION',
  S3SignedurlTokenNotFound = 'S3SIGNEDURL_TOKEN_NOT_FOUND',
  FileNotFound = 'FILE_NOT_FOUND',
  EventbridgeClientError = 'EVENTBRIDGE_CLIENT_ERROR',
  InvalidRequest = 'INVALID_REQUEST',
  ServerError = 'SERVER_ERROR',
  EmailAlreadyExists = 'EMAIL_ALREADY_EXISTS',
  InputParameterMissing = 'INPUT_PARAMETER_MISSING',
  UserNotFound = 'USER_NOT_FOUND',
  UserProfileNotFound = 'USER_PROFILE_NOT_FOUND',
  UserSettingsNotFound = 'USER_SETTINGS_NOT_FOUND',
  ConfigError = 'CONFIG_ERROR',
  EnquiryNotFound = 'ENQUIRY_NOT_FOUND',
  AssessmentNotFound = 'ASSESSMENT_NOT_FOUND',
  PreWorkContentNotFound = 'PRE_WORK_CONTENT_NOT_FOUND',
  VideoExisting = 'VIDEO_EXISTING',
  EventAssessmentNotFound = 'EVENT_ASSESSMENT_NOT_FOUND',
  AssessmentOutcomesNotFound = 'ASSESSMENT_OUTCOMES_NOT_FOUND',
  IntegrationApiError = 'INTEGRATION_API_ERROR',
  EventNotFound = 'EVENT_NOT_FOUND',
  TicketNotFound = 'TICKET_NOT_FOUND',
  TrainingNotFound = 'TRAINING_NOT_FOUND',
  SessionNotFound = 'SESSION_NOT_FOUND',
  PriceNotFound = 'PRICE_NOT_FOUND',
  ProgramNotFound = 'PROGRAM_NOT_FOUND',
  PrismicError = 'PRISMIC_ERROR',
  DashboardEdgesUndefined = 'DASHBOARD_EDGES_UNDEFINED',
  DashboardFieldsUndefined = 'DASHBOARD_FIELDS_UNDEFINED',
  ContentNotFound = 'CONTENT_NOT_FOUND',
  ClubListingNotFound = 'CLUB_LISTING_NOT_FOUND',
  ClubNotFound = 'CLUB_NOT_FOUND',
  AffiliationNotFound = 'AFFILIATION_NOT_FOUND',
  CertificationNotFound = 'CERTIFICATION_NOT_FOUND',
  NoAffiliations = 'NO_AFFILIATIONS',
  InvalidProgramListing = 'INVALID_PROGRAM_LISTING',
  BillingUpdateInProgress = 'BILLING_UPDATE_IN_PROGRESS',
  AffiliationValidation = 'AFFILIATION_VALIDATION',
  AffiliationExists = 'AFFILIATION_EXISTS',
  DuplicateAffiliationAction = 'DUPLICATE_AFFILIATION_ACTION',
  AffiliationActionNotAllowed = 'AFFILIATION_ACTION_NOT_ALLOWED'
}

export type ErrorResult = {
  __typename?: 'ErrorResult';
  component?: Maybe<Scalars['String']>;
  logGroup?: Maybe<Scalars['String']>;
  logStream?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  type: Error;
};

export type Event = {
  __typename?: 'Event';
  deliveryMethod: DeliveryMethodEnum;
  endDate: Scalars['String'];
  eventId: Scalars['String'];
  eventName: Scalars['String'];
  programCode: ProgramCodeEnum;
  programName: Scalars['String'];
  spacesAvailable: Scalars['Int'];
  startDate: Scalars['String'];
  timezone: Scalars['String'];
  venueAddress?: Maybe<Scalars['String']>;
};

export type EventAssessments = {
  __typename?: 'EventAssessments';
  attended?: Maybe<AttendedStatusEnum>;
  developmentSheet?: Maybe<DevelopmentSheet>;
  emergencyContact?: Maybe<EmergencyContact>;
  instructorInfo: InstructorInformation;
  outcome?: Maybe<OutcomeEnum>;
  outcomeTrack?: Maybe<OutcomeTrack>;
  presentationTrack?: Maybe<PresentationTrack>;
  sessionRegistrationId: Scalars['String'];
  trackAllocation?: Maybe<Scalars['String']>;
  trainingJourneyId: Scalars['String'];
};

export type EventDetailsResponse = EventDetailsResult | ErrorResult;

export type EventDetailsResult = {
  __typename?: 'EventDetailsResult';
  cancellationPolicy?: Maybe<Scalars['AWSJSON']>;
  details?: Maybe<Scalars['String']>;
  event: Event;
  requirements?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  tickets: Array<Ticket>;
  trainerDetails?: Maybe<TrainerDetails>;
};

export type EventEdge = {
  __typename?: 'EventEdge';
  cursor: Scalars['String'];
  node: Event;
};

export type EventLocationFilter = {
  near?: Maybe<EventLocationNearInput>;
  within?: Maybe<LocationWithinInput>;
};

export type EventLocationNearInput = {
  coordinate: GeoPointInput;
  distance: Scalars['Int'];
  distanceUnit: LocationDistanceUnit;
};

export enum EventStatusEnum {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  InProgress = 'IN_PROGRESS',
  RegistrationClosed = 'REGISTRATION_CLOSED',
  AwaitingOutcomes = 'AWAITING_OUTCOMES',
  OutcomesCompleted = 'OUTCOMES_COMPLETED',
  Cancelled = 'CANCELLED'
}

export type EventSummary = {
  __typename?: 'EventSummary';
  deliveryMethod: DeliveryMethodEnum;
  endDate: Scalars['AWSDate'];
  eventId: Scalars['String'];
  eventName: Scalars['String'];
  eventStatus: EventStatusEnum;
  programName: Scalars['String'];
  startDate: Scalars['AWSDate'];
  trainingJourneyCount: Scalars['Int'];
  venueAddress?: Maybe<Scalars['String']>;
};

export type EventsResult = {
  __typename?: 'EventsResult';
  edges: Array<Maybe<EventEdge>>;
  pageInfo: PageInfo;
};

export type ExploreProgramsPage = {
  __typename?: 'ExploreProgramsPage';
  products?: Maybe<Array<Maybe<InStudioExploreProgramsTile>>>;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  enabled: Scalars['Boolean'];
  featureFlag: Scalars['String'];
};

export type FeatureFlagQueryInput = {
  featureFlags: Array<Scalars['String']>;
};

export type FeatureFlagResponse = FeatureFlagResult | ErrorResult;

export type FeatureFlagResult = {
  __typename?: 'FeatureFlagResult';
  flags?: Maybe<Array<FeatureFlag>>;
};

export type FilterBy = {
  deliveryMethod: DeliveryMethodEnum;
  location: EventLocationFilter;
  programCode: Array<ProgramCodeEnum>;
  spacesAvailable?: Maybe<Scalars['Int']>;
};

export type FilterEventsInput = {
  filterBy: FilterBy;
  sort: Event_Sort_By;
};

export type FinalizeUploadInput = {
  assessmentId: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  parts: Array<Part>;
  trainingJourneyId: Scalars['String'];
  uploadId: Scalars['String'];
};

export type FinalizeUploadResponse = FinalizeUploadResult | ErrorResult;

export type FinalizeUploadResult = {
  __typename?: 'FinalizeUploadResult';
  comment?: Maybe<Scalars['String']>;
  isSuccess: Scalars['Boolean'];
  playback: Playback;
};

export type GeoPoint = {
  __typename?: 'GeoPoint';
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type GeoPointInput = {
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type GeoPolygon = {
  __typename?: 'GeoPolygon';
  points: Array<GeoPoint>;
};

export type GeoPolygonInput = {
  points: Array<GeoPointInput>;
};

export type GetAccountSettingsResponse = UserAccountSettingsResult | ErrorResult;

export type GetAllTranslationsResponse = GetAllTranslationsResult | ErrorResult;

export type GetAllTranslationsResult = {
  __typename?: 'GetAllTranslationsResult';
  locale?: Maybe<Locale>;
  result?: Maybe<Scalars['AWSJSON']>;
};

export type GetAtHomeProductTilesResult = {
  __typename?: 'GetAtHomeProductTilesResult';
  active?: Maybe<Array<AtHomeProductTile>>;
  inactive?: Maybe<Array<AtHomeProductTile>>;
};

export type GetChainAffiliationsInput = {
  userId: Scalars['ID'];
};

export type GetChainAffiliationsResponse = GetChainAffiliationsResult | ErrorResult;

export type GetChainAffiliationsResult = {
  __typename?: 'GetChainAffiliationsResult';
  clubs?: Maybe<Array<ClubWithAffiliation>>;
};

export type GetChainInstructorAffiliatesInput = {
  clubIds?: Maybe<Array<Scalars['ID']>>;
};

export type GetChainInstructorAffiliatesResponse = GetChainInstructorAffiliatesResult | ErrorResult;

export type GetChainInstructorAffiliatesResult = {
  __typename?: 'GetChainInstructorAffiliatesResult';
  affiliates?: Maybe<Array<InstructorChainAffiliate>>;
  hasPendingAffiliations?: Maybe<Scalars['Boolean']>;
  requests?: Maybe<Array<Maybe<InstructorChainRequest>>>;
};

export type GetChainUser = {
  __typename?: 'GetChainUser';
  chainLocations?: Maybe<Array<UserProfileClub>>;
  isChainUser?: Maybe<Scalars['Boolean']>;
};

export type GetChainUserClubListingsInput = {
  clubIds: Array<Scalars['ID']>;
};

export type GetChainUserClubListingsResponse = GetChainUserClubListingsResult | ErrorResult;

export type GetChainUserClubListingsResult = {
  __typename?: 'GetChainUserClubListingsResult';
  multiListings?: Maybe<Array<ClubMultiListing>>;
  singleListings?: Maybe<Array<ClubSingleListing>>;
};

export type GetClubAffiliationsInput = {
  accountId: Scalars['ID'];
};

export type GetClubAffiliationsResponse = ClubAffiliationsResult | ErrorResult;

export type GetClubByIdInput = {
  id: Scalars['ID'];
};

export type GetClubByIdResponse = UserProfileClub | ErrorResult;

export type GetClubInstructorAffiliatesInput = {
  id: Scalars['ID'];
};

export type GetClubInstructorAffiliatesResponse = GetClubInstructorAffiliatesResult | ErrorResult;

export type GetClubInstructorAffiliatesResult = {
  __typename?: 'GetClubInstructorAffiliatesResult';
  affiliates?: Maybe<Array<InstructorAffiliate>>;
  requests?: Maybe<Array<InstructorRequest>>;
};

export type GetClubListingByIdResponse = SearchClubJobListingsSingleResult | ErrorResult;

export type GetClubListingsByClubIdInput = {
  filters?: Maybe<GetClubListingsByClubIdInputFilters>;
  id: Scalars['ID'];
};

export type GetClubListingsByClubIdInputFilters = {
  programs?: Maybe<Array<Scalars['ID']>>;
  status?: Maybe<ClubListingStatus>;
};

export type GetClubListingsByClubIdResponse = GetClubListingsByClubIdResult | ErrorResult;

export type GetClubListingsByClubIdResult = {
  __typename?: 'GetClubListingsByClubIdResult';
  results: Array<ClubListing>;
};

export type GetClubNotificationsFilter = {
  __typename?: 'GetClubNotificationsFilter';
  clubId: Scalars['ID'];
  dateTimeFilter: DateTimeFilter;
};

export type GetClubNotificationsInput = {
  clubId: Scalars['ID'];
  dateTimeFilter: DateTimeFilterInput;
};

export type GetClubNotificationsResponse = GetClubNotificationsResult | ErrorResult;

export type GetClubNotificationsResult = {
  __typename?: 'GetClubNotificationsResult';
  filter: GetClubNotificationsFilter;
  items?: Maybe<Array<Notification>>;
  totalCount: Scalars['Int'];
  translation?: Maybe<GetNotificationTranslation>;
};

export type GetClubSettingsInput = {
  id: Scalars['ID'];
};

export type GetClubSettingsResponse = GetClubSettingsResult | ErrorResult;

export type GetClubSettingsResult = {
  __typename?: 'GetClubSettingsResult';
  address?: Maybe<Address>;
  email?: Maybe<EmailClubType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type GetClubsAvailabilityOnListingInput = {
  isSubstituteListing: Scalars['Boolean'];
  programName: Scalars['String'];
};

export type GetClubsAvailabilityOnListingResponse = GetClubsAvailabilityOnListingResult | ErrorResult;

export type GetClubsAvailabilityOnListingResult = {
  __typename?: 'GetClubsAvailabilityOnListingResult';
  allClubs?: Maybe<Array<ClubAvailabilityOnListing>>;
  clubsWithExistingListing?: Maybe<Array<ClubAvailabilityOnListing>>;
};

export type GetContactUsContentInput = {
  market: Scalars['String'];
  userType: UserProfileType;
};

export type GetContactUsContentResponse = GetContactUsContentResult | ErrorResult;

export type GetContactUsContentResult = {
  __typename?: 'GetContactUsContentResult';
  result: Array<SectionContentResult>;
};

export type GetCurrentUserPhotoUploadDataResponse = GetCurrentUserPhotoUploadDataResult | ErrorResult;

export type GetCurrentUserPhotoUploadDataResult = {
  __typename?: 'GetCurrentUserPhotoUploadDataResult';
  token: Scalars['String'];
  url: Scalars['String'];
};

export type GetCurrentUserPortfolioUploadDataInput = {
  photoCount: Scalars['Int'];
};

export type GetCurrentUserPortfolioUploadDataResponse = GetCurrentUserPortfolioUploadDataResult | ErrorResult;

export type GetCurrentUserPortfolioUploadDataResult = {
  __typename?: 'GetCurrentUserPortfolioUploadDataResult';
  items: Array<PortfolioUploadItem>;
};

export type GetDashboardResponse = DashboardPageInstructorResponse | DashboardPageClubResponse | ErrorResult;

export type GetEqualOpportunitiesPolicyInput = {
  market: Scalars['String'];
};

export type GetEqualOpportunitiesPolicyResponse = GetEqualOpportunitiesPolicyResult | ErrorResult;

export type GetEqualOpportunitiesPolicyResult = {
  __typename?: 'GetEqualOpportunitiesPolicyResult';
  result: Array<SectionContentResult>;
};

export type GetExploreProgramsPageResult = ExploreProgramsPage | ErrorResult;

export type GetFrequentlyAskedQuestionsInput = {
  market: Scalars['String'];
  userType: UserProfileType;
};

export type GetFrequentlyAskedQuestionsResponse = GetFrequentlyAskedQuestionsResult | ErrorResult;

export type GetFrequentlyAskedQuestionsResult = {
  __typename?: 'GetFrequentlyAskedQuestionsResult';
  result: Array<SectionContentResult>;
};

export type GetHeaderInput = {
  keys?: Maybe<Array<Scalars['String']>>;
};

export type GetHeaderResponse = GetHeaderResult | ErrorResult;

export type GetHeaderResult = {
  __typename?: 'GetHeaderResult';
  results: Array<Header>;
};

export type GetInStudioToolkitResult = {
  __typename?: 'GetInStudioToolkitResult';
  tiles?: Maybe<Array<Maybe<InStudioToolkitTile>>>;
};

export type GetInstructorClubAffiliatesInput = {
  id: Scalars['ID'];
};

export type GetInstructorClubAffiliatesResponse = GetInstructorClubAffiliatesResult | ErrorResult;

export type GetInstructorClubAffiliatesResult = {
  __typename?: 'GetInstructorClubAffiliatesResult';
  affiliations?: Maybe<Array<ClubAffiliates>>;
};

export type GetNavigationInput = {
  market: Scalars['String'];
  role: UserProfileType;
  subroles?: Maybe<Array<InstructorRole>>;
};

export type GetNavigationResponse = Navigation | ErrorResult;

export type GetNotificationResponse = Notification | ErrorResult;

export type GetNotificationTranslation = {
  __typename?: 'GetNotificationTranslation';
  footer: Scalars['String'];
  title: Scalars['String'];
  totalNew: Scalars['String'];
};

export type GetNotificationsResponse = GetNotificationsResult | ErrorResult;

export type GetNotificationsResult = DateTimeFilteredResponse & {
  __typename?: 'GetNotificationsResult';
  filter: DateTimeFilter;
  items?: Maybe<Array<Notification>>;
  totalCount: Scalars['Int'];
  translation?: Maybe<GetNotificationTranslation>;
};

export type GetPickListInput = {
  keys?: Maybe<Array<Scalars['String']>>;
};

export type GetPickListResponse = GetPickListResult | ErrorResult;

export type GetPickListResult = {
  __typename?: 'GetPickListResult';
  results: Array<PickList>;
};

export type GetPresignedUrlInput = {
  assessmentId: Scalars['String'];
  filename: Scalars['String'];
  totalPartNumber: Scalars['Int'];
  trainingJourneyId: Scalars['String'];
  uploadId: Scalars['String'];
};

export type GetPresignedUrlResponse = GetPresignedUrlResult | ErrorResult;

export type GetPresignedUrlResult = {
  __typename?: 'GetPresignedUrlResult';
  partSignedUrlList: Array<PartSignedUrl>;
};

export type GetPrivacySettingValueResult = {
  __typename?: 'GetPrivacySettingValueResult';
  description: Scalars['String'];
  title: Scalars['String'];
  value: PrivacySettingValue;
};

export type GetPrivacySettingValuesResponse = GetPrivacySettingValuesResult | ErrorResult;

export type GetPrivacySettingValuesResult = {
  __typename?: 'GetPrivacySettingValuesResult';
  values: Array<GetPrivacySettingValueResult>;
};

export type GetProfileByIdInput = {
  id: Scalars['ID'];
  type: UserProfileType;
};

export type GetProfileByIdResponse = GetProfileByIdResult | ErrorResult;

export type GetProfileByIdResult = {
  __typename?: 'GetProfileByIdResult';
  hasBeenReported?: Maybe<Scalars['Boolean']>;
  hasExistingAffiliation?: Maybe<Scalars['Boolean']>;
  hasRecentlyDeclinedAffiliation?: Maybe<Scalars['Boolean']>;
  instructorAffiliation?: Maybe<Affiliation>;
  lastMessageIn48HoursSentAt?: Maybe<Scalars['AWSDateTime']>;
  onWatchlist?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<UserProfile>;
  profileUser?: Maybe<User>;
  withinDistance?: Maybe<Scalars['Boolean']>;
};

export type GetSavedInstructorSearchDataInput = {
  id?: Maybe<Scalars['ID']>;
};

export type GetSavedInstructorSearchDataResponse = GetSavedInstructorSearchDataResult | ErrorResult;

export type GetSavedInstructorSearchDataResult = {
  __typename?: 'GetSavedInstructorSearchDataResult';
  results: Array<SavedInstructorSearch>;
};

export type GetSharedNavigation = SharedNavigation | ErrorResult;

export type GetSignedAgreementInput = {
  type: AgreementType;
  version?: Maybe<Scalars['String']>;
};

export type GetUserByAccountIdInput = {
  accountId: Scalars['ID'];
};

export type GetUserByEmailInput = {
  email: Scalars['AWSEmail'];
};

export type GetUserByIdInput = {
  id: Scalars['ID'];
};

export type GetUserEnquiriesResponse = GetUserEnquiriesResult | ErrorResult;

export type GetUserEnquiriesResult = {
  __typename?: 'GetUserEnquiriesResult';
  enquiries?: Maybe<Array<UserEnquiry>>;
};

export type GetUserResponse = UserResult | ErrorResult;

export type GetUserSessionResponse = GetUserSessionResult | ErrorResult;

export type GetUserSessionResult = {
  __typename?: 'GetUserSessionResult';
  country?: Maybe<CountryEnum>;
  eventId: Scalars['String'];
  market?: Maybe<Scalars['String']>;
  state?: Maybe<StatesEnum>;
};

export type GetVideoGuidelinesResponse = GetVideoGuidelinesResult | ErrorResult;

export type GetVideoGuidelinesResult = {
  __typename?: 'GetVideoGuidelinesResult';
  contactUsEmail: Scalars['String'];
  result: Array<SectionContentResult>;
};

export type GetWalkthroughResponse = Walkthrough | ErrorResult;

export type Header = {
  __typename?: 'Header';
  image?: Maybe<ImageSet>;
  key: Scalars['String'];
  subHeading?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  alt?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type ImageInput = {
  alt?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type ImageSet = {
  __typename?: 'ImageSet';
  desktop: Image;
  id: Scalars['ID'];
  mobile: Image;
};

export type InStudioDetailsView = {
  __typename?: 'InStudioDetailsView';
  description: Scalars['String'];
  moreURL?: Maybe<Url>;
  typeInfo?: Maybe<Array<Maybe<ProgramTypeInfo>>>;
  videoContentId?: Maybe<Scalars['String']>;
};

export type InStudioEmptyState = {
  __typename?: 'InStudioEmptyState';
  link: Link;
  subtitle: Scalars['String'];
  title: Scalars['String'];
};

export type InStudioExploreProgramsTile = {
  __typename?: 'InStudioExploreProgramsTile';
  detailView?: Maybe<InStudioDetailsView>;
  enquirySent?: Maybe<Scalars['AWSDateTime']>;
  genre?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  name?: Maybe<Scalars['String']>;
  numberOfCertifiedInstructors?: Maybe<Scalars['Int']>;
  productCategories?: Maybe<Array<Maybe<ProgramTypeEnum>>>;
  productId: Scalars['String'];
};

export type InStudioExploreProgramsTileCardResult = {
  __typename?: 'InStudioExploreProgramsTileCardResult';
  image?: Maybe<Image>;
};

export type InStudioToolkitTile = {
  __typename?: 'InStudioToolkitTile';
  image: Image;
  link: Link;
  subTitle: Scalars['String'];
  title: Scalars['String'];
};

export type IncrementViewClubListingCountInput = {
  clubId: Scalars['ID'];
  listingId: Scalars['ID'];
};

export type IncrementViewClubListingCountResponse = ClubListingResult | ErrorResult;

export type InitializeUploadInput = {
  assessmentId: Scalars['String'];
  contentType: Scalars['String'];
  filename: Scalars['String'];
  trainingJourneyId: Scalars['String'];
};

export type InitializeUploadResponse = InitializeUploadResult | ErrorResult;

export type InitializeUploadResult = {
  __typename?: 'InitializeUploadResult';
  uploadId: Scalars['String'];
};

export type InstructorAffiliate = {
  __typename?: 'InstructorAffiliate';
  actionedDate?: Maybe<Scalars['AWSDateTime']>;
  clubId: Scalars['String'];
  id: Scalars['ID'];
  profile: UserProfileTeaching;
  purchaseOrder?: Maybe<Scalars['String']>;
  requestedBy: UserRoleType;
  requestedDate: Scalars['AWSDateTime'];
  status: AffiliationStatus;
  user: User;
};

export type InstructorAvailability = {
  __typename?: 'InstructorAvailability';
  availableToSubstitute: Scalars['Boolean'];
  description: Scalars['String'];
  title: Scalars['String'];
  value: InstructorAvailabilityValue;
};

export enum InstructorAvailabilityValue {
  Available = 'AVAILABLE',
  Open = 'OPEN',
  Unavailable = 'UNAVAILABLE'
}

export type InstructorCertificate = {
  __typename?: 'InstructorCertificate';
  certificate?: Maybe<Certification>;
  instructorName?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatusEnum>;
};

export type InstructorCertificatesInput = {
  certificates?: Maybe<Array<BilledCertificationInput>>;
  purchaseOrder?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type InstructorCertificationInput = {
  active: Scalars['Boolean'];
  billedClubId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isBlockedFromAutoship?: Maybe<Scalars['Boolean']>;
  lastPurchase?: Maybe<Scalars['String']>;
  link: LinkCertificationInput;
  lmqLevel: Scalars['Float'];
  price?: Maybe<Scalars['String']>;
  program: ProgramInput;
  purchaseOrder?: Maybe<Scalars['String']>;
  releaseActivityStatus?: Maybe<ReleaseActivityStatusEnum>;
  snapshotState?: Maybe<SnapshotStateEnum>;
  status: CertificationStatusEnum;
  steps?: Maybe<Array<CertificationStepInput>>;
  subscribed?: Maybe<Scalars['Boolean']>;
};

export type InstructorChainAffiliate = {
  __typename?: 'InstructorChainAffiliate';
  clubAffiliates?: Maybe<Array<ClubAffiliates>>;
  profile: UserProfileTeaching;
  purchaseOrder?: Maybe<Scalars['String']>;
  user: User;
};

export type InstructorChainRequest = {
  __typename?: 'InstructorChainRequest';
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  distance?: Maybe<Scalars['Float']>;
  distanceUnit?: Maybe<LocationDistanceUnit>;
  id: Scalars['ID'];
  pendingStatus?: Maybe<AffiliationStatus>;
  profile: UserProfileTeaching;
  requestedBy: UserRoleType;
  requestedDate: Scalars['AWSDateTime'];
  status: AffiliationStatus;
  user: User;
};

export type InstructorDetails = {
  __typename?: 'InstructorDetails';
  name: Scalars['String'];
  profileImage?: Maybe<Image>;
};

export type InstructorInformation = {
  __typename?: 'InstructorInformation';
  address?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type InstructorPreferences = {
  __typename?: 'InstructorPreferences';
  programs?: Maybe<Array<Program>>;
  times?: Maybe<Array<TimePreference>>;
  travel?: Maybe<TravelPreference>;
};

export type InstructorPreferencesInput = {
  programs?: Maybe<Array<Scalars['ID']>>;
  times?: Maybe<Array<TimePreferenceInput>>;
  travel?: Maybe<TravelPreferenceInput>;
};

export type InstructorRequest = {
  __typename?: 'InstructorRequest';
  clubId: Scalars['String'];
  distance?: Maybe<Scalars['Float']>;
  distanceUnit?: Maybe<LocationDistanceUnit>;
  id: Scalars['ID'];
  pendingStatus?: Maybe<AffiliationStatus>;
  profile: UserProfileTeaching;
  requestedBy: UserRoleType;
  requestedDate: Scalars['AWSDateTime'];
  status: AffiliationStatus;
  user: User;
};

export enum InstructorRole {
  Instructor = 'INSTRUCTOR',
  Assessor = 'ASSESSOR',
  Presenter = 'PRESENTER',
  Trainer = 'TRAINER'
}

export type InstructorShortlist = {
  __typename?: 'InstructorShortlist';
  distance?: Maybe<Scalars['Float']>;
  distanceUnit?: Maybe<LocationDistanceUnit>;
  hasExistingAffiliation?: Maybe<Scalars['Boolean']>;
  hasRecentlyDeclinedAffiliation?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  instructorAffiliation?: Maybe<Affiliation>;
  lastMessageIn48HoursSentAt?: Maybe<Scalars['AWSDateTime']>;
  profile: UserProfileTeaching;
  user: User;
};

export type InstructorWatchlist = {
  __typename?: 'InstructorWatchlist';
  watchlist?: Maybe<Array<InstructorWatchlistItem>>;
};

export type InstructorWatchlistItem = {
  __typename?: 'InstructorWatchlistItem';
  clubId: Scalars['String'];
  listingId?: Maybe<Scalars['String']>;
};

export type InstructorWatchlistItemInput = {
  clubId: Scalars['String'];
  listingId?: Maybe<Scalars['String']>;
};

export enum LicenseTypeEnum {
  Licensed = 'LICENSED',
  Unlicensed = 'UNLICENSED'
}

export type Link = {
  __typename?: 'Link';
  id: Scalars['ID'];
  isPrimary?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
  url: Url;
};

export type LinkCertificationInput = {
  id: Scalars['ID'];
  text: Scalars['String'];
  url: UrlInput;
};

export type LinkInput = {
  text: Scalars['String'];
  url: UrlInput;
};

export type LinkTile = {
  __typename?: 'LinkTile';
  id: Scalars['ID'];
  imageSet: ImageSet;
  subtitle: Scalars['String'];
  title: Scalars['String'];
  url: Url;
};

export enum LinkTypeIcon {
  Chevron = 'CHEVRON',
  ExternalLink = 'EXTERNAL_LINK'
}

export enum Locale {
  EnGb = 'en_GB',
  EnUs = 'en_US',
  EnUk = 'en_UK',
  EnPh = 'en_PH',
  RuRu = 'ru_RU',
  DeDe = 'de_DE',
  JaJp = 'ja_JP',
  ZhCn = 'zh_CN',
  PtBr = 'pt_BR'
}

export enum LocationDistanceUnit {
  Km = 'KM',
  Miles = 'MILES'
}

export type LocationFilter = {
  __typename?: 'LocationFilter';
  near?: Maybe<LocationNear>;
  point?: Maybe<GeoPoint>;
  within?: Maybe<LocationWithin>;
};

export type LocationFilterInput = {
  near?: Maybe<LocationNearInput>;
  point?: Maybe<GeoPointInput>;
  within?: Maybe<LocationWithinInput>;
};

export type LocationNear = {
  __typename?: 'LocationNear';
  address?: Maybe<Address>;
  distance: Scalars['Int'];
  distanceUnit: LocationDistanceUnit;
};

export type LocationNearInput = {
  address?: Maybe<AddressInput>;
  distance: Scalars['Int'];
  distanceUnit: LocationDistanceUnit;
};

export type LocationWithin = {
  __typename?: 'LocationWithin';
  address?: Maybe<Address>;
  area: GeoPolygon;
};

export type LocationWithinInput = {
  address?: Maybe<AddressInput>;
  area: GeoPolygonInput;
};

export enum LogoIcon {
  DigitalHubWhite = 'DIGITAL_HUB_WHITE',
  DigitalHubBlack = 'DIGITAL_HUB_BLACK'
}


export type MagentoStoreProfileInput = {
  customerId?: Maybe<Scalars['String']>;
  storeName?: Maybe<Scalars['String']>;
};

export type Market = {
  __typename?: 'Market';
  countries?: Maybe<Array<Country>>;
  distanceUnit: LocationDistanceUnit;
  isSupported?: Maybe<Scalars['Boolean']>;
  locale: Locale;
  name: Scalars['String'];
  programs?: Maybe<Array<Program>>;
};

export type MarketInput = {
  countries?: Maybe<Array<CountryInput>>;
  distanceUnit: LocationDistanceUnit;
  locale: Locale;
  name: Scalars['String'];
  programs?: Maybe<Array<ProgramInput>>;
};

export type Menu = {
  __typename?: 'Menu';
  id: Scalars['ID'];
  items: Array<RootMenuItem>;
  name: Scalars['String'];
};

export type MenuItem = {
  __typename?: 'MenuItem';
  analyticsEvent?: Maybe<AnalyticsEvent>;
  id: Scalars['ID'];
  label: Scalars['String'];
  url: Url;
};

export enum MenuItemIcon {
  Goals = 'GOALS',
  Home = 'HOME',
  More = 'MORE',
  Plans = 'PLANS',
  Profile = 'PROFILE',
  Releases = 'RELEASES',
  Trainer = 'TRAINER',
  Assessor = 'ASSESSOR',
  ManageMyPartnership = 'MANAGE_MY_PARTNERSHIP'
}

export type Message = {
  __typename?: 'Message';
  clubId: Scalars['ID'];
  clubName: Scalars['String'];
  id: Scalars['ID'];
  listingId?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  profileId: Scalars['ID'];
  readAt?: Maybe<Scalars['AWSDateTime']>;
  requestedBy: UserRoleType;
  sentAt: Scalars['AWSDateTime'];
};

export enum MessageBusNotificationType {
  ClubNewAffiliationRequest = 'CLUB_NEW_AFFILIATION_REQUEST',
  ClubAffiliationRequestApproved = 'CLUB_AFFILIATION_REQUEST_APPROVED',
  ClubAffiliationRequestDeclined = 'CLUB_AFFILIATION_REQUEST_DECLINED',
  ClubAffiliationRemoved = 'CLUB_AFFILIATION_REMOVED',
  ClubJobListingEnquiry = 'CLUB_JOB_LISTING_ENQUIRY',
  ClubDataWarehouseSync = 'CLUB_DATA_WAREHOUSE_SYNC',
  InstructorNewAffiliationRequest = 'INSTRUCTOR_NEW_AFFILIATION_REQUEST',
  InstructorAffiliationRequestApproved = 'INSTRUCTOR_AFFILIATION_REQUEST_APPROVED',
  InstructorAffiliationRequestDeclined = 'INSTRUCTOR_AFFILIATION_REQUEST_DECLINED',
  InstructorAffiliationRemoved = 'INSTRUCTOR_AFFILIATION_REMOVED',
  InstructorJobListingClubHiring = 'INSTRUCTOR_JOB_LISTING_CLUB_HIRING',
  InstructorClubListingAlert = 'INSTRUCTOR_CLUB_LISTING_ALERT',
  InstructorCreditCardExpiry = 'INSTRUCTOR_CREDIT_CARD_EXPIRY',
  UnknownAffiliationRemoved = 'UNKNOWN_AFFILIATION_REMOVED',
  InstructorDataWarehouseSync = 'INSTRUCTOR_DATA_WAREHOUSE_SYNC',
  SystemGeneratedNotification = 'SYSTEM_GENERATED_NOTIFICATION'
}

export type Mutation = {
  __typename?: 'Mutation';
  _root?: Maybe<Scalars['String']>;
  addClubListing?: Maybe<AddClubListingResponse>;
  addEmergencyContact: AddEmergencyContactResponse;
  addToShortlist?: Maybe<AddToShortlistResponse>;
  addTrainingOutcome: AddTrainingOutcomeResponse;
  bookEvent: BookEventResponse;
  createAffiliation: AffiliationAsyncResponse;
  createAffiliations: ChainAffiliationsResponse;
  createClubListings?: Maybe<CreateClubListingsResponse>;
  createInstructorAccount: CreateInstructorAccountResponse;
  createNotification?: Maybe<GetNotificationResponse>;
  deleteAffiliation: AffiliationAsyncResponse;
  deleteClubListing?: Maybe<DeleteClubListingResponse>;
  deleteClubListings?: Maybe<DeleteClubListingsResponse>;
  deleteCurrentUserPortfolioImage: DeleteCurrentUserPortfolioImageResponse;
  deleteNotification?: Maybe<DeleteNotificationResponse>;
  enquireAboutInStudioPrograms?: Maybe<EnquireAboutInStudioProgramsResponse>;
  enquireAboutOnlineProgram?: Maybe<EnquireAboutOnlineProgramResponse>;
  enquireAboutProgram?: Maybe<EnquireAboutProgramResponse>;
  enquireAboutProgramInExplorePrograms?: Maybe<EnquireAboutExploreProgramsResponse>;
  finalizeUpload?: Maybe<FinalizeUploadResponse>;
  incrementViewClubListingCount?: Maybe<IncrementViewClubListingCountResponse>;
  initializeUpload?: Maybe<InitializeUploadResponse>;
  payForCertification?: Maybe<PayForCertificationResponse>;
  removeAffiliations: ChainAffiliationsRemoveResponse;
  removeFromShortlist?: Maybe<RemoveFromShortlistResponse>;
  reportClubListing?: Maybe<ReportClubListingResponse>;
  reportClubProfile?: Maybe<ReportClubProfileResponse>;
  requestForExtension: RequestForExtensionResponse;
  resendPasswordResetEmail: ResendPasswordResetEmailResponse;
  revertAppMetadata: AppMetadataResponse;
  seedRandomAccounts?: Maybe<SeedRandomAccountsResponse>;
  sendMessage?: Maybe<SendMessageResponse>;
  setAppMetadata: AppMetadataResponse;
  setClubSettings?: Maybe<SetClubSettingsResponse>;
  setInstructorAvailability?: Maybe<SetInstructorAvailabilityResponse>;
  setInstructorTravelPreferences?: Maybe<SetInstructorTravelPreferencesResponse>;
  setInstructorWatchlist?: Maybe<SetInstructorWatchlistResponse>;
  setPrivacySettings?: Maybe<SetPrivacySettingsResponse>;
  setSavedInstructorSearchData?: Maybe<SetSavedInstructorSearchDataResponse>;
  setSignedAgreementData?: Maybe<AgreementDocumentResponse>;
  setWalkthroughToSeen?: Maybe<SetWalkthroughToSeenResponse>;
  testPostUpsertClub?: Maybe<TestPostUpsertClubResponse>;
  testPostUpsertUser?: Maybe<TestPostUpsertUserResponse>;
  toggleNotificationsRead?: Maybe<ToggleNotificationReadResponse>;
  updateAffiliation: AffiliationAsyncResponse;
  updateClubListing?: Maybe<UpdateClubListingResponse>;
  updateClubProfile: UpdateClubProfileResponse;
  updateCurrentUserPortfolioUpload: UpdateCurrentUserPortfolioUploadResponse;
  updateTeachingProfile: UpdateProfileResponse;
  updateUserAccount: UpdateAccountSettingsResponse;
  updateUserPhoto?: Maybe<UpdateUserPhotoResponse>;
  upsertAgreement: UpsertAgreementResponse;
  upsertClub: UpsertClubResponse;
  upsertPricebook: UpsertPricebookResponse;
  upsertUser: UpsertUserResponse;
};


export type Mutation_RootArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationAddClubListingArgs = {
  input: AddClubListingInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationAddEmergencyContactArgs = {
  input: EmergencyContactInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationAddToShortlistArgs = {
  input: AddToShortlistInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationAddTrainingOutcomeArgs = {
  input: AddTrainingOutcomeInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationBookEventArgs = {
  input: BookEventInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationCreateAffiliationArgs = {
  input: CreateAffiliationInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationCreateAffiliationsArgs = {
  input: CreateAffiliationsInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationCreateClubListingsArgs = {
  input: CreateClubListingsInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationCreateInstructorAccountArgs = {
  input: CreateInstructorAccountInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationDeleteAffiliationArgs = {
  input: DeleteAffiliationInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationDeleteClubListingArgs = {
  input: DeleteClubListingInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationDeleteClubListingsArgs = {
  input: DeleteClubListingsInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationDeleteCurrentUserPortfolioImageArgs = {
  input: DeleteCurrentUserPortfolioImageInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationDeleteNotificationArgs = {
  input: DeleteNotificationInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationEnquireAboutInStudioProgramsArgs = {
  input: EnquireAboutInStudioProgramsInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationEnquireAboutOnlineProgramArgs = {
  input: EnquireAboutOnlineProgramInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationEnquireAboutProgramArgs = {
  input: EnquireAboutProgramInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationEnquireAboutProgramInExploreProgramsArgs = {
  input: EnquireAboutExploreProgramsInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationFinalizeUploadArgs = {
  input?: Maybe<FinalizeUploadInput>;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationIncrementViewClubListingCountArgs = {
  input: IncrementViewClubListingCountInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationInitializeUploadArgs = {
  input?: Maybe<InitializeUploadInput>;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationPayForCertificationArgs = {
  input: PayForCertificationInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationRemoveAffiliationsArgs = {
  input: DeleteAffiliationsInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationRemoveFromShortlistArgs = {
  input: RemoveFromShortlistInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationReportClubListingArgs = {
  input: ReportClubListingInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationReportClubProfileArgs = {
  input: ReportClubProfileInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationRequestForExtensionArgs = {
  input: RequestForExtensionInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationResendPasswordResetEmailArgs = {
  input: ResendPasswordResetEmailInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationRevertAppMetadataArgs = {
  input: RevertAppMetadataInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationSeedRandomAccountsArgs = {
  input: SeedRandomAccountsInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationSetAppMetadataArgs = {
  input: SetAppMetadataInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationSetClubSettingsArgs = {
  input: SetClubSettingsInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationSetInstructorAvailabilityArgs = {
  input: SetInstructorAvailabilityInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationSetInstructorTravelPreferencesArgs = {
  input: SetInstructorTravelPreferencesInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationSetInstructorWatchlistArgs = {
  input: SetInstructorWatchlistInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationSetPrivacySettingsArgs = {
  input: SetPrivacySettingsInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationSetSavedInstructorSearchDataArgs = {
  input: SetSavedInstructorSearchDataInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationSetSignedAgreementDataArgs = {
  input: SetSignedAgreementDataInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationSetWalkthroughToSeenArgs = {
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationTestPostUpsertClubArgs = {
  input: TestPostUpsertClubInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationTestPostUpsertUserArgs = {
  input: TestPostUpsertUserInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationToggleNotificationsReadArgs = {
  input: ToggleNotificationReadInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationUpdateAffiliationArgs = {
  input: UpdateAffiliationInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationUpdateClubListingArgs = {
  input: UpdateClubListingInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationUpdateClubProfileArgs = {
  input: UpdateClubProfileInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationUpdateCurrentUserPortfolioUploadArgs = {
  input: UpdateCurrentUserPortfolioUploadInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationUpdateTeachingProfileArgs = {
  input: UpdateTeachingProfileInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserAccountArgs = {
  input: UpdateUserAccountInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserPhotoArgs = {
  input: UpdateUserPhotoInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationUpsertAgreementArgs = {
  input: UpsertAgreementInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationUpsertClubArgs = {
  input: UpsertClubInput;
  metadata?: Maybe<SyncMetadata>;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationUpsertPricebookArgs = {
  input: UpsertPricebookInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type MutationUpsertUserArgs = {
  input: UpsertUserInput;
  metadata?: Maybe<SyncMetadata>;
  _cacheKey?: Maybe<Scalars['String']>;
};

export type NavItem = {
  __typename?: 'NavItem';
  description: Scalars['String'];
  image?: Maybe<Image>;
  key?: Maybe<NavKey>;
  title?: Maybe<Scalars['String']>;
  url: Url;
};

export enum NavKey {
  InstructorPortal = 'INSTRUCTOR_PORTAL',
  LesmillsPlus = 'LESMILLS_PLUS',
  LesmillsEquipmentClub = 'LESMILLS_EQUIPMENT_CLUB',
  ClubPortal = 'CLUB_PORTAL',
  LearningStudio = 'LEARNING_STUDIO',
  MarketingStudio = 'MARKETING_STUDIO',
  LesmillsEquipmentInstructor = 'LESMILLS_EQUIPMENT_INSTRUCTOR'
}

export type Navigation = {
  __typename?: 'Navigation';
  appMenu: Menu;
  userMenu: RootMenuItem;
};

export type Notification = {
  __typename?: 'Notification';
  body: Scalars['String'];
  createdAt: Scalars['String'];
  createdAtLabel: Scalars['String'];
  createdAtTs: Scalars['Long'];
  id: Scalars['ID'];
  link?: Maybe<Link>;
  readAt?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: NotificationType;
  updatedAt: Scalars['String'];
  updatedAtTs: Scalars['Long'];
};

export enum NotificationType {
  Plans = 'PLANS',
  Releases = 'RELEASES',
  Positions = 'POSITIONS'
}

export type OutLink = {
  __typename?: 'OutLink';
  _metadata?: Maybe<OutlinkMetedata>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export enum OutcomeEnum {
  Pass = 'PASS',
  SendNewVideo = 'SEND_NEW_VIDEO',
  ReTrain = 'RE_TRAIN',
  ReSubmitRequired = 'RE_SUBMIT_REQUIRED',
  PassPending = 'PASS_PENDING'
}

export type OutcomeTrack = {
  __typename?: 'OutcomeTrack';
  comment?: Maybe<Scalars['String']>;
  playback: Playback;
  submittedAt?: Maybe<Scalars['String']>;
};

export type OutlinkMetedata = {
  __typename?: 'OutlinkMetedata';
  urlSource?: Maybe<OutlinkSourceEnum>;
};

export enum OutlinkSourceEnum {
  Prismic = 'PRISMIC',
  Magento = 'MAGENTO'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<SearchAfter>;
  hasNextPage: Scalars['Boolean'];
};

export type Part = {
  eTag: Scalars['String'];
  partNumber: Scalars['Int'];
};

export type PartSignedUrl = {
  __typename?: 'PartSignedUrl';
  partNumber: Scalars['Int'];
  url: Scalars['String'];
};

export type PartnershipContent = ClubPartnershipProgramStats | ClubPartnershipStandardContent | ClubPartnershipBusinessSupportContent;

export type PayForCertificationInput = {
  billedClubId: Scalars['ID'];
  instructors: Array<InstructorCertificatesInput>;
};

export type PayForCertificationResponse = PayForCertificationResult | ErrorResult;

export type PayForCertificationResult = {
  __typename?: 'PayForCertificationResult';
  results?: Maybe<Array<InstructorCertificate>>;
};

export type PickList = {
  __typename?: 'PickList';
  items: Array<Scalars['String']>;
  key: Scalars['String'];
};

export type Playback = {
  __typename?: 'Playback';
  playbackUrl: Scalars['String'];
  token: Scalars['String'];
};

export type PortfolioUploadItem = {
  __typename?: 'PortfolioUploadItem';
  token: Scalars['String'];
  url: Scalars['String'];
};

export type PresentationTrack = {
  __typename?: 'PresentationTrack';
  comment?: Maybe<Scalars['String']>;
  playback: Playback;
  submittedAt?: Maybe<Scalars['String']>;
};

export type PreworkContent = {
  __typename?: 'PreworkContent';
  contentList?: Maybe<Array<PreworkContentItem>>;
};

export type PreworkContentItem = {
  __typename?: 'PreworkContentItem';
  title: Scalars['String'];
  type: PreworkContentType;
  url: Url;
};

export enum PreworkContentType {
  Link = 'LINK',
  Pdf = 'PDF',
  Video = 'VIDEO'
}

export type PreworkStep = Step & {
  __typename?: 'PreworkStep';
  activeStepName: Scalars['String'];
  activeStepNumber: Scalars['Int'];
  assessmentVideo?: Maybe<AssessmentVideo>;
  attended?: Maybe<AttendedStatusEnum>;
  cancellationPolicy: Scalars['AWSJSON'];
  contactUsEmail: Scalars['String'];
  deliveryMethod: DeliveryMethodEnum;
  details?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  eventName: Scalars['String'];
  fulfillment?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  onlineTrainingLink?: Maybe<Scalars['String']>;
  passPendingGuide: Scalars['AWSJSON'];
  preworkContent?: Maybe<PreworkContent>;
  programName: Scalars['String'];
  releaseUrl?: Maybe<Link>;
  requirements?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  stepName: Scalars['String'];
  stepNumber: Scalars['Int'];
  timezone: Scalars['String'];
  trackAllocation?: Maybe<Scalars['String']>;
  trainerEmail?: Maybe<Scalars['String']>;
  trainerName?: Maybe<Scalars['String']>;
  venueAddress?: Maybe<Scalars['String']>;
};

export enum PrivacySettingTypeValueInstructor {
  Email = 'EMAIL',
  PhoneNumber = 'PHONE_NUMBER',
  ProfilePhoto = 'PROFILE_PHOTO',
  SocialLinks = 'SOCIAL_LINKS',
  LmqLevel = 'LMQ_LEVEL'
}

export enum PrivacySettingValue {
  Public = 'PUBLIC',
  Restricted = 'RESTRICTED',
  Private = 'PRIVATE'
}

export type PrivacySettingsInstructor = {
  __typename?: 'PrivacySettingsInstructor';
  EMAIL: PrivacySettingValue;
  HIDDEN: Scalars['Boolean'];
  LMQ_LEVEL: PrivacySettingValue;
  PHONE_NUMBER: PrivacySettingValue;
  PROFILE_PHOTO: PrivacySettingValue;
  SOCIAL_LINKS: PrivacySettingValue;
};

export type PrivacySettingsInstructorInput = {
  EMAIL?: Maybe<PrivacySettingValue>;
  HIDDEN?: Maybe<Scalars['Boolean']>;
  LMQ_LEVEL?: Maybe<PrivacySettingValue>;
  PHONE_NUMBER?: Maybe<PrivacySettingValue>;
  PROFILE_PHOTO?: Maybe<PrivacySettingValue>;
  SOCIAL_LINKS?: Maybe<PrivacySettingValue>;
};

export type ProfileLink = {
  __typename?: 'ProfileLink';
  type: ProfileLinkType;
  url: Scalars['String'];
};

export type ProfileLinkInput = {
  type: ProfileLinkType;
  url: Scalars['String'];
};

export enum ProfileLinkType {
  Facebook = 'FACEBOOK',
  Youtube = 'YOUTUBE',
  Instagram = 'INSTAGRAM',
  Tiktok = 'TIKTOK',
  PersonalWebsite = 'PERSONAL_WEBSITE'
}

export type Program = ProgramInterface & {
  __typename?: 'Program';
  code: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initials: Scalars['String'];
  name: Scalars['String'];
  programType?: Maybe<ProgramTypeEnum>;
};

export enum ProgramCodeEnum {
  Ba = 'BA',
  Bb = 'BB',
  Bc = 'BC',
  Bf = 'BF',
  Bj = 'BJ',
  Bp = 'BP',
  Bs = 'BS',
  Bm = 'BM',
  Bme = 'BME',
  Bms = 'BMS',
  Br = 'BR',
  Cr = 'CR',
  Gr = 'GR',
  Tn = 'TN',
  Ls = 'LS',
  Rp = 'RP',
  Sb = 'SB',
  Sp = 'SP',
  Tr = 'TR'
}

export type ProgramInput = {
  code?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  initials?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ProgramInstructorCount = {
  __typename?: 'ProgramInstructorCount';
  belowRecommendedAmount?: Maybe<Scalars['Boolean']>;
  lastEnquiredOn?: Maybe<Scalars['AWSDateTime']>;
  numberOfInstructors?: Maybe<Scalars['Int']>;
};

export type ProgramInterface = {
  code: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initials: Scalars['String'];
  name: Scalars['String'];
  programType?: Maybe<ProgramTypeEnum>;
};

export enum ProgramName {
  Bodyattack = 'BODYATTACK',
  Bodybalance = 'BODYBALANCE',
  Bodycombat = 'BODYCOMBAT',
  Bodyflow = 'BODYFLOW',
  Bodyjam = 'BODYJAM',
  Bodypump = 'BODYPUMP',
  Bodystep = 'BODYSTEP',
  BtmEarlyYears = 'BTM_EARLY_YEARS',
  BtmSchoolYears = 'BTM_SCHOOL_YEARS',
  LesMillsBarre = 'LES_MILLS_BARRE',
  LesMillsCore = 'LES_MILLS_CORE',
  LesMillsGrit = 'LES_MILLS_GRIT',
  LesMillsTone = 'LES_MILLS_TONE',
  LmiStep = 'LMI_STEP',
  Rpm = 'RPM',
  Shbam = 'SHBAM',
  Sprint = 'SPRINT',
  TheTrip = 'THE_TRIP',
  BornToMove = 'BORN_TO_MOVE'
}

export enum ProgramStatus {
  InProgress = 'IN_PROGRESS',
  Active = 'ACTIVE',
  InCancellation = 'IN_CANCELLATION',
  Cancelled = 'CANCELLED',
  NotMapped = 'NOT_MAPPED'
}

export enum ProgramTypeEnum {
  Live = 'LIVE',
  Virtual = 'VIRTUAL',
  AtHome = 'AT_HOME'
}

export type ProgramTypeInfo = {
  __typename?: 'ProgramTypeInfo';
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Promotion = {
  __typename?: 'Promotion';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image: ImageSet;
  link?: Maybe<Link>;
  title: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _root?: Maybe<Scalars['String']>;
  bookedEvent: BookedEventResponse;
  bookedEvents: BookedEventsResponse;
  dynamoMigrate: DynamoMigrateResponse;
  eventDetails: EventDetailsResponse;
  getAgreementDocument: AgreementDocumentResponse;
  getAllTranslations?: Maybe<GetAllTranslationsResponse>;
  getChainAffiliations: GetChainAffiliationsResponse;
  getChainInstructorAffiliates: GetChainInstructorAffiliatesResponse;
  getChainUser?: Maybe<ChainUserResponse>;
  getChainUserClubListings?: Maybe<GetChainUserClubListingsResponse>;
  getClubAffiliations?: Maybe<GetClubAffiliationsResponse>;
  getClubById?: Maybe<GetClubByIdResponse>;
  getClubInstructorAffiliates?: Maybe<GetClubInstructorAffiliatesResponse>;
  getClubListingById: GetClubListingByIdResponse;
  getClubListingsByClubId?: Maybe<GetClubListingsByClubIdResponse>;
  getClubNotifications?: Maybe<GetClubNotificationsResponse>;
  getClubSettings: GetClubSettingsResponse;
  getClubsAvailabilityOnListing?: Maybe<GetClubsAvailabilityOnListingResponse>;
  getContactUsContent: GetContactUsContentResponse;
  getCurrentUser?: Maybe<GetUserResponse>;
  getCurrentUserPhotoUploadData?: Maybe<GetCurrentUserPhotoUploadDataResponse>;
  getCurrentUserPortfolioUploadData: GetCurrentUserPortfolioUploadDataResponse;
  getDashboard?: Maybe<GetDashboardResponse>;
  getEqualOpportunitiesPolicy: GetEqualOpportunitiesPolicyResponse;
  getExploreProgramsPage?: Maybe<GetExploreProgramsPageResult>;
  getFeatureFlag?: Maybe<FeatureFlagResponse>;
  getFrequentlyAskedQuestions: GetFrequentlyAskedQuestionsResponse;
  getHeaders?: Maybe<GetHeaderResponse>;
  getInstructorClubAffiliates: GetInstructorClubAffiliatesResponse;
  getInstructorWatchlist?: Maybe<SearchClubListingsResponse>;
  getNavigation?: Maybe<GetNavigationResponse>;
  getNotifications?: Maybe<GetNotificationsResponse>;
  getPickLists?: Maybe<GetPickListResponse>;
  getPresignedUrl?: Maybe<GetPresignedUrlResponse>;
  getPrivacySettingValues?: Maybe<GetPrivacySettingValuesResponse>;
  getProfileById?: Maybe<GetProfileByIdResponse>;
  getSavedInstructorSearchData?: Maybe<GetSavedInstructorSearchDataResponse>;
  getSharedNavigation?: Maybe<GetSharedNavigation>;
  getUserAccount: GetAccountSettingsResponse;
  getUserEnquiries?: Maybe<GetUserEnquiriesResponse>;
  getUserSession: GetUserSessionResponse;
  getVideoGuidelines: GetVideoGuidelinesResponse;
  getWalkthrough?: Maybe<GetWalkthroughResponse>;
  searchClubListings?: Maybe<SearchClubListingsResponse>;
  searchClubs?: Maybe<SearchClubsResponse>;
  searchEvents: SearchEventsResponse;
  searchInstructors?: Maybe<SearchInstructorsResponse>;
  trainerBooking: TrainerBookingResponse;
  trainerBookings: TrainerBookingsResponse;
  trainingJourney: TrainingJourneyResponse;
  trainingJourneys: TrainingJourneysResponse;
};


export type Query_RootArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryBookedEventArgs = {
  eventId: Scalars['String'];
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryBookedEventsArgs = {
  clubId: Scalars['String'];
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryDynamoMigrateArgs = {
  input: DynamoMigrateInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryEventDetailsArgs = {
  eventId: Scalars['String'];
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetAgreementDocumentArgs = {
  input: GetSignedAgreementInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetAllTranslationsArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetChainAffiliationsArgs = {
  input: GetChainAffiliationsInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetChainInstructorAffiliatesArgs = {
  input?: Maybe<GetChainInstructorAffiliatesInput>;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetChainUserArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetChainUserClubListingsArgs = {
  input: GetChainUserClubListingsInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetClubAffiliationsArgs = {
  input: GetClubAffiliationsInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetClubByIdArgs = {
  input: GetClubByIdInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetClubInstructorAffiliatesArgs = {
  input: GetClubInstructorAffiliatesInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetClubListingByIdArgs = {
  listingId: Scalars['String'];
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetClubListingsByClubIdArgs = {
  input: GetClubListingsByClubIdInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetClubNotificationsArgs = {
  filter: GetClubNotificationsInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetClubSettingsArgs = {
  input: GetClubSettingsInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetClubsAvailabilityOnListingArgs = {
  input: GetClubsAvailabilityOnListingInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetContactUsContentArgs = {
  input: GetContactUsContentInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetCurrentUserArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetCurrentUserPhotoUploadDataArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetCurrentUserPortfolioUploadDataArgs = {
  input: GetCurrentUserPortfolioUploadDataInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetDashboardArgs = {
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetEqualOpportunitiesPolicyArgs = {
  input: GetEqualOpportunitiesPolicyInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetExploreProgramsPageArgs = {
  role: UserRoleInput;
  programName?: Maybe<ProgramName>;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetFeatureFlagArgs = {
  input: FeatureFlagQueryInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetFrequentlyAskedQuestionsArgs = {
  input: GetFrequentlyAskedQuestionsInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetHeadersArgs = {
  input: GetHeaderInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetInstructorClubAffiliatesArgs = {
  input: GetInstructorClubAffiliatesInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetInstructorWatchlistArgs = {
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetNavigationArgs = {
  input?: Maybe<GetNavigationInput>;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetNotificationsArgs = {
  filter: DateTimeFilterInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetPickListsArgs = {
  input: GetPickListInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetPresignedUrlArgs = {
  input?: Maybe<GetPresignedUrlInput>;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetPrivacySettingValuesArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetProfileByIdArgs = {
  input: GetProfileByIdInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetSavedInstructorSearchDataArgs = {
  input: GetSavedInstructorSearchDataInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetSharedNavigationArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetUserAccountArgs = {
  input: GetUserByIdInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetUserEnquiriesArgs = {
  role: UserRoleInput;
  filter: UserEnquiriesFilter;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetUserSessionArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetVideoGuidelinesArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryGetWalkthroughArgs = {
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QuerySearchClubListingsArgs = {
  filter: SearchClubListingsFilter;
  options?: Maybe<SearchClubListingsOptions>;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QuerySearchClubsArgs = {
  filter: SearchClubsFilter;
  options?: Maybe<SearchClubsOptions>;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QuerySearchEventsArgs = {
  searchInfo: SearchEventsInput;
  filterInfo: FilterEventsInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QuerySearchInstructorsArgs = {
  filter: SearchInstructorsFilter;
  options?: Maybe<SearchInstructorsOptions>;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryTrainerBookingArgs = {
  input: TrainerBookingInput;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryTrainerBookingsArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryTrainingJourneyArgs = {
  input: TrainingJourneyInput;
  role?: Maybe<UserRoleInput>;
  _cacheKey?: Maybe<Scalars['String']>;
};


export type QueryTrainingJourneysArgs = {
  _cacheKey?: Maybe<Scalars['String']>;
};

export type ReceivedCertificationOutcomeStep = Step & {
  __typename?: 'ReceivedCertificationOutcomeStep';
  activeStepName: Scalars['String'];
  activeStepNumber: Scalars['Int'];
  attended?: Maybe<AttendedStatusEnum>;
  cancellationPolicy: Scalars['AWSJSON'];
  certificationOutcomes?: Maybe<Array<TrainingOutcome>>;
  certificationUrl?: Maybe<Scalars['String']>;
  contactUsEmail: Scalars['String'];
  deliveryMethod: DeliveryMethodEnum;
  details?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  eventName: Scalars['String'];
  fulfillment?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  onlineTrainingLink?: Maybe<Scalars['String']>;
  passPendingGuide: Scalars['AWSJSON'];
  programName: Scalars['String'];
  requirements?: Maybe<Scalars['String']>;
  resubmitDueDate?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  stepName: Scalars['String'];
  stepNumber: Scalars['Int'];
  timezone: Scalars['String'];
  trackAllocation?: Maybe<Scalars['String']>;
  trainerEmail?: Maybe<Scalars['String']>;
  trainerName?: Maybe<Scalars['String']>;
  venueAddress?: Maybe<Scalars['String']>;
};

export type ReceivedTrainingOutcomeStep = Step & {
  __typename?: 'ReceivedTrainingOutcomeStep';
  activeStepName: Scalars['String'];
  activeStepNumber: Scalars['Int'];
  assessmentOutcomes?: Maybe<Array<TrainingOutcome>>;
  attended?: Maybe<AttendedStatusEnum>;
  cancellationPolicy: Scalars['AWSJSON'];
  cecCertificationUrl?: Maybe<Scalars['String']>;
  contactUsEmail: Scalars['String'];
  deliveryMethod: DeliveryMethodEnum;
  details?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  eventName: Scalars['String'];
  extendedDueDate?: Maybe<Scalars['String']>;
  fulfillment?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  onlineTrainingLink?: Maybe<Scalars['String']>;
  passPendingGuide: Scalars['AWSJSON'];
  programName: Scalars['String'];
  requirements?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  stepName: Scalars['String'];
  stepNumber: Scalars['Int'];
  timezone: Scalars['String'];
  trackAllocation?: Maybe<Scalars['String']>;
  trainerEmail?: Maybe<Scalars['String']>;
  trainerName?: Maybe<Scalars['String']>;
  venueAddress?: Maybe<Scalars['String']>;
};

export enum RecordType {
  PresentationTrack = 'PRESENTATION_TRACK',
  OutcomeTrack = 'OUTCOME_TRACK',
  OneTrack = 'ONE_TRACK',
  Initial = 'INITIAL'
}

export enum ReleaseActivityStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Na = 'NA'
}

export enum ReleaseBundleTypeEnum {
  Standard = 'STANDARD',
  Discounted = 'DISCOUNTED',
  DiscountedChain = 'DISCOUNTED_CHAIN',
  Sunflower = 'SUNFLOWER',
  Nomura = 'NOMURA',
  OgSport = 'OG_SPORT',
  Oasis = 'OASIS',
  Super = 'SUPER',
  Mega = 'MEGA',
  Plus = 'PLUS',
  Shine = 'SHINE'
}

export enum ReleaseStatusEnum {
  Subscribed = 'SUBSCRIBED',
  Unsubscribed = 'UNSUBSCRIBED',
  OnHold = 'ON_HOLD'
}

export type RemoveFromShortlistInput = {
  userId: Scalars['String'];
};

export type RemoveFromShortlistResponse = RemoveFromShortlistResult | ErrorResult;

export type RemoveFromShortlistResult = {
  __typename?: 'RemoveFromShortlistResult';
  shortlist?: Maybe<Array<InstructorShortlist>>;
};

export type ReportClubListingInput = {
  clubId: Scalars['ID'];
  listingId: Scalars['ID'];
  message: Scalars['String'];
  reason: ReportClubListingReasonEnum;
};

export enum ReportClubListingReasonEnum {
  InappropriateContent = 'INAPPROPRIATE_CONTENT',
  MisleadingFalseInformation = 'MISLEADING_FALSE_INFORMATION',
  BullyingDiscriminationHarassment = 'BULLYING_DISCRIMINATION_HARASSMENT'
}

export type ReportClubListingResponse = ClubSingleListing | ClubMultiListing | ErrorResult;

export type ReportClubProfileInput = {
  clubId: Scalars['ID'];
  message: Scalars['String'];
  reason: ReportClubProfileReasonEnum;
};

export enum ReportClubProfileReasonEnum {
  InappropriateContent = 'INAPPROPRIATE_CONTENT',
  MisleadingFalseInformation = 'MISLEADING_FALSE_INFORMATION',
  BullyingDiscriminationHarassment = 'BULLYING_DISCRIMINATION_HARASSMENT'
}

export type ReportClubProfileResponse = ClubProfile | ErrorResult;

export type RequestForExtensionInput = {
  assessmentId: Scalars['String'];
  reason: Scalars['String'];
  trainingJourneyId: Scalars['String'];
};

export type RequestForExtensionResponse = RequestForExtensionResult | ErrorResult;

export type RequestForExtensionResult = {
  __typename?: 'RequestForExtensionResult';
  isSuccess?: Maybe<Scalars['Boolean']>;
};

export enum RequireFilter {
  Default = 'DEFAULT',
  Only = 'ONLY',
  All = 'ALL'
}

export type ResendPasswordResetEmailInput = {
  email: Scalars['String'];
};

export type ResendPasswordResetEmailResponse = ResendPasswordResetEmailResult | ErrorResult;

export type ResendPasswordResetEmailResult = {
  __typename?: 'ResendPasswordResetEmailResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type RevertAppMetadataInput = {
  email: Scalars['String'];
};

export enum Role {
  Instructor = 'INSTRUCTOR',
  Club = 'CLUB'
}

export type RootMenuItem = {
  __typename?: 'RootMenuItem';
  analyticsEvent?: Maybe<AnalyticsEvent>;
  icon: MenuItemIcon;
  id: Scalars['ID'];
  items?: Maybe<Array<MenuItem>>;
  label: Scalars['String'];
  url?: Maybe<Url>;
};

export type SavedClubSearch = {
  __typename?: 'SavedClubSearch';
  filter?: Maybe<SavedClubSearchFilter>;
  sort?: Maybe<SearchClubsSort>;
};

export type SavedClubSearchFilter = {
  __typename?: 'SavedClubSearchFilter';
  name?: Maybe<Scalars['String']>;
  programs?: Maybe<Array<Scalars['ID']>>;
};

export type SavedInstructorSearch = {
  __typename?: 'SavedInstructorSearch';
  filter?: Maybe<SavedSearchClubListingsFilter>;
  sort?: Maybe<SearchClubListingsSort>;
};

export type SavedSearchClubListingsFilter = {
  __typename?: 'SavedSearchClubListingsFilter';
  location?: Maybe<LocationFilter>;
  name?: Maybe<Scalars['String']>;
  programs?: Maybe<Array<Scalars['ID']>>;
  status?: Maybe<ClubListingStatus>;
  substituteListingFilter?: Maybe<SearchClubListingsSubstitute>;
};

export type SearchClubJobListingsSingleResult = SearchClubListingsResultCommon & {
  __typename?: 'SearchClubJobListingsSingleResult';
  distance?: Maybe<Scalars['Float']>;
  distanceUnit?: Maybe<LocationDistanceUnit>;
  enquiredOn?: Maybe<Scalars['AWSDateTime']>;
  hasBeenReported?: Maybe<Scalars['Boolean']>;
  listing: ClubListing;
  messageSentOn?: Maybe<Scalars['AWSDateTime']>;
  onWatchlist?: Maybe<Scalars['Boolean']>;
};

export type SearchClubListingsFilter = {
  location?: Maybe<LocationFilterInput>;
  name?: Maybe<Scalars['String']>;
  programs?: Maybe<Array<Scalars['ID']>>;
  status?: Maybe<ClubListingStatus>;
  substituteListingFilter?: Maybe<SearchClubListingsSubstitute>;
};

export type SearchClubListingsOptions = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<SearchClubListingsSort>;
};

export type SearchClubListingsResponse = SearchClubListingsResult | ErrorResult;

export type SearchClubListingsResult = {
  __typename?: 'SearchClubListingsResult';
  results?: Maybe<Array<SearchClubListingsSingleResult>>;
  total: Scalars['Int'];
};

export type SearchClubListingsResultCommon = {
  distance?: Maybe<Scalars['Float']>;
  distanceUnit?: Maybe<LocationDistanceUnit>;
  hasBeenReported?: Maybe<Scalars['Boolean']>;
  onWatchlist?: Maybe<Scalars['Boolean']>;
};

export type SearchClubListingsSingleResult = SearchClubJobListingsSingleResult | SearchClubProfileSingleResult;

export enum SearchClubListingsSort {
  Nearest = 'NEAREST',
  MostRecent = 'MOST_RECENT',
  Relevance = 'RELEVANCE'
}

export enum SearchClubListingsSubstitute {
  OnlySubstitute = 'ONLY_SUBSTITUTE',
  OnlyNonSubstitute = 'ONLY_NON_SUBSTITUTE',
  All = 'ALL'
}

export type SearchClubProfileSingleResult = SearchClubListingsResultCommon & {
  __typename?: 'SearchClubProfileSingleResult';
  distance?: Maybe<Scalars['Float']>;
  distanceUnit?: Maybe<LocationDistanceUnit>;
  hasBeenReported?: Maybe<Scalars['Boolean']>;
  onWatchlist?: Maybe<Scalars['Boolean']>;
  profile: UserProfileClub;
};

export type SearchClubsFilter = {
  location?: Maybe<LocationFilterInput>;
  name?: Maybe<Scalars['String']>;
  programs?: Maybe<Array<Scalars['ID']>>;
  status?: Maybe<ClubListingStatus>;
};

export type SearchClubsOptions = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<SearchClubsSort>;
};

export type SearchClubsResponse = SearchClubsResult | ErrorResult;

export type SearchClubsResult = {
  __typename?: 'SearchClubsResult';
  results: Array<SearchClubsSingleResult>;
  total: Scalars['Int'];
};

export type SearchClubsSingleResult = {
  __typename?: 'SearchClubsSingleResult';
  clubId: Scalars['String'];
  distance?: Maybe<Scalars['Float']>;
  distanceUnit?: Maybe<LocationDistanceUnit>;
  hasBeenReported?: Maybe<Scalars['Boolean']>;
  instructorAffiliation?: Maybe<Affiliation>;
  onWatchlist?: Maybe<Scalars['Boolean']>;
  profile: UserProfileClub;
};

export enum SearchClubsSort {
  Nearest = 'NEAREST',
  Alphabetical = 'ALPHABETICAL',
  Relevance = 'RELEVANCE'
}

export type SearchEventsInput = {
  after?: Maybe<SearchAfterInput>;
  limit: Scalars['Int'];
};

export type SearchEventsResponse = EventsResult | ErrorResult;

export type SearchInstructorsFilter = {
  location?: Maybe<LocationFilterInput>;
  name?: Maybe<Scalars['String']>;
  onlyAvailableToSubstitute?: Maybe<Scalars['Boolean']>;
  programs?: Maybe<Array<Scalars['ID']>>;
};

export type SearchInstructorsOptions = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<SearchInstructorsSort>;
};

export type SearchInstructorsResponse = SearchInstructorsResult | ErrorResult;

export type SearchInstructorsResult = {
  __typename?: 'SearchInstructorsResult';
  isMatchingFilter?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Array<SearchInstructorsSingleResult>>;
  total: Scalars['Int'];
};

export type SearchInstructorsSingleResult = {
  __typename?: 'SearchInstructorsSingleResult';
  distance?: Maybe<Scalars['Float']>;
  distanceUnit?: Maybe<LocationDistanceUnit>;
  hasExistingAffiliation?: Maybe<Scalars['Boolean']>;
  hasRecentlyDeclinedAffiliation?: Maybe<Scalars['Boolean']>;
  instructorAffiliation?: Maybe<Affiliation>;
  inTravelZone?: Maybe<Scalars['Boolean']>;
  lastMessageIn48HoursSentAt?: Maybe<Scalars['AWSDateTime']>;
  messageSentOn?: Maybe<Scalars['AWSDateTime']>;
  profile: UserProfileTeaching;
  user: User;
};

export enum SearchInstructorsSort {
  Nearest = 'NEAREST',
  Alphabetical = 'ALPHABETICAL',
  Relevance = 'RELEVANCE'
}

export type SectionContentResult = {
  __typename?: 'SectionContentResult';
  content: Array<ContentField>;
  section: Scalars['String'];
};

export type SeedRandomAccountsInput = {
  number?: Maybe<Scalars['Int']>;
};

export type SeedRandomAccountsResponse = SeedRandomAccountsResult | ErrorResult;

export type SeedRandomAccountsResult = {
  __typename?: 'SeedRandomAccountsResult';
  results: Array<UserResult>;
  total: Scalars['Int'];
};

export type SendMessageInput = {
  clubId: Scalars['ID'];
  listingId?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  profileId: Scalars['ID'];
  requestedBy: UserRoleType;
};

export type SendMessageResponse = SendMessageResult | ErrorResult;

export type SendMessageResult = {
  __typename?: 'SendMessageResult';
  readAt?: Maybe<Scalars['AWSDateTime']>;
  sentAt: Scalars['AWSDateTime'];
};

export type SetAppMetadataInput = {
  email: Scalars['String'];
};

export type SetClubSettingsInput = {
  address?: Maybe<AddressInput>;
  email?: Maybe<EmailClubTypeInput>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type SetClubSettingsResponse = SetClubSettingsResult | ErrorResult;

export type SetClubSettingsResult = {
  __typename?: 'SetClubSettingsResult';
  address?: Maybe<Address>;
  email?: Maybe<EmailClubType>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type SetInstructorAvailabilityInput = {
  availability: InstructorAvailabilityValue;
  availableToSubstitute: Scalars['Boolean'];
};

export type SetInstructorAvailabilityResponse = InstructorAvailability | ErrorResult;

export type SetInstructorTravelPreferencesInput = {
  address?: Maybe<AddressInput>;
  distance?: Maybe<Scalars['Int']>;
  distanceUnit?: Maybe<LocationDistanceUnit>;
  id?: Maybe<Scalars['ID']>;
};

export type SetInstructorTravelPreferencesResponse = InstructorPreferences | ErrorResult;

export type SetInstructorWatchlistInput = {
  watchlist: Array<InstructorWatchlistItemInput>;
};

export type SetInstructorWatchlistResponse = InstructorWatchlist | ErrorResult;

export type SetPrivacySettingInput = {
  name: PrivacySettingTypeValueInstructor;
  value: PrivacySettingValue;
};

export type SetPrivacySettingsInput = {
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  settings: Array<SetPrivacySettingInput>;
  type: UserProfileType;
};

export type SetPrivacySettingsResponse = SetPrivacySettingsResult | ErrorResult;

export type SetPrivacySettingsResult = {
  __typename?: 'SetPrivacySettingsResult';
  settings: PrivacySettingsInstructor;
};

export type SetSavedClubSearchDataInput = {
  searches: Array<SetSavedClubSearchDataSingleInput>;
};

export type SetSavedClubSearchDataResponse = SetSavedClubSearchDataResult | ErrorResult;

export type SetSavedClubSearchDataResult = {
  __typename?: 'SetSavedClubSearchDataResult';
  results: Array<SavedClubSearch>;
};

export type SetSavedClubSearchDataSingleInput = {
  filter: SearchClubsFilter;
  sort?: Maybe<SearchClubsSort>;
};

export type SetSavedInstructorSearchDataInput = {
  searches: Array<SetSavedInstructorSearchDataSingleInput>;
};

export type SetSavedInstructorSearchDataResponse = SetSavedInstructorSearchDataResult | ErrorResult;

export type SetSavedInstructorSearchDataResult = {
  __typename?: 'SetSavedInstructorSearchDataResult';
  results: Array<SavedInstructorSearch>;
};

export type SetSavedInstructorSearchDataSingleInput = {
  filter: SearchClubListingsFilter;
  sort?: Maybe<SearchClubListingsSort>;
};

export type SetSignedAgreementDataInput = {
  type: AgreementType;
  version: Scalars['String'];
};

export type SetWalkthroughToSeenResponse = SetWalkthroughToSeenResult | ErrorResult;

export type SetWalkthroughToSeenResult = {
  __typename?: 'SetWalkthroughToSeenResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type SharedNavItem = {
  __typename?: 'SharedNavItem';
  navItems?: Maybe<Array<Maybe<NavItem>>>;
  role?: Maybe<Role>;
};

export type SharedNavigation = {
  __typename?: 'SharedNavigation';
  clubNav?: Maybe<SharedNavItem>;
  instructorNav?: Maybe<SharedNavItem>;
  websiteLink: Url;
};

export type SignedAgreement = {
  __typename?: 'SignedAgreement';
  signedOn: Scalars['AWSDateTime'];
  type: AgreementType;
  version: Scalars['String'];
};

export enum SnapshotStateEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type SocialMediaContent = {
  __typename?: 'SocialMediaContent';
  type: SocialMediaContentType;
  value: Scalars['String'];
};

export enum SocialMediaContentType {
  Video = 'VIDEO',
  Image = 'IMAGE',
  Text = 'TEXT'
}

export type SocialMediaPost = {
  __typename?: 'SocialMediaPost';
  content: SocialMediaContent;
  createdAt: Scalars['AWSDateTime'];
  createdAtLabel: Scalars['String'];
  id: Scalars['ID'];
  type: SocialMediaType;
  url: Scalars['String'];
};

export type SocialMediaSection = {
  __typename?: 'SocialMediaSection';
  items?: Maybe<Array<SocialMediaPost>>;
  title: Scalars['String'];
};

export enum SocialMediaType {
  Youtube = 'YOUTUBE',
  Instagram = 'INSTAGRAM',
  Tiktok = 'TIKTOK',
  Facebook = 'FACEBOOK'
}

export enum StatesEnum {
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  California = 'CALIFORNIA',
  Colorado = 'COLORADO',
  Connecticut = 'CONNECTICUT',
  Delaware = 'DELAWARE',
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Hawaii = 'HAWAII',
  Idaho = 'IDAHO',
  Illinois = 'ILLINOIS',
  Indiana = 'INDIANA',
  Iowa = 'IOWA',
  Kansas = 'KANSAS',
  Kentucky = 'KENTUCKY',
  Louisiana = 'LOUISIANA',
  Maine = 'MAINE',
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Montana = 'MONTANA',
  Nebraska = 'NEBRASKA',
  Nevada = 'NEVADA',
  NewHampshire = 'NEW_HAMPSHIRE',
  NewJersey = 'NEW_JERSEY',
  NewMexico = 'NEW_MEXICO',
  NewYork = 'NEW_YORK',
  NorthCarolina = 'NORTH_CAROLINA',
  NorthDakota = 'NORTH_DAKOTA',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Oregon = 'OREGON',
  Pennsylvania = 'PENNSYLVANIA',
  RhodeIsland = 'RHODE_ISLAND',
  SouthCarolina = 'SOUTH_CAROLINA',
  SouthDakota = 'SOUTH_DAKOTA',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  Utah = 'UTAH',
  Vermont = 'VERMONT',
  Virginia = 'VIRGINIA',
  Washington = 'WASHINGTON',
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING'
}

export type Step = {
  activeStepName: Scalars['String'];
  activeStepNumber: Scalars['Int'];
  attended?: Maybe<AttendedStatusEnum>;
  cancellationPolicy: Scalars['AWSJSON'];
  contactUsEmail: Scalars['String'];
  deliveryMethod: DeliveryMethodEnum;
  details?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  eventName: Scalars['String'];
  fulfillment?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  onlineTrainingLink?: Maybe<Scalars['String']>;
  passPendingGuide: Scalars['AWSJSON'];
  programName: Scalars['String'];
  requirements?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  stepName: Scalars['String'];
  stepNumber: Scalars['Int'];
  timezone: Scalars['String'];
  trackAllocation?: Maybe<Scalars['String']>;
  trainerEmail?: Maybe<Scalars['String']>;
  trainerName?: Maybe<Scalars['String']>;
  venueAddress?: Maybe<Scalars['String']>;
};

export type SubmitCertificationVideoStep = Step & {
  __typename?: 'SubmitCertificationVideoStep';
  activeStepName: Scalars['String'];
  activeStepNumber: Scalars['Int'];
  assessmentVideo?: Maybe<AssessmentVideo>;
  attended?: Maybe<AttendedStatusEnum>;
  cancellationPolicy: Scalars['AWSJSON'];
  contactUsEmail: Scalars['String'];
  deliveryMethod: DeliveryMethodEnum;
  details?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  eventName: Scalars['String'];
  extendedDueDate?: Maybe<Scalars['String']>;
  fulfillment?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  onlineTrainingLink?: Maybe<Scalars['String']>;
  passPendingGuide: Scalars['AWSJSON'];
  programName: Scalars['String'];
  requirements?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  stepName: Scalars['String'];
  stepNumber: Scalars['Int'];
  timezone: Scalars['String'];
  trackAllocation?: Maybe<Scalars['String']>;
  trainerEmail?: Maybe<Scalars['String']>;
  trainerName?: Maybe<Scalars['String']>;
  venueAddress?: Maybe<Scalars['String']>;
};

export type SubmitOutcomeTrackStep = Step & {
  __typename?: 'SubmitOutcomeTrackStep';
  activeStepName: Scalars['String'];
  activeStepNumber: Scalars['Int'];
  assessmentVideo?: Maybe<AssessmentVideo>;
  attended?: Maybe<AttendedStatusEnum>;
  cancellationPolicy: Scalars['AWSJSON'];
  contactUsEmail: Scalars['String'];
  deliveryMethod: DeliveryMethodEnum;
  details?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  eventName: Scalars['String'];
  extendedDueDate?: Maybe<Scalars['String']>;
  fulfillment?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  onlineTrainingLink?: Maybe<Scalars['String']>;
  passPendingGuide: Scalars['AWSJSON'];
  programName: Scalars['String'];
  requirements?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  stepName: Scalars['String'];
  stepNumber: Scalars['Int'];
  timezone: Scalars['String'];
  trackAllocation?: Maybe<Scalars['String']>;
  trainerEmail?: Maybe<Scalars['String']>;
  trainerName?: Maybe<Scalars['String']>;
  venueAddress?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _root?: Maybe<Scalars['String']>;
};

export type SyncMetadata = {
  force_sync?: Maybe<Scalars['Boolean']>;
};

export type TestPostUpsertClubFilterInput = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type TestPostUpsertClubInput = {
  filter?: Maybe<TestPostUpsertClubFilterInput>;
};

export type TestPostUpsertClubResponse = TestPostUpsertClubResult | ErrorResult;

export type TestPostUpsertClubResult = {
  __typename?: 'TestPostUpsertClubResult';
  done: Array<Scalars['String']>;
  failed: Array<Scalars['String']>;
};

export type TestPostUpsertUserFilterInput = {
  emails?: Maybe<Array<Scalars['String']>>;
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type TestPostUpsertUserInput = {
  filter?: Maybe<TestPostUpsertUserFilterInput>;
};

export type TestPostUpsertUserResponse = TestPostUpsertUserResult | ErrorResult;

export type TestPostUpsertUserResult = {
  __typename?: 'TestPostUpsertUserResult';
  done: Array<Scalars['String']>;
  failed: Array<Scalars['String']>;
};

export type Ticket = {
  __typename?: 'Ticket';
  canBeUsedWithDiscountCode: Scalars['Boolean'];
  currencyIsoCode: Scalars['String'];
  endDate: Scalars['String'];
  isDefaultTicket: Scalars['Boolean'];
  name: Scalars['String'];
  price: Scalars['String'];
  startDate: Scalars['String'];
  ticketId: Scalars['String'];
};

export type TimePreference = {
  __typename?: 'TimePreference';
  afternoon?: Maybe<Scalars['Boolean']>;
  evening?: Maybe<Scalars['Boolean']>;
  morning?: Maybe<Scalars['Boolean']>;
};

export type TimePreferenceInput = {
  afternoon?: Maybe<Scalars['Boolean']>;
  evening?: Maybe<Scalars['Boolean']>;
  morning?: Maybe<Scalars['Boolean']>;
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  day: DayOfWeek;
  slots: Array<TimeSlotValue>;
};

export type TimeSlotInput = {
  day: DayOfWeek;
  slots: Array<TimeSlotValue>;
};

export enum TimeSlotValue {
  Morning = 'MORNING',
  Afternoon = 'AFTERNOON',
  Evening = 'EVENING'
}

export type ToggleNotificationReadInput = {
  id: Array<Scalars['ID']>;
  read: Scalars['Boolean'];
};

export type ToggleNotificationReadResponse = ToggleNotificationReadResult | ErrorResult;

export type ToggleNotificationReadResult = {
  __typename?: 'ToggleNotificationReadResult';
  failure: Array<Scalars['ID']>;
  success: Array<Scalars['ID']>;
};

export type TrainerBookingInput = {
  eventId: Scalars['String'];
};

export type TrainerBookingResponse = TrainerBookingResult | ErrorResult;

export type TrainerBookingResult = {
  __typename?: 'TrainerBookingResult';
  eventAssessments: Array<EventAssessments>;
  eventSummary: EventSummary;
};

export type TrainerBookingsResponse = TrainerBookingsResult | ErrorResult;

export type TrainerBookingsResult = {
  __typename?: 'TrainerBookingsResult';
  result: Array<EventSummary>;
};

export type TrainerDetails = {
  __typename?: 'TrainerDetails';
  email: Scalars['String'];
  name: Scalars['String'];
};

export type TrainingBookedStep = Step & {
  __typename?: 'TrainingBookedStep';
  activeStepName: Scalars['String'];
  activeStepNumber: Scalars['Int'];
  attended?: Maybe<AttendedStatusEnum>;
  cancellationPolicy: Scalars['AWSJSON'];
  contactUsEmail: Scalars['String'];
  deliveryMethod: DeliveryMethodEnum;
  details?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContact>;
  endDate: Scalars['String'];
  eventName: Scalars['String'];
  fulfillment?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  onlineTrainingLink?: Maybe<Scalars['String']>;
  parqForm?: Maybe<Scalars['String']>;
  passPendingGuide: Scalars['AWSJSON'];
  programName: Scalars['String'];
  requirements?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  stepName: Scalars['String'];
  stepNumber: Scalars['Int'];
  timezone: Scalars['String'];
  trackAllocation?: Maybe<Scalars['String']>;
  trainerEmail?: Maybe<Scalars['String']>;
  trainerName?: Maybe<Scalars['String']>;
  venueAddress?: Maybe<Scalars['String']>;
};

export type TrainingJourney = {
  __typename?: 'TrainingJourney';
  steps?: Maybe<Array<TrainingStep>>;
};

export type TrainingJourneyInput = {
  trainingId: Scalars['ID'];
};

export type TrainingJourneyResponse = TrainingJourneyResult | ErrorResult;

export type TrainingJourneyResult = {
  __typename?: 'TrainingJourneyResult';
  result?: Maybe<TrainingJourney>;
};

export type TrainingJourneysResponse = TrainingJourneysResult | ErrorResult;

export type TrainingJourneysResult = {
  __typename?: 'TrainingJourneysResult';
  eventsAndTrainingUrl: Scalars['String'];
  trainings?: Maybe<Array<TrainingSummary>>;
};

export type TrainingOutcome = {
  __typename?: 'TrainingOutcome';
  assessmentVideo?: Maybe<AssessmentVideo>;
  developmentSheet?: Maybe<Scalars['String']>;
  outcome?: Maybe<OutcomeEnum>;
  submitDate?: Maybe<Scalars['String']>;
  trainingNotes?: Maybe<Scalars['String']>;
};

export type TrainingStep = TrainingBookedStep | PreworkStep | AttendTrainingStep | SubmitOutcomeTrackStep | ReceivedTrainingOutcomeStep | SubmitCertificationVideoStep | ReceivedCertificationOutcomeStep | CertifiedStep | ContactUsStep;

export type TrainingSummary = {
  __typename?: 'TrainingSummary';
  activeStepName: Scalars['String'];
  activeStepNumber: Scalars['Int'];
  cecCertificationUrl?: Maybe<Scalars['String']>;
  dateCertified?: Maybe<Scalars['String']>;
  deliveryMethod: DeliveryMethodEnum;
  dueDate?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  eventName: Scalars['String'];
  id: Scalars['ID'];
  isContactUs: Scalars['Boolean'];
  programName: Scalars['String'];
  startDate: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  venueAddress?: Maybe<Scalars['String']>;
};

export enum TransactionStatusEnum {
  InProgress = 'IN_PROGRESS',
  Done = 'DONE',
  Failed = 'FAILED'
}

export type TravelPreference = {
  __typename?: 'TravelPreference';
  address?: Maybe<Address>;
  distance?: Maybe<Scalars['Int']>;
  distanceUnit?: Maybe<LocationDistanceUnit>;
};

export type TravelPreferenceInput = {
  address?: Maybe<AddressInput>;
  distance?: Maybe<Scalars['Int']>;
};

export type Url = {
  __typename?: 'URL';
  type: UrlType;
  value: Scalars['String'];
};

export type UrlInput = {
  type: UrlType;
  value: Scalars['String'];
};

export enum UrlType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL'
}

export type UpdateAccountSettingsResponse = UserAccountSettingsResult | ErrorResult;

export type UpdateAffiliationInput = {
  id: Scalars['String'];
  status: AffiliationStatus;
};

export type UpdateClubListingInput = {
  clubId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  expiringAt?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  programs?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<ClubListingStatus>;
  times?: Maybe<Array<TimePreferenceInput>>;
};

export type UpdateClubListingResponse = ClubListingResult | ErrorResult;

export type UpdateClubProfileInput = {
  address?: Maybe<AddressInput>;
  bio?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  hiring?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  links?: Maybe<Array<ProfileLinkInput>>;
  media?: Maybe<Array<ImageInput>>;
};

export type UpdateClubProfileResponse = UserResult | ErrorResult;

export type UpdateCurrentUserPortfolioUploadInput = {
  cancelTokens?: Maybe<Array<Scalars['String']>>;
  confirmTokens?: Maybe<Array<Scalars['String']>>;
  removeImages?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateCurrentUserPortfolioUploadResponse = UpdateCurrentUserPortfolioUploadResult | ErrorResult;

export type UpdateCurrentUserPortfolioUploadResult = {
  __typename?: 'UpdateCurrentUserPortfolioUploadResult';
  profile: UserProfile;
  url: Array<Scalars['String']>;
};

export type UpdateProfileResponse = UserResult | ErrorResult;

export type UpdateTeachingProfileInput = {
  address?: Maybe<AddressInput>;
  availability?: Maybe<InstructorAvailabilityValue>;
  availableToSubstitute?: Maybe<Scalars['Boolean']>;
  bio?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Scalars['String']>>;
  links?: Maybe<Array<ProfileLinkInput>>;
  media?: Maybe<Array<ImageInput>>;
  preferences?: Maybe<InstructorPreferencesInput>;
  privacySettings?: Maybe<PrivacySettingsInstructorInput>;
};

export type UpdateTeachingProfileResponse = UserResult | ErrorResult;

export type UpdateUserAccountInput = {
  address?: Maybe<AddressInput>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type UpdateUserPhotoInput = {
  profileId: Scalars['ID'];
  profileType: UserProfileType;
  token: Scalars['String'];
};

export type UpdateUserPhotoResponse = UpdateUserPhotoResult | ErrorResult;

export type UpdateUserPhotoResult = {
  __typename?: 'UpdateUserPhotoResult';
  profile: UserProfile;
  url: Scalars['AWSURL'];
};

export type UpsertAgreementInput = {
  content: Scalars['String'];
  market: Scalars['String'];
  type: AgreementType;
  version: Scalars['String'];
};

export type UpsertAgreementResponse = UpsertAgreementResult | ErrorResult;

export type UpsertAgreementResult = {
  __typename?: 'UpsertAgreementResult';
  result: AgreementDocument;
};

export type UpsertClubInput = {
  accountId: Scalars['ID'];
  address: AddressInput;
  affiliations: Array<AffiliationInput>;
  currency: Currency;
  customerId?: Maybe<Scalars['String']>;
  email?: Maybe<EmailClubTypeInput>;
  enquiries?: Maybe<Array<EnquiryInput>>;
  hiring?: Maybe<Scalars['Boolean']>;
  location: GeoPointInput;
  market: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  products?: Maybe<Array<UpsertClubProductsInput>>;
  programs?: Maybe<Array<UpsertClubProgramsInput>>;
  releaseBundleType?: Maybe<ReleaseBundleTypeEnum>;
};

export type UpsertClubProductsInput = {
  category?: Maybe<ProgramTypeEnum>;
  displayName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  startDate: Scalars['AWSDateTime'];
  status: ProgramStatus;
};

export type UpsertClubProgramsInput = {
  category?: Maybe<ProgramTypeEnum>;
  displayName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  startDate?: Maybe<Scalars['AWSDateTime']>;
  status: ProgramStatus;
};

export type UpsertClubResponse = UpsertClubResult | ErrorResult;

export type UpsertClubResult = {
  __typename?: 'UpsertClubResult';
  club: Club;
};

export type UpsertPricebookInput = {
  filename: Scalars['String'];
};

export type UpsertPricebookResponse = UpsertPricebookResult | ErrorResult;

export type UpsertPricebookResult = {
  __typename?: 'UpsertPricebookResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type UpsertUserInput = {
  accountId: Scalars['String'];
  address: AddressInput;
  affiliations: Array<AffiliationInput>;
  billing?: Maybe<UserBillingInput>;
  certifications?: Maybe<Array<InstructorCertificationInput>>;
  email: Scalars['AWSEmail'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  location?: Maybe<GeoPointInput>;
  magentoStoreProfile?: Maybe<MagentoStoreProfileInput>;
  market: Scalars['String'];
  personContactId: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<InstructorRole>>;
};

export type UpsertUserResponse = UpsertUserResult | ErrorResult;

export type UpsertUserResult = {
  __typename?: 'UpsertUserResult';
  user: User;
};

export type User = {
  __typename?: 'User';
  accountId: Scalars['ID'];
  affiliations?: Maybe<Array<Affiliation>>;
  email?: Maybe<Scalars['AWSEmail']>;
  external?: Maybe<UserExternalInfo>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  instructorProfile?: Maybe<UserProfileTeaching>;
  lastName: Scalars['String'];
  market: Market;
  phone?: Maybe<Scalars['String']>;
  signedAgreements?: Maybe<Array<SignedAgreement>>;
  userMetaData?: Maybe<UserMetadata>;
};

export type UserAccountSettingsResult = {
  __typename?: 'UserAccountSettingsResult';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  outlinks?: Maybe<Array<OutLink>>;
  phone?: Maybe<Scalars['String']>;
};

export type UserBillingInput = {
  creditCardExpiry?: Maybe<Scalars['String']>;
  creditCardExpiryDate?: Maybe<Scalars['AWSDateTime']>;
  paymentMethod?: Maybe<Scalars['String']>;
};

export type UserEnquiriesFilter = {
  programs?: Maybe<Array<Scalars['String']>>;
  sortBy?: Maybe<UserEnquiriesSort>;
  status?: Maybe<Array<ClubListingStatus>>;
};

export enum UserEnquiriesSort {
  ClubName = 'CLUB_NAME',
  MostRecent = 'MOST_RECENT'
}

export type UserEnquiry = {
  __typename?: 'UserEnquiry';
  listing: EnquiryClubListingSingleResult;
  message: Message;
};

export type UserExternalInfo = {
  __typename?: 'UserExternalInfo';
  accountId: Scalars['ID'];
  contactId?: Maybe<Scalars['String']>;
};

export type UserMetadata = {
  __typename?: 'UserMetadata';
  walkthroughRequired: WalkthroughRequired;
};

export type UserProfile = UserProfileTeaching | UserProfileClub;

export type UserProfileClub = {
  __typename?: 'UserProfileClub';
  address: Address;
  atHomeProducts?: Maybe<GetAtHomeProductTilesResult>;
  bio?: Maybe<Scalars['String']>;
  businessSupport?: Maybe<Array<BusinessSupportSection>>;
  createdAt: Scalars['AWSDateTime'];
  createdAtLabel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['AWSEmail']>;
  enquiries?: Maybe<Array<ClubListingEnquiry>>;
  exploreProgramsCard?: Maybe<InStudioExploreProgramsTileCardResult>;
  hiring?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inStudioToolkit?: Maybe<GetInStudioToolkitResult>;
  isDigitalOnly?: Maybe<Scalars['Boolean']>;
  links?: Maybe<Array<ProfileLink>>;
  listings?: Maybe<Array<ClubListing>>;
  media?: Maybe<Array<Image>>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Image>;
  programs?: Maybe<Array<ClubProgramContent>>;
  shortlist?: Maybe<Array<InstructorShortlist>>;
  strength?: Maybe<Scalars['Int']>;
};

export type UserProfileTeaching = {
  __typename?: 'UserProfileTeaching';
  address: Address;
  availability?: Maybe<InstructorAvailabilityValue>;
  availableToSubstitute?: Maybe<Scalars['Boolean']>;
  bio?: Maybe<Scalars['String']>;
  certifications?: Maybe<Array<Certification>>;
  createdAt: Scalars['AWSDateTime'];
  createdAtLabel?: Maybe<Scalars['String']>;
  enquiries?: Maybe<Array<ClubListingEnquiry>>;
  id: Scalars['ID'];
  interests?: Maybe<Array<Scalars['String']>>;
  links?: Maybe<Array<ProfileLink>>;
  media?: Maybe<Array<Image>>;
  preferences?: Maybe<InstructorPreferences>;
  privacySettings?: Maybe<PrivacySettingsInstructor>;
  profileImage?: Maybe<Image>;
  roles?: Maybe<Array<InstructorRole>>;
  savedSearches?: Maybe<Array<SavedClubSearch>>;
  strength?: Maybe<Scalars['Int']>;
  watchlist?: Maybe<Array<InstructorWatchlistItem>>;
};

export enum UserProfileType {
  Instructor = 'INSTRUCTOR',
  Unknown = 'UNKNOWN',
  Club = 'CLUB'
}

export type UserResult = {
  __typename?: 'UserResult';
  distance?: Maybe<Scalars['Float']>;
  distanceUnit?: Maybe<LocationDistanceUnit>;
  id: Scalars['ID'];
  profiles: Array<UserProfile>;
  settings: UserSettings;
  user: User;
};

export type UserRoleInput = {
  id: Scalars['String'];
  roleType: UserRoleType;
};

export enum UserRoleType {
  Instructor = 'INSTRUCTOR',
  Club = 'CLUB',
  Unknown = 'UNKNOWN'
}

export type UserSettings = {
  __typename?: 'UserSettings';
  _debugIsAdmin?: Maybe<Scalars['Boolean']>;
  _debugIsAffiliated?: Maybe<Scalars['Boolean']>;
  locale: Locale;
};

export type Walkthrough = {
  __typename?: 'Walkthrough';
  pages: Array<WalkthroughPage>;
  totalPages: Scalars['Int'];
};

export type WalkthroughPage = {
  __typename?: 'WalkthroughPage';
  body: Array<Maybe<Scalars['AWSJSON']>>;
  image: ImageSet;
  pageNumber: Scalars['Int'];
  title: Scalars['String'];
};

export type WalkthroughRequired = {
  __typename?: 'WalkthroughRequired';
  club: Scalars['Boolean'];
  instructor: Scalars['Boolean'];
};

export type SearchAfter = {
  __typename?: 'searchAfter';
  eventId: Scalars['String'];
  sortValues: Array<Scalars['String']>;
};

export type SearchAfterInput = {
  eventId: Scalars['String'];
  sortValues: Array<Scalars['String']>;
};

export type InStudioClubProgramFragment = (
  { __typename?: 'ClubProgram' }
  & Pick<ClubProgram, 'id' | 'code' | 'name' | 'status' | 'initials' | 'programType'>
  & { instructorCount?: Maybe<(
    { __typename?: 'ProgramInstructorCount' }
    & Pick<ProgramInstructorCount, 'numberOfInstructors' | 'belowRecommendedAmount' | 'lastEnquiredOn'>
  )> }
);

export type InStudioEmptyStateFragment = (
  { __typename?: 'ClubProgramInStudioEmptyState' }
  & Pick<ClubProgramInStudioEmptyState, 'programType'>
  & { content?: Maybe<(
    { __typename?: 'InStudioEmptyState' }
    & Pick<InStudioEmptyState, 'subtitle' | 'title'>
    & { link: (
      { __typename?: 'Link' }
      & LinkFieldsFragment
    ) }
  )> }
);

export type InStudioToolkitTileFragment = (
  { __typename?: 'InStudioToolkitTile' }
  & Pick<InStudioToolkitTile, 'subTitle' | 'title'>
  & { image: (
    { __typename?: 'Image' }
    & ImageFieldsFragment
  ), link: (
    { __typename?: 'Link' }
    & LinkFieldsFragment
  ) }
);

export type GetClubProgramsForInStudioPageQueryVariables = Exact<{
  input: GetClubByIdInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetClubProgramsForInStudioPageQuery = (
  { __typename?: 'Query' }
  & { getClubById?: Maybe<(
    { __typename?: 'UserProfileClub' }
    & { programs?: Maybe<Array<(
      { __typename?: 'ClubProgram' }
      & InStudioClubProgramFragment
    ) | (
      { __typename?: 'ClubProgramInStudioEmptyState' }
      & InStudioEmptyStateFragment
    )>>, inStudioToolkit?: Maybe<(
      { __typename?: 'GetInStudioToolkitResult' }
      & { tiles?: Maybe<Array<Maybe<(
        { __typename?: 'InStudioToolkitTile' }
        & InStudioToolkitTileFragment
      )>>> }
    )>, exploreProgramsCard?: Maybe<(
      { __typename?: 'InStudioExploreProgramsTileCardResult' }
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )> }
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & Pick<ErrorResult, 'type' | 'message'>
  )> }
);

export type AddTrainingOutcomeMutationVariables = Exact<{
  input: AddTrainingOutcomeInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type AddTrainingOutcomeMutation = (
  { __typename?: 'Mutation' }
  & { addTrainingOutcome: (
    { __typename?: 'AddTrainingOutcomeResult' }
    & Pick<AddTrainingOutcomeResult, 'isSuccess'>
    & { developmentSheet?: Maybe<(
      { __typename?: 'DevelopmentSheet' }
      & Pick<DevelopmentSheet, 'filename' | 'url'>
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type BookEventMutationVariables = Exact<{
  input: BookEventInput;
  role: UserRoleInput;
}>;


export type BookEventMutation = (
  { __typename?: 'Mutation' }
  & { bookEvent: (
    { __typename?: 'BookEventResult' }
    & Pick<BookEventResult, 'magentoLink'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type BookedEventQueryVariables = Exact<{
  eventId: Scalars['String'];
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type BookedEventQuery = (
  { __typename?: 'Query' }
  & { bookedEvent: (
    { __typename?: 'BookedEventResult' }
    & { eventDetails: (
      { __typename?: 'EventDetailsResult' }
      & Pick<EventDetailsResult, 'schedule' | 'details' | 'requirements' | 'cancellationPolicy'>
      & { event: (
        { __typename?: 'Event' }
        & Pick<Event, 'eventId' | 'eventName' | 'startDate' | 'endDate' | 'deliveryMethod' | 'spacesAvailable' | 'venueAddress' | 'programName' | 'timezone'>
      ), trainerDetails?: Maybe<(
        { __typename?: 'TrainerDetails' }
        & Pick<TrainerDetails, 'name' | 'email'>
      )> }
    ), bookedInstructors?: Maybe<Array<(
      { __typename?: 'InstructorDetails' }
      & Pick<InstructorDetails, 'name'>
      & { profileImage?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'url'>
      )> }
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type BookedEventsQueryVariables = Exact<{
  clubId: Scalars['String'];
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type BookedEventsQuery = (
  { __typename?: 'Query' }
  & { bookedEvents: (
    { __typename?: 'BookedEventsResult' }
    & { bookedEvents?: Maybe<Array<(
      { __typename?: 'BookedEvent' }
      & Pick<BookedEvent, 'numberOfBookedInstructors'>
      & { event: (
        { __typename?: 'Event' }
        & Pick<Event, 'eventId' | 'eventName' | 'startDate' | 'endDate' | 'deliveryMethod' | 'spacesAvailable' | 'venueAddress' | 'programName'>
      ) }
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type EventDetailsQueryVariables = Exact<{
  eventId: Scalars['String'];
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type EventDetailsQuery = (
  { __typename?: 'Query' }
  & { eventDetails: (
    { __typename?: 'EventDetailsResult' }
    & Pick<EventDetailsResult, 'schedule' | 'details' | 'requirements' | 'cancellationPolicy'>
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'eventId' | 'eventName' | 'startDate' | 'endDate' | 'deliveryMethod' | 'spacesAvailable' | 'venueAddress' | 'programName' | 'timezone'>
    ), trainerDetails?: Maybe<(
      { __typename?: 'TrainerDetails' }
      & Pick<TrainerDetails, 'name' | 'email'>
    )>, tickets: Array<(
      { __typename?: 'Ticket' }
      & Pick<Ticket, 'ticketId' | 'name' | 'currencyIsoCode' | 'price' | 'startDate' | 'endDate' | 'isDefaultTicket' | 'canBeUsedWithDiscountCode'>
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type InstructorInfoFieldsFragment = (
  { __typename?: 'InstructorInformation' }
  & Pick<InstructorInformation, 'address' | 'email' | 'name' | 'phone'>
);

export type DevelopmentSheetFieldsFragment = (
  { __typename?: 'DevelopmentSheet' }
  & Pick<DevelopmentSheet, 'filename' | 'url'>
);

export type OutcomeTrackFieldsFragment = (
  { __typename?: 'OutcomeTrack' }
  & Pick<OutcomeTrack, 'comment' | 'submittedAt'>
  & { playback: (
    { __typename?: 'Playback' }
    & PlaybackFieldsFragment
  ) }
);

export type PresentationTrackFieldsFragment = (
  { __typename?: 'PresentationTrack' }
  & Pick<PresentationTrack, 'comment' | 'submittedAt'>
  & { playback: (
    { __typename?: 'Playback' }
    & PlaybackFieldsFragment
  ) }
);

export type EventSummaryFieldsFragment = (
  { __typename?: 'EventSummary' }
  & Pick<EventSummary, 'deliveryMethod' | 'endDate' | 'eventId' | 'eventName' | 'eventStatus' | 'programName' | 'startDate' | 'trainingJourneyCount' | 'venueAddress'>
);

export type EventAssessmentFieldsFragment = (
  { __typename?: 'EventAssessments' }
  & Pick<EventAssessments, 'attended' | 'outcome' | 'sessionRegistrationId' | 'trainingJourneyId' | 'trackAllocation'>
  & { developmentSheet?: Maybe<(
    { __typename?: 'DevelopmentSheet' }
    & DevelopmentSheetFieldsFragment
  )>, emergencyContact?: Maybe<(
    { __typename?: 'EmergencyContact' }
    & EmergencyContactFieldsFragment
  )>, instructorInfo: (
    { __typename?: 'InstructorInformation' }
    & InstructorInfoFieldsFragment
  ), outcomeTrack?: Maybe<(
    { __typename?: 'OutcomeTrack' }
    & OutcomeTrackFieldsFragment
  )>, presentationTrack?: Maybe<(
    { __typename?: 'PresentationTrack' }
    & PresentationTrackFieldsFragment
  )> }
);

export type TrainerBookingQueryVariables = Exact<{
  input: TrainerBookingInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type TrainerBookingQuery = (
  { __typename?: 'Query' }
  & { trainerBooking: (
    { __typename?: 'TrainerBookingResult' }
    & { eventAssessments: Array<(
      { __typename?: 'EventAssessments' }
      & EventAssessmentFieldsFragment
    )>, eventSummary: (
      { __typename?: 'EventSummary' }
      & EventSummaryFieldsFragment
    ) }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type TrainerBookingsQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type TrainerBookingsQuery = (
  { __typename?: 'Query' }
  & { trainerBookings: (
    { __typename?: 'TrainerBookingsResult' }
    & { result: Array<(
      { __typename?: 'EventSummary' }
      & Pick<EventSummary, 'deliveryMethod' | 'endDate' | 'eventId' | 'eventName' | 'programName' | 'startDate' | 'eventStatus' | 'trainingJourneyCount' | 'venueAddress'>
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type PlaybackFieldsFragment = (
  { __typename?: 'Playback' }
  & Pick<Playback, 'playbackUrl' | 'token'>
);

export type AssessmentVideoFieldsFragment = (
  { __typename?: 'AssessmentVideo' }
  & Pick<AssessmentVideo, 'assessmentId' | 'comment' | 'fileKey' | 'submitted' | 'submittedDate' | 'recordType' | 'videoNotWorking' | 'extensionRequested'>
  & { playback?: Maybe<(
    { __typename?: 'Playback' }
    & PlaybackFieldsFragment
  )> }
);

export type EmergencyContactFieldsFragment = (
  { __typename?: 'EmergencyContact' }
  & Pick<EmergencyContact, 'address' | 'name' | 'phone'>
);

export type TrainingOutcomeFieldsFragment = (
  { __typename?: 'TrainingOutcome' }
  & Pick<TrainingOutcome, 'developmentSheet' | 'outcome' | 'submitDate' | 'trainingNotes'>
  & { assessmentVideo?: Maybe<(
    { __typename?: 'AssessmentVideo' }
    & AssessmentVideoFieldsFragment
  )> }
);

type StepFields_AttendTrainingStep_Fragment = (
  { __typename?: 'AttendTrainingStep' }
  & Pick<AttendTrainingStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'passPendingGuide' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'venueAddress' | 'contactUsEmail'>
);

type StepFields_PreworkStep_Fragment = (
  { __typename?: 'PreworkStep' }
  & Pick<PreworkStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'passPendingGuide' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'venueAddress' | 'contactUsEmail'>
);

type StepFields_ReceivedCertificationOutcomeStep_Fragment = (
  { __typename?: 'ReceivedCertificationOutcomeStep' }
  & Pick<ReceivedCertificationOutcomeStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'passPendingGuide' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'venueAddress' | 'contactUsEmail'>
);

type StepFields_ReceivedTrainingOutcomeStep_Fragment = (
  { __typename?: 'ReceivedTrainingOutcomeStep' }
  & Pick<ReceivedTrainingOutcomeStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'passPendingGuide' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'venueAddress' | 'contactUsEmail'>
);

type StepFields_SubmitCertificationVideoStep_Fragment = (
  { __typename?: 'SubmitCertificationVideoStep' }
  & Pick<SubmitCertificationVideoStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'passPendingGuide' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'venueAddress' | 'contactUsEmail'>
);

type StepFields_SubmitOutcomeTrackStep_Fragment = (
  { __typename?: 'SubmitOutcomeTrackStep' }
  & Pick<SubmitOutcomeTrackStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'passPendingGuide' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'venueAddress' | 'contactUsEmail'>
);

type StepFields_TrainingBookedStep_Fragment = (
  { __typename?: 'TrainingBookedStep' }
  & Pick<TrainingBookedStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'passPendingGuide' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'venueAddress' | 'contactUsEmail'>
);

export type StepFieldsFragment = StepFields_AttendTrainingStep_Fragment | StepFields_PreworkStep_Fragment | StepFields_ReceivedCertificationOutcomeStep_Fragment | StepFields_ReceivedTrainingOutcomeStep_Fragment | StepFields_SubmitCertificationVideoStep_Fragment | StepFields_SubmitOutcomeTrackStep_Fragment | StepFields_TrainingBookedStep_Fragment;

export type TrainingBookedStepFieldsFragment = (
  { __typename?: 'TrainingBookedStep' }
  & Pick<TrainingBookedStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'passPendingGuide' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'parqForm' | 'venueAddress' | 'contactUsEmail'>
  & { emergencyContact?: Maybe<(
    { __typename?: 'EmergencyContact' }
    & EmergencyContactFieldsFragment
  )> }
);

export type UrlFieldsFragment = (
  { __typename?: 'URL' }
  & Pick<Url, 'type' | 'value'>
);

export type PreworkContentItemFieldsFragment = (
  { __typename?: 'PreworkContentItem' }
  & Pick<PreworkContentItem, 'title' | 'type'>
  & { url: (
    { __typename?: 'URL' }
    & UrlFieldsFragment
  ) }
);

export type PreworkContentFieldsFragment = (
  { __typename?: 'PreworkContent' }
  & { contentList?: Maybe<Array<(
    { __typename?: 'PreworkContentItem' }
    & PreworkContentItemFieldsFragment
  )>> }
);

export type PreworkStepFieldsFragment = (
  { __typename?: 'PreworkStep' }
  & Pick<PreworkStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'passPendingGuide' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'venueAddress' | 'contactUsEmail'>
  & { assessmentVideo?: Maybe<(
    { __typename?: 'AssessmentVideo' }
    & AssessmentVideoFieldsFragment
  )>, preworkContent?: Maybe<(
    { __typename?: 'PreworkContent' }
    & PreworkContentFieldsFragment
  )>, releaseUrl?: Maybe<(
    { __typename?: 'Link' }
    & LinkFieldsFragment
  )> }
);

export type AttendTrainingStepFieldsFragment = (
  { __typename?: 'AttendTrainingStep' }
  & Pick<AttendTrainingStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'passPendingGuide' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'venueAddress' | 'contactUsEmail'>
);

export type SubmitOutcomeTrackStepFieldsFragment = (
  { __typename?: 'SubmitOutcomeTrackStep' }
  & Pick<SubmitOutcomeTrackStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'passPendingGuide' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'extendedDueDate' | 'venueAddress' | 'contactUsEmail'>
  & { assessmentVideo?: Maybe<(
    { __typename?: 'AssessmentVideo' }
    & AssessmentVideoFieldsFragment
  )> }
);

export type ReceivedTrainingOutcomeStepFieldsFragment = (
  { __typename?: 'ReceivedTrainingOutcomeStep' }
  & Pick<ReceivedTrainingOutcomeStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'passPendingGuide' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'cecCertificationUrl' | 'extendedDueDate' | 'venueAddress' | 'contactUsEmail'>
  & { assessmentOutcomes?: Maybe<Array<(
    { __typename?: 'TrainingOutcome' }
    & TrainingOutcomeFieldsFragment
  )>> }
);

export type SubmitCertificationVideoStepFieldsFragment = (
  { __typename?: 'SubmitCertificationVideoStep' }
  & Pick<SubmitCertificationVideoStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'passPendingGuide' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'extendedDueDate' | 'venueAddress' | 'contactUsEmail'>
  & { assessmentVideo?: Maybe<(
    { __typename?: 'AssessmentVideo' }
    & AssessmentVideoFieldsFragment
  )> }
);

export type ReceivedCertificationOutcomeStepFieldsFragment = (
  { __typename?: 'ReceivedCertificationOutcomeStep' }
  & Pick<ReceivedCertificationOutcomeStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'passPendingGuide' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'resubmitDueDate' | 'certificationUrl' | 'venueAddress' | 'contactUsEmail'>
  & { certificationOutcomes?: Maybe<Array<(
    { __typename?: 'TrainingOutcome' }
    & TrainingOutcomeFieldsFragment
  )>> }
);

export type CertifiedStepFieldsFragment = (
  { __typename?: 'CertifiedStep' }
  & Pick<CertifiedStep, 'activeStepName' | 'activeStepNumber' | 'attended' | 'cancellationPolicy' | 'deliveryMethod' | 'details' | 'dueDate' | 'endDate' | 'eventName' | 'fulfillment' | 'level' | 'onlineTrainingLink' | 'passPendingGuide' | 'programName' | 'requirements' | 'schedule' | 'startDate' | 'stepName' | 'stepNumber' | 'timezone' | 'trackAllocation' | 'trainerEmail' | 'trainerName' | 'contactUsEmail' | 'venueAddress'>
);

export type TrainingJourneyQueryVariables = Exact<{
  input: TrainingJourneyInput;
  role?: Maybe<UserRoleInput>;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type TrainingJourneyQuery = (
  { __typename?: 'Query' }
  & { trainingJourney: (
    { __typename?: 'TrainingJourneyResult' }
    & { result?: Maybe<(
      { __typename?: 'TrainingJourney' }
      & { steps?: Maybe<Array<(
        { __typename?: 'TrainingBookedStep' }
        & TrainingBookedStepFieldsFragment
      ) | (
        { __typename?: 'PreworkStep' }
        & PreworkStepFieldsFragment
      ) | (
        { __typename?: 'AttendTrainingStep' }
        & AttendTrainingStepFieldsFragment
      ) | (
        { __typename?: 'SubmitOutcomeTrackStep' }
        & SubmitOutcomeTrackStepFieldsFragment
      ) | (
        { __typename?: 'ReceivedTrainingOutcomeStep' }
        & ReceivedTrainingOutcomeStepFieldsFragment
      ) | (
        { __typename?: 'SubmitCertificationVideoStep' }
        & SubmitCertificationVideoStepFieldsFragment
      ) | (
        { __typename?: 'ReceivedCertificationOutcomeStep' }
        & ReceivedCertificationOutcomeStepFieldsFragment
      ) | (
        { __typename?: 'CertifiedStep' }
        & CertifiedStepFieldsFragment
      ) | (
        { __typename?: 'ContactUsStep' }
        & Pick<ContactUsStep, 'activeStepName' | 'activeStepNumber' | 'stepName' | 'stepNumber' | 'contactUsEmail'>
      )>> }
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type TrainingJourneysQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type TrainingJourneysQuery = (
  { __typename?: 'Query' }
  & { trainingJourneys: (
    { __typename?: 'TrainingJourneysResult' }
    & Pick<TrainingJourneysResult, 'eventsAndTrainingUrl'>
    & { trainings?: Maybe<Array<(
      { __typename?: 'TrainingSummary' }
      & Pick<TrainingSummary, 'id' | 'eventName' | 'deliveryMethod' | 'startDate' | 'endDate' | 'timezone' | 'cecCertificationUrl' | 'venueAddress' | 'activeStepName' | 'activeStepNumber' | 'dueDate' | 'programName' | 'isContactUs' | 'dateCertified'>
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type RequestForExtensionMutationVariables = Exact<{
  input: RequestForExtensionInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type RequestForExtensionMutation = (
  { __typename?: 'Mutation' }
  & { requestForExtension: (
    { __typename?: 'RequestForExtensionResult' }
    & Pick<RequestForExtensionResult, 'isSuccess'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type SearchEventsQueryVariables = Exact<{
  searchInfo: SearchEventsInput;
  filterInfo: FilterEventsInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type SearchEventsQuery = (
  { __typename?: 'Query' }
  & { searchEvents: (
    { __typename?: 'EventsResult' }
    & { edges: Array<Maybe<(
      { __typename?: 'EventEdge' }
      & Pick<EventEdge, 'cursor'>
      & { node: (
        { __typename?: 'Event' }
        & Pick<Event, 'eventId' | 'eventName' | 'startDate' | 'endDate' | 'deliveryMethod' | 'spacesAvailable' | 'venueAddress' | 'programName' | 'programCode'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage'>
      & { endCursor?: Maybe<(
        { __typename?: 'searchAfter' }
        & Pick<SearchAfter, 'eventId' | 'sortValues'>
      )> }
    ) }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type AddClubListingMutationVariables = Exact<{
  input: AddClubListingInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type AddClubListingMutation = (
  { __typename?: 'Mutation' }
  & { addClubListing?: Maybe<(
    { __typename?: 'ClubListingResult' }
    & { results?: Maybe<Array<(
      { __typename?: 'ClubSingleListing' }
      & SingleListingFieldsFragment
    ) | (
      { __typename?: 'ClubMultiListing' }
      & MultiListingFieldsFragment
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type AddEmergencyContactMutationVariables = Exact<{
  input: EmergencyContactInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type AddEmergencyContactMutation = (
  { __typename?: 'Mutation' }
  & { addEmergencyContact: (
    { __typename?: 'AddEmergencyContactResult' }
    & { result?: Maybe<(
      { __typename?: 'EmergencyContact' }
      & Pick<EmergencyContact, 'address' | 'name' | 'phone'>
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type AddToShortlistMutationVariables = Exact<{
  input: AddToShortlistInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type AddToShortlistMutation = (
  { __typename?: 'Mutation' }
  & { addToShortlist?: Maybe<(
    { __typename?: 'AddToShortlistResult' }
    & { shortlist?: Maybe<Array<(
      { __typename?: 'InstructorShortlist' }
      & Pick<InstructorShortlist, 'id' | 'lastMessageIn48HoursSentAt' | 'distanceUnit' | 'distance'>
      & { profile: (
        { __typename?: 'UserProfileTeaching' }
        & InstructorProfileFieldsFragment
      ), user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'lastName' | 'firstName'>
      ) }
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type CreateAffiliationMutationVariables = Exact<{
  input: CreateAffiliationInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type CreateAffiliationMutation = (
  { __typename?: 'Mutation' }
  & { createAffiliation: (
    { __typename?: 'AffiliationAsyncResult' }
    & Pick<AffiliationAsyncResult, 'status'>
    & { affilation?: Maybe<(
      { __typename: 'Affiliation' }
      & Pick<Affiliation, 'actionedDate' | 'clubId' | 'id' | 'latestActionInitiator' | 'pendingStatus' | 'requestedBy' | 'requestedDate' | 'role' | 'status' | 'userId'>
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type CreateClubListingsMutationVariables = Exact<{
  input: CreateClubListingsInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type CreateClubListingsMutation = (
  { __typename?: 'Mutation' }
  & { createClubListings?: Maybe<(
    { __typename?: 'CreateClubListingsResult' }
    & { successfulListings?: Maybe<Array<(
      { __typename?: 'ClubSingleListing' }
      & SingleListingFieldsFragment
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type DeleteAffiliationMutationVariables = Exact<{
  input: DeleteAffiliationInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type DeleteAffiliationMutation = (
  { __typename?: 'Mutation' }
  & { deleteAffiliation: (
    { __typename?: 'AffiliationAsyncResult' }
    & Pick<AffiliationAsyncResult, 'status'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type DeleteClubListingMutationVariables = Exact<{
  input: DeleteClubListingInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type DeleteClubListingMutation = (
  { __typename?: 'Mutation' }
  & { deleteClubListing?: Maybe<(
    { __typename?: 'DeleteClubListingResult' }
    & Pick<DeleteClubListingResult, 'clubId' | 'status' | 'listingId'>
    & { results: Array<(
      { __typename?: 'ClubSingleListing' }
      & SingleListingFieldsFragment
    ) | (
      { __typename?: 'ClubMultiListing' }
      & MultiListingFieldsFragment
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type DeleteCurrentUserPortfolioImageMutationVariables = Exact<{
  input: DeleteCurrentUserPortfolioImageInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type DeleteCurrentUserPortfolioImageMutation = (
  { __typename?: 'Mutation' }
  & { deleteCurrentUserPortfolioImage: (
    { __typename?: 'DeleteCurrentUserPortfolioImageResult' }
    & Pick<DeleteCurrentUserPortfolioImageResult, 'status'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type DeleteMultipleJobListingsMutationVariables = Exact<{
  input: DeleteClubListingsInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type DeleteMultipleJobListingsMutation = (
  { __typename?: 'Mutation' }
  & { deleteClubListings?: Maybe<(
    { __typename?: 'DeleteClubListingsResult' }
    & { success?: Maybe<Array<(
      { __typename?: 'ClubSingleListing' }
      & SingleListingFieldsFragment
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type DigitalOnlyInStudioEnquireMutationVariables = Exact<{
  input: EnquireAboutInStudioProgramsInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type DigitalOnlyInStudioEnquireMutation = (
  { __typename?: 'Mutation' }
  & { enquireAboutInStudioPrograms?: Maybe<(
    { __typename?: 'EnquireAboutInStudioProgramsResult' }
    & Pick<EnquireAboutInStudioProgramsResult, 'isSuccess'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type EnquireAboutOfferingMutationVariables = Exact<{
  input: EnquireAboutOnlineProgramInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type EnquireAboutOfferingMutation = (
  { __typename?: 'Mutation' }
  & { enquireAboutOnlineProgram?: Maybe<(
    { __typename?: 'EnquireAboutOnlineProgramResult' }
    & Pick<EnquireAboutOnlineProgramResult, 'lastEnquiredOn' | 'onlineProgramid'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type EnquireAboutProgramMutationVariables = Exact<{
  input: EnquireAboutProgramInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type EnquireAboutProgramMutation = (
  { __typename?: 'Mutation' }
  & { enquireAboutProgram?: Maybe<(
    { __typename?: 'EnquireAboutProgramResult' }
    & Pick<EnquireAboutProgramResult, 'lastEnquiredOn' | 'programId'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type EnquireAboutProgramInExploreProgramsMutationVariables = Exact<{
  input: EnquireAboutExploreProgramsInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type EnquireAboutProgramInExploreProgramsMutation = (
  { __typename?: 'Mutation' }
  & { enquireAboutProgramInExplorePrograms?: Maybe<(
    { __typename?: 'EnquireAboutExploreProgramsResult' }
    & Pick<EnquireAboutExploreProgramsResult, 'lastEnquiredOn' | 'programId'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type LinkFieldsFragment = (
  { __typename?: 'Link' }
  & Pick<Link, 'id' | 'text'>
  & { url: (
    { __typename?: 'URL' }
    & UrlFieldsFragment
  ) }
);

export type InstructorAvailabilityFieldsFragment = (
  { __typename?: 'InstructorAvailability' }
  & Pick<InstructorAvailability, 'description' | 'title' | 'value'>
);

export type ImageFieldsFragment = (
  { __typename: 'Image' }
  & Pick<Image, 'id' | 'url' | 'alt' | 'height' | 'width'>
);

export type TimePreferencesFragment = (
  { __typename?: 'TimePreference' }
  & Pick<TimePreference, 'morning' | 'afternoon' | 'evening'>
);

export type TravelPreferencesFragment = (
  { __typename?: 'TravelPreference' }
  & Pick<TravelPreference, 'distance' | 'distanceUnit'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & AddressFieldsFragment
  )> }
);

export type ProgramFieldsFragment = (
  { __typename?: 'Program' }
  & Pick<Program, 'code' | 'id' | 'initials' | 'name'>
);

export type PreferenceFieldsFragment = (
  { __typename?: 'InstructorPreferences' }
  & { programs?: Maybe<Array<(
    { __typename?: 'Program' }
    & ProgramFieldsFragment
  )>>, times?: Maybe<Array<(
    { __typename?: 'TimePreference' }
    & TimePreferencesFragment
  )>>, travel?: Maybe<(
    { __typename?: 'TravelPreference' }
    & TravelPreferencesFragment
  )> }
);

export type PrivacyFieldsFragment = (
  { __typename?: 'PrivacySettingsInstructor' }
  & Pick<PrivacySettingsInstructor, 'EMAIL' | 'PHONE_NUMBER' | 'PROFILE_PHOTO' | 'SOCIAL_LINKS' | 'LMQ_LEVEL' | 'HIDDEN'>
);

export type InstructorProfileFieldsFragment = (
  { __typename: 'UserProfileTeaching' }
  & Pick<UserProfileTeaching, 'id' | 'bio' | 'roles' | 'strength' | 'interests' | 'createdAt' | 'availability' | 'createdAtLabel' | 'availableToSubstitute'>
  & { address: (
    { __typename?: 'Address' }
    & AddressFieldsFragment
  ), preferences?: Maybe<(
    { __typename?: 'InstructorPreferences' }
    & PreferenceFieldsFragment
  )>, privacySettings?: Maybe<(
    { __typename?: 'PrivacySettingsInstructor' }
    & PrivacyFieldsFragment
  )>, media?: Maybe<Array<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>>, profileImage?: Maybe<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>, links?: Maybe<Array<(
    { __typename?: 'ProfileLink' }
    & Pick<ProfileLink, 'url' | 'type'>
  )>>, certifications?: Maybe<Array<(
    { __typename?: 'Certification' }
    & Pick<Certification, 'id' | 'lastPurchase' | 'transactionStatus' | 'transactionTimestamp' | 'billedClubId' | 'releaseStatus' | 'status' | 'active' | 'lmqLevel'>
    & { pricePerQuarter?: Maybe<(
      { __typename?: 'CertificationPrice' }
      & Pick<CertificationPrice, 'amount' | 'currency'>
    )>, link: (
      { __typename?: 'Link' }
      & LinkFieldsFragment
    ), program: (
      { __typename?: 'Program' }
      & Pick<Program, 'id' | 'name' | 'code' | 'initials'>
    ) }
  )>>, watchlist?: Maybe<Array<(
    { __typename?: 'InstructorWatchlistItem' }
    & Pick<InstructorWatchlistItem, 'clubId' | 'listingId'>
  )>> }
);

export type ClubProfileFieldsFragment = (
  { __typename: 'UserProfileClub' }
  & Pick<UserProfileClub, 'id' | 'bio' | 'name' | 'email' | 'phone' | 'hiring' | 'strength' | 'createdAt' | 'createdAtLabel' | 'isDigitalOnly'>
  & { address: (
    { __typename?: 'Address' }
    & AddressFieldsFragment
  ), programs?: Maybe<Array<(
    { __typename: 'ClubProgram' }
    & Pick<ClubProgram, 'id' | 'name' | 'code' | 'initials' | 'programType'>
  ) | { __typename?: 'ClubProgramInStudioEmptyState' }>>, media?: Maybe<Array<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>>, profileImage?: Maybe<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>, links?: Maybe<Array<(
    { __typename?: 'ProfileLink' }
    & Pick<ProfileLink, 'url' | 'type'>
  )>> }
);

export type FullUserFieldFragment = (
  { __typename: 'UserResult' }
  & Pick<UserResult, 'id' | 'distance' | 'distanceUnit'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'lastName' | 'firstName' | 'accountId' | 'phone'>
    & { market: (
      { __typename?: 'Market' }
      & Pick<Market, 'isSupported' | 'name' | 'locale' | 'distanceUnit'>
      & { programs?: Maybe<Array<(
        { __typename?: 'Program' }
        & Pick<Program, 'id' | 'name' | 'code' | 'initials'>
      )>> }
    ), signedAgreements?: Maybe<Array<(
      { __typename?: 'SignedAgreement' }
      & Pick<SignedAgreement, 'type' | 'signedOn' | 'version'>
    )>>, userMetaData?: Maybe<(
      { __typename?: 'UserMetadata' }
      & { walkthroughRequired: (
        { __typename?: 'WalkthroughRequired' }
        & Pick<WalkthroughRequired, 'club' | 'instructor'>
      ) }
    )> }
  ), profiles: Array<(
    { __typename?: 'UserProfileTeaching' }
    & InstructorProfileFieldsFragment
  ) | (
    { __typename?: 'UserProfileClub' }
    & ClubProfileFieldsFragment
  )>, settings: (
    { __typename?: 'UserSettings' }
    & Pick<UserSettings, 'locale'>
  ) }
);

export type SingleListingFieldsFragment = (
  { __typename?: 'ClubSingleListing' }
  & Pick<ClubSingleListing, 'createdAt' | 'description' | 'expiringAt' | 'id' | 'searchAppearances' | 'updatedAt' | 'views' | 'isSubstituteListing' | 'status' | 'enquiriesCount'>
  & { clubInfo: (
    { __typename?: 'UserProfileClub' }
    & Pick<UserProfileClub, 'id' | 'name' | 'hiring'>
    & { address: (
      { __typename?: 'Address' }
      & AddressFieldsFragment
    ), profileImage?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )>, programs?: Maybe<Array<(
      { __typename?: 'ClubProgram' }
      & Pick<ClubProgram, 'name'>
    ) | { __typename?: 'ClubProgramInStudioEmptyState' }>> }
  ), program: (
    { __typename?: 'ClubProgram' }
    & Pick<ClubProgram, 'code' | 'id' | 'initials' | 'name'>
  ), times: Array<(
    { __typename?: 'TimePreference' }
    & TimePreferencesFragment
  )> }
);

export type MultiListingFieldsFragment = (
  { __typename?: 'ClubMultiListing' }
  & Pick<ClubMultiListing, 'createdAt' | 'description' | 'expiringAt' | 'id' | 'searchAppearances' | 'status' | 'updatedAt' | 'views' | 'enquiriesCount'>
  & { clubInfo: (
    { __typename?: 'UserProfileClub' }
    & Pick<UserProfileClub, 'id' | 'name'>
    & { address: (
      { __typename?: 'Address' }
      & AddressFieldsFragment
    ), profileImage?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )>, programs?: Maybe<Array<(
      { __typename?: 'ClubProgram' }
      & Pick<ClubProgram, 'name'>
    ) | { __typename?: 'ClubProgramInStudioEmptyState' }>> }
  ), programs: Array<(
    { __typename?: 'ClubProgram' }
    & Pick<ClubProgram, 'code' | 'id' | 'initials' | 'name'>
  )> }
);

export type SavedInstructorSearchFragment = (
  { __typename?: 'SavedInstructorSearch' }
  & Pick<SavedInstructorSearch, 'sort'>
  & { filter?: Maybe<(
    { __typename?: 'SavedSearchClubListingsFilter' }
    & Pick<SavedSearchClubListingsFilter, 'name' | 'programs' | 'substituteListingFilter'>
    & { location?: Maybe<(
      { __typename?: 'LocationFilter' }
      & { within?: Maybe<(
        { __typename?: 'LocationWithin' }
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & AddressFieldsFragment
        )>, area: (
          { __typename?: 'GeoPolygon' }
          & { points: Array<(
            { __typename?: 'GeoPoint' }
            & Pick<GeoPoint, 'lat' | 'lon'>
          )> }
        ) }
      )>, near?: Maybe<(
        { __typename?: 'LocationNear' }
        & Pick<LocationNear, 'distance' | 'distanceUnit'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & AddressFieldsFragment
        )> }
      )>, point?: Maybe<(
        { __typename?: 'GeoPoint' }
        & Pick<GeoPoint, 'lat' | 'lon'>
      )> }
    )> }
  )> }
);

export type AddressFieldsFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'city' | 'state' | 'street' | 'country' | 'stateCode' | 'postalCode' | 'streetName' | 'countryCode' | 'streetNumber'>
  & { location?: Maybe<(
    { __typename?: 'GeoPoint' }
    & Pick<GeoPoint, 'lat' | 'lon'>
  )> }
);

export type ErrorResultFieldsFragment = (
  { __typename: 'ErrorResult' }
  & Pick<ErrorResult, 'type' | 'message' | 'requestId' | 'logGroup' | 'logStream'>
);

export type ClubFieldsFragment = (
  { __typename?: 'Club' }
  & Pick<Club, 'accountId' | 'createdAt' | 'currency' | 'id' | 'name' | 'parent' | 'phone'>
  & { email?: Maybe<(
    { __typename?: 'EmailClubType' }
    & Pick<EmailClubType, 'affiliations' | 'jobListings' | 'main'>
  )>, market: (
    { __typename?: 'Market' }
    & Pick<Market, 'name' | 'locale' | 'distanceUnit'>
    & { programs?: Maybe<Array<(
      { __typename?: 'Program' }
      & Pick<Program, 'id' | 'name' | 'code' | 'initials'>
    )>> }
  ), profile: (
    { __typename?: 'UserProfileClub' }
    & ClubProfileFieldsFragment
  ) }
);

export type AffiliationFieldsFragment = (
  { __typename?: 'Affiliation' }
  & Pick<Affiliation, 'actionedDate' | 'clubId' | 'id' | 'latestActionInitiator' | 'pendingStatus' | 'requestedBy' | 'requestedDate' | 'role' | 'status' | 'userId'>
);

export type ManagePeopleUserAffiliateFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'phone' | 'email' | 'accountId' | 'lastName' | 'firstName'>
  & { market: (
    { __typename?: 'Market' }
    & Pick<Market, 'name' | 'locale' | 'distanceUnit'>
  ) }
);

export type GetAgreementDocumentQueryVariables = Exact<{
  input: GetSignedAgreementInput;
  _cacheKey?: Maybe<Scalars['String']>;
  versionOnly?: Maybe<Scalars['Boolean']>;
}>;


export type GetAgreementDocumentQuery = (
  { __typename?: 'Query' }
  & { getAgreementDocument: (
    { __typename?: 'AgreementDocumentResult' }
    & MakeOptional<Pick<AgreementDocumentResult, 'version' | 'content'>, 'content'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type ClubWithAffiliationFragment = (
  { __typename?: 'ClubWithAffiliation' }
  & Pick<ClubWithAffiliation, 'clubId' | 'clubName' | 'isDigitalOnly'>
  & { latestAffiliation?: Maybe<(
    { __typename?: 'Affiliation' }
    & AffiliationFieldsFragment
  )> }
);

export type GetChainAffiliationQueryVariables = Exact<{
  input: GetChainAffiliationsInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetChainAffiliationQuery = (
  { __typename?: 'Query' }
  & { getChainAffiliations: (
    { __typename?: 'GetChainAffiliationsResult' }
    & { clubs?: Maybe<Array<(
      { __typename?: 'ClubWithAffiliation' }
      & ClubWithAffiliationFragment
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & Pick<ErrorResult, 'type' | 'message'>
  ) }
);

export type ManagePeopleClubAffiliatesFieldsFragment = (
  { __typename?: 'ClubAffiliates' }
  & Pick<ClubAffiliates, 'actionedDate' | 'clubId' | 'distance' | 'distanceUnit' | 'id' | 'pendingStatus' | 'requestedBy' | 'requestedDate' | 'status'>
  & { profile: (
    { __typename?: 'UserProfileClub' }
    & ClubProfileFieldsFragment
  ) }
);

export type GetChainAffiliatedInstructorQueryVariables = Exact<{
  input?: Maybe<GetChainInstructorAffiliatesInput>;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetChainAffiliatedInstructorQuery = (
  { __typename?: 'Query' }
  & { getChainInstructorAffiliates: (
    { __typename: 'GetChainInstructorAffiliatesResult' }
    & Pick<GetChainInstructorAffiliatesResult, 'hasPendingAffiliations'>
    & { affiliates?: Maybe<Array<(
      { __typename?: 'InstructorChainAffiliate' }
      & Pick<InstructorChainAffiliate, 'purchaseOrder'>
      & { clubAffiliates?: Maybe<Array<(
        { __typename?: 'ClubAffiliates' }
        & ManagePeopleClubAffiliatesFieldsFragment
      )>>, profile: (
        { __typename?: 'UserProfileTeaching' }
        & InstructorProfileFieldsFragment
      ), user: (
        { __typename?: 'User' }
        & ManagePeopleUserAffiliateFieldsFragment
      ) }
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type GetChainInstructorRequestsQueryVariables = Exact<{
  input: GetChainInstructorAffiliatesInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetChainInstructorRequestsQuery = (
  { __typename?: 'Query' }
  & { getChainInstructorAffiliates: (
    { __typename: 'GetChainInstructorAffiliatesResult' }
    & Pick<GetChainInstructorAffiliatesResult, 'hasPendingAffiliations'>
    & { requests?: Maybe<Array<Maybe<(
      { __typename?: 'InstructorChainRequest' }
      & Pick<InstructorChainRequest, 'clubId' | 'clubName' | 'distance' | 'distanceUnit' | 'id' | 'pendingStatus' | 'requestedBy' | 'requestedDate' | 'status'>
      & { profile: (
        { __typename?: 'UserProfileTeaching' }
        & InstructorProfileFieldsFragment
      ), user: (
        { __typename?: 'User' }
        & ManagePeopleUserAffiliateFieldsFragment
      ) }
    )>>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type GetChainUserQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetChainUserQuery = (
  { __typename?: 'Query' }
  & { getChainUser?: Maybe<(
    { __typename?: 'GetChainUser' }
    & Pick<GetChainUser, 'isChainUser'>
    & { chainLocations?: Maybe<Array<(
      { __typename?: 'UserProfileClub' }
      & ClubProfileFieldsFragment
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & Pick<ErrorResult, 'type' | 'message'>
  )> }
);

export type GetChainUserClubListingsQueryVariables = Exact<{
  input: GetChainUserClubListingsInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetChainUserClubListingsQuery = (
  { __typename?: 'Query' }
  & { getChainUserClubListings?: Maybe<(
    { __typename?: 'GetChainUserClubListingsResult' }
    & { singleListings?: Maybe<Array<(
      { __typename?: 'ClubSingleListing' }
      & SingleListingFieldsFragment
    )>>, multiListings?: Maybe<Array<(
      { __typename?: 'ClubMultiListing' }
      & MultiListingFieldsFragment
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type BusinessSupportSectionContentFragment = (
  { __typename?: 'BusinessSupportSection' }
  & Pick<BusinessSupportSection, 'id' | 'sectionTitle'>
  & { sectionTiles?: Maybe<Array<(
    { __typename?: 'BusinessSupportTile' }
    & Pick<BusinessSupportTile, 'id' | 'title' | 'subtitle'>
    & { image: (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'alt' | 'url'>
    ), url: (
      { __typename?: 'URL' }
      & Pick<Url, 'type' | 'value'>
    ) }
  )>> }
);

export type GetClubBusinessSupportContentQueryVariables = Exact<{
  input: GetClubByIdInput;
}>;


export type GetClubBusinessSupportContentQuery = (
  { __typename?: 'Query' }
  & { getClubById?: Maybe<(
    { __typename?: 'UserProfileClub' }
    & { businessSupport?: Maybe<Array<(
      { __typename?: 'BusinessSupportSection' }
      & BusinessSupportSectionContentFragment
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & Pick<ErrorResult, 'type' | 'message'>
  )> }
);

export type GetClubInstructorAffiliatesQueryVariables = Exact<{
  input: GetClubInstructorAffiliatesInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetClubInstructorAffiliatesQuery = (
  { __typename?: 'Query' }
  & { getClubInstructorAffiliates?: Maybe<(
    { __typename: 'GetClubInstructorAffiliatesResult' }
    & { affiliates?: Maybe<Array<(
      { __typename?: 'InstructorAffiliate' }
      & Pick<InstructorAffiliate, 'purchaseOrder' | 'actionedDate' | 'id' | 'requestedBy' | 'requestedDate' | 'status' | 'clubId'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'phone' | 'email' | 'accountId' | 'lastName' | 'firstName'>
        & { market: (
          { __typename?: 'Market' }
          & Pick<Market, 'name' | 'locale' | 'distanceUnit'>
        ) }
      ), profile: (
        { __typename?: 'UserProfileTeaching' }
        & InstructorProfileFieldsFragment
      ) }
    )>>, requests?: Maybe<Array<(
      { __typename?: 'InstructorRequest' }
      & Pick<InstructorRequest, 'id' | 'distance' | 'distanceUnit' | 'requestedDate' | 'requestedBy' | 'status' | 'pendingStatus' | 'clubId'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'phone' | 'email' | 'accountId' | 'lastName' | 'firstName'>
        & { market: (
          { __typename?: 'Market' }
          & Pick<Market, 'name' | 'locale' | 'distanceUnit'>
        ) }
      ), profile: (
        { __typename?: 'UserProfileTeaching' }
        & InstructorProfileFieldsFragment
      ) }
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetClubListingByIdQueryVariables = Exact<{
  listingId: Scalars['String'];
}>;


export type GetClubListingByIdQuery = (
  { __typename?: 'Query' }
  & { getClubListingById: (
    { __typename?: 'SearchClubJobListingsSingleResult' }
    & Pick<SearchClubJobListingsSingleResult, 'distance' | 'distanceUnit' | 'messageSentOn' | 'enquiredOn' | 'onWatchlist' | 'hasBeenReported'>
    & { listing: (
      { __typename?: 'ClubSingleListing' }
      & SingleListingFieldsFragment
    ) | (
      { __typename?: 'ClubMultiListing' }
      & MultiListingFieldsFragment
    ) }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type GetClubListingsByClubIdQueryVariables = Exact<{
  input: GetClubListingsByClubIdInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetClubListingsByClubIdQuery = (
  { __typename?: 'Query' }
  & { getClubListingsByClubId?: Maybe<(
    { __typename?: 'GetClubListingsByClubIdResult' }
    & { results: Array<(
      { __typename?: 'ClubSingleListing' }
      & SingleListingFieldsFragment
    ) | (
      { __typename?: 'ClubMultiListing' }
      & MultiListingFieldsFragment
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetClubSettingsQueryVariables = Exact<{
  input: GetClubSettingsInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetClubSettingsQuery = (
  { __typename?: 'Query' }
  & { getClubSettings: (
    { __typename?: 'GetClubSettingsResult' }
    & Pick<GetClubSettingsResult, 'id' | 'name' | 'phone'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'state' | 'street' | 'country' | 'stateCode' | 'postalCode' | 'countryCode'>
      & { location?: Maybe<(
        { __typename?: 'GeoPoint' }
        & Pick<GeoPoint, 'lat' | 'lon'>
      )> }
    )>, email?: Maybe<(
      { __typename?: 'EmailClubType' }
      & Pick<EmailClubType, 'main' | 'affiliations' | 'jobListings'>
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type GetContactUsContentQueryVariables = Exact<{
  input: GetContactUsContentInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetContactUsContentQuery = (
  { __typename?: 'Query' }
  & { getContactUsContent: (
    { __typename?: 'GetContactUsContentResult' }
    & { result: Array<(
      { __typename?: 'SectionContentResult' }
      & Pick<SectionContentResult, 'section'>
      & { content: Array<(
        { __typename?: 'ContentField' }
        & Pick<ContentField, 'title' | 'subHeading' | 'contentText'>
      )> }
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type GetCurrentUserQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { getCurrentUser?: Maybe<(
    { __typename?: 'UserResult' }
    & FullUserFieldFragment
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetCurrentUserPhotoUploadDataQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetCurrentUserPhotoUploadDataQuery = (
  { __typename?: 'Query' }
  & { getCurrentUserPhotoUploadData?: Maybe<(
    { __typename: 'GetCurrentUserPhotoUploadDataResult' }
    & Pick<GetCurrentUserPhotoUploadDataResult, 'url' | 'token'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetCurrentUserPortfolioUploadDataQueryVariables = Exact<{
  input: GetCurrentUserPortfolioUploadDataInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetCurrentUserPortfolioUploadDataQuery = (
  { __typename?: 'Query' }
  & { getCurrentUserPortfolioUploadData: (
    { __typename?: 'GetCurrentUserPortfolioUploadDataResult' }
    & { items: Array<(
      { __typename?: 'PortfolioUploadItem' }
      & Pick<PortfolioUploadItem, 'url' | 'token'>
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type ClubDashboardBusinessSupportContentFragment = (
  { __typename?: 'ClubPartnershipBusinessSupportContent' }
  & Pick<ClubPartnershipBusinessSupportContent, 'title' | 'description'>
  & { link: (
    { __typename?: 'Link' }
    & Pick<Link, 'text'>
    & { url: (
      { __typename?: 'URL' }
      & Pick<Url, 'type' | 'value'>
    ) }
  ) }
);

export type ClubDashboardStandardContentFragment = (
  { __typename?: 'ClubPartnershipStandardContent' }
  & Pick<ClubPartnershipStandardContent, 'title' | 'subtitle'>
  & { link: (
    { __typename?: 'Link' }
    & Pick<Link, 'text'>
    & { url: (
      { __typename?: 'URL' }
      & Pick<Url, 'type' | 'value'>
    ) }
  ), image: (
    { __typename?: 'ImageSet' }
    & { mobile: (
      { __typename?: 'Image' }
      & Pick<Image, 'alt' | 'url'>
    ), desktop: (
      { __typename?: 'Image' }
      & Pick<Image, 'alt' | 'url'>
    ) }
  ) }
);

export type ClubDashboardProgramInfoFragment = (
  { __typename?: 'ClubPartnershipProgramStat' }
  & Pick<ClubPartnershipProgramStat, 'total' | 'type'>
  & { icon: (
    { __typename?: 'Image' }
    & Pick<Image, 'alt' | 'url'>
  ) }
);

export type ClubDashboardProgramStatsFragment = (
  { __typename?: 'ClubPartnershipProgramStats' }
  & { programsStats?: Maybe<Array<(
    { __typename?: 'ClubPartnershipProgramStat' }
    & ClubDashboardProgramInfoFragment
  )>> }
);

export type GetDashboardQueryVariables = Exact<{
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetDashboardQuery = (
  { __typename: 'Query' }
  & { getDashboard?: Maybe<(
    { __typename: 'DashboardPageInstructorResponse' }
    & Pick<DashboardPageInstructorResponse, 'id'>
    & { translation: (
      { __typename?: 'DashboardPageTranslation' }
      & Pick<DashboardPageTranslation, 'title'>
    ), socialMedia: (
      { __typename?: 'SocialMediaSection' }
      & Pick<SocialMediaSection, 'title'>
      & { items?: Maybe<Array<(
        { __typename?: 'SocialMediaPost' }
        & Pick<SocialMediaPost, 'id' | 'url' | 'type' | 'createdAt' | 'createdAtLabel'>
        & { content: (
          { __typename?: 'SocialMediaContent' }
          & Pick<SocialMediaContent, 'type' | 'value'>
        ) }
      )>> }
    ), links?: Maybe<Array<(
      { __typename?: 'LinkTile' }
      & Pick<LinkTile, 'id' | 'title' | 'subtitle'>
      & { imageSet: (
        { __typename?: 'ImageSet' }
        & Pick<ImageSet, 'id'>
        & { desktop: (
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'alt' | 'url'>
        ), mobile: (
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'alt' | 'url'>
        ) }
      ), url: (
        { __typename?: 'URL' }
        & Pick<Url, 'type' | 'value'>
      ) }
    )>>, certifications?: Maybe<Array<(
      { __typename?: 'Certification' }
      & Pick<Certification, 'id' | 'status' | 'active' | 'lmqLevel'>
      & { link: (
        { __typename?: 'Link' }
        & Pick<Link, 'id' | 'text'>
        & { url: (
          { __typename?: 'URL' }
          & Pick<Url, 'type' | 'value'>
        ) }
      ), steps?: Maybe<Array<(
        { __typename?: 'CertificationStep' }
        & Pick<CertificationStep, 'id' | 'label' | 'status'>
        & { action?: Maybe<(
          { __typename?: 'Link' }
          & Pick<Link, 'id' | 'text'>
          & { url: (
            { __typename?: 'URL' }
            & Pick<Url, 'value' | 'type'>
          ) }
        )> }
      )>>, program: (
        { __typename?: 'Program' }
        & Pick<Program, 'id' | 'name' | 'code' | 'initials'>
      ) }
    )>>, promotions?: Maybe<Array<(
      { __typename?: 'Promotion' }
      & Pick<Promotion, 'id' | 'title' | 'description'>
      & { image: (
        { __typename?: 'ImageSet' }
        & Pick<ImageSet, 'id'>
        & { desktop: (
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'alt' | 'url' | 'width' | 'height'>
        ), mobile: (
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'alt' | 'url' | 'width' | 'height'>
        ) }
      ), link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'id' | 'text'>
        & { url: (
          { __typename?: 'URL' }
          & Pick<Url, 'value' | 'type'>
        ) }
      )> }
    )>> }
  ) | (
    { __typename: 'DashboardPageClubResponse' }
    & Pick<DashboardPageClubResponse, 'id'>
    & { links?: Maybe<Array<(
      { __typename?: 'LinkTile' }
      & Pick<LinkTile, 'id' | 'title' | 'subtitle'>
      & { imageSet: (
        { __typename?: 'ImageSet' }
        & Pick<ImageSet, 'id'>
        & { desktop: (
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'alt' | 'url'>
        ), mobile: (
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'alt' | 'url'>
        ) }
      ), url: (
        { __typename?: 'URL' }
        & Pick<Url, 'type' | 'value'>
      ) }
    )>>, promotions?: Maybe<Array<(
      { __typename?: 'Promotion' }
      & Pick<Promotion, 'id' | 'title' | 'description'>
      & { image: (
        { __typename?: 'ImageSet' }
        & Pick<ImageSet, 'id'>
        & { desktop: (
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'alt' | 'url' | 'width' | 'height'>
        ), mobile: (
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'alt' | 'url' | 'width' | 'height'>
        ) }
      ), link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'id' | 'text'>
        & { url: (
          { __typename?: 'URL' }
          & Pick<Url, 'value' | 'type'>
        ) }
      )> }
    )>>, partnership?: Maybe<Array<(
      { __typename?: 'ClubPartnershipProgramStats' }
      & ClubDashboardProgramStatsFragment
    ) | (
      { __typename?: 'ClubPartnershipStandardContent' }
      & ClubDashboardStandardContentFragment
    ) | (
      { __typename?: 'ClubPartnershipBusinessSupportContent' }
      & ClubDashboardBusinessSupportContentFragment
      & ClubDashboardBusinessSupportContentFragment
    )>>, myTools?: Maybe<Array<(
      { __typename?: 'DashboardMyTools' }
      & Pick<DashboardMyTools, 'id' | 'title' | 'subtitle'>
      & { imageSet: (
        { __typename?: 'ImageSet' }
        & Pick<ImageSet, 'id'>
        & { desktop: (
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'alt' | 'url'>
        ), mobile: (
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'alt' | 'url'>
        ) }
      ), url: (
        { __typename?: 'URL' }
        & Pick<Url, 'type' | 'value'>
      ) }
    )>> }
  ) | (
    { __typename: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetEqualOpportunitiesPolicyQueryVariables = Exact<{
  input: GetEqualOpportunitiesPolicyInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetEqualOpportunitiesPolicyQuery = (
  { __typename?: 'Query' }
  & { getEqualOpportunitiesPolicy: (
    { __typename?: 'GetEqualOpportunitiesPolicyResult' }
    & { result: Array<(
      { __typename?: 'SectionContentResult' }
      & Pick<SectionContentResult, 'section'>
      & { content: Array<(
        { __typename?: 'ContentField' }
        & Pick<ContentField, 'title' | 'subHeading' | 'contentText'>
      )> }
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type GetFrequentlyAskedQuestionsQueryVariables = Exact<{
  input: GetFrequentlyAskedQuestionsInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetFrequentlyAskedQuestionsQuery = (
  { __typename?: 'Query' }
  & { getFrequentlyAskedQuestions: (
    { __typename?: 'GetFrequentlyAskedQuestionsResult' }
    & { result: Array<(
      { __typename?: 'SectionContentResult' }
      & Pick<SectionContentResult, 'section'>
      & { content: Array<(
        { __typename?: 'ContentField' }
        & Pick<ContentField, 'title' | 'subHeading' | 'contentText'>
      )> }
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type GetHeaderContentQueryVariables = Exact<{
  input: GetHeaderInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetHeaderContentQuery = (
  { __typename?: 'Query' }
  & { getHeaders?: Maybe<(
    { __typename: 'GetHeaderResult' }
    & { results: Array<(
      { __typename?: 'Header' }
      & Pick<Header, 'key' | 'title' | 'subHeading'>
      & { image?: Maybe<(
        { __typename?: 'ImageSet' }
        & Pick<ImageSet, 'id'>
        & { desktop: (
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'alt' | 'url'>
        ), mobile: (
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'alt' | 'url'>
        ) }
      )> }
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type InStudioManageOptionsFragment = (
  { __typename?: 'GetPickListResult' }
  & { results: Array<(
    { __typename?: 'PickList' }
    & Pick<PickList, 'items' | 'key'>
  )> }
);

export type GetInStudioManageOptionsQueryVariables = Exact<{
  input: GetPickListInput;
}>;


export type GetInStudioManageOptionsQuery = (
  { __typename?: 'Query' }
  & { getPickLists?: Maybe<(
    { __typename: 'GetPickListResult' }
    & InStudioManageOptionsFragment
  ) | (
    { __typename: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetUserAccountQueryVariables = Exact<{
  input: GetUserByIdInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetUserAccountQuery = (
  { __typename: 'Query' }
  & { getUserAccount: (
    { __typename: 'UserAccountSettingsResult' }
    & Pick<UserAccountSettingsResult, 'id' | 'email' | 'firstName' | 'lastName' | 'phone'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & AddressFieldsFragment
    )>, outlinks?: Maybe<Array<(
      { __typename?: 'OutLink' }
      & Pick<OutLink, 'url' | 'title'>
      & { _metadata?: Maybe<(
        { __typename?: 'OutlinkMetedata' }
        & Pick<OutlinkMetedata, 'urlSource'>
      )> }
    )>> }
  ) | (
    { __typename: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type GetInstructorEnquiriesQueryVariables = Exact<{
  role: UserRoleInput;
  filter: UserEnquiriesFilter;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetInstructorEnquiriesQuery = (
  { __typename?: 'Query' }
  & { getUserEnquiries?: Maybe<(
    { __typename?: 'GetUserEnquiriesResult' }
    & { enquiries?: Maybe<Array<(
      { __typename?: 'UserEnquiry' }
      & { listing: (
        { __typename?: 'EnquiryClubListingSingleResult' }
        & Pick<EnquiryClubListingSingleResult, 'distance' | 'distanceUnit' | 'messageSentOn' | 'enquiredOn' | 'onWatchlist' | 'hasBeenReported'>
        & { listing: (
          { __typename: 'ClubMultiListing' }
          & { clubInfo: (
            { __typename?: 'UserProfileClub' }
            & Pick<UserProfileClub, 'id' | 'name' | 'hiring'>
            & { address: (
              { __typename?: 'Address' }
              & AddressFieldsFragment
            ), profileImage?: Maybe<(
              { __typename?: 'Image' }
              & Pick<Image, 'url'>
            )>, programs?: Maybe<Array<(
              { __typename?: 'ClubProgram' }
              & Pick<ClubProgram, 'name'>
            ) | { __typename?: 'ClubProgramInStudioEmptyState' }>> }
          ) }
          & MultiListingFieldsFragment
        ) | (
          { __typename: 'ClubSingleListing' }
          & { clubInfo: (
            { __typename?: 'UserProfileClub' }
            & Pick<UserProfileClub, 'id' | 'name' | 'hiring'>
            & { address: (
              { __typename?: 'Address' }
              & AddressFieldsFragment
            ), profileImage?: Maybe<(
              { __typename?: 'Image' }
              & Pick<Image, 'url'>
            )>, programs?: Maybe<Array<(
              { __typename?: 'ClubProgram' }
              & Pick<ClubProgram, 'name'>
            ) | { __typename?: 'ClubProgramInStudioEmptyState' }>> }
          ) }
          & SingleListingFieldsFragment
        ) | (
          { __typename: 'ClubListingUnknown' }
          & Pick<ClubListingUnknown, 'id'>
          & { clubInfo: (
            { __typename?: 'UserProfileClub' }
            & Pick<UserProfileClub, 'id' | 'name' | 'hiring'>
            & { address: (
              { __typename?: 'Address' }
              & AddressFieldsFragment
            ), profileImage?: Maybe<(
              { __typename?: 'Image' }
              & Pick<Image, 'url'>
            )>, programs?: Maybe<Array<(
              { __typename?: 'ClubProgram' }
              & Pick<ClubProgram, 'name'>
            ) | { __typename?: 'ClubProgramInStudioEmptyState' }>> }
          ) }
        ) }
      ), message: (
        { __typename?: 'Message' }
        & Pick<Message, 'clubId' | 'clubName' | 'id' | 'listingId' | 'message' | 'profileId' | 'readAt' | 'requestedBy' | 'sentAt'>
      ) }
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetInstructorFacilitiesQueryVariables = Exact<{
  input: GetInstructorClubAffiliatesInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetInstructorFacilitiesQuery = (
  { __typename?: 'Query' }
  & { getInstructorClubAffiliates: (
    { __typename: 'GetInstructorClubAffiliatesResult' }
    & { affiliations?: Maybe<Array<(
      { __typename?: 'ClubAffiliates' }
      & Pick<ClubAffiliates, 'actionedDate' | 'clubId' | 'distance' | 'distanceUnit' | 'id' | 'requestedDate' | 'requestedBy' | 'status' | 'pendingStatus' | 'isDigitalOnly'>
      & { profile: (
        { __typename?: 'UserProfileClub' }
        & ClubProfileFieldsFragment
      ) }
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type GetInstructorPortfolioQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetInstructorPortfolioQuery = (
  { __typename?: 'Query' }
  & { getCurrentUser?: Maybe<(
    { __typename: 'UserResult' }
    & { profiles: Array<(
      { __typename?: 'UserProfileTeaching' }
      & { media?: Maybe<Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'url'>
      )>> }
    ) | (
      { __typename?: 'UserProfileClub' }
      & { media?: Maybe<Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'url'>
      )>> }
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetInstructorWatchlistQueryVariables = Exact<{
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetInstructorWatchlistQuery = (
  { __typename?: 'Query' }
  & { getInstructorWatchlist?: Maybe<(
    { __typename?: 'SearchClubListingsResult' }
    & Pick<SearchClubListingsResult, 'total'>
    & { results?: Maybe<Array<(
      { __typename?: 'SearchClubJobListingsSingleResult' }
      & Pick<SearchClubJobListingsSingleResult, 'distance' | 'distanceUnit' | 'messageSentOn' | 'enquiredOn' | 'onWatchlist' | 'hasBeenReported'>
      & { listing: (
        { __typename?: 'ClubSingleListing' }
        & SingleListingFieldsFragment
      ) | (
        { __typename?: 'ClubMultiListing' }
        & MultiListingFieldsFragment
      ) }
    ) | (
      { __typename?: 'SearchClubProfileSingleResult' }
      & Pick<SearchClubProfileSingleResult, 'distance' | 'distanceUnit' | 'onWatchlist' | 'hasBeenReported'>
      & { profile: (
        { __typename?: 'UserProfileClub' }
        & ClubProfileFieldsFragment
      ) }
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type LocationsFragment = (
  { __typename?: 'ClubAvailabilityOnListing' }
  & Pick<ClubAvailabilityOnListing, 'clubId' | 'clubName' | 'isAlreadyListed' | 'isClubLicensed'>
  & { clubProfileImage?: Maybe<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>, clubAddress?: Maybe<(
    { __typename?: 'Address' }
    & AddressFieldsFragment
  )> }
);

export type GetJobListingAvailableLocationsQueryVariables = Exact<{
  input: GetClubsAvailabilityOnListingInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetJobListingAvailableLocationsQuery = (
  { __typename?: 'Query' }
  & { getClubsAvailabilityOnListing?: Maybe<(
    { __typename?: 'GetClubsAvailabilityOnListingResult' }
    & { clubsWithExistingListing?: Maybe<Array<(
      { __typename?: 'ClubAvailabilityOnListing' }
      & LocationsFragment
    )>>, allClubs?: Maybe<Array<(
      { __typename?: 'ClubAvailabilityOnListing' }
      & LocationsFragment
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & Pick<ErrorResult, 'type' | 'message'>
  )> }
);

export type GetNavigationQueryVariables = Exact<{
  input: GetNavigationInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetNavigationQuery = (
  { __typename?: 'Query' }
  & { getNavigation?: Maybe<(
    { __typename: 'Navigation' }
    & { appMenu: (
      { __typename?: 'Menu' }
      & Pick<Menu, 'id' | 'name'>
      & { items: Array<(
        { __typename?: 'RootMenuItem' }
        & Pick<RootMenuItem, 'icon' | 'id' | 'label' | 'analyticsEvent'>
        & { url?: Maybe<(
          { __typename?: 'URL' }
          & Pick<Url, 'type' | 'value'>
        )>, items?: Maybe<Array<(
          { __typename?: 'MenuItem' }
          & Pick<MenuItem, 'id' | 'label' | 'analyticsEvent'>
          & { url: (
            { __typename?: 'URL' }
            & Pick<Url, 'type' | 'value'>
          ) }
        )>> }
      )> }
    ), userMenu: (
      { __typename?: 'RootMenuItem' }
      & Pick<RootMenuItem, 'icon' | 'id' | 'label' | 'analyticsEvent'>
      & { url?: Maybe<(
        { __typename?: 'URL' }
        & Pick<Url, 'type' | 'value'>
      )>, items?: Maybe<Array<(
        { __typename?: 'MenuItem' }
        & Pick<MenuItem, 'id' | 'label' | 'analyticsEvent'>
        & { url: (
          { __typename?: 'URL' }
          & Pick<Url, 'type' | 'value'>
        ) }
      )>> }
    ) }
  ) | (
    { __typename: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type NotificationItemFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, 'id' | 'type' | 'body' | 'title' | 'createdAt' | 'createdAtLabel' | 'readAt'>
  & { link?: Maybe<(
    { __typename?: 'Link' }
    & Pick<Link, 'id' | 'text'>
    & { url: (
      { __typename?: 'URL' }
      & Pick<Url, 'type' | 'value'>
    ) }
  )> }
);

export type NotificationsResultFragment = (
  { __typename?: 'GetNotificationsResult' }
  & Pick<GetNotificationsResult, 'totalCount'>
  & { filter: (
    { __typename?: 'DateTimeFilter' }
    & Pick<DateTimeFilter, 'type' | 'value' | 'limit'>
  ), items?: Maybe<Array<(
    { __typename?: 'Notification' }
    & NotificationItemFragment
  )>> }
);

export type GetNotificationsQueryVariables = Exact<{
  filter: DateTimeFilterInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetNotificationsQuery = (
  { __typename?: 'Query' }
  & { getNotifications?: Maybe<(
    { __typename?: 'GetNotificationsResult' }
    & NotificationsResultFragment
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetProfileQueryVariables = Exact<{
  input: GetProfileByIdInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetProfileQuery = (
  { __typename?: 'Query' }
  & { getProfileById?: Maybe<(
    { __typename?: 'GetProfileByIdResult' }
    & Pick<GetProfileByIdResult, 'hasBeenReported' | 'withinDistance' | 'lastMessageIn48HoursSentAt' | 'hasRecentlyDeclinedAffiliation' | 'hasExistingAffiliation' | 'onWatchlist'>
    & { profile?: Maybe<(
      { __typename?: 'UserProfileTeaching' }
      & InstructorProfileFieldsFragment
    ) | (
      { __typename?: 'UserProfileClub' }
      & ClubProfileFieldsFragment
    )>, instructorAffiliation?: Maybe<(
      { __typename?: 'Affiliation' }
      & Pick<Affiliation, 'id' | 'clubId' | 'pendingStatus' | 'actionedDate' | 'requestedDate' | 'requestedBy' | 'role' | 'userId' | 'status'>
    )>, profileUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'lastName' | 'firstName' | 'accountId' | 'phone'>
      & { market: (
        { __typename?: 'Market' }
        & Pick<Market, 'name' | 'locale' | 'distanceUnit'>
        & { programs?: Maybe<Array<(
          { __typename?: 'Program' }
          & Pick<Program, 'id' | 'name' | 'code' | 'initials'>
        )>> }
      ) }
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetProfileMediaQueryVariables = Exact<{
  input: GetProfileByIdInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetProfileMediaQuery = (
  { __typename?: 'Query' }
  & { getProfileById?: Maybe<(
    { __typename?: 'GetProfileByIdResult' }
    & { profile?: Maybe<(
      { __typename?: 'UserProfileTeaching' }
      & { media?: Maybe<Array<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )>> }
    ) | (
      { __typename?: 'UserProfileClub' }
      & { media?: Maybe<Array<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )>> }
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetProfilesQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetProfilesQuery = (
  { __typename?: 'Query' }
  & { getCurrentUser?: Maybe<(
    { __typename: 'UserResult' }
    & Pick<UserResult, 'id'>
    & { profiles: Array<(
      { __typename?: 'UserProfileTeaching' }
      & InstructorProfileFieldsFragment
    ) | (
      { __typename?: 'UserProfileClub' }
      & ClubProfileFieldsFragment
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetSavedInstructorSearchDataQueryVariables = Exact<{
  input: GetSavedInstructorSearchDataInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetSavedInstructorSearchDataQuery = (
  { __typename?: 'Query' }
  & { getSavedInstructorSearchData?: Maybe<(
    { __typename?: 'GetSavedInstructorSearchDataResult' }
    & { results: Array<(
      { __typename?: 'SavedInstructorSearch' }
      & SavedInstructorSearchFragment
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetSharedNavigationQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetSharedNavigationQuery = (
  { __typename?: 'Query' }
  & { getSharedNavigation?: Maybe<(
    { __typename?: 'SharedNavigation' }
    & { instructorNav?: Maybe<(
      { __typename?: 'SharedNavItem' }
      & Pick<SharedNavItem, 'role'>
      & { navItems?: Maybe<Array<Maybe<(
        { __typename?: 'NavItem' }
        & Pick<NavItem, 'key' | 'title' | 'description'>
        & { url: (
          { __typename?: 'URL' }
          & Pick<Url, 'type' | 'value'>
        ), image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'url' | 'alt' | 'height' | 'width'>
        )> }
      )>>> }
    )>, clubNav?: Maybe<(
      { __typename?: 'SharedNavItem' }
      & Pick<SharedNavItem, 'role'>
      & { navItems?: Maybe<Array<Maybe<(
        { __typename?: 'NavItem' }
        & Pick<NavItem, 'key' | 'title' | 'description'>
        & { url: (
          { __typename?: 'URL' }
          & Pick<Url, 'type' | 'value'>
        ), image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'url' | 'alt' | 'height' | 'width'>
        )> }
      )>>> }
    )>, websiteLink: (
      { __typename?: 'URL' }
      & Pick<Url, 'type' | 'value'>
    ) }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetClubInstructorShortlistQueryVariables = Exact<{
  input: GetProfileByIdInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetClubInstructorShortlistQuery = (
  { __typename?: 'Query' }
  & { getProfileById?: Maybe<(
    { __typename?: 'GetProfileByIdResult' }
    & { profile?: Maybe<{ __typename?: 'UserProfileTeaching' } | (
      { __typename?: 'UserProfileClub' }
      & { shortlist?: Maybe<Array<(
        { __typename?: 'InstructorShortlist' }
        & Pick<InstructorShortlist, 'id' | 'lastMessageIn48HoursSentAt' | 'distanceUnit' | 'distance' | 'hasExistingAffiliation' | 'hasRecentlyDeclinedAffiliation'>
        & { profile: (
          { __typename?: 'UserProfileTeaching' }
          & InstructorProfileFieldsFragment
        ), user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'lastName' | 'firstName'>
        ), instructorAffiliation?: Maybe<(
          { __typename?: 'Affiliation' }
          & Pick<Affiliation, 'id' | 'clubId' | 'pendingStatus' | 'actionedDate' | 'requestedDate' | 'requestedBy' | 'role' | 'userId' | 'status'>
        )> }
      )>> }
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetTranslationsQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetTranslationsQuery = (
  { __typename?: 'Query' }
  & { getAllTranslations?: Maybe<(
    { __typename?: 'GetAllTranslationsResult' }
    & Pick<GetAllTranslationsResult, 'result'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetUserQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getCurrentUser?: Maybe<(
    { __typename: 'UserResult' }
    & Pick<UserResult, 'id' | 'distance' | 'distanceUnit'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'lastName' | 'firstName' | 'accountId' | 'phone'>
      & { market: (
        { __typename?: 'Market' }
        & Pick<Market, 'name' | 'locale' | 'distanceUnit'>
        & { programs?: Maybe<Array<(
          { __typename?: 'Program' }
          & Pick<Program, 'id' | 'name' | 'code' | 'initials'>
        )>> }
      ), signedAgreements?: Maybe<Array<(
        { __typename?: 'SignedAgreement' }
        & Pick<SignedAgreement, 'type' | 'signedOn' | 'version'>
      )>> }
    ), settings: (
      { __typename?: 'UserSettings' }
      & Pick<UserSettings, 'locale'>
    ), profiles: Array<(
      { __typename: 'UserProfileTeaching' }
      & Pick<UserProfileTeaching, 'id'>
    ) | (
      { __typename: 'UserProfileClub' }
      & Pick<UserProfileClub, 'id'>
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type GetVideoGuidelinesQueryVariables = Exact<{
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetVideoGuidelinesQuery = (
  { __typename?: 'Query' }
  & { getVideoGuidelines: (
    { __typename?: 'GetVideoGuidelinesResult' }
    & Pick<GetVideoGuidelinesResult, 'contactUsEmail'>
    & { result: Array<(
      { __typename?: 'SectionContentResult' }
      & Pick<SectionContentResult, 'section'>
      & { content: Array<(
        { __typename?: 'ContentField' }
        & Pick<ContentField, 'title' | 'subHeading' | 'contentText'>
      )> }
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type GetWalkthroughContentQueryVariables = Exact<{
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetWalkthroughContentQuery = (
  { __typename?: 'Query' }
  & { getWalkthrough?: Maybe<(
    { __typename?: 'Walkthrough' }
    & { pages: Array<(
      { __typename?: 'WalkthroughPage' }
      & Pick<WalkthroughPage, 'title' | 'body'>
      & { image: (
        { __typename?: 'ImageSet' }
        & { desktop: (
          { __typename?: 'Image' }
          & ImageFieldsFragment
        ), mobile: (
          { __typename?: 'Image' }
          & ImageFieldsFragment
        ) }
      ) }
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type IncrementViewClubListingCountMutationVariables = Exact<{
  input: IncrementViewClubListingCountInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type IncrementViewClubListingCountMutation = (
  { __typename?: 'Mutation' }
  & { incrementViewClubListingCount?: Maybe<(
    { __typename?: 'ClubListingResult' }
    & { results?: Maybe<Array<(
      { __typename?: 'ClubSingleListing' }
      & SingleListingFieldsFragment
    ) | (
      { __typename?: 'ClubMultiListing' }
      & MultiListingFieldsFragment
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type InitApplicationQueryVariables = Exact<{
  featureFlags: Array<Scalars['String']> | Scalars['String'];
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type InitApplicationQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'UserResult' }
    & FullUserFieldFragment
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )>, getAllTranslations?: Maybe<(
    { __typename?: 'GetAllTranslationsResult' }
    & Pick<GetAllTranslationsResult, 'result' | 'locale'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )>, getFeatureFlag?: Maybe<(
    { __typename?: 'FeatureFlagResult' }
    & { flags?: Maybe<Array<(
      { __typename?: 'FeatureFlag' }
      & Pick<FeatureFlag, 'featureFlag' | 'enabled'>
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type ManagePeopleCreateAffiliationsMutationVariables = Exact<{
  input: CreateAffiliationsInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type ManagePeopleCreateAffiliationsMutation = (
  { __typename?: 'Mutation' }
  & { createAffiliations: (
    { __typename?: 'ChainAffiliationsResult' }
    & { successfulAffiliations?: Maybe<Array<(
      { __typename?: 'ChainAffiliation' }
      & Pick<ChainAffiliation, 'actionedDate' | 'clubId' | 'id' | 'latestActionInitiator' | 'pendingStatus' | 'requestedBy' | 'requestedDate' | 'role' | 'status' | 'userId'>
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type ManagePeopleRemoveAffiliationsMutationVariables = Exact<{
  input: DeleteAffiliationsInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type ManagePeopleRemoveAffiliationsMutation = (
  { __typename?: 'Mutation' }
  & { removeAffiliations: (
    { __typename?: 'ChainAffiliationsRemoveRresult' }
    & Pick<ChainAffiliationsRemoveRresult, 'receivedAffiliationIds' | 'removedAffiliationIds' | 'removedUserId'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type MarkNotificationsAsReadMutationVariables = Exact<{
  id: Array<Scalars['ID']> | Scalars['ID'];
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type MarkNotificationsAsReadMutation = (
  { __typename?: 'Mutation' }
  & { toggleNotificationsRead?: Maybe<(
    { __typename: 'ToggleNotificationReadResult' }
    & Pick<ToggleNotificationReadResult, 'success' | 'failure'>
  ) | (
    { __typename: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type MarkNotificationsAsUnreadMutationVariables = Exact<{
  id: Array<Scalars['ID']> | Scalars['ID'];
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type MarkNotificationsAsUnreadMutation = (
  { __typename?: 'Mutation' }
  & { toggleNotificationsRead?: Maybe<(
    { __typename: 'ToggleNotificationReadResult' }
    & Pick<ToggleNotificationReadResult, 'success' | 'failure'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type AtHomeProductTileFragment = (
  { __typename?: 'AtHomeProductTile' }
  & Pick<AtHomeProductTile, 'title' | 'description'>
  & { onlineProgram: (
    { __typename?: 'ClubOnlineProgram' }
    & Pick<ClubOnlineProgram, 'id' | 'activeSince' | 'lastEnquiredOn'>
  ), buttons?: Maybe<Array<(
    { __typename?: 'Link' }
    & Pick<Link, 'isPrimary' | 'id' | 'text'>
    & { url: (
      { __typename?: 'URL' }
      & Pick<Url, 'type' | 'value'>
    ) }
  )>>, image: (
    { __typename?: 'ImageSet' }
    & Pick<ImageSet, 'id'>
    & { desktop: (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'alt' | 'url'>
    ), mobile: (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'alt' | 'url'>
    ) }
  ), programs?: Maybe<Array<(
    { __typename?: 'ClubProgram' }
    & Pick<ClubProgram, 'id' | 'code' | 'name'>
  )>> }
);

export type GetAtHomeItemsQueryVariables = Exact<{
  input: GetClubByIdInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetAtHomeItemsQuery = (
  { __typename?: 'Query' }
  & { getClubById?: Maybe<(
    { __typename?: 'UserProfileClub' }
    & { atHomeProducts?: Maybe<(
      { __typename?: 'GetAtHomeProductTilesResult' }
      & { active?: Maybe<Array<(
        { __typename?: 'AtHomeProductTile' }
        & AtHomeProductTileFragment
      )>>, inactive?: Maybe<Array<(
        { __typename?: 'AtHomeProductTile' }
        & AtHomeProductTileFragment
      )>> }
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & Pick<ErrorResult, 'type' | 'message'>
  )> }
);

export type ExploreProgramTileFragment = (
  { __typename?: 'InStudioExploreProgramsTile' }
  & Pick<InStudioExploreProgramsTile, 'productId' | 'name' | 'genre' | 'productCategories' | 'numberOfCertifiedInstructors' | 'enquirySent'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'alt' | 'url'>
  )> }
);

export type GetExploreProgramsPageQueryVariables = Exact<{
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetExploreProgramsPageQuery = (
  { __typename?: 'Query' }
  & { getExploreProgramsPage?: Maybe<(
    { __typename?: 'ExploreProgramsPage' }
    & { products?: Maybe<Array<Maybe<(
      { __typename?: 'InStudioExploreProgramsTile' }
      & ExploreProgramTileFragment
    )>>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & Pick<ErrorResult, 'type' | 'message'>
  )> }
);

export type InStudioDetailsViewFragment = (
  { __typename?: 'InStudioDetailsView' }
  & Pick<InStudioDetailsView, 'description' | 'videoContentId'>
  & { typeInfo?: Maybe<Array<Maybe<(
    { __typename?: 'ProgramTypeInfo' }
    & Pick<ProgramTypeInfo, 'description' | 'title'>
  )>>>, moreURL?: Maybe<(
    { __typename?: 'URL' }
    & Pick<Url, 'type' | 'value'>
  )> }
);

export type DetailedExploreProgramTileFragment = (
  { __typename?: 'InStudioExploreProgramsTile' }
  & Pick<InStudioExploreProgramsTile, 'productId' | 'name' | 'genre' | 'productCategories' | 'numberOfCertifiedInstructors' | 'enquirySent'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'alt' | 'url'>
  )>, detailView?: Maybe<(
    { __typename?: 'InStudioDetailsView' }
    & InStudioDetailsViewFragment
  )> }
);

export type GetExploreProgramsPageDetailedProgramQueryVariables = Exact<{
  role: UserRoleInput;
  programName?: Maybe<ProgramName>;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetExploreProgramsPageDetailedProgramQuery = (
  { __typename?: 'Query' }
  & { getExploreProgramsPage?: Maybe<(
    { __typename?: 'ExploreProgramsPage' }
    & { products?: Maybe<Array<Maybe<(
      { __typename?: 'InStudioExploreProgramsTile' }
      & DetailedExploreProgramTileFragment
    )>>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & Pick<ErrorResult, 'type' | 'message'>
  )> }
);

export type RemoveFromShortlistMutationVariables = Exact<{
  input: RemoveFromShortlistInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type RemoveFromShortlistMutation = (
  { __typename?: 'Mutation' }
  & { removeFromShortlist?: Maybe<(
    { __typename?: 'RemoveFromShortlistResult' }
    & { shortlist?: Maybe<Array<(
      { __typename?: 'InstructorShortlist' }
      & Pick<InstructorShortlist, 'id' | 'lastMessageIn48HoursSentAt' | 'distanceUnit' | 'distance'>
      & { profile: (
        { __typename?: 'UserProfileTeaching' }
        & InstructorProfileFieldsFragment
      ), user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'lastName' | 'firstName'>
      ) }
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type ReportClubListingMutationVariables = Exact<{
  input: ReportClubListingInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type ReportClubListingMutation = (
  { __typename?: 'Mutation' }
  & { reportClubListing?: Maybe<{ __typename: 'ClubSingleListing' } | { __typename: 'ClubMultiListing' } | (
    { __typename: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type ReportClubProfileMutationVariables = Exact<{
  input: ReportClubProfileInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type ReportClubProfileMutation = (
  { __typename?: 'Mutation' }
  & { reportClubProfile?: Maybe<{ __typename: 'ClubProfile' } | (
    { __typename: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type RevertAppMetadataMutationVariables = Exact<{
  input: RevertAppMetadataInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type RevertAppMetadataMutation = (
  { __typename?: 'Mutation' }
  & { revertAppMetadata: (
    { __typename?: 'AppMetadataResult' }
    & Pick<AppMetadataResult, 'isSuccess'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type SearchClubListingsQueryVariables = Exact<{
  filter: SearchClubListingsFilter;
  options?: Maybe<SearchClubListingsOptions>;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type SearchClubListingsQuery = (
  { __typename?: 'Query' }
  & { searchClubListings?: Maybe<(
    { __typename?: 'SearchClubListingsResult' }
    & Pick<SearchClubListingsResult, 'total'>
    & { results?: Maybe<Array<(
      { __typename?: 'SearchClubJobListingsSingleResult' }
      & Pick<SearchClubJobListingsSingleResult, 'distance' | 'distanceUnit' | 'messageSentOn' | 'enquiredOn' | 'onWatchlist' | 'hasBeenReported'>
      & { listing: (
        { __typename?: 'ClubSingleListing' }
        & SingleListingFieldsFragment
      ) | (
        { __typename?: 'ClubMultiListing' }
        & MultiListingFieldsFragment
      ) }
    ) | (
      { __typename?: 'SearchClubProfileSingleResult' }
      & Pick<SearchClubProfileSingleResult, 'distance' | 'distanceUnit' | 'onWatchlist' | 'hasBeenReported'>
      & { profile: (
        { __typename?: 'UserProfileClub' }
        & ClubProfileFieldsFragment
      ) }
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type SearchClubsQueryVariables = Exact<{
  filter: SearchClubsFilter;
  options?: Maybe<SearchClubsOptions>;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type SearchClubsQuery = (
  { __typename?: 'Query' }
  & { searchClubs?: Maybe<(
    { __typename?: 'SearchClubsResult' }
    & Pick<SearchClubsResult, 'total'>
    & { results: Array<(
      { __typename?: 'SearchClubsSingleResult' }
      & Pick<SearchClubsSingleResult, 'clubId' | 'distance' | 'distanceUnit'>
      & { profile: (
        { __typename?: 'UserProfileClub' }
        & ClubProfileFieldsFragment
      ), instructorAffiliation?: Maybe<(
        { __typename?: 'Affiliation' }
        & Pick<Affiliation, 'id' | 'userId' | 'clubId' | 'requestedBy' | 'actionedDate' | 'status' | 'pendingStatus' | 'requestedDate' | 'latestActionInitiator'>
      )> }
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type SearchInstructorsSingleResultFragment = (
  { __typename?: 'SearchInstructorsSingleResult' }
  & Pick<SearchInstructorsSingleResult, 'distance' | 'messageSentOn' | 'inTravelZone' | 'hasExistingAffiliation' | 'hasRecentlyDeclinedAffiliation' | 'lastMessageIn48HoursSentAt'>
  & { instructorAffiliation?: Maybe<(
    { __typename?: 'Affiliation' }
    & Pick<Affiliation, 'id' | 'clubId' | 'pendingStatus' | 'actionedDate' | 'requestedDate' | 'requestedBy' | 'role' | 'userId' | 'status'>
  )>, profile: (
    { __typename?: 'UserProfileTeaching' }
    & InstructorProfileFieldsFragment
  ), user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'lastName' | 'firstName' | 'accountId'>
    & { market: (
      { __typename?: 'Market' }
      & Pick<Market, 'name' | 'locale' | 'distanceUnit'>
    ) }
  ) }
);

export type SearchInstructorsResultFragment = (
  { __typename?: 'SearchInstructorsResult' }
  & Pick<SearchInstructorsResult, 'isMatchingFilter' | 'total'>
  & { results?: Maybe<Array<(
    { __typename?: 'SearchInstructorsSingleResult' }
    & SearchInstructorsSingleResultFragment
  )>> }
);

export type SearchInstructorsQueryVariables = Exact<{
  filter: SearchInstructorsFilter;
  options?: Maybe<SearchInstructorsOptions>;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type SearchInstructorsQuery = (
  { __typename?: 'Query' }
  & { searchInstructors?: Maybe<(
    { __typename?: 'SearchInstructorsResult' }
    & SearchInstructorsResultFragment
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type SendMessageMutationVariables = Exact<{
  input: SendMessageInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type SendMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendMessage?: Maybe<(
    { __typename?: 'SendMessageResult' }
    & Pick<SendMessageResult, 'sentAt'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type SetClubSettingsMutationVariables = Exact<{
  input: SetClubSettingsInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type SetClubSettingsMutation = (
  { __typename?: 'Mutation' }
  & { setClubSettings?: Maybe<(
    { __typename?: 'SetClubSettingsResult' }
    & Pick<SetClubSettingsResult, 'id' | 'name' | 'phone'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & AddressFieldsFragment
    )>, email?: Maybe<(
      { __typename?: 'EmailClubType' }
      & Pick<EmailClubType, 'main' | 'affiliations' | 'jobListings'>
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type SetInstructorAvailabilityMutationVariables = Exact<{
  input: SetInstructorAvailabilityInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type SetInstructorAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { setInstructorAvailability?: Maybe<(
    { __typename?: 'InstructorAvailability' }
    & Pick<InstructorAvailability, 'value' | 'availableToSubstitute'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type SetInstructorWatchlistMutationVariables = Exact<{
  input: SetInstructorWatchlistInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type SetInstructorWatchlistMutation = (
  { __typename?: 'Mutation' }
  & { setInstructorWatchlist?: Maybe<(
    { __typename?: 'InstructorWatchlist' }
    & { watchlist?: Maybe<Array<(
      { __typename?: 'InstructorWatchlistItem' }
      & Pick<InstructorWatchlistItem, 'clubId' | 'listingId'>
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type SetSavedInstructorSearchDataMutationVariables = Exact<{
  input: SetSavedInstructorSearchDataInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type SetSavedInstructorSearchDataMutation = (
  { __typename?: 'Mutation' }
  & { setSavedInstructorSearchData?: Maybe<(
    { __typename?: 'SetSavedInstructorSearchDataResult' }
    & { results: Array<(
      { __typename?: 'SavedInstructorSearch' }
      & SavedInstructorSearchFragment
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type SetSignedAgreementDataMutationVariables = Exact<{
  input: SetSignedAgreementDataInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type SetSignedAgreementDataMutation = (
  { __typename?: 'Mutation' }
  & { setSignedAgreementData?: Maybe<(
    { __typename?: 'AgreementDocumentResult' }
    & Pick<AgreementDocumentResult, 'signedOn' | 'version'>
    & { agreementType: AgreementDocumentResult['type'] }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type SetWalkthroughToSeenMutationVariables = Exact<{
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type SetWalkthroughToSeenMutation = (
  { __typename?: 'Mutation' }
  & { setWalkthroughToSeen?: Maybe<(
    { __typename?: 'SetWalkthroughToSeenResult' }
    & Pick<SetWalkthroughToSeenResult, 'success'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type UpdateAffiliateBillingMutationVariables = Exact<{
  input: PayForCertificationInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type UpdateAffiliateBillingMutation = (
  { __typename?: 'Mutation' }
  & { payForCertification?: Maybe<(
    { __typename?: 'PayForCertificationResult' }
    & { results?: Maybe<Array<(
      { __typename?: 'InstructorCertificate' }
      & Pick<InstructorCertificate, 'instructorName'>
      & { certificate?: Maybe<(
        { __typename?: 'Certification' }
        & Pick<Certification, 'active' | 'billedClubId' | 'id' | 'lastPurchase' | 'lmqLevel' | 'transactionStatus' | 'releaseStatus' | 'status'>
        & { pricePerQuarter?: Maybe<(
          { __typename?: 'CertificationPrice' }
          & Pick<CertificationPrice, 'amount' | 'currency'>
        )>, program: (
          { __typename?: 'Program' }
          & Pick<Program, 'code' | 'id' | 'initials' | 'name'>
        ) }
      )> }
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type UpdateAffiliationMutationVariables = Exact<{
  input: UpdateAffiliationInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type UpdateAffiliationMutation = (
  { __typename?: 'Mutation' }
  & { updateAffiliation: (
    { __typename?: 'AffiliationAsyncResult' }
    & Pick<AffiliationAsyncResult, 'status' | 'hasPendingAffiliations'>
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type UpdateClubListingMutationVariables = Exact<{
  input: UpdateClubListingInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type UpdateClubListingMutation = (
  { __typename?: 'Mutation' }
  & { updateClubListing?: Maybe<(
    { __typename?: 'ClubListingResult' }
    & { results?: Maybe<Array<(
      { __typename?: 'ClubSingleListing' }
      & SingleListingFieldsFragment
    ) | (
      { __typename?: 'ClubMultiListing' }
      & MultiListingFieldsFragment
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type UpdateClubProfileMutationVariables = Exact<{
  input: UpdateClubProfileInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type UpdateClubProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateClubProfile: (
    { __typename?: 'UserResult' }
    & FullUserFieldFragment
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type FinishPortfolioUpdateMutationVariables = Exact<{
  input: UpdateCurrentUserPortfolioUploadInput;
  role: UserRoleInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type FinishPortfolioUpdateMutation = (
  { __typename?: 'Mutation' }
  & { updateCurrentUserPortfolioUpload: (
    { __typename?: 'UpdateCurrentUserPortfolioUploadResult' }
    & Pick<UpdateCurrentUserPortfolioUploadResult, 'url'>
    & { profile: (
      { __typename?: 'UserProfileTeaching' }
      & InstructorProfileFieldsFragment
    ) | (
      { __typename?: 'UserProfileClub' }
      & ClubProfileFieldsFragment
    ) }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type UpdateTeachingProfileMutationVariables = Exact<{
  input: UpdateTeachingProfileInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type UpdateTeachingProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateTeachingProfile: (
    { __typename?: 'UserResult' }
    & FullUserFieldFragment
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type UpdateUserAccountMutationVariables = Exact<{
  input: UpdateUserAccountInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type UpdateUserAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateUserAccount: (
    { __typename?: 'UserAccountSettingsResult' }
    & Pick<UserAccountSettingsResult, 'email' | 'firstName' | 'id' | 'lastName' | 'phone'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & AddressFieldsFragment
    )>, outlinks?: Maybe<Array<(
      { __typename?: 'OutLink' }
      & Pick<OutLink, 'url' | 'title'>
    )>> }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type UpdateUserPhotoMutationVariables = Exact<{
  input: UpdateUserPhotoInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type UpdateUserPhotoMutation = (
  { __typename?: 'Mutation' }
  & { updateUserPhoto?: Maybe<(
    { __typename?: 'UpdateUserPhotoResult' }
    & Pick<UpdateUserPhotoResult, 'url'>
    & { profile: (
      { __typename?: 'UserProfileTeaching' }
      & InstructorProfileFieldsFragment
    ) | (
      { __typename?: 'UserProfileClub' }
      & ClubProfileFieldsFragment
    ) }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  )> }
);

export type FinalizeUploadMutationVariables = Exact<{
  input: FinalizeUploadInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type FinalizeUploadMutation = (
  { __typename?: 'Mutation' }
  & { finalizeUpload?: Maybe<(
    { __typename?: 'FinalizeUploadResult' }
    & Pick<FinalizeUploadResult, 'isSuccess' | 'comment'>
    & { playback: (
      { __typename?: 'Playback' }
      & Pick<Playback, 'playbackUrl' | 'token'>
    ) }
  ) | (
    { __typename?: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type GetPresignedUrlQueryVariables = Exact<{
  input: GetPresignedUrlInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type GetPresignedUrlQuery = (
  { __typename?: 'Query' }
  & { getPresignedUrl?: Maybe<(
    { __typename?: 'GetPresignedUrlResult' }
    & { partSignedUrlList: Array<(
      { __typename?: 'PartSignedUrl' }
      & Pick<PartSignedUrl, 'partNumber' | 'url'>
    )> }
  ) | (
    { __typename?: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type InitializeUploadMutationVariables = Exact<{
  input: InitializeUploadInput;
  _cacheKey?: Maybe<Scalars['String']>;
}>;


export type InitializeUploadMutation = (
  { __typename?: 'Mutation' }
  & { initializeUpload?: Maybe<(
    { __typename?: 'InitializeUploadResult' }
    & Pick<InitializeUploadResult, 'uploadId'>
  ) | (
    { __typename?: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export const InStudioClubProgramFragmentDoc = `
    fragment InStudioClubProgram on ClubProgram {
  id
  code
  name
  status
  initials
  programType
  instructorCount {
    numberOfInstructors
    belowRecommendedAmount
    lastEnquiredOn
  }
}
    `;
export const UrlFieldsFragmentDoc = `
    fragment urlFields on URL {
  type
  value
}
    `;
export const LinkFieldsFragmentDoc = `
    fragment linkFields on Link {
  id
  text
  url {
    ...urlFields
  }
}
    ${UrlFieldsFragmentDoc}`;
export const InStudioEmptyStateFragmentDoc = `
    fragment InStudioEmptyState on ClubProgramInStudioEmptyState {
  programType
  content {
    subtitle
    title
    link {
      ...linkFields
    }
  }
}
    ${LinkFieldsFragmentDoc}`;
export const ImageFieldsFragmentDoc = `
    fragment imageFields on Image {
  __typename
  id
  url
  alt
  height
  width
}
    `;
export const InStudioToolkitTileFragmentDoc = `
    fragment InStudioToolkitTile on InStudioToolkitTile {
  image {
    ...imageFields
  }
  link {
    ...linkFields
  }
  subTitle
  title
}
    ${ImageFieldsFragmentDoc}
${LinkFieldsFragmentDoc}`;
export const EventSummaryFieldsFragmentDoc = `
    fragment eventSummaryFields on EventSummary {
  deliveryMethod
  endDate
  eventId
  eventName
  eventStatus
  programName
  startDate
  trainingJourneyCount
  venueAddress
}
    `;
export const DevelopmentSheetFieldsFragmentDoc = `
    fragment developmentSheetFields on DevelopmentSheet {
  filename
  url
}
    `;
export const EmergencyContactFieldsFragmentDoc = `
    fragment emergencyContactFields on EmergencyContact {
  address
  name
  phone
}
    `;
export const InstructorInfoFieldsFragmentDoc = `
    fragment instructorInfoFields on InstructorInformation {
  address
  email
  name
  phone
}
    `;
export const PlaybackFieldsFragmentDoc = `
    fragment playbackFields on Playback {
  playbackUrl
  token
}
    `;
export const OutcomeTrackFieldsFragmentDoc = `
    fragment outcomeTrackFields on OutcomeTrack {
  comment
  submittedAt
  playback {
    ...playbackFields
  }
}
    ${PlaybackFieldsFragmentDoc}`;
export const PresentationTrackFieldsFragmentDoc = `
    fragment presentationTrackFields on PresentationTrack {
  comment
  submittedAt
  playback {
    ...playbackFields
  }
}
    ${PlaybackFieldsFragmentDoc}`;
export const EventAssessmentFieldsFragmentDoc = `
    fragment eventAssessmentFields on EventAssessments {
  attended
  developmentSheet {
    ...developmentSheetFields
  }
  emergencyContact {
    ...emergencyContactFields
  }
  instructorInfo {
    ...instructorInfoFields
  }
  outcome
  outcomeTrack {
    ...outcomeTrackFields
  }
  presentationTrack {
    ...presentationTrackFields
  }
  sessionRegistrationId
  trainingJourneyId
  trackAllocation
}
    ${DevelopmentSheetFieldsFragmentDoc}
${EmergencyContactFieldsFragmentDoc}
${InstructorInfoFieldsFragmentDoc}
${OutcomeTrackFieldsFragmentDoc}
${PresentationTrackFieldsFragmentDoc}`;
export const StepFieldsFragmentDoc = `
    fragment stepFields on Step {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  passPendingGuide
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  venueAddress
  contactUsEmail
}
    `;
export const TrainingBookedStepFieldsFragmentDoc = `
    fragment trainingBookedStepFields on TrainingBookedStep {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  passPendingGuide
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  parqForm
  venueAddress
  contactUsEmail
  emergencyContact {
    ...emergencyContactFields
  }
}
    ${EmergencyContactFieldsFragmentDoc}`;
export const AssessmentVideoFieldsFragmentDoc = `
    fragment assessmentVideoFields on AssessmentVideo {
  assessmentId
  comment
  fileKey
  submitted
  submittedDate
  recordType
  videoNotWorking
  playback {
    ...playbackFields
  }
  extensionRequested
}
    ${PlaybackFieldsFragmentDoc}`;
export const PreworkContentItemFieldsFragmentDoc = `
    fragment PreworkContentItemFields on PreworkContentItem {
  title
  type
  url {
    ...urlFields
  }
}
    ${UrlFieldsFragmentDoc}`;
export const PreworkContentFieldsFragmentDoc = `
    fragment preworkContentFields on PreworkContent {
  contentList {
    ...PreworkContentItemFields
  }
}
    ${PreworkContentItemFieldsFragmentDoc}`;
export const PreworkStepFieldsFragmentDoc = `
    fragment preworkStepFields on PreworkStep {
  activeStepName
  activeStepNumber
  assessmentVideo {
    ...assessmentVideoFields
  }
  attended
  cancellationPolicy
  passPendingGuide
  preworkContent {
    ...preworkContentFields
  }
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  releaseUrl {
    ...linkFields
  }
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  venueAddress
  contactUsEmail
}
    ${AssessmentVideoFieldsFragmentDoc}
${PreworkContentFieldsFragmentDoc}
${LinkFieldsFragmentDoc}`;
export const AttendTrainingStepFieldsFragmentDoc = `
    fragment attendTrainingStepFields on AttendTrainingStep {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  passPendingGuide
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  venueAddress
  contactUsEmail
}
    `;
export const SubmitOutcomeTrackStepFieldsFragmentDoc = `
    fragment submitOutcomeTrackStepFields on SubmitOutcomeTrackStep {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  passPendingGuide
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  extendedDueDate
  venueAddress
  contactUsEmail
  assessmentVideo {
    ...assessmentVideoFields
  }
}
    ${AssessmentVideoFieldsFragmentDoc}`;
export const TrainingOutcomeFieldsFragmentDoc = `
    fragment trainingOutcomeFields on TrainingOutcome {
  developmentSheet
  outcome
  submitDate
  trainingNotes
  assessmentVideo {
    ...assessmentVideoFields
  }
}
    ${AssessmentVideoFieldsFragmentDoc}`;
export const ReceivedTrainingOutcomeStepFieldsFragmentDoc = `
    fragment receivedTrainingOutcomeStepFields on ReceivedTrainingOutcomeStep {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  passPendingGuide
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  cecCertificationUrl
  extendedDueDate
  venueAddress
  contactUsEmail
  assessmentOutcomes {
    ...trainingOutcomeFields
  }
}
    ${TrainingOutcomeFieldsFragmentDoc}`;
export const SubmitCertificationVideoStepFieldsFragmentDoc = `
    fragment submitCertificationVideoStepFields on SubmitCertificationVideoStep {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  passPendingGuide
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  extendedDueDate
  venueAddress
  contactUsEmail
  assessmentVideo {
    ...assessmentVideoFields
  }
}
    ${AssessmentVideoFieldsFragmentDoc}`;
export const ReceivedCertificationOutcomeStepFieldsFragmentDoc = `
    fragment receivedCertificationOutcomeStepFields on ReceivedCertificationOutcomeStep {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  passPendingGuide
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  resubmitDueDate
  certificationUrl
  venueAddress
  contactUsEmail
  certificationOutcomes {
    ...trainingOutcomeFields
  }
}
    ${TrainingOutcomeFieldsFragmentDoc}`;
export const CertifiedStepFieldsFragmentDoc = `
    fragment certifiedStepFields on CertifiedStep {
  activeStepName
  activeStepNumber
  attended
  cancellationPolicy
  deliveryMethod
  details
  dueDate
  endDate
  eventName
  fulfillment
  level
  onlineTrainingLink
  passPendingGuide
  programName
  requirements
  schedule
  startDate
  stepName
  stepNumber
  timezone
  trackAllocation
  trainerEmail
  trainerName
  contactUsEmail
  venueAddress
}
    `;
export const InstructorAvailabilityFieldsFragmentDoc = `
    fragment instructorAvailabilityFields on InstructorAvailability {
  description
  title
  value
}
    `;
export const AddressFieldsFragmentDoc = `
    fragment addressFields on Address {
  city
  state
  street
  country
  stateCode
  postalCode
  streetName
  countryCode
  streetNumber
  location {
    lat
    lon
  }
}
    `;
export const ProgramFieldsFragmentDoc = `
    fragment programFields on Program {
  code
  id
  initials
  name
}
    `;
export const TimePreferencesFragmentDoc = `
    fragment timePreferences on TimePreference {
  morning
  afternoon
  evening
}
    `;
export const TravelPreferencesFragmentDoc = `
    fragment travelPreferences on TravelPreference {
  address {
    ...addressFields
  }
  distance
  distanceUnit
}
    ${AddressFieldsFragmentDoc}`;
export const PreferenceFieldsFragmentDoc = `
    fragment preferenceFields on InstructorPreferences {
  programs {
    ...programFields
  }
  times {
    ...timePreferences
  }
  travel {
    ...travelPreferences
  }
}
    ${ProgramFieldsFragmentDoc}
${TimePreferencesFragmentDoc}
${TravelPreferencesFragmentDoc}`;
export const PrivacyFieldsFragmentDoc = `
    fragment privacyFields on PrivacySettingsInstructor {
  EMAIL
  PHONE_NUMBER
  PROFILE_PHOTO
  SOCIAL_LINKS
  LMQ_LEVEL
  HIDDEN
}
    `;
export const InstructorProfileFieldsFragmentDoc = `
    fragment instructorProfileFields on UserProfileTeaching {
  id
  __typename
  id
  bio
  address {
    ...addressFields
  }
  roles
  strength
  interests
  createdAt
  availability
  createdAtLabel
  availableToSubstitute
  preferences {
    ...preferenceFields
  }
  privacySettings {
    ...privacyFields
  }
  media {
    ...imageFields
  }
  profileImage {
    ...imageFields
  }
  links {
    url
    type
  }
  certifications {
    id
    lastPurchase
    pricePerQuarter {
      amount
      currency
    }
    transactionStatus
    transactionTimestamp
    billedClubId
    releaseStatus
    status
    active
    lmqLevel
    link {
      ...linkFields
    }
    program {
      id
      name
      code
      initials
    }
  }
  watchlist {
    clubId
    listingId
  }
}
    ${AddressFieldsFragmentDoc}
${PreferenceFieldsFragmentDoc}
${PrivacyFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${LinkFieldsFragmentDoc}`;
export const ClubProfileFieldsFragmentDoc = `
    fragment clubProfileFields on UserProfileClub {
  id
  __typename
  bio
  name
  email
  phone
  hiring
  address {
    ...addressFields
  }
  strength
  createdAt
  createdAtLabel
  programs {
    ... on ClubProgram {
      __typename
      id
      name
      code
      initials
      programType
    }
  }
  media {
    ...imageFields
  }
  profileImage {
    ...imageFields
  }
  links {
    url
    type
  }
  isDigitalOnly
}
    ${AddressFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const FullUserFieldFragmentDoc = `
    fragment fullUserField on UserResult {
  __typename
  id
  distance
  distanceUnit
  user {
    id
    email
    market {
      isSupported
      name
      locale
      distanceUnit
      programs {
        id
        name
        code
        initials
      }
    }
    lastName
    firstName
    accountId
    phone
    signedAgreements {
      type
      signedOn
      version
    }
    userMetaData {
      walkthroughRequired {
        club
        instructor
      }
    }
  }
  profiles {
    ... on UserProfileTeaching {
      ...instructorProfileFields
    }
    ... on UserProfileClub {
      ...clubProfileFields
    }
  }
  settings {
    locale
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}`;
export const SingleListingFieldsFragmentDoc = `
    fragment singleListingFields on ClubSingleListing {
  clubInfo {
    id
    name
    address {
      ...addressFields
    }
    profileImage {
      url
    }
    programs {
      ... on ClubProgram {
        name
      }
    }
    hiring
  }
  createdAt
  description
  expiringAt
  id
  searchAppearances
  updatedAt
  views
  isSubstituteListing
  program {
    code
    id
    initials
    name
  }
  status
  times {
    ...timePreferences
  }
  enquiriesCount
}
    ${AddressFieldsFragmentDoc}
${TimePreferencesFragmentDoc}`;
export const MultiListingFieldsFragmentDoc = `
    fragment multiListingFields on ClubMultiListing {
  clubInfo {
    id
    name
    address {
      ...addressFields
    }
    profileImage {
      url
    }
    programs {
      ... on ClubProgram {
        name
      }
    }
  }
  createdAt
  description
  expiringAt
  id
  searchAppearances
  programs {
    code
    id
    initials
    name
  }
  status
  updatedAt
  views
  enquiriesCount
}
    ${AddressFieldsFragmentDoc}`;
export const SavedInstructorSearchFragmentDoc = `
    fragment savedInstructorSearch on SavedInstructorSearch {
  filter {
    name
    programs
    substituteListingFilter
    location {
      within {
        address {
          ...addressFields
        }
        area {
          points {
            lat
            lon
          }
        }
      }
      near {
        address {
          ...addressFields
        }
        distance
        distanceUnit
      }
      point {
        lat
        lon
      }
    }
  }
  sort
}
    ${AddressFieldsFragmentDoc}`;
export const ErrorResultFieldsFragmentDoc = `
    fragment errorResultFields on ErrorResult {
  type
  message
  requestId
  logGroup
  logStream
  __typename
}
    `;
export const ClubFieldsFragmentDoc = `
    fragment clubFields on Club {
  accountId
  createdAt
  currency
  email {
    affiliations
    jobListings
    main
  }
  id
  market {
    name
    locale
    distanceUnit
    programs {
      id
      name
      code
      initials
    }
  }
  name
  parent
  phone
  profile {
    ... on UserProfileClub {
      ...clubProfileFields
    }
  }
}
    ${ClubProfileFieldsFragmentDoc}`;
export const ManagePeopleUserAffiliateFieldsFragmentDoc = `
    fragment managePeopleUserAffiliateFields on User {
  id
  phone
  email
  accountId
  market {
    name
    locale
    distanceUnit
  }
  lastName
  firstName
}
    `;
export const AffiliationFieldsFragmentDoc = `
    fragment affiliationFields on Affiliation {
  actionedDate
  clubId
  id
  latestActionInitiator
  pendingStatus
  requestedBy
  requestedDate
  role
  status
  userId
}
    `;
export const ClubWithAffiliationFragmentDoc = `
    fragment clubWithAffiliation on ClubWithAffiliation {
  clubId
  clubName
  latestAffiliation {
    ...affiliationFields
  }
  isDigitalOnly
}
    ${AffiliationFieldsFragmentDoc}`;
export const ManagePeopleClubAffiliatesFieldsFragmentDoc = `
    fragment managePeopleClubAffiliatesFields on ClubAffiliates {
  actionedDate
  clubId
  distance
  distanceUnit
  id
  pendingStatus
  profile {
    ... on UserProfileClub {
      ...clubProfileFields
    }
  }
  requestedBy
  requestedDate
  status
}
    ${ClubProfileFieldsFragmentDoc}`;
export const BusinessSupportSectionContentFragmentDoc = `
    fragment BusinessSupportSectionContent on BusinessSupportSection {
  id
  sectionTitle
  sectionTiles {
    id
    title
    subtitle
    image {
      id
      alt
      url
    }
    url {
      type
      value
    }
  }
}
    `;
export const ClubDashboardBusinessSupportContentFragmentDoc = `
    fragment ClubDashboardBusinessSupportContent on ClubPartnershipBusinessSupportContent {
  link {
    text
    url {
      type
      value
    }
  }
  title
  description
}
    `;
export const ClubDashboardStandardContentFragmentDoc = `
    fragment ClubDashboardStandardContent on ClubPartnershipStandardContent {
  title
  subtitle
  link {
    url {
      type
      value
    }
    text
  }
  image {
    mobile {
      alt
      url
    }
    desktop {
      alt
      url
    }
  }
}
    `;
export const ClubDashboardProgramInfoFragmentDoc = `
    fragment ClubDashboardProgramInfo on ClubPartnershipProgramStat {
  total
  type
  icon {
    alt
    url
  }
}
    `;
export const ClubDashboardProgramStatsFragmentDoc = `
    fragment ClubDashboardProgramStats on ClubPartnershipProgramStats {
  programsStats {
    ...ClubDashboardProgramInfo
  }
}
    ${ClubDashboardProgramInfoFragmentDoc}`;
export const InStudioManageOptionsFragmentDoc = `
    fragment InStudioManageOptions on GetPickListResult {
  results {
    items
    key
  }
}
    `;
export const LocationsFragmentDoc = `
    fragment locations on ClubAvailabilityOnListing {
  clubId
  clubName
  isAlreadyListed
  isClubLicensed
  clubProfileImage {
    ...imageFields
  }
  clubAddress {
    ...addressFields
  }
}
    ${ImageFieldsFragmentDoc}
${AddressFieldsFragmentDoc}`;
export const NotificationItemFragmentDoc = `
    fragment notificationItem on Notification {
  id
  type
  body
  title
  createdAt
  createdAtLabel
  readAt
  link {
    id
    text
    url {
      type
      value
    }
  }
}
    `;
export const NotificationsResultFragmentDoc = `
    fragment notificationsResult on GetNotificationsResult {
  filter {
    type
    value
    limit
  }
  totalCount
  items {
    ...notificationItem
  }
}
    ${NotificationItemFragmentDoc}`;
export const AtHomeProductTileFragmentDoc = `
    fragment AtHomeProductTile on AtHomeProductTile {
  title
  description
  onlineProgram {
    id
    activeSince
    lastEnquiredOn
  }
  buttons {
    isPrimary
    id
    text
    url {
      type
      value
    }
  }
  image {
    id
    desktop {
      id
      alt
      url
    }
    mobile {
      id
      alt
      url
    }
  }
  programs {
    id
    code
    name
  }
}
    `;
export const ExploreProgramTileFragmentDoc = `
    fragment ExploreProgramTile on InStudioExploreProgramsTile {
  productId
  name
  genre
  productCategories
  numberOfCertifiedInstructors
  enquirySent
  image {
    id
    alt
    url
  }
}
    `;
export const InStudioDetailsViewFragmentDoc = `
    fragment InStudioDetailsView on InStudioDetailsView {
  typeInfo {
    description
    title
  }
  description
  videoContentId
  moreURL {
    type
    value
  }
}
    `;
export const DetailedExploreProgramTileFragmentDoc = `
    fragment DetailedExploreProgramTile on InStudioExploreProgramsTile {
  productId
  name
  genre
  productCategories
  numberOfCertifiedInstructors
  enquirySent
  image {
    id
    alt
    url
  }
  detailView {
    ...InStudioDetailsView
  }
}
    ${InStudioDetailsViewFragmentDoc}`;
export const SearchInstructorsSingleResultFragmentDoc = `
    fragment searchInstructorsSingleResult on SearchInstructorsSingleResult {
  distance
  messageSentOn
  inTravelZone
  hasExistingAffiliation
  hasRecentlyDeclinedAffiliation
  lastMessageIn48HoursSentAt
  instructorAffiliation {
    id
    clubId
    pendingStatus
    actionedDate
    requestedDate
    requestedBy
    role
    userId
    status
  }
  profile {
    ... on UserProfileTeaching {
      ...instructorProfileFields
    }
  }
  user {
    id
    email
    lastName
    firstName
    accountId
    market {
      name
      locale
      distanceUnit
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}`;
export const SearchInstructorsResultFragmentDoc = `
    fragment searchInstructorsResult on SearchInstructorsResult {
  isMatchingFilter
  total
  results {
    ...searchInstructorsSingleResult
  }
}
    ${SearchInstructorsSingleResultFragmentDoc}`;
export const GetClubProgramsForInStudioPageDocument = `
    query getClubProgramsForInStudioPage($input: GetClubByIdInput!, $_cacheKey: String) {
  getClubById(input: $input, _cacheKey: $_cacheKey) {
    ... on UserProfileClub {
      programs {
        ... on ClubProgram {
          ...InStudioClubProgram
        }
        ... on ClubProgramInStudioEmptyState {
          ...InStudioEmptyState
        }
      }
      inStudioToolkit {
        tiles {
          ...InStudioToolkitTile
        }
      }
      exploreProgramsCard {
        image {
          ...imageFields
        }
      }
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${InStudioClubProgramFragmentDoc}
${InStudioEmptyStateFragmentDoc}
${InStudioToolkitTileFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const useGetClubProgramsForInStudioPageQuery = <
      TData = GetClubProgramsForInStudioPageQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetClubProgramsForInStudioPageQueryVariables, 
      options?: UseQueryOptions<GetClubProgramsForInStudioPageQuery, TError, TData>
    ) => 
    useQuery<GetClubProgramsForInStudioPageQuery, TError, TData>(
      ['getClubProgramsForInStudioPage', variables],
      fetcher<GetClubProgramsForInStudioPageQuery, GetClubProgramsForInStudioPageQueryVariables>(client, GetClubProgramsForInStudioPageDocument, variables),
      options
    );
export const AddTrainingOutcomeDocument = `
    mutation addTrainingOutcome($input: AddTrainingOutcomeInput!, $_cacheKey: String) {
  addTrainingOutcome(input: $input, _cacheKey: $_cacheKey) {
    ... on AddTrainingOutcomeResult {
      isSuccess
      developmentSheet {
        filename
        url
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useAddTrainingOutcomeMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<AddTrainingOutcomeMutation, TError, AddTrainingOutcomeMutationVariables, TContext>
    ) => 
    useMutation<AddTrainingOutcomeMutation, TError, AddTrainingOutcomeMutationVariables, TContext>(
      (variables?: AddTrainingOutcomeMutationVariables) => fetcher<AddTrainingOutcomeMutation, AddTrainingOutcomeMutationVariables>(client, AddTrainingOutcomeDocument, variables)(),
      options
    );
export const BookEventDocument = `
    mutation bookEvent($input: BookEventInput!, $role: UserRoleInput!) {
  bookEvent(input: $input, role: $role) {
    ... on BookEventResult {
      magentoLink
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useBookEventMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<BookEventMutation, TError, BookEventMutationVariables, TContext>
    ) => 
    useMutation<BookEventMutation, TError, BookEventMutationVariables, TContext>(
      (variables?: BookEventMutationVariables) => fetcher<BookEventMutation, BookEventMutationVariables>(client, BookEventDocument, variables)(),
      options
    );
export const BookedEventDocument = `
    query bookedEvent($eventId: String!, $role: UserRoleInput!, $_cacheKey: String) {
  bookedEvent(eventId: $eventId, role: $role, _cacheKey: $_cacheKey) {
    ... on BookedEventResult {
      eventDetails {
        event {
          eventId
          eventName
          startDate
          endDate
          deliveryMethod
          spacesAvailable
          venueAddress
          programName
          timezone
        }
        trainerDetails {
          name
          email
        }
        schedule
        details
        requirements
        cancellationPolicy
      }
      bookedInstructors {
        name
        profileImage {
          url
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useBookedEventQuery = <
      TData = BookedEventQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: BookedEventQueryVariables, 
      options?: UseQueryOptions<BookedEventQuery, TError, TData>
    ) => 
    useQuery<BookedEventQuery, TError, TData>(
      ['bookedEvent', variables],
      fetcher<BookedEventQuery, BookedEventQueryVariables>(client, BookedEventDocument, variables),
      options
    );
export const BookedEventsDocument = `
    query bookedEvents($clubId: String!, $_cacheKey: String) {
  bookedEvents(clubId: $clubId, _cacheKey: $_cacheKey) {
    ... on BookedEventsResult {
      bookedEvents {
        event {
          eventId
          eventName
          startDate
          endDate
          deliveryMethod
          spacesAvailable
          venueAddress
          programName
        }
        numberOfBookedInstructors
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useBookedEventsQuery = <
      TData = BookedEventsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: BookedEventsQueryVariables, 
      options?: UseQueryOptions<BookedEventsQuery, TError, TData>
    ) => 
    useQuery<BookedEventsQuery, TError, TData>(
      ['bookedEvents', variables],
      fetcher<BookedEventsQuery, BookedEventsQueryVariables>(client, BookedEventsDocument, variables),
      options
    );
export const EventDetailsDocument = `
    query eventDetails($eventId: String!, $role: UserRoleInput!, $_cacheKey: String) {
  eventDetails(eventId: $eventId, role: $role, _cacheKey: $_cacheKey) {
    ... on EventDetailsResult {
      event {
        eventId
        eventName
        startDate
        endDate
        deliveryMethod
        spacesAvailable
        venueAddress
        programName
        timezone
      }
      trainerDetails {
        name
        email
      }
      schedule
      details
      requirements
      cancellationPolicy
      tickets {
        ticketId
        name
        currencyIsoCode
        price
        startDate
        endDate
        isDefaultTicket
        canBeUsedWithDiscountCode
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useEventDetailsQuery = <
      TData = EventDetailsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: EventDetailsQueryVariables, 
      options?: UseQueryOptions<EventDetailsQuery, TError, TData>
    ) => 
    useQuery<EventDetailsQuery, TError, TData>(
      ['eventDetails', variables],
      fetcher<EventDetailsQuery, EventDetailsQueryVariables>(client, EventDetailsDocument, variables),
      options
    );
export const TrainerBookingDocument = `
    query trainerBooking($input: TrainerBookingInput!, $_cacheKey: String) {
  trainerBooking(input: $input, _cacheKey: $_cacheKey) {
    ... on TrainerBookingResult {
      eventAssessments {
        ...eventAssessmentFields
      }
      eventSummary {
        ...eventSummaryFields
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${EventAssessmentFieldsFragmentDoc}
${EventSummaryFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useTrainerBookingQuery = <
      TData = TrainerBookingQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: TrainerBookingQueryVariables, 
      options?: UseQueryOptions<TrainerBookingQuery, TError, TData>
    ) => 
    useQuery<TrainerBookingQuery, TError, TData>(
      ['trainerBooking', variables],
      fetcher<TrainerBookingQuery, TrainerBookingQueryVariables>(client, TrainerBookingDocument, variables),
      options
    );
export const TrainerBookingsDocument = `
    query trainerBookings($_cacheKey: String) {
  trainerBookings(_cacheKey: $_cacheKey) {
    ... on TrainerBookingsResult {
      result {
        deliveryMethod
        endDate
        eventId
        eventName
        programName
        startDate
        eventStatus
        trainingJourneyCount
        venueAddress
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useTrainerBookingsQuery = <
      TData = TrainerBookingsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: TrainerBookingsQueryVariables, 
      options?: UseQueryOptions<TrainerBookingsQuery, TError, TData>
    ) => 
    useQuery<TrainerBookingsQuery, TError, TData>(
      ['trainerBookings', variables],
      fetcher<TrainerBookingsQuery, TrainerBookingsQueryVariables>(client, TrainerBookingsDocument, variables),
      options
    );
export const TrainingJourneyDocument = `
    query trainingJourney($input: TrainingJourneyInput!, $role: UserRoleInput, $_cacheKey: String) {
  trainingJourney(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on TrainingJourneyResult {
      result {
        steps {
          ... on TrainingBookedStep {
            ...trainingBookedStepFields
          }
          ... on PreworkStep {
            ...preworkStepFields
          }
          ... on AttendTrainingStep {
            ...attendTrainingStepFields
          }
          ... on SubmitOutcomeTrackStep {
            ...submitOutcomeTrackStepFields
          }
          ... on ReceivedTrainingOutcomeStep {
            ...receivedTrainingOutcomeStepFields
          }
          ... on SubmitCertificationVideoStep {
            ...submitCertificationVideoStepFields
          }
          ... on ReceivedCertificationOutcomeStep {
            ...receivedCertificationOutcomeStepFields
          }
          ... on CertifiedStep {
            ...certifiedStepFields
          }
          ... on ContactUsStep {
            activeStepName
            activeStepNumber
            stepName
            stepNumber
            contactUsEmail
          }
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${TrainingBookedStepFieldsFragmentDoc}
${PreworkStepFieldsFragmentDoc}
${AttendTrainingStepFieldsFragmentDoc}
${SubmitOutcomeTrackStepFieldsFragmentDoc}
${ReceivedTrainingOutcomeStepFieldsFragmentDoc}
${SubmitCertificationVideoStepFieldsFragmentDoc}
${ReceivedCertificationOutcomeStepFieldsFragmentDoc}
${CertifiedStepFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useTrainingJourneyQuery = <
      TData = TrainingJourneyQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: TrainingJourneyQueryVariables, 
      options?: UseQueryOptions<TrainingJourneyQuery, TError, TData>
    ) => 
    useQuery<TrainingJourneyQuery, TError, TData>(
      ['trainingJourney', variables],
      fetcher<TrainingJourneyQuery, TrainingJourneyQueryVariables>(client, TrainingJourneyDocument, variables),
      options
    );
export const TrainingJourneysDocument = `
    query trainingJourneys($_cacheKey: String) {
  trainingJourneys(_cacheKey: $_cacheKey) {
    ... on TrainingJourneysResult {
      trainings {
        id
        eventName
        deliveryMethod
        startDate
        endDate
        timezone
        cecCertificationUrl
        venueAddress
        activeStepName
        activeStepNumber
        dueDate
        programName
        isContactUs
        dateCertified
      }
      eventsAndTrainingUrl
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useTrainingJourneysQuery = <
      TData = TrainingJourneysQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: TrainingJourneysQueryVariables, 
      options?: UseQueryOptions<TrainingJourneysQuery, TError, TData>
    ) => 
    useQuery<TrainingJourneysQuery, TError, TData>(
      ['trainingJourneys', variables],
      fetcher<TrainingJourneysQuery, TrainingJourneysQueryVariables>(client, TrainingJourneysDocument, variables),
      options
    );
export const RequestForExtensionDocument = `
    mutation requestForExtension($input: RequestForExtensionInput!, $_cacheKey: String) {
  requestForExtension(input: $input, _cacheKey: $_cacheKey) {
    ... on RequestForExtensionResult {
      isSuccess
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useRequestForExtensionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<RequestForExtensionMutation, TError, RequestForExtensionMutationVariables, TContext>
    ) => 
    useMutation<RequestForExtensionMutation, TError, RequestForExtensionMutationVariables, TContext>(
      (variables?: RequestForExtensionMutationVariables) => fetcher<RequestForExtensionMutation, RequestForExtensionMutationVariables>(client, RequestForExtensionDocument, variables)(),
      options
    );
export const SearchEventsDocument = `
    query searchEvents($searchInfo: SearchEventsInput!, $filterInfo: FilterEventsInput!, $_cacheKey: String) {
  searchEvents(
    searchInfo: $searchInfo
    filterInfo: $filterInfo
    _cacheKey: $_cacheKey
  ) {
    ... on EventsResult {
      edges {
        cursor
        node {
          eventId
          eventName
          startDate
          endDate
          deliveryMethod
          spacesAvailable
          venueAddress
          programName
          programCode
        }
      }
      pageInfo {
        endCursor {
          eventId
          sortValues
        }
        hasNextPage
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useSearchEventsQuery = <
      TData = SearchEventsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: SearchEventsQueryVariables, 
      options?: UseQueryOptions<SearchEventsQuery, TError, TData>
    ) => 
    useQuery<SearchEventsQuery, TError, TData>(
      ['searchEvents', variables],
      fetcher<SearchEventsQuery, SearchEventsQueryVariables>(client, SearchEventsDocument, variables),
      options
    );
export const AddClubListingDocument = `
    mutation addClubListing($input: AddClubListingInput!, $_cacheKey: String) {
  addClubListing(input: $input, _cacheKey: $_cacheKey) {
    ... on ClubListingResult {
      results {
        ... on ClubSingleListing {
          ...singleListingFields
        }
        ... on ClubMultiListing {
          ...multiListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useAddClubListingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<AddClubListingMutation, TError, AddClubListingMutationVariables, TContext>
    ) => 
    useMutation<AddClubListingMutation, TError, AddClubListingMutationVariables, TContext>(
      (variables?: AddClubListingMutationVariables) => fetcher<AddClubListingMutation, AddClubListingMutationVariables>(client, AddClubListingDocument, variables)(),
      options
    );
export const AddEmergencyContactDocument = `
    mutation addEmergencyContact($input: EmergencyContactInput!, $_cacheKey: String) {
  addEmergencyContact(input: $input, _cacheKey: $_cacheKey) {
    ... on AddEmergencyContactResult {
      result {
        address
        name
        phone
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useAddEmergencyContactMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<AddEmergencyContactMutation, TError, AddEmergencyContactMutationVariables, TContext>
    ) => 
    useMutation<AddEmergencyContactMutation, TError, AddEmergencyContactMutationVariables, TContext>(
      (variables?: AddEmergencyContactMutationVariables) => fetcher<AddEmergencyContactMutation, AddEmergencyContactMutationVariables>(client, AddEmergencyContactDocument, variables)(),
      options
    );
export const AddToShortlistDocument = `
    mutation addToShortlist($input: AddToShortlistInput!, $role: UserRoleInput!, $_cacheKey: String) {
  addToShortlist(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on AddToShortlistResult {
      shortlist {
        id
        profile {
          ...instructorProfileFields
        }
        user {
          id
          lastName
          firstName
        }
        lastMessageIn48HoursSentAt
        distanceUnit
        distance
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useAddToShortlistMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<AddToShortlistMutation, TError, AddToShortlistMutationVariables, TContext>
    ) => 
    useMutation<AddToShortlistMutation, TError, AddToShortlistMutationVariables, TContext>(
      (variables?: AddToShortlistMutationVariables) => fetcher<AddToShortlistMutation, AddToShortlistMutationVariables>(client, AddToShortlistDocument, variables)(),
      options
    );
export const CreateAffiliationDocument = `
    mutation createAffiliation($input: CreateAffiliationInput!, $role: UserRoleInput!, $_cacheKey: String) {
  createAffiliation(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on AffiliationAsyncResult {
      status
      affilation {
        __typename
        actionedDate
        clubId
        id
        latestActionInitiator
        pendingStatus
        requestedBy
        requestedDate
        role
        status
        userId
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useCreateAffiliationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateAffiliationMutation, TError, CreateAffiliationMutationVariables, TContext>
    ) => 
    useMutation<CreateAffiliationMutation, TError, CreateAffiliationMutationVariables, TContext>(
      (variables?: CreateAffiliationMutationVariables) => fetcher<CreateAffiliationMutation, CreateAffiliationMutationVariables>(client, CreateAffiliationDocument, variables)(),
      options
    );
export const CreateClubListingsDocument = `
    mutation createClubListings($input: CreateClubListingsInput!, $_cacheKey: String) {
  createClubListings(input: $input, _cacheKey: $_cacheKey) {
    ... on CreateClubListingsResult {
      successfulListings {
        ... on ClubSingleListing {
          ...singleListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useCreateClubListingsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateClubListingsMutation, TError, CreateClubListingsMutationVariables, TContext>
    ) => 
    useMutation<CreateClubListingsMutation, TError, CreateClubListingsMutationVariables, TContext>(
      (variables?: CreateClubListingsMutationVariables) => fetcher<CreateClubListingsMutation, CreateClubListingsMutationVariables>(client, CreateClubListingsDocument, variables)(),
      options
    );
export const DeleteAffiliationDocument = `
    mutation deleteAffiliation($input: DeleteAffiliationInput!, $role: UserRoleInput!, $_cacheKey: String) {
  deleteAffiliation(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on AffiliationAsyncResult {
      status
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useDeleteAffiliationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<DeleteAffiliationMutation, TError, DeleteAffiliationMutationVariables, TContext>
    ) => 
    useMutation<DeleteAffiliationMutation, TError, DeleteAffiliationMutationVariables, TContext>(
      (variables?: DeleteAffiliationMutationVariables) => fetcher<DeleteAffiliationMutation, DeleteAffiliationMutationVariables>(client, DeleteAffiliationDocument, variables)(),
      options
    );
export const DeleteClubListingDocument = `
    mutation deleteClubListing($input: DeleteClubListingInput!, $_cacheKey: String) {
  deleteClubListing(input: $input, _cacheKey: $_cacheKey) {
    ... on DeleteClubListingResult {
      clubId
      status
      listingId
      results {
        ... on ClubSingleListing {
          ...singleListingFields
        }
        ... on ClubMultiListing {
          ...multiListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useDeleteClubListingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<DeleteClubListingMutation, TError, DeleteClubListingMutationVariables, TContext>
    ) => 
    useMutation<DeleteClubListingMutation, TError, DeleteClubListingMutationVariables, TContext>(
      (variables?: DeleteClubListingMutationVariables) => fetcher<DeleteClubListingMutation, DeleteClubListingMutationVariables>(client, DeleteClubListingDocument, variables)(),
      options
    );
export const DeleteCurrentUserPortfolioImageDocument = `
    mutation deleteCurrentUserPortfolioImage($input: DeleteCurrentUserPortfolioImageInput!, $role: UserRoleInput!, $_cacheKey: String) {
  deleteCurrentUserPortfolioImage(
    input: $input
    role: $role
    _cacheKey: $_cacheKey
  ) {
    ... on DeleteCurrentUserPortfolioImageResult {
      status
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useDeleteCurrentUserPortfolioImageMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<DeleteCurrentUserPortfolioImageMutation, TError, DeleteCurrentUserPortfolioImageMutationVariables, TContext>
    ) => 
    useMutation<DeleteCurrentUserPortfolioImageMutation, TError, DeleteCurrentUserPortfolioImageMutationVariables, TContext>(
      (variables?: DeleteCurrentUserPortfolioImageMutationVariables) => fetcher<DeleteCurrentUserPortfolioImageMutation, DeleteCurrentUserPortfolioImageMutationVariables>(client, DeleteCurrentUserPortfolioImageDocument, variables)(),
      options
    );
export const DeleteMultipleJobListingsDocument = `
    mutation deleteMultipleJobListings($input: DeleteClubListingsInput!, $_cacheKey: String) {
  deleteClubListings(input: $input, _cacheKey: $_cacheKey) {
    ... on DeleteClubListingsResult {
      success {
        ... on ClubSingleListing {
          ...singleListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useDeleteMultipleJobListingsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<DeleteMultipleJobListingsMutation, TError, DeleteMultipleJobListingsMutationVariables, TContext>
    ) => 
    useMutation<DeleteMultipleJobListingsMutation, TError, DeleteMultipleJobListingsMutationVariables, TContext>(
      (variables?: DeleteMultipleJobListingsMutationVariables) => fetcher<DeleteMultipleJobListingsMutation, DeleteMultipleJobListingsMutationVariables>(client, DeleteMultipleJobListingsDocument, variables)(),
      options
    );
export const DigitalOnlyInStudioEnquireDocument = `
    mutation digitalOnlyInStudioEnquire($input: EnquireAboutInStudioProgramsInput!, $role: UserRoleInput!, $_cacheKey: String) {
  enquireAboutInStudioPrograms(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on EnquireAboutInStudioProgramsResult {
      isSuccess
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useDigitalOnlyInStudioEnquireMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<DigitalOnlyInStudioEnquireMutation, TError, DigitalOnlyInStudioEnquireMutationVariables, TContext>
    ) => 
    useMutation<DigitalOnlyInStudioEnquireMutation, TError, DigitalOnlyInStudioEnquireMutationVariables, TContext>(
      (variables?: DigitalOnlyInStudioEnquireMutationVariables) => fetcher<DigitalOnlyInStudioEnquireMutation, DigitalOnlyInStudioEnquireMutationVariables>(client, DigitalOnlyInStudioEnquireDocument, variables)(),
      options
    );
export const EnquireAboutOfferingDocument = `
    mutation enquireAboutOffering($input: EnquireAboutOnlineProgramInput!, $role: UserRoleInput!, $_cacheKey: String) {
  enquireAboutOnlineProgram(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on EnquireAboutOnlineProgramResult {
      lastEnquiredOn
      onlineProgramid
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useEnquireAboutOfferingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<EnquireAboutOfferingMutation, TError, EnquireAboutOfferingMutationVariables, TContext>
    ) => 
    useMutation<EnquireAboutOfferingMutation, TError, EnquireAboutOfferingMutationVariables, TContext>(
      (variables?: EnquireAboutOfferingMutationVariables) => fetcher<EnquireAboutOfferingMutation, EnquireAboutOfferingMutationVariables>(client, EnquireAboutOfferingDocument, variables)(),
      options
    );
export const EnquireAboutProgramDocument = `
    mutation enquireAboutProgram($input: EnquireAboutProgramInput!, $role: UserRoleInput!, $_cacheKey: String) {
  enquireAboutProgram(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on EnquireAboutProgramResult {
      lastEnquiredOn
      programId
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useEnquireAboutProgramMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<EnquireAboutProgramMutation, TError, EnquireAboutProgramMutationVariables, TContext>
    ) => 
    useMutation<EnquireAboutProgramMutation, TError, EnquireAboutProgramMutationVariables, TContext>(
      (variables?: EnquireAboutProgramMutationVariables) => fetcher<EnquireAboutProgramMutation, EnquireAboutProgramMutationVariables>(client, EnquireAboutProgramDocument, variables)(),
      options
    );
export const EnquireAboutProgramInExploreProgramsDocument = `
    mutation enquireAboutProgramInExplorePrograms($input: EnquireAboutExploreProgramsInput!, $role: UserRoleInput!, $_cacheKey: String) {
  enquireAboutProgramInExplorePrograms(
    input: $input
    role: $role
    _cacheKey: $_cacheKey
  ) {
    ... on EnquireAboutExploreProgramsResult {
      lastEnquiredOn
      programId
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useEnquireAboutProgramInExploreProgramsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<EnquireAboutProgramInExploreProgramsMutation, TError, EnquireAboutProgramInExploreProgramsMutationVariables, TContext>
    ) => 
    useMutation<EnquireAboutProgramInExploreProgramsMutation, TError, EnquireAboutProgramInExploreProgramsMutationVariables, TContext>(
      (variables?: EnquireAboutProgramInExploreProgramsMutationVariables) => fetcher<EnquireAboutProgramInExploreProgramsMutation, EnquireAboutProgramInExploreProgramsMutationVariables>(client, EnquireAboutProgramInExploreProgramsDocument, variables)(),
      options
    );
export const GetAgreementDocumentDocument = `
    query getAgreementDocument($input: GetSignedAgreementInput!, $_cacheKey: String, $versionOnly: Boolean = false) {
  getAgreementDocument(input: $input, _cacheKey: $_cacheKey) {
    ... on AgreementDocumentResult {
      version
      content @skip(if: $versionOnly)
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useGetAgreementDocumentQuery = <
      TData = GetAgreementDocumentQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetAgreementDocumentQueryVariables, 
      options?: UseQueryOptions<GetAgreementDocumentQuery, TError, TData>
    ) => 
    useQuery<GetAgreementDocumentQuery, TError, TData>(
      ['getAgreementDocument', variables],
      fetcher<GetAgreementDocumentQuery, GetAgreementDocumentQueryVariables>(client, GetAgreementDocumentDocument, variables),
      options
    );
export const GetChainAffiliationDocument = `
    query getChainAffiliation($input: GetChainAffiliationsInput!, $_cacheKey: String) {
  getChainAffiliations(input: $input, _cacheKey: $_cacheKey) {
    ... on GetChainAffiliationsResult {
      clubs {
        ...clubWithAffiliation
      }
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${ClubWithAffiliationFragmentDoc}`;
export const useGetChainAffiliationQuery = <
      TData = GetChainAffiliationQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetChainAffiliationQueryVariables, 
      options?: UseQueryOptions<GetChainAffiliationQuery, TError, TData>
    ) => 
    useQuery<GetChainAffiliationQuery, TError, TData>(
      ['getChainAffiliation', variables],
      fetcher<GetChainAffiliationQuery, GetChainAffiliationQueryVariables>(client, GetChainAffiliationDocument, variables),
      options
    );
export const GetChainAffiliatedInstructorDocument = `
    query getChainAffiliatedInstructor($input: GetChainInstructorAffiliatesInput, $_cacheKey: String) {
  getChainInstructorAffiliates(input: $input, _cacheKey: $_cacheKey) {
    ... on GetChainInstructorAffiliatesResult {
      __typename
      affiliates {
        clubAffiliates {
          ... on ClubAffiliates {
            ...managePeopleClubAffiliatesFields
          }
        }
        profile {
          ... on UserProfileTeaching {
            ...instructorProfileFields
          }
        }
        purchaseOrder
        user {
          ... on User {
            ...managePeopleUserAffiliateFields
          }
        }
      }
      hasPendingAffiliations
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ManagePeopleClubAffiliatesFieldsFragmentDoc}
${InstructorProfileFieldsFragmentDoc}
${ManagePeopleUserAffiliateFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetChainAffiliatedInstructorQuery = <
      TData = GetChainAffiliatedInstructorQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetChainAffiliatedInstructorQueryVariables, 
      options?: UseQueryOptions<GetChainAffiliatedInstructorQuery, TError, TData>
    ) => 
    useQuery<GetChainAffiliatedInstructorQuery, TError, TData>(
      ['getChainAffiliatedInstructor', variables],
      fetcher<GetChainAffiliatedInstructorQuery, GetChainAffiliatedInstructorQueryVariables>(client, GetChainAffiliatedInstructorDocument, variables),
      options
    );
export const GetChainInstructorRequestsDocument = `
    query getChainInstructorRequests($input: GetChainInstructorAffiliatesInput!, $_cacheKey: String) {
  getChainInstructorAffiliates(input: $input, _cacheKey: $_cacheKey) {
    ... on GetChainInstructorAffiliatesResult {
      __typename
      requests {
        clubId
        clubName
        distance
        distanceUnit
        id
        pendingStatus
        profile {
          ... on UserProfileTeaching {
            ...instructorProfileFields
          }
        }
        requestedBy
        requestedDate
        status
        user {
          ... on User {
            ...managePeopleUserAffiliateFields
          }
        }
      }
      hasPendingAffiliations
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ManagePeopleUserAffiliateFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetChainInstructorRequestsQuery = <
      TData = GetChainInstructorRequestsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetChainInstructorRequestsQueryVariables, 
      options?: UseQueryOptions<GetChainInstructorRequestsQuery, TError, TData>
    ) => 
    useQuery<GetChainInstructorRequestsQuery, TError, TData>(
      ['getChainInstructorRequests', variables],
      fetcher<GetChainInstructorRequestsQuery, GetChainInstructorRequestsQueryVariables>(client, GetChainInstructorRequestsDocument, variables),
      options
    );
export const GetChainUserDocument = `
    query getChainUser($_cacheKey: String) {
  getChainUser(_cacheKey: $_cacheKey) {
    ... on GetChainUser {
      chainLocations {
        ...clubProfileFields
      }
      isChainUser
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${ClubProfileFieldsFragmentDoc}`;
export const useGetChainUserQuery = <
      TData = GetChainUserQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetChainUserQueryVariables, 
      options?: UseQueryOptions<GetChainUserQuery, TError, TData>
    ) => 
    useQuery<GetChainUserQuery, TError, TData>(
      ['getChainUser', variables],
      fetcher<GetChainUserQuery, GetChainUserQueryVariables>(client, GetChainUserDocument, variables),
      options
    );
export const GetChainUserClubListingsDocument = `
    query getChainUserClubListings($input: GetChainUserClubListingsInput!, $_cacheKey: String) {
  getChainUserClubListings(input: $input, _cacheKey: $_cacheKey) {
    ... on GetChainUserClubListingsResult {
      singleListings {
        ... on ClubSingleListing {
          ...singleListingFields
        }
      }
      multiListings {
        ... on ClubMultiListing {
          ...multiListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetChainUserClubListingsQuery = <
      TData = GetChainUserClubListingsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetChainUserClubListingsQueryVariables, 
      options?: UseQueryOptions<GetChainUserClubListingsQuery, TError, TData>
    ) => 
    useQuery<GetChainUserClubListingsQuery, TError, TData>(
      ['getChainUserClubListings', variables],
      fetcher<GetChainUserClubListingsQuery, GetChainUserClubListingsQueryVariables>(client, GetChainUserClubListingsDocument, variables),
      options
    );
export const GetClubBusinessSupportContentDocument = `
    query getClubBusinessSupportContent($input: GetClubByIdInput!) {
  getClubById(input: $input) {
    ... on UserProfileClub {
      businessSupport {
        ...BusinessSupportSectionContent
      }
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${BusinessSupportSectionContentFragmentDoc}`;
export const useGetClubBusinessSupportContentQuery = <
      TData = GetClubBusinessSupportContentQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetClubBusinessSupportContentQueryVariables, 
      options?: UseQueryOptions<GetClubBusinessSupportContentQuery, TError, TData>
    ) => 
    useQuery<GetClubBusinessSupportContentQuery, TError, TData>(
      ['getClubBusinessSupportContent', variables],
      fetcher<GetClubBusinessSupportContentQuery, GetClubBusinessSupportContentQueryVariables>(client, GetClubBusinessSupportContentDocument, variables),
      options
    );
export const GetClubInstructorAffiliatesDocument = `
    query getClubInstructorAffiliates($input: GetClubInstructorAffiliatesInput!, $_cacheKey: String) {
  getClubInstructorAffiliates(input: $input, _cacheKey: $_cacheKey) {
    ... on GetClubInstructorAffiliatesResult {
      __typename
      affiliates {
        purchaseOrder
        actionedDate
        id
        requestedBy
        requestedDate
        status
        user {
          id
          phone
          email
          accountId
          market {
            name
            locale
            distanceUnit
          }
          lastName
          firstName
        }
        clubId
        profile {
          ... on UserProfileTeaching {
            ...instructorProfileFields
          }
        }
      }
      requests {
        id
        distance
        distanceUnit
        requestedDate
        requestedBy
        status
        pendingStatus
        user {
          id
          phone
          email
          accountId
          market {
            name
            locale
            distanceUnit
          }
          lastName
          firstName
        }
        clubId
        profile {
          ... on UserProfileTeaching {
            ...instructorProfileFields
          }
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetClubInstructorAffiliatesQuery = <
      TData = GetClubInstructorAffiliatesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetClubInstructorAffiliatesQueryVariables, 
      options?: UseQueryOptions<GetClubInstructorAffiliatesQuery, TError, TData>
    ) => 
    useQuery<GetClubInstructorAffiliatesQuery, TError, TData>(
      ['getClubInstructorAffiliates', variables],
      fetcher<GetClubInstructorAffiliatesQuery, GetClubInstructorAffiliatesQueryVariables>(client, GetClubInstructorAffiliatesDocument, variables),
      options
    );
export const GetClubListingByIdDocument = `
    query getClubListingById($listingId: String!) {
  getClubListingById(listingId: $listingId) {
    ... on SearchClubJobListingsSingleResult {
      distance
      distanceUnit
      messageSentOn
      enquiredOn
      onWatchlist
      hasBeenReported
      listing {
        ... on ClubSingleListing {
          ...singleListingFields
        }
        ... on ClubMultiListing {
          ...multiListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetClubListingByIdQuery = <
      TData = GetClubListingByIdQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetClubListingByIdQueryVariables, 
      options?: UseQueryOptions<GetClubListingByIdQuery, TError, TData>
    ) => 
    useQuery<GetClubListingByIdQuery, TError, TData>(
      ['getClubListingById', variables],
      fetcher<GetClubListingByIdQuery, GetClubListingByIdQueryVariables>(client, GetClubListingByIdDocument, variables),
      options
    );
export const GetClubListingsByClubIdDocument = `
    query getClubListingsByClubId($input: GetClubListingsByClubIdInput!, $_cacheKey: String) {
  getClubListingsByClubId(input: $input, _cacheKey: $_cacheKey) {
    ... on GetClubListingsByClubIdResult {
      results {
        ... on ClubSingleListing {
          ...singleListingFields
        }
        ... on ClubMultiListing {
          ...multiListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetClubListingsByClubIdQuery = <
      TData = GetClubListingsByClubIdQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetClubListingsByClubIdQueryVariables, 
      options?: UseQueryOptions<GetClubListingsByClubIdQuery, TError, TData>
    ) => 
    useQuery<GetClubListingsByClubIdQuery, TError, TData>(
      ['getClubListingsByClubId', variables],
      fetcher<GetClubListingsByClubIdQuery, GetClubListingsByClubIdQueryVariables>(client, GetClubListingsByClubIdDocument, variables),
      options
    );
export const GetClubSettingsDocument = `
    query getClubSettings($input: GetClubSettingsInput!, $_cacheKey: String) {
  getClubSettings(input: $input, _cacheKey: $_cacheKey) {
    ... on GetClubSettingsResult {
      id
      address {
        city
        state
        street
        country
        stateCode
        postalCode
        countryCode
        location {
          lat
          lon
        }
      }
      email {
        main
        affiliations
        jobListings
      }
      id
      name
      phone
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useGetClubSettingsQuery = <
      TData = GetClubSettingsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetClubSettingsQueryVariables, 
      options?: UseQueryOptions<GetClubSettingsQuery, TError, TData>
    ) => 
    useQuery<GetClubSettingsQuery, TError, TData>(
      ['getClubSettings', variables],
      fetcher<GetClubSettingsQuery, GetClubSettingsQueryVariables>(client, GetClubSettingsDocument, variables),
      options
    );
export const GetContactUsContentDocument = `
    query getContactUsContent($input: GetContactUsContentInput!, $_cacheKey: String) {
  getContactUsContent(input: $input, _cacheKey: $_cacheKey) {
    ... on GetContactUsContentResult {
      result {
        section
        content {
          title
          subHeading
          contentText
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useGetContactUsContentQuery = <
      TData = GetContactUsContentQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetContactUsContentQueryVariables, 
      options?: UseQueryOptions<GetContactUsContentQuery, TError, TData>
    ) => 
    useQuery<GetContactUsContentQuery, TError, TData>(
      ['getContactUsContent', variables],
      fetcher<GetContactUsContentQuery, GetContactUsContentQueryVariables>(client, GetContactUsContentDocument, variables),
      options
    );
export const GetCurrentUserDocument = `
    query getCurrentUser($_cacheKey: String) {
  getCurrentUser(_cacheKey: $_cacheKey) {
    ... on UserResult {
      ...fullUserField
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${FullUserFieldFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetCurrentUserQuery = <
      TData = GetCurrentUserQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetCurrentUserQueryVariables, 
      options?: UseQueryOptions<GetCurrentUserQuery, TError, TData>
    ) => 
    useQuery<GetCurrentUserQuery, TError, TData>(
      ['getCurrentUser', variables],
      fetcher<GetCurrentUserQuery, GetCurrentUserQueryVariables>(client, GetCurrentUserDocument, variables),
      options
    );
export const GetCurrentUserPhotoUploadDataDocument = `
    query getCurrentUserPhotoUploadData($_cacheKey: String) {
  getCurrentUserPhotoUploadData(_cacheKey: $_cacheKey) {
    ... on GetCurrentUserPhotoUploadDataResult {
      url
      token
      __typename
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useGetCurrentUserPhotoUploadDataQuery = <
      TData = GetCurrentUserPhotoUploadDataQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetCurrentUserPhotoUploadDataQueryVariables, 
      options?: UseQueryOptions<GetCurrentUserPhotoUploadDataQuery, TError, TData>
    ) => 
    useQuery<GetCurrentUserPhotoUploadDataQuery, TError, TData>(
      ['getCurrentUserPhotoUploadData', variables],
      fetcher<GetCurrentUserPhotoUploadDataQuery, GetCurrentUserPhotoUploadDataQueryVariables>(client, GetCurrentUserPhotoUploadDataDocument, variables),
      options
    );
export const GetCurrentUserPortfolioUploadDataDocument = `
    query getCurrentUserPortfolioUploadData($input: GetCurrentUserPortfolioUploadDataInput!, $_cacheKey: String) {
  getCurrentUserPortfolioUploadData(input: $input, _cacheKey: $_cacheKey) {
    ... on GetCurrentUserPortfolioUploadDataResult {
      items {
        url
        token
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useGetCurrentUserPortfolioUploadDataQuery = <
      TData = GetCurrentUserPortfolioUploadDataQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetCurrentUserPortfolioUploadDataQueryVariables, 
      options?: UseQueryOptions<GetCurrentUserPortfolioUploadDataQuery, TError, TData>
    ) => 
    useQuery<GetCurrentUserPortfolioUploadDataQuery, TError, TData>(
      ['getCurrentUserPortfolioUploadData', variables],
      fetcher<GetCurrentUserPortfolioUploadDataQuery, GetCurrentUserPortfolioUploadDataQueryVariables>(client, GetCurrentUserPortfolioUploadDataDocument, variables),
      options
    );
export const GetDashboardDocument = `
    query getDashboard($role: UserRoleInput!, $_cacheKey: String) {
  __typename
  getDashboard(role: $role, _cacheKey: $_cacheKey) {
    __typename
    ... on DashboardPageInstructorResponse {
      id
      translation {
        title
      }
      socialMedia {
        title
        items {
          id
          url
          type
          createdAt
          createdAtLabel
          content {
            type
            value
          }
        }
      }
      links {
        id
        imageSet {
          id
          desktop {
            id
            alt
            url
          }
          mobile {
            id
            alt
            url
          }
        }
        title
        subtitle
        url {
          type
          value
        }
      }
      translation {
        title
      }
      certifications {
        id
        status
        active
        lmqLevel
        link {
          id
          url {
            type
            value
          }
          text
        }
        steps {
          id
          label
          status
          action {
            id
            url {
              value
              type
            }
            text
          }
        }
        program {
          id
          name
          code
          initials
        }
      }
      promotions {
        id
        title
        image {
          id
          desktop {
            id
            alt
            url
            width
            height
          }
          mobile {
            id
            alt
            url
            width
            height
          }
        }
        description
        link {
          id
          text
          url {
            value
            type
          }
        }
      }
    }
    ... on DashboardPageClubResponse {
      id
      links {
        id
        imageSet {
          id
          desktop {
            id
            alt
            url
          }
          mobile {
            id
            alt
            url
          }
        }
        title
        subtitle
        url {
          type
          value
        }
      }
      promotions {
        id
        title
        image {
          id
          desktop {
            id
            alt
            url
            width
            height
          }
          mobile {
            id
            alt
            url
            width
            height
          }
        }
        description
        link {
          id
          text
          url {
            value
            type
          }
        }
      }
      partnership {
        ... on ClubPartnershipProgramStats {
          ...ClubDashboardProgramStats
        }
        ... on ClubPartnershipStandardContent {
          ...ClubDashboardStandardContent
        }
        ... on ClubPartnershipBusinessSupportContent {
          ...ClubDashboardBusinessSupportContent
        }
        ... on ClubPartnershipBusinessSupportContent {
          ...ClubDashboardBusinessSupportContent
        }
      }
      myTools {
        id
        imageSet {
          id
          desktop {
            id
            alt
            url
          }
          mobile {
            id
            alt
            url
          }
        }
        title
        subtitle
        url {
          type
          value
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ClubDashboardProgramStatsFragmentDoc}
${ClubDashboardStandardContentFragmentDoc}
${ClubDashboardBusinessSupportContentFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetDashboardQuery = <
      TData = GetDashboardQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetDashboardQueryVariables, 
      options?: UseQueryOptions<GetDashboardQuery, TError, TData>
    ) => 
    useQuery<GetDashboardQuery, TError, TData>(
      ['getDashboard', variables],
      fetcher<GetDashboardQuery, GetDashboardQueryVariables>(client, GetDashboardDocument, variables),
      options
    );
export const GetEqualOpportunitiesPolicyDocument = `
    query getEqualOpportunitiesPolicy($input: GetEqualOpportunitiesPolicyInput!, $_cacheKey: String) {
  getEqualOpportunitiesPolicy(input: $input, _cacheKey: $_cacheKey) {
    ... on GetEqualOpportunitiesPolicyResult {
      result {
        section
        content {
          title
          subHeading
          contentText
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useGetEqualOpportunitiesPolicyQuery = <
      TData = GetEqualOpportunitiesPolicyQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetEqualOpportunitiesPolicyQueryVariables, 
      options?: UseQueryOptions<GetEqualOpportunitiesPolicyQuery, TError, TData>
    ) => 
    useQuery<GetEqualOpportunitiesPolicyQuery, TError, TData>(
      ['getEqualOpportunitiesPolicy', variables],
      fetcher<GetEqualOpportunitiesPolicyQuery, GetEqualOpportunitiesPolicyQueryVariables>(client, GetEqualOpportunitiesPolicyDocument, variables),
      options
    );
export const GetFrequentlyAskedQuestionsDocument = `
    query getFrequentlyAskedQuestions($input: GetFrequentlyAskedQuestionsInput!, $_cacheKey: String) {
  getFrequentlyAskedQuestions(input: $input, _cacheKey: $_cacheKey) {
    ... on GetFrequentlyAskedQuestionsResult {
      result {
        section
        content {
          title
          subHeading
          contentText
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useGetFrequentlyAskedQuestionsQuery = <
      TData = GetFrequentlyAskedQuestionsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetFrequentlyAskedQuestionsQueryVariables, 
      options?: UseQueryOptions<GetFrequentlyAskedQuestionsQuery, TError, TData>
    ) => 
    useQuery<GetFrequentlyAskedQuestionsQuery, TError, TData>(
      ['getFrequentlyAskedQuestions', variables],
      fetcher<GetFrequentlyAskedQuestionsQuery, GetFrequentlyAskedQuestionsQueryVariables>(client, GetFrequentlyAskedQuestionsDocument, variables),
      options
    );
export const GetHeaderContentDocument = `
    query getHeaderContent($input: GetHeaderInput!, $_cacheKey: String) {
  getHeaders(input: $input, _cacheKey: $_cacheKey) {
    ... on GetHeaderResult {
      __typename
      results {
        image {
          id
          desktop {
            id
            alt
            url
          }
          mobile {
            id
            alt
            url
          }
        }
        key
        title
        subHeading
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useGetHeaderContentQuery = <
      TData = GetHeaderContentQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetHeaderContentQueryVariables, 
      options?: UseQueryOptions<GetHeaderContentQuery, TError, TData>
    ) => 
    useQuery<GetHeaderContentQuery, TError, TData>(
      ['getHeaderContent', variables],
      fetcher<GetHeaderContentQuery, GetHeaderContentQueryVariables>(client, GetHeaderContentDocument, variables),
      options
    );
export const GetInStudioManageOptionsDocument = `
    query getInStudioManageOptions($input: GetPickListInput!) {
  getPickLists(input: $input) {
    ... on GetPickListResult {
      __typename
      ...InStudioManageOptions
    }
    ... on ErrorResult {
      __typename
      ...errorResultFields
    }
  }
}
    ${InStudioManageOptionsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetInStudioManageOptionsQuery = <
      TData = GetInStudioManageOptionsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetInStudioManageOptionsQueryVariables, 
      options?: UseQueryOptions<GetInStudioManageOptionsQuery, TError, TData>
    ) => 
    useQuery<GetInStudioManageOptionsQuery, TError, TData>(
      ['getInStudioManageOptions', variables],
      fetcher<GetInStudioManageOptionsQuery, GetInStudioManageOptionsQueryVariables>(client, GetInStudioManageOptionsDocument, variables),
      options
    );
export const GetUserAccountDocument = `
    query getUserAccount($input: GetUserByIdInput!, $role: UserRoleInput!, $_cacheKey: String) {
  __typename
  getUserAccount(input: $input, role: $role, _cacheKey: $_cacheKey) {
    __typename
    ... on UserAccountSettingsResult {
      id
      address {
        ...addressFields
      }
      email
      firstName
      lastName
      outlinks {
        url
        title
        _metadata {
          urlSource
        }
      }
      phone
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${AddressFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetUserAccountQuery = <
      TData = GetUserAccountQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetUserAccountQueryVariables, 
      options?: UseQueryOptions<GetUserAccountQuery, TError, TData>
    ) => 
    useQuery<GetUserAccountQuery, TError, TData>(
      ['getUserAccount', variables],
      fetcher<GetUserAccountQuery, GetUserAccountQueryVariables>(client, GetUserAccountDocument, variables),
      options
    );
export const GetInstructorEnquiriesDocument = `
    query getInstructorEnquiries($role: UserRoleInput!, $filter: UserEnquiriesFilter!, $_cacheKey: String) {
  getUserEnquiries(role: $role, filter: $filter, _cacheKey: $_cacheKey) {
    ... on GetUserEnquiriesResult {
      enquiries {
        listing {
          ... on EnquiryClubListingSingleResult {
            distance
            distanceUnit
            messageSentOn
            enquiredOn
            onWatchlist
            hasBeenReported
            listing {
              ... on ClubSingleListing {
                __typename
                clubInfo {
                  id
                  name
                  hiring
                  address {
                    ...addressFields
                  }
                  profileImage {
                    url
                  }
                  programs {
                    ... on ClubProgram {
                      name
                    }
                  }
                }
                ...singleListingFields
              }
              ... on ClubMultiListing {
                __typename
                clubInfo {
                  id
                  name
                  hiring
                  address {
                    ...addressFields
                  }
                  profileImage {
                    url
                  }
                  programs {
                    ... on ClubProgram {
                      name
                    }
                  }
                }
                ...multiListingFields
              }
              ... on ClubListingUnknown {
                __typename
                clubInfo {
                  id
                  name
                  hiring
                  address {
                    ...addressFields
                  }
                  profileImage {
                    url
                  }
                  programs {
                    ... on ClubProgram {
                      name
                    }
                  }
                }
                id
              }
            }
          }
        }
        message {
          clubId
          clubName
          id
          listingId
          message
          profileId
          readAt
          requestedBy
          sentAt
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${AddressFieldsFragmentDoc}
${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetInstructorEnquiriesQuery = <
      TData = GetInstructorEnquiriesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetInstructorEnquiriesQueryVariables, 
      options?: UseQueryOptions<GetInstructorEnquiriesQuery, TError, TData>
    ) => 
    useQuery<GetInstructorEnquiriesQuery, TError, TData>(
      ['getInstructorEnquiries', variables],
      fetcher<GetInstructorEnquiriesQuery, GetInstructorEnquiriesQueryVariables>(client, GetInstructorEnquiriesDocument, variables),
      options
    );
export const GetInstructorFacilitiesDocument = `
    query getInstructorFacilities($input: GetInstructorClubAffiliatesInput!, $_cacheKey: String) {
  getInstructorClubAffiliates(input: $input, _cacheKey: $_cacheKey) {
    ... on GetInstructorClubAffiliatesResult {
      __typename
      affiliations {
        actionedDate
        clubId
        distance
        distanceUnit
        id
        profile {
          ... on UserProfileClub {
            ...clubProfileFields
          }
        }
        requestedDate
        requestedBy
        status
        pendingStatus
        isDigitalOnly
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ClubProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetInstructorFacilitiesQuery = <
      TData = GetInstructorFacilitiesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetInstructorFacilitiesQueryVariables, 
      options?: UseQueryOptions<GetInstructorFacilitiesQuery, TError, TData>
    ) => 
    useQuery<GetInstructorFacilitiesQuery, TError, TData>(
      ['getInstructorFacilities', variables],
      fetcher<GetInstructorFacilitiesQuery, GetInstructorFacilitiesQueryVariables>(client, GetInstructorFacilitiesDocument, variables),
      options
    );
export const GetInstructorPortfolioDocument = `
    query getInstructorPortfolio($_cacheKey: String) {
  getCurrentUser(_cacheKey: $_cacheKey) {
    ... on UserResult {
      __typename
      profiles {
        ... on UserProfileTeaching {
          media {
            id
            url
          }
        }
        ... on UserProfileClub {
          media {
            id
            url
          }
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useGetInstructorPortfolioQuery = <
      TData = GetInstructorPortfolioQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetInstructorPortfolioQueryVariables, 
      options?: UseQueryOptions<GetInstructorPortfolioQuery, TError, TData>
    ) => 
    useQuery<GetInstructorPortfolioQuery, TError, TData>(
      ['getInstructorPortfolio', variables],
      fetcher<GetInstructorPortfolioQuery, GetInstructorPortfolioQueryVariables>(client, GetInstructorPortfolioDocument, variables),
      options
    );
export const GetInstructorWatchlistDocument = `
    query getInstructorWatchlist($role: UserRoleInput!, $_cacheKey: String) {
  getInstructorWatchlist(role: $role, _cacheKey: $_cacheKey) {
    ... on ErrorResult {
      ...errorResultFields
    }
    ... on SearchClubListingsResult {
      total
      results {
        ... on SearchClubJobListingsSingleResult {
          distance
          distanceUnit
          messageSentOn
          enquiredOn
          onWatchlist
          hasBeenReported
          listing {
            ... on ClubSingleListing {
              ...singleListingFields
            }
            ... on ClubMultiListing {
              ...multiListingFields
            }
          }
        }
        ... on SearchClubProfileSingleResult {
          distance
          distanceUnit
          onWatchlist
          hasBeenReported
          profile {
            ...clubProfileFields
          }
        }
      }
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}
${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}`;
export const useGetInstructorWatchlistQuery = <
      TData = GetInstructorWatchlistQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetInstructorWatchlistQueryVariables, 
      options?: UseQueryOptions<GetInstructorWatchlistQuery, TError, TData>
    ) => 
    useQuery<GetInstructorWatchlistQuery, TError, TData>(
      ['getInstructorWatchlist', variables],
      fetcher<GetInstructorWatchlistQuery, GetInstructorWatchlistQueryVariables>(client, GetInstructorWatchlistDocument, variables),
      options
    );
export const GetJobListingAvailableLocationsDocument = `
    query getJobListingAvailableLocations($input: GetClubsAvailabilityOnListingInput!, $_cacheKey: String) {
  getClubsAvailabilityOnListing(input: $input, _cacheKey: $_cacheKey) {
    ... on GetClubsAvailabilityOnListingResult {
      clubsWithExistingListing {
        ...locations
      }
      allClubs {
        ...locations
      }
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${LocationsFragmentDoc}`;
export const useGetJobListingAvailableLocationsQuery = <
      TData = GetJobListingAvailableLocationsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetJobListingAvailableLocationsQueryVariables, 
      options?: UseQueryOptions<GetJobListingAvailableLocationsQuery, TError, TData>
    ) => 
    useQuery<GetJobListingAvailableLocationsQuery, TError, TData>(
      ['getJobListingAvailableLocations', variables],
      fetcher<GetJobListingAvailableLocationsQuery, GetJobListingAvailableLocationsQueryVariables>(client, GetJobListingAvailableLocationsDocument, variables),
      options
    );
export const GetNavigationDocument = `
    query getNavigation($input: GetNavigationInput!, $_cacheKey: String) {
  getNavigation(input: $input, _cacheKey: $_cacheKey) {
    __typename
    ... on Navigation {
      __typename
      appMenu {
        id
        name
        items {
          icon
          id
          label
          analyticsEvent
          url {
            type
            value
          }
          items {
            id
            label
            analyticsEvent
            url {
              type
              value
            }
          }
        }
      }
      userMenu {
        icon
        id
        label
        analyticsEvent
        url {
          type
          value
        }
        items {
          id
          label
          analyticsEvent
          url {
            type
            value
          }
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useGetNavigationQuery = <
      TData = GetNavigationQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetNavigationQueryVariables, 
      options?: UseQueryOptions<GetNavigationQuery, TError, TData>
    ) => 
    useQuery<GetNavigationQuery, TError, TData>(
      ['getNavigation', variables],
      fetcher<GetNavigationQuery, GetNavigationQueryVariables>(client, GetNavigationDocument, variables),
      options
    );
export const GetNotificationsDocument = `
    query getNotifications($filter: DateTimeFilterInput!, $role: UserRoleInput!, $_cacheKey: String) {
  getNotifications(filter: $filter, role: $role, _cacheKey: $_cacheKey) {
    ... on GetNotificationsResult {
      ...notificationsResult
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${NotificationsResultFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetNotificationsQuery = <
      TData = GetNotificationsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetNotificationsQueryVariables, 
      options?: UseQueryOptions<GetNotificationsQuery, TError, TData>
    ) => 
    useQuery<GetNotificationsQuery, TError, TData>(
      ['getNotifications', variables],
      fetcher<GetNotificationsQuery, GetNotificationsQueryVariables>(client, GetNotificationsDocument, variables),
      options
    );
export const GetProfileDocument = `
    query getProfile($input: GetProfileByIdInput!, $role: UserRoleInput!, $_cacheKey: String) {
  getProfileById(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on GetProfileByIdResult {
      profile {
        ... on UserProfileTeaching {
          ...instructorProfileFields
        }
        ... on UserProfileClub {
          ...clubProfileFields
        }
      }
      hasBeenReported
      withinDistance
      lastMessageIn48HoursSentAt
      hasRecentlyDeclinedAffiliation
      hasExistingAffiliation
      onWatchlist
      instructorAffiliation {
        id
        clubId
        pendingStatus
        actionedDate
        requestedDate
        requestedBy
        role
        userId
        status
      }
      profileUser {
        id
        email
        market {
          name
          locale
          distanceUnit
          programs {
            id
            name
            code
            initials
          }
        }
        lastName
        firstName
        accountId
        phone
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetProfileQuery = <
      TData = GetProfileQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetProfileQueryVariables, 
      options?: UseQueryOptions<GetProfileQuery, TError, TData>
    ) => 
    useQuery<GetProfileQuery, TError, TData>(
      ['getProfile', variables],
      fetcher<GetProfileQuery, GetProfileQueryVariables>(client, GetProfileDocument, variables),
      options
    );
export const GetProfileMediaDocument = `
    query getProfileMedia($input: GetProfileByIdInput!, $role: UserRoleInput!, $_cacheKey: String) {
  getProfileById(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on GetProfileByIdResult {
      profile {
        ... on UserProfileTeaching {
          media {
            ...imageFields
          }
        }
        ... on UserProfileClub {
          media {
            ...imageFields
          }
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ImageFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetProfileMediaQuery = <
      TData = GetProfileMediaQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetProfileMediaQueryVariables, 
      options?: UseQueryOptions<GetProfileMediaQuery, TError, TData>
    ) => 
    useQuery<GetProfileMediaQuery, TError, TData>(
      ['getProfileMedia', variables],
      fetcher<GetProfileMediaQuery, GetProfileMediaQueryVariables>(client, GetProfileMediaDocument, variables),
      options
    );
export const GetProfilesDocument = `
    query getProfiles($_cacheKey: String) {
  getCurrentUser(_cacheKey: $_cacheKey) {
    ... on UserResult {
      id
      __typename
      profiles {
        ... on UserProfileTeaching {
          ...instructorProfileFields
        }
        ... on UserProfileClub {
          ...clubProfileFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetProfilesQuery = <
      TData = GetProfilesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetProfilesQueryVariables, 
      options?: UseQueryOptions<GetProfilesQuery, TError, TData>
    ) => 
    useQuery<GetProfilesQuery, TError, TData>(
      ['getProfiles', variables],
      fetcher<GetProfilesQuery, GetProfilesQueryVariables>(client, GetProfilesDocument, variables),
      options
    );
export const GetSavedInstructorSearchDataDocument = `
    query GetSavedInstructorSearchData($input: GetSavedInstructorSearchDataInput!, $_cacheKey: String) {
  getSavedInstructorSearchData(input: $input, _cacheKey: $_cacheKey) {
    ... on GetSavedInstructorSearchDataResult {
      results {
        ...savedInstructorSearch
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SavedInstructorSearchFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetSavedInstructorSearchDataQuery = <
      TData = GetSavedInstructorSearchDataQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetSavedInstructorSearchDataQueryVariables, 
      options?: UseQueryOptions<GetSavedInstructorSearchDataQuery, TError, TData>
    ) => 
    useQuery<GetSavedInstructorSearchDataQuery, TError, TData>(
      ['GetSavedInstructorSearchData', variables],
      fetcher<GetSavedInstructorSearchDataQuery, GetSavedInstructorSearchDataQueryVariables>(client, GetSavedInstructorSearchDataDocument, variables),
      options
    );
export const GetSharedNavigationDocument = `
    query getSharedNavigation($_cacheKey: String) {
  getSharedNavigation(_cacheKey: $_cacheKey) {
    ... on SharedNavigation {
      instructorNav {
        role
        navItems {
          key
          title
          description
          url {
            type
            value
          }
          image {
            id
            url
            alt
            height
            width
          }
        }
      }
      clubNav {
        role
        navItems {
          key
          title
          description
          url {
            type
            value
          }
          image {
            id
            url
            alt
            height
            width
          }
        }
      }
      websiteLink {
        type
        value
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useGetSharedNavigationQuery = <
      TData = GetSharedNavigationQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetSharedNavigationQueryVariables, 
      options?: UseQueryOptions<GetSharedNavigationQuery, TError, TData>
    ) => 
    useQuery<GetSharedNavigationQuery, TError, TData>(
      ['getSharedNavigation', variables],
      fetcher<GetSharedNavigationQuery, GetSharedNavigationQueryVariables>(client, GetSharedNavigationDocument, variables),
      options
    );
export const GetClubInstructorShortlistDocument = `
    query getClubInstructorShortlist($input: GetProfileByIdInput!, $role: UserRoleInput!, $_cacheKey: String) {
  getProfileById(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on GetProfileByIdResult {
      profile {
        ... on UserProfileClub {
          shortlist {
            id
            profile {
              ...instructorProfileFields
            }
            user {
              id
              lastName
              firstName
            }
            lastMessageIn48HoursSentAt
            distanceUnit
            distance
            hasExistingAffiliation
            hasRecentlyDeclinedAffiliation
            instructorAffiliation {
              id
              clubId
              pendingStatus
              actionedDate
              requestedDate
              requestedBy
              role
              userId
              status
            }
          }
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetClubInstructorShortlistQuery = <
      TData = GetClubInstructorShortlistQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetClubInstructorShortlistQueryVariables, 
      options?: UseQueryOptions<GetClubInstructorShortlistQuery, TError, TData>
    ) => 
    useQuery<GetClubInstructorShortlistQuery, TError, TData>(
      ['getClubInstructorShortlist', variables],
      fetcher<GetClubInstructorShortlistQuery, GetClubInstructorShortlistQueryVariables>(client, GetClubInstructorShortlistDocument, variables),
      options
    );
export const GetTranslationsDocument = `
    query getTranslations($_cacheKey: String) {
  getAllTranslations(_cacheKey: $_cacheKey) {
    ... on GetAllTranslationsResult {
      result
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useGetTranslationsQuery = <
      TData = GetTranslationsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetTranslationsQueryVariables, 
      options?: UseQueryOptions<GetTranslationsQuery, TError, TData>
    ) => 
    useQuery<GetTranslationsQuery, TError, TData>(
      ['getTranslations', variables],
      fetcher<GetTranslationsQuery, GetTranslationsQueryVariables>(client, GetTranslationsDocument, variables),
      options
    );
export const GetUserDocument = `
    query getUser($_cacheKey: String) {
  getCurrentUser(_cacheKey: $_cacheKey) {
    ... on UserResult {
      id
      __typename
      distance
      distanceUnit
      user {
        id
        email
        market {
          name
          locale
          distanceUnit
          programs {
            id
            name
            code
            initials
          }
        }
        lastName
        firstName
        accountId
        phone
        signedAgreements {
          type
          signedOn
          version
        }
      }
      settings {
        locale
      }
      profiles {
        ... on UserProfileTeaching {
          id
          __typename
        }
        ... on UserProfileClub {
          id
          __typename
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useGetUserQuery = <
      TData = GetUserQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetUserQueryVariables, 
      options?: UseQueryOptions<GetUserQuery, TError, TData>
    ) => 
    useQuery<GetUserQuery, TError, TData>(
      ['getUser', variables],
      fetcher<GetUserQuery, GetUserQueryVariables>(client, GetUserDocument, variables),
      options
    );
export const GetVideoGuidelinesDocument = `
    query getVideoGuidelines($_cacheKey: String) {
  getVideoGuidelines(_cacheKey: $_cacheKey) {
    ... on GetVideoGuidelinesResult {
      result {
        section
        content {
          title
          subHeading
          contentText
        }
      }
      contactUsEmail
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useGetVideoGuidelinesQuery = <
      TData = GetVideoGuidelinesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetVideoGuidelinesQueryVariables, 
      options?: UseQueryOptions<GetVideoGuidelinesQuery, TError, TData>
    ) => 
    useQuery<GetVideoGuidelinesQuery, TError, TData>(
      ['getVideoGuidelines', variables],
      fetcher<GetVideoGuidelinesQuery, GetVideoGuidelinesQueryVariables>(client, GetVideoGuidelinesDocument, variables),
      options
    );
export const GetWalkthroughContentDocument = `
    query getWalkthroughContent($role: UserRoleInput!, $_cacheKey: String) {
  getWalkthrough(role: $role, _cacheKey: $_cacheKey) {
    ... on Walkthrough {
      pages {
        title
        body
        image {
          desktop {
            ...imageFields
          }
          mobile {
            ...imageFields
          }
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ImageFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useGetWalkthroughContentQuery = <
      TData = GetWalkthroughContentQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetWalkthroughContentQueryVariables, 
      options?: UseQueryOptions<GetWalkthroughContentQuery, TError, TData>
    ) => 
    useQuery<GetWalkthroughContentQuery, TError, TData>(
      ['getWalkthroughContent', variables],
      fetcher<GetWalkthroughContentQuery, GetWalkthroughContentQueryVariables>(client, GetWalkthroughContentDocument, variables),
      options
    );
export const IncrementViewClubListingCountDocument = `
    mutation incrementViewClubListingCount($input: IncrementViewClubListingCountInput!, $_cacheKey: String) {
  incrementViewClubListingCount(input: $input, _cacheKey: $_cacheKey) {
    ... on ClubListingResult {
      results {
        ... on ClubSingleListing {
          ...singleListingFields
        }
        ... on ClubMultiListing {
          ...multiListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useIncrementViewClubListingCountMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<IncrementViewClubListingCountMutation, TError, IncrementViewClubListingCountMutationVariables, TContext>
    ) => 
    useMutation<IncrementViewClubListingCountMutation, TError, IncrementViewClubListingCountMutationVariables, TContext>(
      (variables?: IncrementViewClubListingCountMutationVariables) => fetcher<IncrementViewClubListingCountMutation, IncrementViewClubListingCountMutationVariables>(client, IncrementViewClubListingCountDocument, variables)(),
      options
    );
export const InitApplicationDocument = `
    query initApplication($featureFlags: [String!]!, $_cacheKey: String) {
  getUser: getCurrentUser(_cacheKey: $_cacheKey) {
    ... on UserResult {
      ...fullUserField
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
  getAllTranslations(_cacheKey: $_cacheKey) {
    ... on GetAllTranslationsResult {
      result
      locale
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
  getFeatureFlag(input: {featureFlags: $featureFlags}, _cacheKey: $_cacheKey) {
    ... on FeatureFlagResult {
      flags {
        featureFlag
        enabled
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${FullUserFieldFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useInitApplicationQuery = <
      TData = InitApplicationQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: InitApplicationQueryVariables, 
      options?: UseQueryOptions<InitApplicationQuery, TError, TData>
    ) => 
    useQuery<InitApplicationQuery, TError, TData>(
      ['initApplication', variables],
      fetcher<InitApplicationQuery, InitApplicationQueryVariables>(client, InitApplicationDocument, variables),
      options
    );
export const ManagePeopleCreateAffiliationsDocument = `
    mutation managePeopleCreateAffiliations($input: CreateAffiliationsInput!, $_cacheKey: String) {
  createAffiliations(input: $input, _cacheKey: $_cacheKey) {
    ... on ChainAffiliationsResult {
      successfulAffiliations {
        actionedDate
        clubId
        id
        latestActionInitiator
        pendingStatus
        requestedBy
        requestedDate
        role
        status
        userId
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useManagePeopleCreateAffiliationsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<ManagePeopleCreateAffiliationsMutation, TError, ManagePeopleCreateAffiliationsMutationVariables, TContext>
    ) => 
    useMutation<ManagePeopleCreateAffiliationsMutation, TError, ManagePeopleCreateAffiliationsMutationVariables, TContext>(
      (variables?: ManagePeopleCreateAffiliationsMutationVariables) => fetcher<ManagePeopleCreateAffiliationsMutation, ManagePeopleCreateAffiliationsMutationVariables>(client, ManagePeopleCreateAffiliationsDocument, variables)(),
      options
    );
export const ManagePeopleRemoveAffiliationsDocument = `
    mutation managePeopleRemoveAffiliations($input: DeleteAffiliationsInput!, $_cacheKey: String) {
  removeAffiliations(input: $input, _cacheKey: $_cacheKey) {
    ... on ChainAffiliationsRemoveRresult {
      receivedAffiliationIds
      removedAffiliationIds
      removedUserId
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useManagePeopleRemoveAffiliationsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<ManagePeopleRemoveAffiliationsMutation, TError, ManagePeopleRemoveAffiliationsMutationVariables, TContext>
    ) => 
    useMutation<ManagePeopleRemoveAffiliationsMutation, TError, ManagePeopleRemoveAffiliationsMutationVariables, TContext>(
      (variables?: ManagePeopleRemoveAffiliationsMutationVariables) => fetcher<ManagePeopleRemoveAffiliationsMutation, ManagePeopleRemoveAffiliationsMutationVariables>(client, ManagePeopleRemoveAffiliationsDocument, variables)(),
      options
    );
export const MarkNotificationsAsReadDocument = `
    mutation markNotificationsAsRead($id: [ID!]!, $role: UserRoleInput!, $_cacheKey: String) {
  toggleNotificationsRead(
    input: {id: $id, read: true}
    role: $role
    _cacheKey: $_cacheKey
  ) {
    __typename
    ... on ToggleNotificationReadResult {
      __typename
      success
      failure
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useMarkNotificationsAsReadMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<MarkNotificationsAsReadMutation, TError, MarkNotificationsAsReadMutationVariables, TContext>
    ) => 
    useMutation<MarkNotificationsAsReadMutation, TError, MarkNotificationsAsReadMutationVariables, TContext>(
      (variables?: MarkNotificationsAsReadMutationVariables) => fetcher<MarkNotificationsAsReadMutation, MarkNotificationsAsReadMutationVariables>(client, MarkNotificationsAsReadDocument, variables)(),
      options
    );
export const MarkNotificationsAsUnreadDocument = `
    mutation markNotificationsAsUnread($id: [ID!]!, $role: UserRoleInput!, $_cacheKey: String) {
  toggleNotificationsRead(
    input: {id: $id, read: false}
    role: $role
    _cacheKey: $_cacheKey
  ) {
    ... on ToggleNotificationReadResult {
      __typename
      success
      failure
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useMarkNotificationsAsUnreadMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<MarkNotificationsAsUnreadMutation, TError, MarkNotificationsAsUnreadMutationVariables, TContext>
    ) => 
    useMutation<MarkNotificationsAsUnreadMutation, TError, MarkNotificationsAsUnreadMutationVariables, TContext>(
      (variables?: MarkNotificationsAsUnreadMutationVariables) => fetcher<MarkNotificationsAsUnreadMutation, MarkNotificationsAsUnreadMutationVariables>(client, MarkNotificationsAsUnreadDocument, variables)(),
      options
    );
export const GetAtHomeItemsDocument = `
    query getAtHomeItems($input: GetClubByIdInput!, $_cacheKey: String) {
  getClubById(input: $input, _cacheKey: $_cacheKey) {
    ... on UserProfileClub {
      atHomeProducts {
        active {
          ...AtHomeProductTile
        }
        inactive {
          ...AtHomeProductTile
        }
      }
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${AtHomeProductTileFragmentDoc}`;
export const useGetAtHomeItemsQuery = <
      TData = GetAtHomeItemsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetAtHomeItemsQueryVariables, 
      options?: UseQueryOptions<GetAtHomeItemsQuery, TError, TData>
    ) => 
    useQuery<GetAtHomeItemsQuery, TError, TData>(
      ['getAtHomeItems', variables],
      fetcher<GetAtHomeItemsQuery, GetAtHomeItemsQueryVariables>(client, GetAtHomeItemsDocument, variables),
      options
    );
export const GetExploreProgramsPageDocument = `
    query getExploreProgramsPage($role: UserRoleInput!, $_cacheKey: String) {
  getExploreProgramsPage(role: $role, _cacheKey: $_cacheKey) {
    ... on ExploreProgramsPage {
      products {
        ...ExploreProgramTile
      }
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${ExploreProgramTileFragmentDoc}`;
export const useGetExploreProgramsPageQuery = <
      TData = GetExploreProgramsPageQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetExploreProgramsPageQueryVariables, 
      options?: UseQueryOptions<GetExploreProgramsPageQuery, TError, TData>
    ) => 
    useQuery<GetExploreProgramsPageQuery, TError, TData>(
      ['getExploreProgramsPage', variables],
      fetcher<GetExploreProgramsPageQuery, GetExploreProgramsPageQueryVariables>(client, GetExploreProgramsPageDocument, variables),
      options
    );
export const GetExploreProgramsPageDetailedProgramDocument = `
    query getExploreProgramsPageDetailedProgram($role: UserRoleInput!, $programName: ProgramName, $_cacheKey: String) {
  getExploreProgramsPage(
    role: $role
    programName: $programName
    _cacheKey: $_cacheKey
  ) {
    ... on ExploreProgramsPage {
      products {
        ...DetailedExploreProgramTile
      }
    }
    ... on ErrorResult {
      type
      message
    }
  }
}
    ${DetailedExploreProgramTileFragmentDoc}`;
export const useGetExploreProgramsPageDetailedProgramQuery = <
      TData = GetExploreProgramsPageDetailedProgramQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetExploreProgramsPageDetailedProgramQueryVariables, 
      options?: UseQueryOptions<GetExploreProgramsPageDetailedProgramQuery, TError, TData>
    ) => 
    useQuery<GetExploreProgramsPageDetailedProgramQuery, TError, TData>(
      ['getExploreProgramsPageDetailedProgram', variables],
      fetcher<GetExploreProgramsPageDetailedProgramQuery, GetExploreProgramsPageDetailedProgramQueryVariables>(client, GetExploreProgramsPageDetailedProgramDocument, variables),
      options
    );
export const RemoveFromShortlistDocument = `
    mutation removeFromShortlist($input: RemoveFromShortlistInput!, $role: UserRoleInput!, $_cacheKey: String) {
  removeFromShortlist(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on RemoveFromShortlistResult {
      shortlist {
        id
        profile {
          ...instructorProfileFields
        }
        user {
          id
          lastName
          firstName
        }
        lastMessageIn48HoursSentAt
        distanceUnit
        distance
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useRemoveFromShortlistMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<RemoveFromShortlistMutation, TError, RemoveFromShortlistMutationVariables, TContext>
    ) => 
    useMutation<RemoveFromShortlistMutation, TError, RemoveFromShortlistMutationVariables, TContext>(
      (variables?: RemoveFromShortlistMutationVariables) => fetcher<RemoveFromShortlistMutation, RemoveFromShortlistMutationVariables>(client, RemoveFromShortlistDocument, variables)(),
      options
    );
export const ReportClubListingDocument = `
    mutation reportClubListing($input: ReportClubListingInput!, $_cacheKey: String) {
  reportClubListing(input: $input, _cacheKey: $_cacheKey) {
    __typename
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useReportClubListingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<ReportClubListingMutation, TError, ReportClubListingMutationVariables, TContext>
    ) => 
    useMutation<ReportClubListingMutation, TError, ReportClubListingMutationVariables, TContext>(
      (variables?: ReportClubListingMutationVariables) => fetcher<ReportClubListingMutation, ReportClubListingMutationVariables>(client, ReportClubListingDocument, variables)(),
      options
    );
export const ReportClubProfileDocument = `
    mutation reportClubProfile($input: ReportClubProfileInput!, $_cacheKey: String) {
  reportClubProfile(input: $input, _cacheKey: $_cacheKey) {
    __typename
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useReportClubProfileMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<ReportClubProfileMutation, TError, ReportClubProfileMutationVariables, TContext>
    ) => 
    useMutation<ReportClubProfileMutation, TError, ReportClubProfileMutationVariables, TContext>(
      (variables?: ReportClubProfileMutationVariables) => fetcher<ReportClubProfileMutation, ReportClubProfileMutationVariables>(client, ReportClubProfileDocument, variables)(),
      options
    );
export const RevertAppMetadataDocument = `
    mutation revertAppMetadata($input: RevertAppMetadataInput!, $_cacheKey: String) {
  revertAppMetadata(input: $input, _cacheKey: $_cacheKey) {
    ... on AppMetadataResult {
      isSuccess
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useRevertAppMetadataMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<RevertAppMetadataMutation, TError, RevertAppMetadataMutationVariables, TContext>
    ) => 
    useMutation<RevertAppMetadataMutation, TError, RevertAppMetadataMutationVariables, TContext>(
      (variables?: RevertAppMetadataMutationVariables) => fetcher<RevertAppMetadataMutation, RevertAppMetadataMutationVariables>(client, RevertAppMetadataDocument, variables)(),
      options
    );
export const SearchClubListingsDocument = `
    query searchClubListings($filter: SearchClubListingsFilter!, $options: SearchClubListingsOptions, $_cacheKey: String) {
  searchClubListings(filter: $filter, options: $options, _cacheKey: $_cacheKey) {
    ... on ErrorResult {
      ...errorResultFields
    }
    ... on SearchClubListingsResult {
      total
      results {
        ... on SearchClubJobListingsSingleResult {
          distance
          distanceUnit
          messageSentOn
          enquiredOn
          onWatchlist
          hasBeenReported
          listing {
            ... on ClubSingleListing {
              ...singleListingFields
            }
            ... on ClubMultiListing {
              ...multiListingFields
            }
          }
        }
        ... on SearchClubProfileSingleResult {
          distance
          distanceUnit
          onWatchlist
          hasBeenReported
          profile {
            ...clubProfileFields
          }
        }
      }
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}
${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}`;
export const useSearchClubListingsQuery = <
      TData = SearchClubListingsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: SearchClubListingsQueryVariables, 
      options?: UseQueryOptions<SearchClubListingsQuery, TError, TData>
    ) => 
    useQuery<SearchClubListingsQuery, TError, TData>(
      ['searchClubListings', variables],
      fetcher<SearchClubListingsQuery, SearchClubListingsQueryVariables>(client, SearchClubListingsDocument, variables),
      options
    );
export const SearchClubsDocument = `
    query searchClubs($filter: SearchClubsFilter!, $options: SearchClubsOptions, $_cacheKey: String) {
  searchClubs(filter: $filter, options: $options, _cacheKey: $_cacheKey) {
    ... on ErrorResult {
      ...errorResultFields
    }
    ... on SearchClubsResult {
      total
      results {
        ... on SearchClubsSingleResult {
          clubId
          profile {
            ...clubProfileFields
          }
          instructorAffiliation {
            id
            userId
            clubId
            requestedBy
            actionedDate
            status
            pendingStatus
            requestedDate
            latestActionInitiator
          }
          distance
          distanceUnit
        }
      }
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}`;
export const useSearchClubsQuery = <
      TData = SearchClubsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: SearchClubsQueryVariables, 
      options?: UseQueryOptions<SearchClubsQuery, TError, TData>
    ) => 
    useQuery<SearchClubsQuery, TError, TData>(
      ['searchClubs', variables],
      fetcher<SearchClubsQuery, SearchClubsQueryVariables>(client, SearchClubsDocument, variables),
      options
    );
export const SearchInstructorsDocument = `
    query searchInstructors($filter: SearchInstructorsFilter!, $options: SearchInstructorsOptions, $role: UserRoleInput!, $_cacheKey: String) {
  searchInstructors(
    filter: $filter
    options: $options
    role: $role
    _cacheKey: $_cacheKey
  ) {
    ... on SearchInstructorsResult {
      ...searchInstructorsResult
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SearchInstructorsResultFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useSearchInstructorsQuery = <
      TData = SearchInstructorsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: SearchInstructorsQueryVariables, 
      options?: UseQueryOptions<SearchInstructorsQuery, TError, TData>
    ) => 
    useQuery<SearchInstructorsQuery, TError, TData>(
      ['searchInstructors', variables],
      fetcher<SearchInstructorsQuery, SearchInstructorsQueryVariables>(client, SearchInstructorsDocument, variables),
      options
    );
export const SendMessageDocument = `
    mutation sendMessage($input: SendMessageInput!, $_cacheKey: String) {
  sendMessage(input: $input, _cacheKey: $_cacheKey) {
    ... on SendMessageResult {
      sentAt
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useSendMessageMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<SendMessageMutation, TError, SendMessageMutationVariables, TContext>
    ) => 
    useMutation<SendMessageMutation, TError, SendMessageMutationVariables, TContext>(
      (variables?: SendMessageMutationVariables) => fetcher<SendMessageMutation, SendMessageMutationVariables>(client, SendMessageDocument, variables)(),
      options
    );
export const SetClubSettingsDocument = `
    mutation setClubSettings($input: SetClubSettingsInput!, $_cacheKey: String) {
  setClubSettings(input: $input, _cacheKey: $_cacheKey) {
    ... on SetClubSettingsResult {
      id
      address {
        ...addressFields
      }
      email {
        main
        affiliations
        jobListings
      }
      id
      name
      phone
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${AddressFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useSetClubSettingsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<SetClubSettingsMutation, TError, SetClubSettingsMutationVariables, TContext>
    ) => 
    useMutation<SetClubSettingsMutation, TError, SetClubSettingsMutationVariables, TContext>(
      (variables?: SetClubSettingsMutationVariables) => fetcher<SetClubSettingsMutation, SetClubSettingsMutationVariables>(client, SetClubSettingsDocument, variables)(),
      options
    );
export const SetInstructorAvailabilityDocument = `
    mutation setInstructorAvailability($input: SetInstructorAvailabilityInput!, $_cacheKey: String) {
  setInstructorAvailability(input: $input, _cacheKey: $_cacheKey) {
    ... on InstructorAvailability {
      value
      availableToSubstitute
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useSetInstructorAvailabilityMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<SetInstructorAvailabilityMutation, TError, SetInstructorAvailabilityMutationVariables, TContext>
    ) => 
    useMutation<SetInstructorAvailabilityMutation, TError, SetInstructorAvailabilityMutationVariables, TContext>(
      (variables?: SetInstructorAvailabilityMutationVariables) => fetcher<SetInstructorAvailabilityMutation, SetInstructorAvailabilityMutationVariables>(client, SetInstructorAvailabilityDocument, variables)(),
      options
    );
export const SetInstructorWatchlistDocument = `
    mutation setInstructorWatchlist($input: SetInstructorWatchlistInput!, $role: UserRoleInput!, $_cacheKey: String) {
  setInstructorWatchlist(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on InstructorWatchlist {
      watchlist {
        clubId
        listingId
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useSetInstructorWatchlistMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<SetInstructorWatchlistMutation, TError, SetInstructorWatchlistMutationVariables, TContext>
    ) => 
    useMutation<SetInstructorWatchlistMutation, TError, SetInstructorWatchlistMutationVariables, TContext>(
      (variables?: SetInstructorWatchlistMutationVariables) => fetcher<SetInstructorWatchlistMutation, SetInstructorWatchlistMutationVariables>(client, SetInstructorWatchlistDocument, variables)(),
      options
    );
export const SetSavedInstructorSearchDataDocument = `
    mutation SetSavedInstructorSearchData($input: SetSavedInstructorSearchDataInput!, $_cacheKey: String) {
  setSavedInstructorSearchData(input: $input, _cacheKey: $_cacheKey) {
    ... on SetSavedInstructorSearchDataResult {
      results {
        ...savedInstructorSearch
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SavedInstructorSearchFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useSetSavedInstructorSearchDataMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<SetSavedInstructorSearchDataMutation, TError, SetSavedInstructorSearchDataMutationVariables, TContext>
    ) => 
    useMutation<SetSavedInstructorSearchDataMutation, TError, SetSavedInstructorSearchDataMutationVariables, TContext>(
      (variables?: SetSavedInstructorSearchDataMutationVariables) => fetcher<SetSavedInstructorSearchDataMutation, SetSavedInstructorSearchDataMutationVariables>(client, SetSavedInstructorSearchDataDocument, variables)(),
      options
    );
export const SetSignedAgreementDataDocument = `
    mutation setSignedAgreementData($input: SetSignedAgreementDataInput!, $_cacheKey: String) {
  setSignedAgreementData(input: $input, _cacheKey: $_cacheKey) {
    ... on AgreementDocumentResult {
      agreementType: type
      signedOn
      version
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useSetSignedAgreementDataMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<SetSignedAgreementDataMutation, TError, SetSignedAgreementDataMutationVariables, TContext>
    ) => 
    useMutation<SetSignedAgreementDataMutation, TError, SetSignedAgreementDataMutationVariables, TContext>(
      (variables?: SetSignedAgreementDataMutationVariables) => fetcher<SetSignedAgreementDataMutation, SetSignedAgreementDataMutationVariables>(client, SetSignedAgreementDataDocument, variables)(),
      options
    );
export const SetWalkthroughToSeenDocument = `
    mutation setWalkthroughToSeen($role: UserRoleInput!, $_cacheKey: String) {
  setWalkthroughToSeen(role: $role, _cacheKey: $_cacheKey) {
    ... on SetWalkthroughToSeenResult {
      success
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useSetWalkthroughToSeenMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<SetWalkthroughToSeenMutation, TError, SetWalkthroughToSeenMutationVariables, TContext>
    ) => 
    useMutation<SetWalkthroughToSeenMutation, TError, SetWalkthroughToSeenMutationVariables, TContext>(
      (variables?: SetWalkthroughToSeenMutationVariables) => fetcher<SetWalkthroughToSeenMutation, SetWalkthroughToSeenMutationVariables>(client, SetWalkthroughToSeenDocument, variables)(),
      options
    );
export const UpdateAffiliateBillingDocument = `
    mutation updateAffiliateBilling($input: PayForCertificationInput!, $_cacheKey: String) {
  payForCertification(input: $input, _cacheKey: $_cacheKey) {
    ... on PayForCertificationResult {
      results {
        certificate {
          active
          billedClubId
          id
          lastPurchase
          lmqLevel
          pricePerQuarter {
            amount
            currency
          }
          program {
            code
            id
            initials
            name
          }
          transactionStatus
          releaseStatus
          status
        }
        instructorName
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useUpdateAffiliateBillingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateAffiliateBillingMutation, TError, UpdateAffiliateBillingMutationVariables, TContext>
    ) => 
    useMutation<UpdateAffiliateBillingMutation, TError, UpdateAffiliateBillingMutationVariables, TContext>(
      (variables?: UpdateAffiliateBillingMutationVariables) => fetcher<UpdateAffiliateBillingMutation, UpdateAffiliateBillingMutationVariables>(client, UpdateAffiliateBillingDocument, variables)(),
      options
    );
export const UpdateAffiliationDocument = `
    mutation updateAffiliation($input: UpdateAffiliationInput!, $role: UserRoleInput!, $_cacheKey: String) {
  updateAffiliation(input: $input, role: $role, _cacheKey: $_cacheKey) {
    ... on AffiliationAsyncResult {
      status
      hasPendingAffiliations
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${ErrorResultFieldsFragmentDoc}`;
export const useUpdateAffiliationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateAffiliationMutation, TError, UpdateAffiliationMutationVariables, TContext>
    ) => 
    useMutation<UpdateAffiliationMutation, TError, UpdateAffiliationMutationVariables, TContext>(
      (variables?: UpdateAffiliationMutationVariables) => fetcher<UpdateAffiliationMutation, UpdateAffiliationMutationVariables>(client, UpdateAffiliationDocument, variables)(),
      options
    );
export const UpdateClubListingDocument = `
    mutation updateClubListing($input: UpdateClubListingInput!, $_cacheKey: String) {
  updateClubListing(input: $input, _cacheKey: $_cacheKey) {
    ... on ClubListingResult {
      results {
        ... on ClubSingleListing {
          ...singleListingFields
        }
        ... on ClubMultiListing {
          ...multiListingFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${SingleListingFieldsFragmentDoc}
${MultiListingFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useUpdateClubListingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateClubListingMutation, TError, UpdateClubListingMutationVariables, TContext>
    ) => 
    useMutation<UpdateClubListingMutation, TError, UpdateClubListingMutationVariables, TContext>(
      (variables?: UpdateClubListingMutationVariables) => fetcher<UpdateClubListingMutation, UpdateClubListingMutationVariables>(client, UpdateClubListingDocument, variables)(),
      options
    );
export const UpdateClubProfileDocument = `
    mutation updateClubProfile($input: UpdateClubProfileInput!, $_cacheKey: String) {
  updateClubProfile(input: $input, _cacheKey: $_cacheKey) {
    ... on UserResult {
      ...fullUserField
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${FullUserFieldFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useUpdateClubProfileMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateClubProfileMutation, TError, UpdateClubProfileMutationVariables, TContext>
    ) => 
    useMutation<UpdateClubProfileMutation, TError, UpdateClubProfileMutationVariables, TContext>(
      (variables?: UpdateClubProfileMutationVariables) => fetcher<UpdateClubProfileMutation, UpdateClubProfileMutationVariables>(client, UpdateClubProfileDocument, variables)(),
      options
    );
export const FinishPortfolioUpdateDocument = `
    mutation finishPortfolioUpdate($input: UpdateCurrentUserPortfolioUploadInput!, $role: UserRoleInput!, $_cacheKey: String) {
  updateCurrentUserPortfolioUpload(
    input: $input
    role: $role
    _cacheKey: $_cacheKey
  ) {
    ... on UpdateCurrentUserPortfolioUploadResult {
      url
      profile {
        ... on UserProfileTeaching {
          ...instructorProfileFields
        }
        ... on UserProfileClub {
          ...clubProfileFields
        }
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useFinishPortfolioUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<FinishPortfolioUpdateMutation, TError, FinishPortfolioUpdateMutationVariables, TContext>
    ) => 
    useMutation<FinishPortfolioUpdateMutation, TError, FinishPortfolioUpdateMutationVariables, TContext>(
      (variables?: FinishPortfolioUpdateMutationVariables) => fetcher<FinishPortfolioUpdateMutation, FinishPortfolioUpdateMutationVariables>(client, FinishPortfolioUpdateDocument, variables)(),
      options
    );
export const UpdateTeachingProfileDocument = `
    mutation updateTeachingProfile($input: UpdateTeachingProfileInput!, $_cacheKey: String) {
  updateTeachingProfile(input: $input, _cacheKey: $_cacheKey) {
    ... on UserResult {
      ...fullUserField
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${FullUserFieldFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useUpdateTeachingProfileMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateTeachingProfileMutation, TError, UpdateTeachingProfileMutationVariables, TContext>
    ) => 
    useMutation<UpdateTeachingProfileMutation, TError, UpdateTeachingProfileMutationVariables, TContext>(
      (variables?: UpdateTeachingProfileMutationVariables) => fetcher<UpdateTeachingProfileMutation, UpdateTeachingProfileMutationVariables>(client, UpdateTeachingProfileDocument, variables)(),
      options
    );
export const UpdateUserAccountDocument = `
    mutation updateUserAccount($input: UpdateUserAccountInput!, $_cacheKey: String) {
  updateUserAccount(input: $input, _cacheKey: $_cacheKey) {
    ... on UserAccountSettingsResult {
      address {
        ...addressFields
      }
      email
      firstName
      id
      lastName
      phone
      outlinks {
        url
        title
      }
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${AddressFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useUpdateUserAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateUserAccountMutation, TError, UpdateUserAccountMutationVariables, TContext>
    ) => 
    useMutation<UpdateUserAccountMutation, TError, UpdateUserAccountMutationVariables, TContext>(
      (variables?: UpdateUserAccountMutationVariables) => fetcher<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>(client, UpdateUserAccountDocument, variables)(),
      options
    );
export const UpdateUserPhotoDocument = `
    mutation updateUserPhoto($input: UpdateUserPhotoInput!, $_cacheKey: String) {
  updateUserPhoto(input: $input, _cacheKey: $_cacheKey) {
    ... on UpdateUserPhotoResult {
      profile {
        ... on UserProfileTeaching {
          ...instructorProfileFields
        }
        ... on UserProfileClub {
          ...clubProfileFields
        }
      }
      url
    }
    ... on ErrorResult {
      ...errorResultFields
    }
  }
}
    ${InstructorProfileFieldsFragmentDoc}
${ClubProfileFieldsFragmentDoc}
${ErrorResultFieldsFragmentDoc}`;
export const useUpdateUserPhotoMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateUserPhotoMutation, TError, UpdateUserPhotoMutationVariables, TContext>
    ) => 
    useMutation<UpdateUserPhotoMutation, TError, UpdateUserPhotoMutationVariables, TContext>(
      (variables?: UpdateUserPhotoMutationVariables) => fetcher<UpdateUserPhotoMutation, UpdateUserPhotoMutationVariables>(client, UpdateUserPhotoDocument, variables)(),
      options
    );
export const FinalizeUploadDocument = `
    mutation finalizeUpload($input: FinalizeUploadInput!, $_cacheKey: String) {
  finalizeUpload(input: $input, _cacheKey: $_cacheKey) {
    ... on FinalizeUploadResult {
      isSuccess
      comment
      playback {
        playbackUrl
        token
      }
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export const useFinalizeUploadMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<FinalizeUploadMutation, TError, FinalizeUploadMutationVariables, TContext>
    ) => 
    useMutation<FinalizeUploadMutation, TError, FinalizeUploadMutationVariables, TContext>(
      (variables?: FinalizeUploadMutationVariables) => fetcher<FinalizeUploadMutation, FinalizeUploadMutationVariables>(client, FinalizeUploadDocument, variables)(),
      options
    );
export const GetPresignedUrlDocument = `
    query getPresignedUrl($input: GetPresignedUrlInput!, $_cacheKey: String) {
  getPresignedUrl(input: $input, _cacheKey: $_cacheKey) {
    ... on GetPresignedUrlResult {
      partSignedUrlList {
        partNumber
        url
      }
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export const useGetPresignedUrlQuery = <
      TData = GetPresignedUrlQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetPresignedUrlQueryVariables, 
      options?: UseQueryOptions<GetPresignedUrlQuery, TError, TData>
    ) => 
    useQuery<GetPresignedUrlQuery, TError, TData>(
      ['getPresignedUrl', variables],
      fetcher<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>(client, GetPresignedUrlDocument, variables),
      options
    );
export const InitializeUploadDocument = `
    mutation initializeUpload($input: InitializeUploadInput!, $_cacheKey: String) {
  initializeUpload(input: $input, _cacheKey: $_cacheKey) {
    ... on InitializeUploadResult {
      uploadId
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export const useInitializeUploadMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<InitializeUploadMutation, TError, InitializeUploadMutationVariables, TContext>
    ) => 
    useMutation<InitializeUploadMutation, TError, InitializeUploadMutationVariables, TContext>(
      (variables?: InitializeUploadMutationVariables) => fetcher<InitializeUploadMutation, InitializeUploadMutationVariables>(client, InitializeUploadDocument, variables)(),
      options
    );