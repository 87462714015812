import React from 'react'
import ApolloProvider from 'src/contexts/ApolloProvider'
import ReactQueryProvider from 'src/contexts/ReactQueryProvider'

const ApiClientProvider: React.FC = ({ children }) => {
  return (
    <ApolloProvider>
      <ReactQueryProvider>{children}</ReactQueryProvider>
    </ApolloProvider>
  )
}

export default ApiClientProvider
