import React from 'react'
import styled, { css } from 'styled-components'

import { Box as MuiBox, BoxProps } from '@material-ui/core'

import sizeValueToCss from 'src/utils/sizeValueToCss'
import transformColor from 'src/utils/transformColor'

const Box = styled(MuiBox)<{
  $color: string
  $size: string | number
}>(
  ({ theme, $color, $size }) => css`
    display: inline-block;
    box-sizing: border-box;
    width: ${sizeValueToCss($size)};
    height: ${sizeValueToCss($size)};
    line-height: ${sizeValueToCss($size)};
    border-radius: ${sizeValueToCss($size)};
    background-color: ${transformColor($color, theme)};
    transition: background-color 0.5s;
  `
)

export interface EllipseBaseProps extends BoxProps {
  color: string
  size: string | number
}
const EllipseBase: React.FC<EllipseBaseProps> = ({ color, size, ...props }) => (
  <Box {...props} $color={color} $size={size} />
)

export default EllipseBase
