import { useEffect, useState } from 'react'
import { useGetAgreementDocumentLazyQuery } from 'src/generated/hooks'
import { AgreementType, AgreementDocumentResult } from 'src/generated/graphql'
import useProfile from 'src/hooks/useProfile'
import { isErrorResult, isClubProfile } from 'src/utils/typeGuards'
import { useMutation } from 'react-query'
import { useGraphQLClientRequest } from 'src/hooks/useGraphQLClientRequest'
import {
  SetSignedAgreementDataDocument,
  SetSignedAgreementDataMutationVariables,
  SetSignedAgreementDataMutation,
} from 'src/generated/graphql-react-query'
import useUser from 'src/hooks/useUser'

export enum PrivacyAgreementStatus {
  // Not applicable for instructor users
  NotApplicable = 'not_applicable',

  // Data is loading to determine the state
  Loading = 'loading',

  // Agreement is signed and valid
  Valid = 'valid',

  // Agreement not signed (or outdated version is signed)
  SigningRequired = 'signing_required',

  // Request to BE to sign agreement is currently in progress
  SigningInProgress = 'signing_in_progress',

  // Request to BE to sign agreement has failed
  SigningError = 'signing_error',

  // There is a signed privacy agreement but an error occurred while
  // trying to verify if it is the latest version or during attempting
  // to fetch the latest version
  SignedUnverified = 'signed_unverified',

  // User needs to sign a DPA but we couldn't fetch it
  FetchAgreementError = 'fetch_agreement_error',

  // User is on the fetch error state and triggered a retry
  FetchAgreementErrorRetrying = 'fetch_agreement_error_retrying',
}

const useClubTerms = () => {
  const {
    data: { profile },
  } = useProfile()

  const {
    data: { fullUser },
    operations: { updateUser },
  } = useUser()

  // Only applicable for club profiles. Need to wait for the user to be loaded as well.
  const isClubView = !!fullUser?.user && !!profile && isClubProfile(profile)

  const [status, setStatus] = useState(PrivacyAgreementStatus.Loading)

  const [documentToSign, setDocumentToSign] = useState<null | Pick<
    AgreementDocumentResult,
    'version' | 'content'
  >>(null)

  // Check if the user has signed privacy agreement(s) already
  const existingSignedAgreements =
    fullUser?.user.signedAgreements?.filter(
      item => item.type === AgreementType.DataPrivacy
    ) ?? []

  const onError = () => {
    setStatus(
      existingSignedAgreements.length > 0
        ? PrivacyAgreementStatus.SignedUnverified
        : PrivacyAgreementStatus.FetchAgreementError
    )
  }

  const [
    getLatestVersion,
    { data: versionData, error: versionError, called: getLatestVersionCalled },
  ] = useGetAgreementDocumentLazyQuery({
    variables: {
      input: {
        type: AgreementType.DataPrivacy,
      },
      versionOnly: true,
    },
  })

  const [getAgreementDocument, { data: documentData, error: documentError }] =
    useGetAgreementDocumentLazyQuery({
      variables: {
        input: {
          type: AgreementType.DataPrivacy,
        },
      },
    })

  const request = useGraphQLClientRequest()
  const { mutateAsync: setSignedAgreementData } = useMutation(
    (variables: SetSignedAgreementDataMutationVariables) =>
      request<SetSignedAgreementDataMutation>(
        SetSignedAgreementDataDocument,
        variables
      )
  )

  // 1. If instructor profile, set status to not applicable
  // 2. If club profile and user has no signed privacy agreement -> fetch latest version
  // 3. Otherwise, need to check which version they signed (don't over-fetch by
  //    fetching the whole agreement every time the user opens the app).
  useEffect(() => {
    if (!isClubView) {
      setStatus(PrivacyAgreementStatus.NotApplicable)
    } else if (existingSignedAgreements.length === 0) {
      setStatus(PrivacyAgreementStatus.Loading)
      getAgreementDocument()
    } else if (!getLatestVersionCalled) {
      setStatus(PrivacyAgreementStatus.Loading)
      getLatestVersion()
    }
  }, [isClubView]) // eslint-disable-line react-hooks/exhaustive-deps

  // When the version data changes:
  // 1. If error or missing, call onError
  // 2. If success, check the latest version matches with the currently signed version (if any)
  //    a. No -> trigger fetch of the full document content
  //    b. Yes -> set status to Valid
  useEffect(() => {
    if (!versionData) {
      return
    }
    const data = versionData.getAgreementDocument

    if (data && !isErrorResult(data)) {
      const existingSignedAgreementValid = !!existingSignedAgreements.find(
        item => item.version === data.version
      )

      if (existingSignedAgreementValid) {
        setStatus(PrivacyAgreementStatus.Valid)
      } else {
        // Need to fetch the new agreement
        getAgreementDocument()
      }
    } else {
      onError()
    }
  }, [versionData]) // eslint-disable-line react-hooks/exhaustive-deps

  // When the full document data changes:
  // 1. If error or missing, call onError
  // 2. If success, set status to SigningRequired
  useEffect(() => {
    if (!documentData) {
      return
    }
    const data = documentData.getAgreementDocument

    if (data && !isErrorResult(data)) {
      setStatus(PrivacyAgreementStatus.SigningRequired)
      setDocumentToSign(data)
    } else {
      onError()
    }
  }, [documentData]) // eslint-disable-line react-hooks/exhaustive-deps

  // If error fetching latest version or the latest document, call onError
  useEffect(() => {
    if (versionError || documentError) {
      onError()
    }
  }, [versionError, documentError]) // eslint-disable-line react-hooks/exhaustive-deps

  const signDocument = async () => {
    setStatus(PrivacyAgreementStatus.SigningInProgress)
    try {
      const result = await setSignedAgreementData({
        input: {
          version: documentToSign!.version,
          type: AgreementType.DataPrivacy,
        },
      })
      const data = result?.setSignedAgreementData

      if (data && !isErrorResult(data)) {
        setStatus(PrivacyAgreementStatus.Valid)

        // update cached user
        const { signedOn, version, agreementType } = data
        updateUser({
          user: {
            ...fullUser!.user,
            signedAgreements: [
              ...(fullUser!.user.signedAgreements ?? []),
              { signedOn, version, type: agreementType },
            ],
          },
        })
      } else {
        setStatus(PrivacyAgreementStatus.SigningError)
      }
    } catch (e) {
      setStatus(PrivacyAgreementStatus.SigningError)
    }
  }

  const retryGetAgreement = () => {
    setStatus(PrivacyAgreementStatus.FetchAgreementErrorRetrying)
    getAgreementDocument()
  }

  return {
    data: {
      status,
      documentToSign,
    },
    operations: {
      retryGetAgreement,
      signDocument,
    },
  }
}

export default useClubTerms
