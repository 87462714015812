import React from 'react'
import i18n from 'i18next'

import {
  Box,
  Typography,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent,
  DialogActions as MuiDialogActions,
  useMediaQuery,
} from '@material-ui/core'

import styled, { useTheme } from 'styled-components'
import Button from 'src/components/button/Button'
import { CloseButton } from 'src/components/dialog/index'
import Caption from 'src/components/dataDisplay/Caption'
import { UtilityIcon } from 'src/components/dataDisplay/Illustration'
import { allowNewline } from 'src/utils/helpers'

export interface DialogProps extends Omit<MuiDialogProps, 'onClose'> {
  description?: string | React.ReactNode
  title: string
  confirmButtonText?: string
  cancelButtonText?: string
  onCancel?: () => void
  onConfirm: () => void
  isSaving?: boolean
  disableCloseButton?: boolean
  hideCancelButton?: boolean
  descriptionColor?: 'textSecondary' | 'textPrimary'
  captionText?: string
  captionIcon?: UtilityIcon
}

const Dialog = styled(MuiDialog)`
  text-align: center;
`

const DialogActions = styled(MuiDialogActions)`
  justify-content: center;
  padding-bottom: 0px;
`

const StyledCloseButton = styled(CloseButton)`
  margin: 0;
`

// This component is intended to be deprecated, we would replace all instances of it with ConfirmCancelDialog.tsx

const ConfirmDialog: React.FC<DialogProps> = ({
  title,
  description,
  descriptionColor = 'textPrimary',
  confirmButtonText = i18n.t('Global.buttonLabels.confirm'),
  cancelButtonText = i18n.t('Global.buttonLabels.cancel'),
  onCancel,
  onConfirm,
  isSaving,
  disableCloseButton = true,
  hideCancelButton,
  captionText,
  captionIcon,
  ...props
}) => {
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog aria-labelledby="form-dialog-title" {...props}>
      <Box display="flex" flexDirection="column" p={5.5}>
        {!isMobileScreen && !disableCloseButton && (
          <StyledCloseButton disabled={isSaving} onClick={onCancel} />
        )}
        <Typography variant="h6" id="form-dialog-title">
          {title}
        </Typography>
        <DialogContent>
          <Box py={2}>
            {description && (
              <Typography
                variant="body1"
                color={descriptionColor}
                {...allowNewline}
              >
                {description}
              </Typography>
            )}
          </Box>
          {captionText && <Caption text={captionText} icon={captionIcon} />}
        </DialogContent>
        <DialogActions>
          {onCancel && !hideCancelButton && (
            <Button variant="outlined" onClick={onCancel} disabled={isSaving}>
              {cancelButtonText}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={onConfirm}
            loading={isSaving}
            data-testid="confirmButton"
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default ConfirmDialog
