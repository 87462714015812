const prod = /connect\.lesmills\.com/
const uat = /connect\.preprod\.lesmills\.com/
const test = /connect\.test\.lesmills\.com/

export function getEnv(): string {
  const { host } = window.location
  const isProd = prod.test(host)
  const isUat = uat.test(host)
  const isTest = test.test(host)

  return isProd ? 'prod' : isUat ? 'uat' : isTest ? 'test' : 'dev'
}
