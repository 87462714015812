/**
 * Adding feature flag below
 * feature flag key and value MUST be same
 */
export enum Flags {
  sharedPlatform = 'sharedPlatform',
  chainManagePeople = 'chainManagePeople',
  chainJobListing = 'chainJobListing',
  digitalOnlyClub = 'digitalOnlyClub',
  paymentDeeplink = 'paymentDeeplink',
  trainingAndAssessmentInitial = 'trainingAndAssessmentInitial',
  trainingAndAssessmentInitialTrainer = 'trainingAndAssessmentInitialTrainer',
  eventsBookAndPayInitial = 'eventsBookAndPayInitial',
  eventsBookAndPayClub = 'eventsBookAndPayClub',
}

export type FlagsProps = Record<Flags, boolean>
