import { PropsWithChildren } from 'react'

import AppSkeleton from 'src/components/pageSkeletons/App'
import useClubTerms, { PrivacyAgreementStatus } from 'src/hooks/useClubTerms'
import ClubPrivacyAgreement from 'src/components/clubPrivacyAgreement/ClubPrivacyAgreement'
import ClubPrivacyAgreementError from 'src/components/clubPrivacyAgreement/ClubPrivacyAgreementError'

const ClubTermsGuard = ({ children }: PropsWithChildren<{}>) => {
  const {
    data: { status, documentToSign },
    operations: { signDocument, retryGetAgreement },
  } = useClubTerms()

  switch (status) {
    case PrivacyAgreementStatus.Loading:
      return <AppSkeleton />
    case PrivacyAgreementStatus.SigningRequired:
    case PrivacyAgreementStatus.SigningError:
    case PrivacyAgreementStatus.SigningInProgress:
      return (
        <ClubPrivacyAgreement
          document={documentToSign!}
          signAgreement={signDocument}
          loading={status === PrivacyAgreementStatus.SigningInProgress}
          error={status === PrivacyAgreementStatus.SigningError}
        />
      )
    case PrivacyAgreementStatus.FetchAgreementError:
    case PrivacyAgreementStatus.FetchAgreementErrorRetrying:
      return (
        <ClubPrivacyAgreementError
          retry={retryGetAgreement}
          isRetrying={
            status === PrivacyAgreementStatus.FetchAgreementErrorRetrying
          }
        />
      )
    case PrivacyAgreementStatus.NotApplicable:
    case PrivacyAgreementStatus.SignedUnverified:
    case PrivacyAgreementStatus.Valid:
    default:
      return <>{children}</>
  }
}

export default ClubTermsGuard
