import React from 'react'
import styled, { css } from 'styled-components'
import {
  Box,
  Toolbar,
  IconButton,
  AppBar as MuiAppBar,
  useScrollTrigger,
  Slide,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import { useLocation } from 'react-router-dom'
import useMediaQueries from 'src/hooks/useMediaQueries'
import useAppState from 'src/hooks/useAppState'
import { OpenMenuAction } from 'src/contexts/AppState'
import Notifications from 'src/components/notifications'
import Illustration, { LogoIcon } from 'src/components/dataDisplay/Illustration'
import { ClubPaths, InstructorPaths } from 'src/routes'
import { ContentContainerId } from 'src/components/layout/AuthorizedAppLayout'
import SharedPlatformNavigationContainer from 'src/modules/SharedPlatformNavigation/SharedPlatformNavigationContainer'

export const APPBAR_HEIGHT = 64

const StyledAppBar = styled(MuiAppBar)<{
  $isMobileLayout?: boolean
  $isDashboard?: boolean
}>(
  ({ theme, $isMobileLayout, $isDashboard }) => css`
    height: ${APPBAR_HEIGHT}px;
    background-color: ${theme.palette.brand.black};
    transition: height 0.5s, background-color 0.5s;

    ${!$isMobileLayout &&
    css`
      height: unset;
      background-color: transparent;
      position: relative;
      margin-bottom: ${$isDashboard ? APPBAR_HEIGHT : 0}px;
    `}
  `
)

const StyledIllustration = styled(Illustration)(
  ({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      & img {
        height: 100%;
      }
    }
  `
)

const StyledToolbar = styled(Toolbar)(
  ({ theme }) => css`
    display: flex;
    margin: auto 0;
    justify-content: space-between;
    ${theme.breakpoints.up('md')} {
      position: absolute;
      top: 0;
      width: 100%;
      background-color: transparent;
      height: 0;
    }
  `
)

const MenuButton = styled(IconButton)(
  ({ theme }) => css`
    color: ${theme.palette.brand.white};
    margin-right: ${theme.spacing(2.625)}px;
    height: 24px;
    width: 24px;
    padding: 0;
  `
)

const AppBar: React.FC = () => {
  const { isExtraSmallScreen: isMobile, isSmallScreen: isMobileLayout } =
    useMediaQueries()

  const {
    dispatch,
    state: { featureFlags },
  } = useAppState()
  const handleMenuButtonClick = () => {
    dispatch(OpenMenuAction())
  }
  const location = useLocation()

  const pathname = location.pathname

  const isDashboard =
    pathname === ClubPaths.Dashboard || pathname === InstructorPaths.Dashboard

  const trigger = useScrollTrigger({
    target: document.getElementById(ContentContainerId) || undefined,
  })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <StyledAppBar
        color="transparent"
        elevation={0}
        $isMobileLayout={isMobileLayout}
        $isDashboard={isDashboard}
      >
        <StyledToolbar>
          <Box display="flex" alignItems="center">
            {isMobileLayout && (
              <MenuButton aria-label="menu" onClick={handleMenuButtonClick}>
                <MenuIcon />
              </MenuButton>
            )}
            <StyledIllustration
              height={isMobileLayout ? 31 : 'auto'}
              type={
                isDashboard && !isMobileLayout
                  ? LogoIcon.LesMillsBlack
                  : LogoIcon.LesMillsWhite
              }
              data-qa-id="LogoIcon"
              ml={!isMobile ? 1 : 0}
            />
          </Box>
          <Box
            mr={isMobileLayout ? 2 : 3}
            display="flex"
            height="100%"
            alignItems="center"
          >
            {featureFlags?.sharedPlatform && (
              <SharedPlatformNavigationContainer isDashboard={isDashboard} />
            )}
            <Notifications isDashboard={isDashboard} />
          </Box>
        </StyledToolbar>
      </StyledAppBar>
    </Slide>
  )
}

export default AppBar
