import React, { useEffect, useRef, useState } from 'react'
import styled, { css, StyledProps } from 'styled-components'

import { Box, Typography } from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'

import useMediaQueries from 'src/hooks/useMediaQueries'
import AppBar, { APPBAR_HEIGHT } from 'src/components/layout/AppBar'
import ErrorBoundary from 'src/utils/ErrorBoundary'
import Navigation from 'src/modules/_core/Navigation'
import AdminBanner from 'src/components/admin/AdminBanner'
import useUser from 'src/hooks/useUser'
import ScrollToTopButton from 'src/components/inputs/ScrollToTopButton'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Main = styled.main`
  display: flex;
  flex: 1;
  overflow: auto;
`

const NavigationContainer = styled.div<{
  $isMobileLayout?: boolean
}>(
  ({ $isMobileLayout }) => css`
    transition: width 0.5s;
    overflow-x: hidden;
    ${$isMobileLayout
      ? css`
          width: 0;
        `
      : css`
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
        `}
  `
)

const ContentContainer = styled.div<{
  $isMobileLayout?: boolean
}>(
  ({ $isMobileLayout }) => css`
    flex: 1;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 0;
    padding-left: ${$isMobileLayout ? 0 : '80px'};
    scroll-behavior: smooth;
  `
)

const MainWrapper = styled(Box)(
  ({ $isMobileLayout }: StyledProps<{ $isMobileLayout?: boolean }>) => css`
    padding-top: ${$isMobileLayout ? APPBAR_HEIGHT : 0}px;
  `
)

// TODO: Placeholder - no designs exist
const FallbackBox = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.background.grey};
  `
)

const NavFallback = (
  <FallbackBox>
    <ReportProblemIcon color="error" />
    <Typography color="error">Error loading navigation.</Typography>
  </FallbackBox>
)
type Props = {
  id: string
  isMobileLayout: boolean
}
const ScrollToTopWrapper: React.FC<Props> = ({
  children,
  id,
  isMobileLayout,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [scrollButtonVisible, setScrollButtonVisible] = useState(false)

  const handleScroll = () => {
    if (ref.current) {
      if (ref.current.scrollTop > 348) {
        if (!scrollButtonVisible) setScrollButtonVisible(true)
      } else {
        if (scrollButtonVisible) setScrollButtonVisible(false)
      }
    }
  }

  useEffect(() => {
    const temp = ref.current
    if (temp) temp.addEventListener('scroll', handleScroll)
    if (temp) return () => temp.removeEventListener('scroll', handleScroll)
  })
  return (
    <ContentContainer id={id} $isMobileLayout={isMobileLayout} ref={ref}>
      {
        <ScrollToTopButton
          isVisible={scrollButtonVisible}
          isMobileLayout={isMobileLayout}
        />
      }
      {children}
    </ContentContainer>
  )
}
// Since our layout is driven by the Main and the ContentContaineer
// we need reference for the content container for example to handle the scroll
export const ContentContainerId = 'ContentView'

const AuthorizedAppLayout: React.FC = ({ children }) => {
  const { isSmallScreen: isMobileLayout } = useMediaQueries()
  const {
    data: { user },
  } = useUser()

  return (
    <Container>
      <AdminBanner />
      <Main>
        <NavigationContainer $isMobileLayout={isMobileLayout}>
          <ErrorBoundary fallbackComponent={NavFallback}>
            {user && <Navigation />}
          </ErrorBoundary>
        </NavigationContainer>
        <ScrollToTopWrapper
          id={ContentContainerId}
          isMobileLayout={isMobileLayout}
        >
          <AppBar />
          <MainWrapper $isMobileLayout={isMobileLayout}>{children}</MainWrapper>
        </ScrollToTopWrapper>
      </Main>
    </Container>
  )
}

export default AuthorizedAppLayout
