import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import LoadingSpinner from 'src/assets/images/gifs/loadingSpinnerLmi.gif'
import { useTranslation } from 'react-i18next/'

const ImageFrame = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: -96px;
`

const DefaultPage = () => {
  const { t } = useTranslation()
  return (
    <Box display="flex" width="100%" height="100%" overflow="hidden">
      <Box flex={1} height="100%" width="100%" maxWidth="100%" overflow="auto">
        <ImageFrame>
          <img src={LoadingSpinner} alt={t('Global.loading')} />
        </ImageFrame>
      </Box>
    </Box>
  )
}

export default DefaultPage
