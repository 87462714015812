import { useQuery } from 'react-query'
import {
  InitApplicationDocument,
  InitApplicationQuery,
} from 'src/generated/graphql-react-query'
import { useEffect, useMemo } from 'react'
import { isEqual } from 'lodash'
import { useGraphQLClientRequest } from 'src/hooks/useGraphQLClientRequest'

import useAppState from 'src/hooks/useAppState'
import {
  InitialDataLoaded,
  SetCurrentFlags,
  UserHasUnsupportedMarket,
} from 'src/contexts/AppState'
import { isErrorResult } from 'src/utils/typeGuards'
import { isUnsupportedMarket } from 'src/utils/helpers'
import useFeatureFlag from 'src/hooks/featureFlag/useFeatureFlag'

const useInitApplication = () => {
  const { state, dispatch } = useAppState()

  const {
    data: { featureFlagsToQuery },
    operations: { getFeatureFlags },
  } = useFeatureFlag()

  const request = useGraphQLClientRequest()

  const { data, isSuccess, refetch, isFetching } = useQuery<
    InitApplicationQuery | undefined
  >(
    'init-application',
    () =>
      request(InitApplicationDocument, {
        featureFlags: featureFlagsToQuery,
      }),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !state.initialDataLoaded,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )

  useEffect(() => {
    if (isSuccess && !state.initialDataLoaded) {
      dispatch(InitialDataLoaded())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, state.initialDataLoaded])

  useEffect(() => {
    if (
      isSuccess &&
      data?.getFeatureFlag &&
      !isErrorResult(data?.getFeatureFlag)
    ) {
      const featureFlags = getFeatureFlags(data?.getFeatureFlag)
      featureFlags &&
        !isEqual(featureFlags, state.featureFlags) &&
        dispatch(SetCurrentFlags(featureFlags))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data])

  const appFailedToInitialise = useMemo(
    () =>
      isErrorResult(data?.getUser) || isErrorResult(data?.getAllTranslations),
    [data]
  )

  useEffect(() => {
    if (
      isSuccess &&
      !isErrorResult(data?.getUser) &&
      isUnsupportedMarket(data?.getUser?.user)
    ) {
      dispatch(UserHasUnsupportedMarket())
    }
  }, [isSuccess, data, dispatch])

  return {
    data,
    refetch,
    isFetching,
    appInitialised: state.initialDataLoaded && state.i18nLoaded,
    appFailedToInitialise,
  }
}

export default useInitApplication
