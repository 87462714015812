import React from 'react'
import { useHistory } from 'react-router-dom'
import useMediaQueries from 'src/hooks/useMediaQueries'
import NavBar from 'src/components/navigation/NavBar'
import NavBarSmall from 'src/components/navigation/NavBarSmall'
import useNavigation from 'src/hooks/useNavigation'
import { EventCategory, EventAction } from 'src/contexts/Analytics'
import useAnalytics from 'src/hooks/useAnalytics'
import { RootMenuItem, MenuItem } from 'src/generated/graphql'

const Navigation: React.FC = () => {
  const {
    data: { appMenu, loading, error },
  } = useNavigation()

  const history = useHistory()
  const analytics = useAnalytics()

  const { isSmallScreen: useSmallNavBar } = useMediaQueries()
  // TODO: Show error toast message once we have designs

  const trackAnalytics = (item: RootMenuItem | MenuItem) => {
    if (item.analyticsEvent != null) {
      analytics.sendEvent(
        item.analyticsEvent,
        EventCategory.Button,
        EventAction.Click,
        {
          destinationUrl: item.url?.value,
          originUrl: history.location.pathname,
        }
      )
    }
  }

  if (error) {
    return null
  }

  return (
    <>
      <NavBar
        data={appMenu}
        loading={loading}
        disabled={useSmallNavBar}
        data-qa-id="DesktopNavBar"
        trackAnalytics={trackAnalytics}
      />
      <NavBarSmall
        data={appMenu}
        loading={loading}
        disabled={!useSmallNavBar}
        data-qa-id="DeviceNavBar"
        trackAnalytics={trackAnalytics}
      />
    </>
  )
}

export default Navigation
