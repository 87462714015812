import { useContext } from 'react'

import { InitDataContext } from 'src/contexts/InitData'

const useUser = () => {
  const {
    user: userResult,
    updateUser,
    isChainUser,
  } = useContext(InitDataContext)!
  const user = userResult?.user ?? null

  return {
    data: {
      id: userResult?.id ?? null,
      settings: userResult?.settings ?? null,
      fullUser: userResult,
      user,
      isChainUser,
    },
    operations: {
      updateUser,
    },
  }
}

export default useUser
