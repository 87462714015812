import {
  useContext,
  createContext,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react'
import useInitApplication from 'src/hooks/useInitApplication'
import {
  FullUserFieldFragment,
  GetAllTranslationsResult,
  UserProfileType,
} from 'src/generated/graphql-react-query'
import { isErrorResult } from 'src/utils/typeGuards'
import { filterProfilesByProfileType } from 'src/utils/helpers'

import { UserProfile } from 'src/hooks/useProfile'

type UserResult = FullUserFieldFragment | null
type TranslationsResult = GetAllTranslationsResult | null

export type InitData = {
  user: UserResult
  translations: TranslationsResult
  updateUser: (user: Partial<UserResult>) => void
  hasUserSeenOnboardingModal: boolean // TODO: remove this values when LCS-333 is ready
  setHasUserSeenOnboardingModal: (value: boolean) => void // TODO: remove this values when LCS-333 is ready
  isChainUser?: boolean
}

export const InitDataContext = createContext<InitData | null>(null)

export const InitDataProvider = ({ children }: PropsWithChildren<{}>) => {
  const { data } = useInitApplication()
  const [user, setUser] = useState<UserResult>(null)
  const [isChainUser, setIsChainUser] = useState<boolean | undefined>(undefined)
  const [translations, setTranslations] = useState<TranslationsResult>(null)
  const [hasUserSeenOnboardingModal, setHasUserSeenOnboardingModal] =
    useState<boolean>(true) // TODO: get this value when LCS-333 is ready

  useEffect(() => {
    if (data && !isErrorResult(data?.getUser)) {
      const clubProfiles = filterProfilesByProfileType(
        data?.getUser?.profiles as UserProfile[],
        UserProfileType.Club
      )
      setUser(data.getUser ?? null)
      setIsChainUser(clubProfiles.length > 1)
    }

    if (
      data?.getAllTranslations &&
      !isErrorResult(data?.getAllTranslations) &&
      data.getAllTranslations?.result
    ) {
      const result =
        typeof data.getAllTranslations.result === 'string'
          ? JSON.parse(data.getAllTranslations.result)
          : data.getAllTranslations.result
      setTranslations({ ...data.getAllTranslations, result } ?? null)
    }
  }, [data])

  const updateUser = (data: Partial<UserResult>) => {
    setUser(user ? { ...user, ...data } : user)
  }

  const value: InitData = {
    user,
    translations,
    updateUser,
    hasUserSeenOnboardingModal, // TODO: remove this values when LCS-333 is ready
    setHasUserSeenOnboardingModal, // TODO: remove this values when LCS-333 is ready
    isChainUser,
  }

  return (
    <InitDataContext.Provider value={value}>
      {children}
    </InitDataContext.Provider>
  )
}

export const useInitData = () => {
  const context = useContext(InitDataContext)
  if (context === undefined) {
    throw new Error(`useInitData must be used within a InitDataProvider`)
  }
  return context
}
