import React, { useRef, useState, useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import {
  Box,
  Typography,
  ClickAwayListener,
  Slide,
  Popper as MuiPopper,
} from '@material-ui/core'
import { ReactComponent as LesMillsNavIcon } from 'src/assets/images/icons/lesMillsNavigation.svg'

import ErrorBoundary from 'src/utils/ErrorBoundary'

import { UserProfile, UrlType, Url, Maybe } from 'src/generated/graphql'

import useMediaQueries from 'src/hooks/useMediaQueries'
import Button from 'src/components/button/Button'

import Link from 'src/components/navigation/Link'
import NavigationHeader, {
  NavigationHeaderProps,
} from 'src/modules/SharedPlatformNavigation/NavigationHeader'
import { SharedPlatformNavigationContainerProps } from 'src/modules/SharedPlatformNavigation/SharedPlatformNavigationContainer'

import NavigationItem, {
  NavigationItemProps,
} from 'src/modules/SharedPlatformNavigation/NavigationItem'
import NavigationItemSkeleton from 'src/modules/SharedPlatformNavigation/NavigationItemSkeleton'
import NavigationErrorResult from 'src/modules/SharedPlatformNavigation/NavigationErrorResult'

import useAppState from 'src/hooks/useAppState'
import { SetIsNotificationOpen } from 'src/contexts/AppState'
import { ClubPaths, InstructorPaths } from 'src/routes'
import { isClubProfile } from 'src/utils/typeGuards'

const NavigationContainer = styled(Box)`
  max-width: 872px;
  display: flex;
  flex-direction: column;
`

const HeaderContainer = styled(Box)`
  width: 100%;
`

// Body has full control over width container
export const BodyContainer = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(4)}px;
    background: ${theme.palette.brand.white};
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(2)}px;
    justify-content: center;
    & > :only-child {
      max-width: 460px;
    }
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      padding: ${theme.spacing(3)}px;
      gap: 0;
      & > :only-child {
        max-width: 100%;
      }
      & > :first-child {
        margin-bottom: ${theme.spacing(4)}px;
      }
    }
  `
)

const FooterContainer = styled(Box)(
  ({ theme }) => css`
    color: ${theme.palette.brand.black};
    font-size: ${theme.typography.pxToRem(12)};
    flex: 0 0 auto;
    font-weight: normal;
    border-top: 1px solid ${theme.palette.brand.gray};
    ${theme.breakpoints.down('sm')} {
      text-align: center;
    }
  `
)

export const ProductSection = styled(Box)(
  ({ theme }) => css`
    width: 100vw;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    & > :not(:last-child) {
      margin-bottom: ${theme.spacing(1)}px;
    }
  `
)

const Popper = styled(MuiPopper)`
  display: flex;
`

const sharedStyle = css`
  overflow-y: auto;
  border-radius: 4px;
  background: ${({ theme }) => theme.palette.brand.white};
`
const DesktopNavigationWrapper = styled(Box)(
  ({ theme }) => css`
    ${sharedStyle}
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid ${theme.palette.brand.gray};
    margin-top: ${theme.spacing(2)}px;
  `
)

const MobileNavigationWrapper = styled(Box)`
  ${sharedStyle}
  width: 100vw;
  height: 100vh;
  margin-top: -5px;
`

const ActionLink = styled(Link)(
  ({ theme }) => css`
    font-weight: ${theme.typography.fontWeightBold};
  `
)

const StyledNavButton = styled(Button)<{
  $isDashboard?: boolean
}>(
  ({ theme, $isDashboard }) => css`
    color: ${$isDashboard
      ? theme.palette.brand.black
      : theme.palette.brand.white};
    padding: ${theme.spacing(2)}px;
    border-radius: ${theme.spacing(8)}px;

    :hover {
      background-color: ${$isDashboard
        ? 'rgba(0, 0, 0, 0.04)'
        : 'rgba(0, 0, 0, 0.4)'};
    }

    & svg {
      stroke: currentColor;
      fill: currentColor;
    }
    ${theme.breakpoints.down('sm')} {
      color: ${theme.palette.brand.white};
    }
  `
)

interface NavigationPortalProps {
  isMobileLayout?: boolean
  anchorEl: Element | HTMLElement
  open: boolean
  onCloseRequest: () => void
}

const NavigationPortal: React.FC<NavigationPortalProps> = ({
  anchorEl,
  isMobileLayout,
  onCloseRequest,
  children,
  open,
}) => {
  return createPortal(
    <>
      <ClickAwayListener onClickAway={onCloseRequest}>
        <Popper anchorEl={anchorEl} open={open}>
          {isMobileLayout ? (
            <Slide in={open}>
              <MobileNavigationWrapper>{children}</MobileNavigationWrapper>
            </Slide>
          ) : (
            <DesktopNavigationWrapper>{children}</DesktopNavigationWrapper>
          )}
        </Popper>
      </ClickAwayListener>
    </>,
    anchorEl?.parentElement || document.body
  )
}

export interface SharedPlatformNavigationDialogProps
  extends SharedPlatformNavigationContainerProps {
  headerContent: NavigationHeaderProps
  trackAnalytics?: () => void
  bodyContent: {
    instructorItems: Maybe<NavigationItemProps>[]
    clubItems: Maybe<NavigationItemProps>[]
    isLoading: boolean
    isRefetching: boolean
    isError: boolean
    handleReload?: () => void
    handleAnalyticsEvent?: ({
      itemId,
      itemTitle,
    }: {
      itemId?: string
      itemTitle?: string
    }) => void
  }
  footerContent: {
    websiteLink?: Url
  }
}

const SharedPlatformNavigationDialog: React.FC<SharedPlatformNavigationDialogProps> =
  ({
    isDashboard,
    headerContent,
    trackAnalytics,
    bodyContent: {
      instructorItems,
      clubItems,
      isLoading,
      isRefetching,
      isError,
      handleReload,
      handleAnalyticsEvent,
    },
    footerContent: { websiteLink },
  }) => {
    const [showNavigationContent, setShowNavigationContent] = useState(false)
    const {
      dispatch,
      state: { isNotificationOpen },
    } = useAppState()

    const { isSmallScreen: isMobileLayout } = useMediaQueries()
    const { t } = useTranslation()

    const iconButtonRef = useRef() as React.MutableRefObject<HTMLElement>
    const cornerRef = useRef() as React.MutableRefObject<HTMLElement>

    const handleCloseNavigationPortal = useCallback(() => {
      if (showNavigationContent) {
        dispatch(SetIsNotificationOpen(false))
        setShowNavigationContent(false)
      }
    }, [dispatch, showNavigationContent])

    const handleIconButtonClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.preventDefault()
      event.stopPropagation()
      if (showNavigationContent && !isNotificationOpen) {
        handleCloseNavigationPortal()
      } else {
        trackAnalytics?.()
        dispatch(SetIsNotificationOpen(false))
        setShowNavigationContent(true)
      }
    }

    const handleOnClickItem = useCallback(
      (item?: NavigationItemProps | null) =>
        handleAnalyticsEvent?.({
          itemId: item?.key ?? '',
          itemTitle: item?.title ?? '',
        }),
      [handleAnalyticsEvent]
    )

    const linkToAccountSupport = useMemo(
      () =>
        isClubProfile(headerContent?.activeProfile as UserProfile)
          ? ClubPaths.Support
          : InstructorPaths.Support,
      [headerContent.activeProfile]
    )

    const loadingBodyContentSkeleton = useMemo(
      () => (
        <ProductSection>
          {new Array(4).fill(null).map((_, index) => (
            <NavigationItemSkeleton key={index} />
          ))}
        </ProductSection>
      ),
      []
    )

    const userHasMultipleAccounts = useMemo(
      () =>
        !!headerContent.instructorProfile &&
        !!headerContent.clubProfiles?.length,
      [headerContent.clubProfiles?.length, headerContent.instructorProfile]
    )

    // Render Instructor items only if user logged in is dual account or instructor is activeProfile
    const renderedInstructorItems = useMemo(() => {
      return (
        (userHasMultipleAccounts ||
          (!isClubProfile(headerContent?.activeProfile as UserProfile) &&
            !!instructorItems.length)) && (
          <ProductSection>
            <Typography color="textSecondary">
              {t('PlatformNavigation.myAccount')}
            </Typography>
            {instructorItems.map(item => (
              <NavigationItem
                key={item?.key}
                title={item?.title}
                description={item?.description || ''}
                url={item?.url}
                image={item?.image}
                onClick={() => handleOnClickItem(item)}
              />
            ))}
          </ProductSection>
        )
      )
    }, [
      userHasMultipleAccounts,
      headerContent?.activeProfile,
      instructorItems,
      t,
      handleOnClickItem,
    ])

    // Render Club items only if user logged in is dual account or club is activeProfile
    const renderedClubItems = useMemo(() => {
      return (
        (userHasMultipleAccounts ||
          (isClubProfile(headerContent?.activeProfile as UserProfile) &&
            !!clubItems.length)) && (
          <ProductSection>
            <Typography color="textSecondary">
              {t('PlatformNavigation.clubAccount')}
            </Typography>
            {clubItems.map(item => (
              <NavigationItem
                key={item?.key}
                title={item?.title}
                description={item?.description || ''}
                url={item?.url}
                image={item?.image}
                onClick={() => handleOnClickItem(item)}
              />
            ))}
          </ProductSection>
        )
      )
    }, [
      userHasMultipleAccounts,
      headerContent?.activeProfile,
      clubItems,
      t,
      handleOnClickItem,
    ])

    // Mobile: logged in as Club ? Club items appears first : Instructor items appears first
    const renderedItemsBodyContent = useMemo(() => {
      if (isMobileLayout) {
        return isClubProfile(headerContent?.activeProfile as UserProfile) ? (
          <>
            {renderedClubItems}
            {renderedInstructorItems}
          </>
        ) : (
          <>
            {renderedInstructorItems}
            {renderedClubItems}
          </>
        )
      } else {
        return (
          <>
            {renderedInstructorItems}
            {renderedClubItems}
          </>
        )
      }
    }, [
      headerContent?.activeProfile,
      isMobileLayout,
      renderedClubItems,
      renderedInstructorItems,
    ])

    const showSharedNavPortal = useMemo(
      () => showNavigationContent && !isNotificationOpen,
      [isNotificationOpen, showNavigationContent]
    )

    return (
      <>
        <Box
          style={{ position: 'fixed', top: '0px', left: '0px' }}
          {...{ ref: cornerRef }}
        />

        <StyledNavButton
          innerRef={iconButtonRef}
          variant="text"
          onClick={handleIconButtonClick}
          endIcon={<LesMillsNavIcon />}
          $isDashboard={isDashboard}
        >
          {!isMobileLayout && t('PlatformNavigation.buttonNavigation')}
        </StyledNavButton>

        <NavigationPortal
          anchorEl={isMobileLayout ? cornerRef.current : iconButtonRef.current}
          isMobileLayout={isMobileLayout}
          onCloseRequest={handleCloseNavigationPortal}
          open={showSharedNavPortal}
        >
          {/* UI Modal Body */}
          <NavigationContainer>
            <HeaderContainer>
              <NavigationHeader
                {...headerContent}
                onCloseDialog={handleCloseNavigationPortal}
              />
            </HeaderContainer>

            <BodyContainer>
              {isLoading ? (
                loadingBodyContentSkeleton
              ) : isError ? (
                <NavigationErrorResult
                  isLoading={isRefetching}
                  onClick={handleReload}
                />
              ) : (
                renderedItemsBodyContent
              )}
            </BodyContainer>

            <FooterContainer>
              <Box
                py={3}
                mx={4}
                textAlign={userHasMultipleAccounts ? 'end' : 'center'}
              >
                <Trans
                  i18nKey="PlatformNavigation.footer"
                  components={[
                    <ActionLink
                      url={{
                        value: websiteLink?.value || '',
                        type: UrlType.External,
                      }}
                    />,
                    <ActionLink
                      onClick={handleCloseNavigationPortal}
                      url={{
                        value: linkToAccountSupport,
                        type: UrlType.Internal,
                      }}
                    />,
                  ]}
                />
              </Box>
            </FooterContainer>
          </NavigationContainer>
        </NavigationPortal>
      </>
    )
  }

const SharedPlatformNavigationDialogWithErrorBoundary: React.FC<SharedPlatformNavigationDialogProps> =
  props => (
    <ErrorBoundary>
      <SharedPlatformNavigationDialog {...props} />
    </ErrorBoundary>
  )

export default SharedPlatformNavigationDialogWithErrorBoundary
