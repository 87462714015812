import React from 'react'
import styled, { css } from 'styled-components'

import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import { Container } from 'src/modules/SharedPlatformNavigation/NavigationItem'

const StyledContainer = styled(Container)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.brand.gray};
    padding: ${theme.spacing(3)}px;
  `
)

const NavigationItemSkeleton: React.FC = () => (
  <StyledContainer>
    <Box display="flex" width="100%">
      <Skeleton
        variant="rect"
        width="100%"
        height={40}
        style={{ maxWidth: '270px' }} /* as per design */
      />
    </Box>
  </StyledContainer>
)

export default React.memo(NavigationItemSkeleton)
